import { useChargeCards } from '@queries/use-credit-cards';
import { CreditCard } from '@services/flexbase/card.model';
import { useChargeCardsFilters } from 'areas/cards/credit-cards/credit-cards-header/use-credit-cards-filters';
import { ChargeCardsTableHeader } from './charge-cards-table-header';
import DebitCardDetails from 'areas/banking/debit-cards/debit-card-details/debit-card-details';
import { CardByUser } from '@services/flexbase/banking.model';
import { CardsTable } from 'areas/cards/credit-cards/cards-table';

export const ChargeCardsTable = () => {
  const filterHook = useChargeCardsFilters();
  const searchTerm = filterHook.getFilterByKey('search')?.filterValue;

  const dataQuery = useChargeCards({
    full: true,
    searchTerm,
  });

  const getTableHeader = (cards: CreditCard[]) => {
    return <ChargeCardsTableHeader cardsData={cards} />;
  };

  const getDetailsModal = (card: CreditCard) => {
    return <DebitCardDetails card={card as unknown as CardByUser} />;
  };

  return (
    <CardsTable
      dataQuery={dataQuery}
      filterHook={filterHook}
      getTableHeader={getTableHeader}
      getDetailsModal={getDetailsModal}
    />
  );
};
