import { useStyles } from '../credit-transactions.styles';
import { DateTime } from 'luxon';
import { Avatar, Text } from '@mantine/core';
import { formatCurrency } from '@utilities/formatters';
import { ArrowRightIconGreen } from 'assets/svg';
import { CreditTransactionsTableRow } from '../credit-transactions-table-helpers';
import { useActiveExpenseLink } from '@utilities/integrations/accounting';

type TransactionDetailProps = {
  transaction: CreditTransactionsTableRow;
  showBottomBorder: boolean;
  onClick: (transction: CreditTransactionsTableRow) => void;
};

const TransactionDetailRow = ({
  transaction,
  showBottomBorder,
  onClick,
}: TransactionDetailProps) => {
  const { classes } = useStyles();
  const { expenseLink } = useActiveExpenseLink();

  const dateString = DateTime.fromSQL(transaction.date).toLocaleString(
    DateTime.DATE_SHORT,
  );

  return (
    <>
      <div
        className={classes.transactionRow}
        onClick={() => onClick(transaction)}
      >
        {transaction.logoUrl ? (
          <img
            src={transaction.logoUrl}
            className={classes.storeLogo}
            alt={transaction.toFrom}
          />
        ) : (
          <Avatar
            radius="md"
            className={classes.storeIcon}
            style={{ minWidth: 'unset' }}
          >
            {transaction.toFrom?.charAt(0) || '$'}
          </Avatar>
        )}

        <div>
          <div className={classes.text}>{transaction.toFrom}</div>
          <div className={classes.text} style={{ fontSize: '12px' }}>
            {transaction.type}{' '}
            <Text fw="bold" span mx={'.25em'}>
              ·
            </Text>{' '}
            {dateString}
          </div>

          {expenseLink?.enabledExpenses ? (
            <div
              className={classes.text}
              style={{ color: '#808080', fontSize: '12px' }}
            >
              {transaction.selectedCategory?.name || 'No category'}
            </div>
          ) : null}
        </div>

        <div style={{ marginLeft: 'auto' }}>
          <div
            className={classes.text}
            style={{
              fontSize: '16px',
              color: +transaction.amount < 0 ? 'green' : '',
            }}
          >
            {+transaction.amount < 0 && '+ '}
            {formatCurrency(Math.abs(+transaction.amount))}
            <span style={{ marginLeft: '8px' }}>
              <ArrowRightIconGreen />
            </span>
          </div>
        </div>
      </div>
      {showBottomBorder && (
        <div
          style={{
            marginLeft: '8px',
            borderBottom: '1px solid #E6E7E9',
          }}
        ></div>
      )}
    </>
  );
};

export default TransactionDetailRow;
