import { createContext, ReactNode, useContext, useEffect } from 'react';
import { useGetOnboardingStatus } from '../queries/use-get-onboarding-status';
import { LoadingOverlay } from '@mantine/core';
import { useSetRecoilState } from 'recoil';
import { _ApplicationState } from '../recoil-state/application/product-onboarding.models';
import { ApplicationState } from '../recoil-state/application/product-onboarding';

type OnboardingStatusContextReturnType = {
  status: _ApplicationState;
  refreshStatus: () => void;
};

const OnboardingStatusContext =
  createContext<OnboardingStatusContextReturnType | null>(null);

export function OnboardingStatusProvider({
  children,
}: {
  children: ReactNode;
}) {
  const setApplicationState = useSetRecoilState(ApplicationState);
  const { data, isLoading, refetch } = useGetOnboardingStatus(false);

  useEffect(() => {
    if (data) {
      setApplicationState(data);
    }
  }, [data]);

  if (!data) {
    return isLoading ? <LoadingOverlay visible={true} /> : false;
  }

  return (
    <OnboardingStatusContext.Provider
      value={{ status: data, refreshStatus: refetch }}
    >
      {children}
    </OnboardingStatusContext.Provider>
  );
}

export const useOnboardingStatusContext =
  (): OnboardingStatusContextReturnType => {
    const context = useContext(OnboardingStatusContext);

    if (context === null) {
      throw new Error(
        'useOnboardingStatusContext must be used within OnboardingStatusProvider',
      );
    }

    return context;
  };
