import { useForm } from '@mantine/form';
import { useCreateWizard } from '../create-wizard';
import { TextInput } from '@mantine/core';
import { RequiredFieldValidator } from '@utilities/validators/validate-required';
import { CustomWizardStepLayout } from '../wizard-step-layout';

export const StepOne = () => {
  const { state, getState, setState, goToNextStep, onNext } = useCreateWizard();
  const form = useForm({
    initialValues: {
      someStateValue: state.someStateValue,
    },
    validate: {
      someStateValue: RequiredFieldValidator(),
    },
  });

  const updateCounter = () => {
    let currentState = state;

    // eslint-disable-next-line
    console.log(`start state: ${currentState.counter}`);

    // update the state
    setState((prev) => ({
      ...prev,
      counter: prev.counter + 1,
    }));

    // state.counter is still the value from the old render
    // eslint-disable-next-line
    console.log(`stale state: ${state.counter}`);

    // use getState() to fetch the current state within the render
    currentState = getState();
    // eslint-disable-next-line
    console.log(`fresh state: ${currentState.counter}`);
  };

  onNext(
    form.onSubmit(({ someStateValue }) => {
      updateCounter();

      goToNextStep({
        someStateValue,
      });
    }),
  );

  return (
    <CustomWizardStepLayout hideBack>
      <p>step one content</p>

      <TextInput
        label="Some value"
        required
        {...form.getInputProps('someStateValue')}
      />
    </CustomWizardStepLayout>
  );
};

StepOne.stepId = 'step-one';
