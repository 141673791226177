import { Stack, Text, useMantineTheme } from '@mantine/core';
import { Dropzone, FileRejection, FileWithPath } from '@mantine/dropzone';
import { useState } from 'react';
import { TbUpload } from 'react-icons/tb';
import { useInvoiceWizard } from '../../invoice-wizard';
import { useCreateInvoiceDocument } from '@queries/use-documents';

const ACCEPTABLE_UPLOAD_TYPES = ['image/png', 'image/jpeg', 'application/pdf'];
const MAX_SIZE = 10 * 1024 * 1024; // 10 MB in bytes

const useRejectionErrors = () => {
  // yanked from @mantine/dropzone, which uses react-dropzone under the hood
  enum ErrorCode {
    FileInvalidType = 'file-invalid-type',
    FileTooLarge = 'file-too-large',
    FileTooSmall = 'file-too-small',
    TooManyFiles = 'too-many-files',
  }

  const errorCodeToMessage: Record<ErrorCode, string> = {
    [ErrorCode.FileInvalidType]: 'File type is not supported',
    [ErrorCode.FileTooLarge]: 'File is too large',
    [ErrorCode.FileTooSmall]: 'File is too small',
    [ErrorCode.TooManyFiles]: 'Too many files selected',
  };

  const [rejectErrors, setRejectErrors] = useState<string[]>([]);

  const handleReject = (rejections: FileRejection[]) => {
    const errorMessages = rejections.map((r) => {
      const errorCode = r.errors[0].code as ErrorCode;
      const name = r.file.name;
      return `${name}: ${errorCodeToMessage[errorCode]}`;
    });

    setRejectErrors(errorMessages);
  };

  const clearRejectErrors = () => {
    setRejectErrors([]);
  };

  return { rejectErrors, handleReject, clearRejectErrors };
};

type Props = {
  onUpload: (file: FileWithPath[]) => void;
};

const InvoiceUpload = ({ onUpload }: Props) => {
  const theme = useMantineTheme();
  const { state, setState, onNext, goToNextStep, onEvent } = useInvoiceWizard();
  const { isInvoiceDraft, isActionDisabled } = state;
  const {
    mutate: mutateCreateInvoiceDocument,
    mutateAsync: mutateCreateInvoiceDocumentAsync,
    error: createInvoiceDocumentError,
  } = useCreateInvoiceDocument();
  const { rejectErrors, handleReject, clearRejectErrors } =
    useRejectionErrors();

  const handleDrop = (file: FileWithPath[]) => {
    onUpload(file);
    clearRejectErrors();
  };

  onNext(() => {
    if (state.uploadedDocument && !state.existingDocumentId) {
      mutateCreateInvoiceDocument(
        {
          file: state.uploadedDocument,
          type: 'billpay',
        },
        {
          onSuccess: (data) => {
            setState((prev) => ({
              ...prev,
              existingDocumentId: data.id,
            }));
            goToNextStep();
          },
        },
      );
    }
    // if we already have an existing documentId (no-reupload), go to the next step
    if (state.existingDocument && state.existingDocumentId) {
      goToNextStep();
    }
  });

  onEvent('onSaveEdits', async () => {
    if (state.uploadedDocument && !state.existingDocumentId) {
      try {
        const data = await mutateCreateInvoiceDocumentAsync({
          file: state.uploadedDocument,
          type: 'billpay',
        });
        setState((prev) => ({
          ...prev,
          existingDocumentId: data.id,
        }));
        return {
          existingDocumentId: data.id,
        };
      } catch (err) {
        return false;
      }
    }
  });

  return (
    <>
      <Dropzone
        disabled={!isInvoiceDraft || isActionDisabled}
        onDrop={handleDrop}
        onReject={handleReject}
        maxSize={MAX_SIZE}
        maxFiles={1}
        accept={ACCEPTABLE_UPLOAD_TYPES}
        my={'lg'}
      >
        <Stack spacing="md" align="center" my={'xl'} mx={'md'}>
          <TbUpload size={20} color={theme.colors.neutral[5]} />
          <Text fw={600}>Drag &amp; drop or click to choose a file</Text>
          <Text size="xs" color="dimmed">
            PNG, JPG, and PDF accepted (Size limit: 10mb)
          </Text>
        </Stack>
      </Dropzone>

      {rejectErrors.length > 0 &&
        rejectErrors.map((error, index) => (
          <Text
            size="xs"
            color={theme.colors.red[6]}
            mt={index > 0 ? undefined : 'md'}
            key={`${index}-${error}`}
          >
            {error}
          </Text>
        ))}
      {createInvoiceDocumentError && (
        <Text size="xs" color={theme.colors.red[6]}>
          Error uploading document: {createInvoiceDocumentError.message}
        </Text>
      )}
    </>
  );
};

export default InvoiceUpload;
