import { Button, Group, LoadingOverlay, Text } from '@mantine/core';
import { PlusSignIcon } from 'assets/svg';
import {
  onError,
  usePlaidBankingComponent,
} from '@utilities/custom-hooks/plaid-banking-component';
import { useState } from 'react';
import { useStyles } from '../styles';
import CashBalanceWidget from '../components/cash-balance.widget';
import { ProgressPercent } from '@flexbase-eng/web-components';
import MoveFundsWidget from '../components/move-funds.widget';
import Risk from '../risk';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  loadTreasuryInfo,
  TreasuryManagementState,
} from '../treasury-management.state';
import TableContainerWithSearchFilter from 'components/table/table-container';
import FlexAccountsTable from '../components/treasury-tables/flex-accounts-table';
import { showNotification } from '@mantine/notifications';

const TreasuryDashboard = () => {
  const { classes } = useStyles();
  const [loading, setLoading] = useState(false);
  // If more fine-grained control needed, use const [x, setX] = useRecoilStateLoadable and switch on x.state
  const [{ bankSnapshots, flexTreasuryAccounts }, setTreasuryMgmtState] =
    useRecoilState(TreasuryManagementState);

  const { totalAvailableBalance, uninsuredCapitalBalance } = useRecoilValue(
    TreasuryManagementState,
  );
  const insuredAmount = totalAvailableBalance - uninsuredCapitalBalance;
  // TODO: Investigate why Plaid component won't open locally. Has ready: false status
  const { open } = usePlaidBankingComponent({
    onSuccess: () => onLinkBank(),
    onError,
    setLoading,
  });

  const onLinkBank = async () => {
    showNotification({
      color: 'flexbase-teal',
      title: 'Bank Account Linked!',
      message: 'Calculating treasury accounts with new external account',
    });
    try {
      setLoading(true);
      const newState = await loadTreasuryInfo();

      setTreasuryMgmtState(newState);
    } catch (error) {
      showNotification({
        color: 'red',
        title: 'Error',
        message: 'Unable to refresh linked bank account info',
      });
    } finally {
      setLoading(false);
    }
  };

  const downloadStatement = () => {
    window.open(`/accounts/risk-statement`);
  };

  return (
    <div>
      {/* TODO Make loading UI better*/}
      <LoadingOverlay visible={loading} />
      <Group>
        <CashBalanceWidget />
        <ProgressPercent
          title="Total Insured Capital"
          content="% insured"
          currentAmount={insuredAmount}
          totalAvailableBalance={totalAvailableBalance}
        />
        <MoveFundsWidget />
      </Group>
      <div className={classes.spacingTop}>
        <TableContainerWithSearchFilter
          title={
            <Text color="#5F5F5F" weight={500} size={16}>
              Flex Accounts
            </Text>
          }
        >
          <FlexAccountsTable
            loading={loading}
            accounts={flexTreasuryAccounts}
          ></FlexAccountsTable>
        </TableContainerWithSearchFilter>
      </div>
      <div className={classes.spacingTop}>
        <Risk bankSnapshots={bankSnapshots} onLinkBankClick={() => open()} />
      </div>
      <Group mb={30}>
        <Button
          data-testid="button-link-accounts"
          variant="outline"
          size="lg"
          leftIcon={<PlusSignIcon />}
          classNames={{
            root: classes.buttonRoot,
            label: classes.buttonLabel,
            inner: classes.buttonInner,
          }}
          onClick={() => {
            open();
          }}
        >
          Link external account
        </Button>
        <Button
          variant="outline"
          size="lg"
          leftIcon={<PlusSignIcon />}
          classNames={{
            root: classes.buttonRoot,
            label: classes.buttonLabel,
            inner: classes.buttonInner,
          }}
          onClick={() => {
            downloadStatement();
          }}
        >
          Download Capital Diversification Report
        </Button>
      </Group>
    </div>
  );
};

export default TreasuryDashboard;
