import { DepositAccount } from '../../services/flexbase/banking.model';
import getPaddedAccountMask from './get-padded-account-mask';

export const formatInitials = (name: string): string => {
  const initials =
    name
      ?.split(' ')
      ?.reduce(
        (prev: string, curr: string) => prev + curr[0]?.toUpperCase(),
        '',
      ) || '';

  if (initials.length > 2) {
    return `${initials.charAt(0)}${initials.charAt(initials.length - 1)}`;
  }

  return initials;
};

export const capitalizeOnlyFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);
};

export const formatNumberStringWithDecimals = (str: string) => {
  let val = str.replace(/[^\d]/g, '');
  if (val.length < 2) {
    val = `0.0${val}`;
  } else if (val.length === 2) {
    val = `0.${val}`;
  } else {
    val = val.slice(0, val.length - 2) + '.' + val.slice(-2);
  }
  return val;
};
/**
 * Takes a string and returns a numeric string with a maximum of one decimal point
 * @param input
 */
export const formatNumberInputString = (input: string) => {
  if (input.length === 0) {
    return '';
  } else {
    const cleanedString = input.replace(/[^\d.]/g, (match, offset) => {
      return offset === 0 && match === '.' ? match : '';
    });

    const [integerPart, decimalPart] = cleanedString.split('.');

    return decimalPart !== undefined
      ? `${integerPart}.${decimalPart}`
      : integerPart;
  }
};

export const formatDepositAccountName = (
  accountData: DepositAccount | DepositAccount[],
  accountId?: string,
) => {
  let account = accountData as DepositAccount;

  if (Array.isArray(accountData) && accountId) {
    account = accountData.find((acc) => acc.id === accountId) as DepositAccount;
  }

  const nickName = account?.nickName;
  const name = account?.name;
  const accountName = nickName || name || 'Deposit Account';

  return accountName;
};

export const formatDepositAccountDetails = (
  accountData: DepositAccount | DepositAccount[],
  accountId?: string,
) => {
  let account = accountData as DepositAccount;

  if (Array.isArray(accountData) && accountId) {
    account = accountData.find((acc) => acc.id === accountId) as DepositAccount;
  }

  const accountName = formatDepositAccountName(accountData, accountId);
  const accountNumber = account?.accountNumber ?? '';
  const maskedAccountNumber = getPaddedAccountMask(accountNumber, 3);

  return `${accountName} ${maskedAccountNumber}`;
};
