import { createStyles } from '@mantine/core';

export const useBillPayStyles = createStyles((theme) => ({
  card: {
    position: 'relative',
    padding: theme.spacing.xl,
    borderRadius: theme.defaultRadius,
    color: theme.fn.themeColor('neutral', 1),
    backgroundColor: theme.fn.themeColor('neutral', 0),
    border: `1px solid ${theme.fn.themeColor('neutral', 1)}`,
  },
}));
