import { useCreateWizard } from '../create-wizard';
import { CustomWizardStepLayout } from '../wizard-step-layout';

export const StepTwo = () => {
  const { onBack, onNext, goToNextStep, goToPreviousStep } = useCreateWizard();

  onBack(() => {
    goToPreviousStep();
  });

  onNext(() => {
    goToNextStep();
  });

  return (
    <CustomWizardStepLayout>
      <p>step two content</p>
    </CustomWizardStepLayout>
  );
};

StepTwo.stepId = 'step-two';
