import { useQuery } from '@tanstack/react-query';
import { getProductOnboardingStatus } from '../recoil-state/application/product-onboarding';

const QUERY_KEY = 'GET_ONBOARDING_STATUS';

export const useGetOnboardingStatus = (full = true) => {
  return useQuery({
    queryKey: [QUERY_KEY, full],
    queryFn: () => getProductOnboardingStatus(full),
  });
};
