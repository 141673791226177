import { useRecoilValue } from 'recoil';
import { useStartScreenStyles } from '../styles';
import { CreditBankingForm } from './credit-banking-form';
import { RegistrationProductsState } from '../../../../../recoil-state/application/product-onboarding';
import { isEqual } from 'underscore';

export const CreditBankingContentLg = () => {
  const { classes } = useStartScreenStyles();
  const selectedProduct = useRecoilValue(RegistrationProductsState);

  return (
    <div style={{ marginTop: 'auto' }}>
      <div className={classes.contentContainer}>
        <div className={classes.gridLayout}>
          <div className={classes.formContainer}>
            <CreditBankingForm />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-end',
            }}
          >
            <img
              src={
                isEqual(selectedProduct, ['BANKING'])
                  ? '/images/front-debit-card.png'
                  : '/images/img.png'
              }
              className={classes.image}
              alt="Flex"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
