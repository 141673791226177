import { Select, Text, TextInput } from '@mantine/core';
import CurrencySelect from 'areas/payments/components/send-payment/components/currency-select';
import {
  countrySelectOptions,
  isInternationalWire,
} from 'areas/payments/components/send-payment/international-payments/util';
import { InternationalRecipientForm } from 'areas/payments/components/send-payment/international-payments/util/types';

type Props = {
  form: InternationalRecipientForm;
};

const InitialRequirementsForm = ({ form }: Props) => {
  return (
    <form>
      <Text size="md" mt="2rem" fw="600">
        Payment information
      </Text>

      {isInternationalWire(form.values.type) && (
        <CurrencySelect mt={'md'} {...form.getInputProps('currency')} />
      )}

      <Select
        mt={'md'}
        label="Country of recipient bank"
        placeholder="Enter country of the recipient's bank"
        data={countrySelectOptions}
        searchable
        {...form.getInputProps('countryOfRecipientsBank')}
      />

      <TextInput
        mt={'md'}
        label={'Account nickname'}
        placeholder={'Enter account nickname'}
        c={'neutral.8'}
        {...form.getInputProps('nickName')}
      />

      <TextInput
        mt={'md'}
        label={'Account holder'}
        placeholder={'Enter account holder'}
        c={'neutral.8'}
        {...form.getInputProps('accountHolder')}
      />

      <Text size="md" mt="2rem" fw="600">
        Recipient information
      </Text>

      <Select
        mt={'md'}
        label="Recipient country"
        placeholder="Enter country of the recipient"
        data={countrySelectOptions}
        searchable
        {...form.getInputProps('countryOfRecipient')}
      />
    </form>
  );
};

export default InitialRequirementsForm;
