import { flexbaseOnboardingClient } from '../services/flexbase-client';
import { useQuery } from '@tanstack/react-query';

const QUERY_KEY = 'get_document_image_url';

async function getDocument(docId: string) {
  return await flexbaseOnboardingClient.downloadDocument(docId);
}

export function useGetDocument(docId: string) {
  return useQuery({
    queryKey: [QUERY_KEY, docId],
    queryFn: () => getDocument(docId),
    staleTime: Infinity,
    enabled: !!docId,
  });
}
