import {
  Button,
  Group,
  NumberInput,
  Select,
  Stack,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';

type SampleAccount = {
  id: string;
  nickName: string;
  accountNumber: string;
  routingNumber: string;
  accountType: string;
  type: string;
};

const getSelectOptions = (accounts?: SampleAccount[]) => {
  if (!accounts) {
    return [];
  }

  return accounts?.map((acct) => ({
    value: acct.id,
    label: `BANKING - ${acct.nickName} - ending in ${acct.accountNumber?.slice(
      -4,
    )}`,
  }));
};

const sampleAccountsFromApi = [
  {
    id: '1',
    nickName: 'Nick Name',
    accountNumber: '123456789',
    routingNumber: '123456789',
    accountType: 'checking',
    type: 'depositAccount',
  },
  {
    id: '2',
    nickName: 'Nick Name Too',
    accountNumber: '8675309',
    routingNumber: '123456789',
    accountType: 'checking',
    type: 'depositAccount',
  },
];

const Form = () => {
  const form = useForm({
    initialValues: {
      amount: 0,
      reason: '',
      depositAccount: '',
    },
    validate: {
      reason: (value) => (value ? null : 'Reason is required'),
      depositAccount: (value) => (value ? null : 'Deposit account is required'),
    },
  });

  const depositAccounts = getSelectOptions(sampleAccountsFromApi);

  const handleSubmit = (values: typeof form.values) => {
    console.error(values);
  };

  const handleReset = () => {
    form.reset();
  };

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Stack>
        <NumberInput
          label="Amount"
          placeholder="Enter amount"
          min={0}
          {...form.getInputProps('amount')}
        />

        <TextInput
          label="Reason"
          placeholder="Enter reason"
          {...form.getInputProps('reason')}
        />

        <Select
          label="Deposit account"
          placeholder="Select deposit account"
          data={depositAccounts}
          {...form.getInputProps('depositAccount')}
        />

        <Group>
          <Button type="submit">Save</Button>
          <Button variant="outline" onClick={handleReset}>
            Cancel
          </Button>
        </Group>
      </Stack>
    </form>
  );
};

export default Form;
