import { BeneficiaryOrCounterparty } from '@utilities/custom-hooks/use-beneficiaries';
import { useIntlPaymentsFeatureFlag } from '@utilities/feature-flags';
import CounterpartyAccountDetails from '../counterparty/details';
import { omit } from 'underscore';
import { PayMethod } from '../send-payment/payment.states';
import { Recipient } from 'types/recipient';
import { BeneficiaryResponse } from '../send-payment/international-payments/util/types';

const flattenPaymentMethods = (recipient: Recipient) => {
  const addTypeToBeneficiaryResponse = (
    beneficiaries: BeneficiaryResponse[],
    type: PayMethod,
  ) =>
    beneficiaries.map((beneficiary) => ({
      ...beneficiary,
      type,
    }));

  return [
    ...(recipient.wire ?? []),
    ...(recipient.ach ?? []),
    ...addTypeToBeneficiaryResponse(
      recipient.internationalWire ?? [],
      'internationalWire',
    ),
    ...addTypeToBeneficiaryResponse(
      recipient.internationalWireUsd ?? [],
      'internationalWireUSD',
    ),
  ];
};

// placeholder fn for native Object.groupBy() until we upgrade TS to latest
const groupBy = (arr: any[], key: string) => {
  return arr.reduce((acc, item) => {
    (acc[item[key]] = acc[item[key]] || []).push(item);
    return acc;
  }, {});
};

type GroupedRecipientsByType = Record<PayMethod, BeneficiaryOrCounterparty[]>;

type Props = {
  recipient: Recipient;
};

const ViewRecipientModal = ({ recipient }: Props) => {
  const intlPaymentsFeatureFlagIsOn = useIntlPaymentsFeatureFlag();
  const flattenedRecipients = flattenPaymentMethods(recipient);
  const groupedData: GroupedRecipientsByType = groupBy(
    flattenedRecipients,
    'type',
  );
  const filteredGroupedData = intlPaymentsFeatureFlagIsOn
    ? groupedData
    : omit(groupedData, 'internationalWire', 'internationalWireUSD');

  return (
    <>
      {Object.keys(filteredGroupedData).map((key, i) => (
        <CounterpartyAccountDetails
          key={`${key}-${i}`}
          type={key as PayMethod}
          counterparties={groupedData[key as PayMethod]}
          recipientType={recipient.type}
        />
      ))}
    </>
  );
};

export default ViewRecipientModal;
