import { useForm } from '@mantine/form';
import { Button } from '@mantine/core';

import {
  AccountProps,
  DepositAccount,
  PlaidAccount,
} from '../move-funds/move-funds.model';
import { useStyles } from './add-funds';
import { ArrowsHorizontalIcon } from 'assets/svg';
import { PaymentForm } from 'types/payments';
import AccountSelection from 'areas/payments/components/account-selection';
import FlexNumberInput from '../../payments/components/common/flex-number-input';

type Props = {
  transferLoading: boolean;
  plaidAccounts: PlaidAccount[];
  depositAccounts: DepositAccount[];
  onMakeTransfer: (values: PaymentForm) => void;
};

const TransferFundsSection = ({
  plaidAccounts,
  onMakeTransfer,
  depositAccounts,
  transferLoading,
}: Props) => {
  const transferForm = useForm({
    initialValues: {
      amount: 0,
      toAccount: depositAccounts[0],
      fromAccount: plaidAccounts[0],
    },
  });

  const { classes, theme } = useStyles({
    amount: transferForm.values.amount ?? 0,
  });

  const selectAccount = (value: AccountProps, accountType: string) => {
    transferForm.setValues({
      ...transferForm.values,
      [`${accountType}Account`]: value,
    });
  };

  const transferFrom = (value: AccountProps) => {
    selectAccount(value, 'from');
  };

  const transferTo = (value: AccountProps) => {
    selectAccount(value, 'to');
  };

  return (
    <div>
      <FlexNumberInput
        thousandSeparator
        decimalScale={2}
        allowNegative={false}
        prefix="$"
        placeholder="$0"
        mb={20}
        variant="unstyled"
        data-testid="amount"
        error={transferForm.errors.amount}
        classNames={{ input: classes.amountInput }}
        value={transferForm.values.amount}
        onValueChange={(v) =>
          transferForm.setFieldValue('amount', v.floatValue ?? 0)
        }
        c={transferForm.values.amount === 0 ? '#C4C6C8' : '#000'}
      />

      <div className={classes.accountsSection}>
        <span className={classes.label}>Transferring from</span>
        <AccountSelection
          currentAccount={transferForm.values.fromAccount}
          accounts={plaidAccounts}
          onAccountChange={(c) => {
            transferFrom(c);
          }}
        />
      </div>

      <div className={classes.accountsSection}>
        <span className={classes.label}>Transferring to</span>
        <AccountSelection
          currentAccount={transferForm.values.toAccount}
          accounts={depositAccounts}
          onAccountChange={(c) => {
            transferTo(c);
          }}
        />
      </div>

      <div className={classes.rowContent}>
        <div className={classes.disclaimer}>
          {`By clicking Transfer, I authorize Flex to initiate the transaction detailed above. Flex is powered by Flexbase.`}
        </div>
        <Button
          variant="light"
          loading={transferLoading}
          onClick={() => onMakeTransfer(transferForm.values)}
          leftIcon={
            <ArrowsHorizontalIcon
              width={20}
              fill={theme.fn.themeColor('neutral', 0)}
            />
          }
        >
          Transfer
        </Button>
      </div>
    </div>
  );
};

export default TransferFundsSection;
