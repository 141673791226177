import { Box, Collapse, Divider, SelectItem, rem } from '@mantine/core';
import {
  AccountingSettingsExpensesSyncPreferencesSyncPreferenceEnum,
  ExpenseLink,
} from '@flexbase-eng/types/dist/accounting';
import { IntegrationsSettingsLayout } from './integration-settings.layout';
import { IntegrationSettingsSection } from './integration-settings.section';
import { SettingsRow } from './integration-settings.settings-row';
import { useForm } from '@mantine/form';
import { ConditionalFieldValidator, RequiredFieldValidator } from '@utilities/validators';
import { notifications } from '@mantine/notifications';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import {
  isQuickbooksBankFeeds,
  isQuickbooksExpenses,
  useIntegrationLinks,
} from '@utilities/integrations';
import { IntuitBankFeeds } from './intuit-bank-feeds';
import { IntegrationCardItem } from '../list-integrations';
import {
  useCreateIntuitFeedConnection,
  useUpdateAccountingSettings,
} from '@queries/use-integrations';
import { useDisconnectIntegration } from '../utils/use-disconnect-integration';

type IntegrationSettingsProps = {
  integration: IntegrationCardItem;
  expenseLink: ExpenseLink;
};

type DefaultIntegrationFormValues = {
  enabledCreditFeeds: boolean;
  enabledExpenses: boolean;
  enabledBankFeeds: boolean;
  enabledAccountsPayable: boolean;
  generalLedgerId: string | undefined;
  accountsPayableLedgerId: string | undefined;
  autosync: boolean;
  defaultCategoryId: string | undefined;
};

type SettingsConfig = {
  credit:
    | {
        showCreditFeeds: boolean;
        showGeneralLedger: boolean;
        showAccountMapping: boolean;
        showExpenseCategorization: boolean;
      }
    | undefined;
  banking:
    | {
        showBankFeeds: boolean;
      }
    | undefined;
  accountsPayable:
    | {
        showAccountsPayable: boolean;
        showGeneralLedger: boolean;
      }
    | undefined;
  intuitFeeds:
    | {
        showBankFeeds: boolean;
      }
    | undefined;
};

function getSettingsConfig(expenseLink: ExpenseLink): SettingsConfig {
  const isQuickBooks = isQuickbooksExpenses(expenseLink);

  const platformConfig = {
    credit: {
      showGeneralLedger: true,
      showAccountMapping: true,
      showCreditFeeds: expenseLink.enabledCreditFeeds !== undefined,
      showExpenseCategorization: expenseLink.enabledExpenses !== undefined,
    },
    banking: {
      showBankFeeds:
        !isQuickBooks && expenseLink.enabledBankFeeds !== undefined,
    },
    intuitFeeds: {
      showBankFeeds: isQuickBooks,
    },
    accountsPayable: {
      showAccountsPayable: expenseLink.enabledAccountsPayable !== undefined,
      showGeneralLedger: true,
    },
  } satisfies SettingsConfig;

  const hasCreditSettings = Object.values(platformConfig.credit).some(Boolean);
  const hasBankingSettings = Object.values(platformConfig.banking).some(
    Boolean,
  );
  const hasAccountsPayableSettings = Object.values(
    platformConfig.accountsPayable,
  ).some(Boolean);
  const hasIntuitFeedsSettings = Object.values(platformConfig.intuitFeeds).some(
    Boolean,
  );

  return {
    credit: hasCreditSettings ? platformConfig.credit : undefined,
    banking: hasBankingSettings ? platformConfig.banking : undefined,
    accountsPayable: hasAccountsPayableSettings
      ? platformConfig.accountsPayable
      : undefined,
    intuitFeeds: hasIntuitFeedsSettings
      ? platformConfig.intuitFeeds
      : undefined,
  };
}

export const IntegrationSettings = ({
  integration,
  expenseLink,
}: IntegrationSettingsProps) => {
  const navigate = useNavigate();
  const { intuitFeedsLink } = useIntegrationLinks();
  const { mutate: createFeedConnection, isPending: isCreateFeedPending } =
    useCreateIntuitFeedConnection();
  const { mutate: saveAccountingSettings, isPending: isSavingSettings } =
    useUpdateAccountingSettings();
  const { disconnectIntegration, isPending: isDisconnecting } =
    useDisconnectIntegration();

  const form = useForm<DefaultIntegrationFormValues>({
    initialValues: {
      enabledCreditFeeds: !!expenseLink.enabledCreditFeeds,
      enabledExpenses: !!expenseLink.enabledExpenses,
      generalLedgerId: expenseLink.generalLedgers?.find(
        (l) => l.defaultGeneralLedger,
      )?.id,
      accountsPayableLedgerId: expenseLink.accountsPayableLedgers?.find(
        (l) => l.defaultGeneralLedger,
      )?.id,
      autosync: expenseLink.syncPreferences === 'autoSync',
      defaultCategoryId: expenseLink.accounts?.find((a) => a.defaultCategory)
        ?.id,
      enabledBankFeeds: !!expenseLink.enabledBankFeeds,
      enabledAccountsPayable: !!expenseLink.enabledAccountsPayable,
    },
    validate: {
      generalLedgerId: RequiredFieldValidator(),
      defaultCategoryId: (val, formValues) => {
        return ConditionalFieldValidator(
          formValues.enabledExpenses && formValues.autosync,
        )(val);
      },
    },
  });

  // not part of form because it's unrelated to the overall form save state
  const [intuitBankFeedsEnabled, setIntuitBankFeedsEnabled] = useState(
    !!intuitFeedsLink?.enabledBankFeeds,
  );

  const platformLabel = integration.title;

  const settingsConfig = getSettingsConfig(expenseLink);

  const generalLedgerSelectItems =
    expenseLink.generalLedgers?.map<SelectItem>((ledger) => {
      return {
        label: ledger.name,
        value: ledger.id,
      };
    }) || [];

  const accountsPayableLedgerSelectItems =
    expenseLink.accountsPayableLedgers?.map<SelectItem>((ledger) => {
      return {
        label: ledger.name,
        value: ledger.id,
      };
    }) || [];

  const categorySelectItems =
    expenseLink.accounts?.map<SelectItem>((c) => {
      return {
        label: c.displayName || c.name,
        value: c.id,
      };
    }) || [];

  const handleConnectBankFeed = () => {
    setIntuitBankFeedsEnabled(true);

    createFeedConnection(undefined, {
      onError: () => {
        setIntuitBankFeedsEnabled(false);
        notifications.show({
          color: 'critical.2',
          message: 'An unexpected error occurred while enabling feeds.',
        });
      },
    });
  };

  const handleIntuitBankFeedChange = async (toEnabled: boolean) => {
    if (isCreateFeedPending || isSavingSettings || !intuitFeedsLink) {
      return;
    }

    saveAccountingSettings(
      {
        connectionId: intuitFeedsLink.connectionId,
        request: {
          bankFeeds: {
            enabled: toEnabled,
          },
        },
      },
      {
        onSuccess: () => {
          setIntuitBankFeedsEnabled(toEnabled);
        },
        onError: () => {
          const enableDisable = toEnabled ? 'enabling' : 'disabling';

          notifications.show({
            color: 'critical.2',
            message: `An unexpected error occurred while ${enableDisable} bank feeds.`,
          });
        },
      },
    );
  };

  const handleDisconnect = () => {
    if (isQuickbooksBankFeeds(expenseLink)) {
      handleIntuitBankFeedChange(false);
    } else {
      disconnectIntegration({
        platformLabel: integration.title,
        connectionId: expenseLink.connectionId,
        onSuccess: () => {
          navigate('/settings/integrations', {
            replace: true,
          });
        },
        onConfirmed: () => {
          handleIntuitBankFeedChange(false);
        },
      });
    }
  };

  const handleSave = form.onSubmit((formValues) => {
    const syncPreference = formValues.autosync
      ? AccountingSettingsExpensesSyncPreferencesSyncPreferenceEnum.AutoSync
      : AccountingSettingsExpensesSyncPreferencesSyncPreferenceEnum.AutoCategorization;
    const categoryId = formValues.autosync
      ? formValues.defaultCategoryId
      : undefined;

    saveAccountingSettings(
      {
        connectionId: expenseLink.connectionId,
        request: {
          expenses: settingsConfig.credit?.showExpenseCategorization
            ? {
                enabled: formValues.enabledExpenses,
                generalLedger: formValues.generalLedgerId || undefined, // some customers are having this send null instead of undefined for some reason
                syncPreferences: {
                  syncPreference,
                  accountId: categoryId,
                },
              }
            : undefined,
          creditFeeds: settingsConfig.credit?.showCreditFeeds
            ? {
                enabled: formValues.enabledCreditFeeds,
              }
            : undefined,
          bankFeeds: settingsConfig.banking?.showBankFeeds
            ? {
                enabled: formValues.enabledBankFeeds,
              }
            : undefined,
          accountsPayable: settingsConfig.accountsPayable?.showAccountsPayable
            ? {
                enabled: formValues.enabledAccountsPayable,
                generalLedger: formValues.accountsPayableLedgerId || undefined,
              }
            : undefined,
        },
      },
      {
        onSuccess: () => {
          form.resetDirty();
          notifications.show({
            color: 'primarySecondarySuccess.2',
            message: 'Saved successfully.',
          });
        },
        onError: (e) => {
          console.error(`Unable to save preferences:`, e);
          notifications.show({
            color: 'critical.2',
            message: 'An unexpected error occurred while saving.',
          });
        },
      },
    );
  });

  return (
    <IntegrationsSettingsLayout
      form={form}
      platformLabel={platformLabel}
      saving={isSavingSettings}
      disconnecting={isDisconnecting}
      onSave={handleSave}
      onDisconnect={handleDisconnect}
    >
      {settingsConfig.credit ? (
        <IntegrationSettingsSection label="Credit transactions">
          {settingsConfig.credit.showCreditFeeds ? (
            <>
              <SettingsRow.Switch
                label="Sync credit card transactions to banking feed"
                description={`Enables expenses to be synced to ${platformLabel}.`}
                switchProps={form.getInputProps('enabledCreditFeeds', {
                  type: 'checkbox',
                })}
              />

              <Divider />
            </>
          ) : null}

          {settingsConfig.credit.showGeneralLedger ? (
            <>
              <SettingsRow
                label="Select general ledger account for your Flex credit card account"
                description="Choose the general ledger account you want transaction data from."
              >
                <SettingsRow.SelectList
                  label="Credit card account name"
                  selectProps={{
                    data: generalLedgerSelectItems,
                    searchable: true,
                    ...form.getInputProps('generalLedgerId'),
                  }}
                />
              </SettingsRow>

              <Divider />
            </>
          ) : null}

          {settingsConfig.credit.showExpenseCategorization ? (
            <>
              <SettingsRow.Switch
                label="Enable categorization in Flex"
                description={`This enables transactions to be categorized using categories from ${platformLabel}.`}
                switchProps={form.getInputProps('enabledExpenses', {
                  type: 'checkbox',
                })}
              >
                <SettingsRow.Switch
                  label="Auto-categorize"
                  description="Enables all transactions to use a default accounting category."
                  switchProps={form.getInputProps('autosync', {
                    type: 'checkbox',
                  })}
                  disabled={!form.values.enabledExpenses}
                />

                {/* Collapse animation is janky if the parent is flex, so wrap with Box */}
                <Box>
                  <Collapse in={form.values.autosync}>
                    <Box mt={rem(10)}>
                      <SettingsRow.SelectList
                        label="Default category"
                        selectProps={{
                          data: categorySelectItems,
                          ...form.getInputProps('defaultCategoryId'),
                        }}
                        disabled={!form.values.enabledExpenses}
                      />
                    </Box>
                  </Collapse>
                </Box>
              </SettingsRow.Switch>

              <Divider />
            </>
          ) : null}

          {settingsConfig.credit.showAccountMapping ? (
            <>
              <SettingsRow.Button
                label="Account mapping"
                description="Customize how your chart of accounts appear in Flex."
                buttonLabel="Map accounts"
                buttonProps={{
                  variant: 'outline',
                  onClick: () => {
                    if (expenseLink.connectionId) {
                      navigate(
                        `/settings/integrations/mappings?connectionId=${expenseLink.connectionId}`,
                      );
                    }
                  },
                }}
              />

              <Divider />
            </>
          ) : null}
        </IntegrationSettingsSection>
      ) : null}

      {settingsConfig.banking ? (
        <IntegrationSettingsSection label="Banking transactions">
          {settingsConfig.banking.showBankFeeds ? (
            <>
              <SettingsRow.Switch
                label="Sync to banking feed"
                description="Sync all transactions to your banking feed. Categorized transactions may then sync to your expense record."
                switchProps={form.getInputProps('enabledBankFeeds', {
                  type: 'checkbox',
                })}
              />

              <Divider />
            </>
          ) : null}
        </IntegrationSettingsSection>
      ) : null}

      {settingsConfig.accountsPayable ? (
        <IntegrationSettingsSection label="Bill Pay">
          {settingsConfig.accountsPayable.showAccountsPayable ? (
            <>
              <SettingsRow.Switch
                label="Sync bills, bill payments and receipts"
                description="Send scheduled bills and bill payments to your accounting solution."
                switchProps={form.getInputProps('enabledAccountsPayable', {
                  type: 'checkbox',
                })}
              />

              <Divider />
            </>
          ) : null}
          {settingsConfig.accountsPayable.showAccountsPayable ? (
            <>
              <SettingsRow
                label="Select general ledger account for your bills"
                description="Choose the general ledger account you want all bills and bill payment to be synced to"
              >
                <SettingsRow.SelectList
                  label="Accounts payable name"
                  disabled={!form.getInputProps('enabledAccountsPayable').value}
                  selectProps={{
                    data: accountsPayableLedgerSelectItems,
                    searchable: true,
                    ...form.getInputProps('accountsPayableLedgerId'),
                  }}
                />
              </SettingsRow>

              <Divider />
            </>
          ) : null}
        </IntegrationSettingsSection>
      ) : null}

      {settingsConfig.intuitFeeds ? (
        <IntegrationSettingsSection label="Banking transactions">
          <>
            {intuitFeedsLink ? (
              <SettingsRow.Switch
                label="Enable bank feeds"
                switchProps={{
                  checked: intuitBankFeedsEnabled,
                  onChange: (e) => handleIntuitBankFeedChange(e.target.checked),
                }}
              >
                <Collapse in={intuitBankFeedsEnabled}>
                  <IntuitBankFeeds link={intuitFeedsLink} />
                </Collapse>
              </SettingsRow.Switch>
            ) : (
              <SettingsRow.Button
                label="Bank feeds"
                description="Integrate your Flex line of credit and banking accounts to QBO bank feeds."
                buttonLabel="Enable"
                buttonProps={{
                  variant: 'outline',
                  loading: isCreateFeedPending,
                  onClick: handleConnectBankFeed,
                }}
              />
            )}

            <Divider />
          </>
        </IntegrationSettingsSection>
      ) : null}
    </IntegrationsSettingsLayout>
  );
};
