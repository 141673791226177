import { filterDirectionToLabel } from '@common/filter/amount-filter';
import { formatCurrency } from '@utilities/formatters';
import { QuantityFilter } from '@common/filter/quantity-filter';
import { useBillpayInvoicesModalFilters } from '../use-billpay-bills-filters';

export const InvoiceAmountFilter = () => {
  const { addFilter, getFilterByKey } = useBillpayInvoicesModalFilters();

  return (
    <QuantityFilter
      value={
        getFilterByKey('amount')?.filterValue || {
          amount: '',
          direction: 'equal',
        }
      }
      onChange={(val) => {
        const amountInCents = Math.round(parseFloat(val.amount) * 100);

        addFilter('amount', {
          label: `Amount - ${filterDirectionToLabel(
            val.direction,
          )} ${formatCurrency(val.amount)}`,
          key: 'amount',
          fn: (row) => {
            const amountNum = Math.abs(Number(row.total));
            const filterNum = amountInCents;

            switch (val.direction) {
              case 'greaterThan':
                return amountNum > filterNum;
              case 'lessThan':
                return amountNum < filterNum;
              case 'equal':
              default:
                return amountNum === filterNum;
            }
          },
          filterValue: val,
          showChip: true,
        });
      }}
    />
  );
};
