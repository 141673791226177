import { FileWithPath } from '@mantine/dropzone';
import { InvoiceWizard, useInvoiceWizard } from '../../invoice-wizard';
import InvoiceUpload from './invoice-upload';
import { useEffect } from 'react';

const UploadInvoiceStep = () => {
  const { state, setState } = useInvoiceWizard();
  const handleUpload = (file: FileWithPath[]) => {
    setState((prev) => ({
      ...prev,
      uploadedDocument: file[0],
      existingDocumentId: undefined, // clear out documentId if uploading new invoice
    }));
  };

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      isNextEnabled: !!(state.uploadedDocument || state.existingDocument),
    }));
  }, [state.uploadedDocument]);

  return (
    <InvoiceWizard.Step hideNext hideBack>
      <InvoiceUpload onUpload={handleUpload} />
    </InvoiceWizard.Step>
  );
};

UploadInvoiceStep.stepId = 'upload-invoice-step';

export default UploadInvoiceStep;
