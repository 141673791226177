import { useState, useEffect } from 'react';

export type ExampleData = {
  exampleItems: ExampleItem[];
};

export type ExampleItem = {
  id: string;
  dueDate: string;
  recipientName: string;
  invoiceNumber: string;
  total: number;
  createdAt: string;
  status: ExampleItemStatus;
  last4: string;
};

export enum ExampleItemStatus {
  Requested = 'requested',
  Drafted = 'drafted',
  Approved = 'approved',
  Paid = 'paid',
}

const exampleData: ExampleData = {
  exampleItems: [
    {
      id: '1',
      dueDate: '2024-12-01',
      recipientName: 'John Doe',
      invoiceNumber: 'INV-001',
      total: 50000,
      createdAt: '2023-11-01',
      status: ExampleItemStatus.Requested,
      last4: '1234',
    },
    {
      id: '2',
      dueDate: '2024-12-05',
      recipientName: 'Jane Smith',
      invoiceNumber: 'INV-002',
      total: 75000,
      createdAt: '2023-11-05',
      status: ExampleItemStatus.Drafted,
      last4: '5678',
    },
    {
      id: '3',
      dueDate: '2024-12-10',
      recipientName: 'Alice Johnson',
      invoiceNumber: 'INV-003',
      total: 60000,
      createdAt: '2023-11-10',
      status: ExampleItemStatus.Approved,
      last4: '9101',
    },
    {
      id: '4',
      dueDate: '2024-12-15',
      recipientName: 'Bob Brown',
      invoiceNumber: 'INV-004',
      total: 80000,
      createdAt: '2023-11-15',
      status: ExampleItemStatus.Paid,
      last4: '1121',
    },
    {
      id: '5',
      dueDate: '2024-12-20',
      recipientName: 'Charlie Davis',
      invoiceNumber: 'INV-005',
      total: 45000,
      createdAt: '2023-11-20',
      status: ExampleItemStatus.Requested,
      last4: '3141',
    },
    {
      id: '6',
      dueDate: '2024-12-25',
      recipientName: 'Diana Evans',
      invoiceNumber: 'INV-006',
      total: 70000,
      createdAt: '2023-11-25',
      status: ExampleItemStatus.Drafted,
      last4: '5161',
    },
    {
      id: '7',
      dueDate: '2024-12-30',
      recipientName: 'Evan Foster',
      invoiceNumber: 'INV-007',
      total: 55000,
      createdAt: '2023-11-30',
      status: ExampleItemStatus.Approved,
      last4: '7181',
    },
    {
      id: '8',
      dueDate: '2025-01-01',
      recipientName: 'Fiona Green',
      invoiceNumber: 'INV-008',
      total: 90000,
      createdAt: '2023-12-01',
      status: ExampleItemStatus.Paid,
      last4: '9202',
    },
    {
      id: '9',
      dueDate: '2025-01-05',
      recipientName: 'George Harris',
      invoiceNumber: 'INV-009',
      total: 65000,
      createdAt: '2023-12-05',
      status: ExampleItemStatus.Requested,
      last4: '1222',
    },
    {
      id: '10',
      dueDate: '2025-01-10',
      recipientName: 'Hannah Irving',
      invoiceNumber: 'INV-010',
      total: 75000,
      createdAt: '2023-12-10',
      status: ExampleItemStatus.Drafted,
      last4: '3242',
    },
    {
      id: '11',
      dueDate: '2025-01-15',
      recipientName: 'Ian Jackson',
      invoiceNumber: 'INV-011',
      total: 50000,
      createdAt: '2023-12-15',
      status: ExampleItemStatus.Approved,
      last4: '5262',
    },
    {
      id: '12',
      dueDate: '2025-01-20',
      recipientName: 'Jackie King',
      invoiceNumber: 'INV-012',
      total: 85000,
      createdAt: '2023-12-20',
      status: ExampleItemStatus.Paid,
      last4: '7282',
    },
    {
      id: '13',
      dueDate: '2025-01-25',
      recipientName: 'Kevin Lewis',
      invoiceNumber: 'INV-013',
      total: 60000,
      createdAt: '2023-12-25',
      status: ExampleItemStatus.Requested,
      last4: '9303',
    },
    {
      id: '14',
      dueDate: '2025-01-30',
      recipientName: 'Laura Miller',
      invoiceNumber: 'INV-014',
      total: 70000,
      createdAt: '2023-12-30',
      status: ExampleItemStatus.Drafted,
      last4: '1323',
    },
    {
      id: '15',
      dueDate: '2025-02-01',
      recipientName: 'Michael Nelson',
      invoiceNumber: 'INV-015',
      total: 55000,
      createdAt: '2024-01-01',
      status: ExampleItemStatus.Approved,
      last4: '3343',
    },
    {
      id: '16',
      dueDate: '2025-02-05',
      recipientName: 'Nina Owens',
      invoiceNumber: 'INV-016',
      total: 90000,
      createdAt: '2024-01-05',
      status: ExampleItemStatus.Paid,
      last4: '5363',
    },
    {
      id: '17',
      dueDate: '2025-02-10',
      recipientName: 'Oscar Perry',
      invoiceNumber: 'INV-017',
      total: 65000,
      createdAt: '2024-01-10',
      status: ExampleItemStatus.Requested,
      last4: '7383',
    },
    {
      id: '18',
      dueDate: '2025-02-15',
      recipientName: 'Paula Quinn',
      invoiceNumber: 'INV-018',
      total: 75000,
      createdAt: '2024-01-15',
      status: ExampleItemStatus.Drafted,
      last4: '9404',
    },
    {
      id: '19',
      dueDate: '2025-02-20',
      recipientName: 'Quincy Roberts',
      invoiceNumber: 'INV-019',
      total: 50000,
      createdAt: '2024-01-20',
      status: ExampleItemStatus.Approved,
      last4: '1424',
    },
    {
      id: '20',
      dueDate: '2025-02-25',
      recipientName: 'Rachel Scott',
      invoiceNumber: 'INV-020',
      total: 85000,
      createdAt: '2024-01-25',
      status: ExampleItemStatus.Paid,
      last4: '3444',
    },
  ],
};

export const useExampleData = () => {
  const [data, setData] = useState<ExampleData | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      // Simulate loading time
      await new Promise((resolve) => setTimeout(resolve, 1000));

      // Hardcoded test data
      setData(exampleData);
      setIsLoading(false);
      setIsSuccess(true);
    };

    fetchData();
  }, []);

  return { data, isLoading, isSuccess };
};
