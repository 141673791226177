import { createStyles, Badge } from '@mantine/core';
import { useBillPayPayments } from './use-bill-pay';

const ActionRequiredBadge = () => {
  const { classes } = useStyles();
  const { data } = useBillPayPayments();

  const count = (data.payments ?? []).filter(
    (cc) =>
      cc.status === 'AwaitingApproval' || cc.status === 'AwaitingConfirmation',
  ).length;

  return (
    <Badge
      classNames={{
        inner: classes.badgeInner,
      }}
      bg="primarySecondarySuccess.3"
    >
      {count}
    </Badge>
  );
};

export default ActionRequiredBadge;

const useStyles = createStyles((theme) => ({
  badgeInner: {
    color: theme.fn.themeColor('neutral', 0),
  },
}));
