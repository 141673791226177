import { atom } from 'recoil';

type State = {
  redirectUrl: string;
  shouldRefetchOtp: boolean;
  connectionId: string | null;
  logedByflow: boolean;
  loginDataLoaded: boolean;
};
export const integrationModalState = atom<State>({
  key: 'integrationModalState',
  default: {
    redirectUrl: '',
    shouldRefetchOtp: false,
    connectionId: null,
    logedByflow: false,
    loginDataLoaded: false,
  },
});
