import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Box, Group } from '@mantine/core';
import {
  getYears,
  filterStatementsByYear,
} from 'areas/banking/statements/utils';
import { useGetCreditOrBankingStatements } from '@queries/use-statements';
import { statementsStyles } from '../../cards/statements/styles';
import ChargeCardsStatementsTable from './charge-cards-statements-table';
import { ApplicationState } from 'recoil-state/application/product-onboarding';
import StatementsYearSelection from 'areas/banking/statements/statements-year-selection';

const ChargeCardsStatements = () => {
  const { accountId } = useRecoilValue(ApplicationState);
  const { data, isLoading } = useGetCreditOrBankingStatements(
    accountId,
    'credit',
  );
  const statementsList = data ?? [];

  const [year, setYear] = useState('');
  const { classes } = statementsStyles();
  const listOfYears = getYears(statementsList);
  const chosenYear = year ? year : listOfYears[0];

  const handleYearChange = (yearChoosen: string) => {
    setYear(yearChoosen);
  };

  const statementsByYear = filterStatementsByYear(
    chosenYear,
    statementsList,
  ).reverse();

  return (
    <Box className={classes.container}>
      <Group position="right" mb="xl">
        <StatementsYearSelection
          yearSelected={chosenYear}
          listOfYears={listOfYears}
          onYearChange={handleYearChange}
        />
      </Group>
      <ChargeCardsStatementsTable
        statements={statementsByYear}
        isLoading={isLoading}
      />
    </Box>
  );
};

export default ChargeCardsStatements;
