import TabTableLayout from '@common/layouts/tab-table-layout';
import {
  Badge,
  Button,
  MantineTheme,
  Text,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import GenericError from 'areas/banking/utils/generic-error';
import {
  StarIcon,
  SearchIcon,
  DollarCircleIcon,
  SendIcon,
  TeamsIcon,
  CalendarIcon,
  CardIcon,
} from 'assets/svg';
import FlexbaseInput from 'components/input/flexbase-input';
import { FlexbaseTable } from 'components/table';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getMonthDayYear, sortDate } from 'utilities/dates/dates';
import { formatApiStrings } from 'utilities/formatters/format-api-strings';
import { stripCurrencySymbol } from 'utilities/formatters';
import { ExpandingFilterBar } from '@common/filter/expanding-filter-bar';
import PaymentHeader from './payment-header';
import { usePaymentFilters } from './use-payment-filters';
import { PaymentStatusFilter } from './filters/payment-status-filter';
import { PaymentAmountFilter } from './filters/payment-amount-filter';
import { PaymentTypeFilter } from './filters/payment-type-filter';
import { PaymentRecipientFilter } from './filters/payment-recipient-filter';
import { PaymentDateRangeFilter } from './filters/payment-date-range.filter';
import { PaymentAccountNameFilter } from './filters/payment-account-name-filter';
import { isTruthyString } from 'utilities/validators/validate-string';
import { OverflowTooltip } from '@flexbase-eng/web-components';
import PaymentDetailsModal from '../common/payment-details-modal';
import { TablePayment, generateRows } from '../common/payments-rows';
import { usePayments } from '@utilities/custom-hooks/use-payments';
import { TableColumn } from 'react-data-table-component';
import { FaInfo } from 'react-icons/fa';
import { tooltipPaymentInfo } from '../common/tooltip-payment-info';
import { domesticPaymentTypes } from '@services/flexbase/banking.model';
import DisplayingPayAmount from '../common/displaying-pay-amount';

const selectAmountColumn = (row: { amount: string }) => {
  const parsedValue = stripCurrencySymbol(row.amount);
  return Number(parsedValue);
};

const filters = [
  {
    key: 'date',
    header: 'Date',
    icon: <CalendarIcon />,
    component: <PaymentDateRangeFilter />,
  },
  {
    key: 'status',
    header: 'Status',
    icon: <StarIcon />,
    component: <PaymentStatusFilter />,
  },
  {
    key: 'type',
    header: 'Type',
    icon: <SendIcon />,
    component: <PaymentTypeFilter />,
  },
  {
    key: 'amount',
    header: 'Amount',
    icon: <DollarCircleIcon />,
    component: <PaymentAmountFilter />,
  },
  {
    key: 'accountName',
    header: 'Account name',
    icon: <CardIcon />,
    component: <PaymentAccountNameFilter />,
  },
  {
    key: 'recipient',
    header: 'Recipient',
    icon: <TeamsIcon />,
    component: <PaymentRecipientFilter />,
  },
];
// configure columns for rows
const getColumns = (
  theme: MantineTheme,
  isAccountPaymentsDetailsPage: boolean,
): TableColumn<TablePayment>[] => {
  return [
    {
      name: 'Date created',
      selector: (row) => row.createdAt,
      format: (row) => getMonthDayYear(row.createdAt),
      sortable: true,
      sortFunction: sortDate,
      grow: 1,
    },
    {
      name: 'Recipient',
      selector: (row) => formatApiStrings(row.recipient) || '',
      sortable: true,
      grow: 1,
    },
    {
      name: 'Amount',
      selector: selectAmountColumn,
      cell: (row) => DisplayingPayAmount(row),
      format: (row) => row.amount,
      sortable: true,
      grow: isAccountPaymentsDetailsPage ? 0.7 : 1.4,
    },
    {
      name: 'Type',
      selector: (row) => formatApiStrings(row.type),
      sortable: true,
      grow: isAccountPaymentsDetailsPage ? 1 : 2,
    },
    {
      name: 'Sender',
      selector: (row) => row.requestedBy ?? 'N/A',
      sortable: true,
      grow: 1,
    },
    {
      name: 'Account',
      cell: (row) => <OverflowTooltip text={row.accountName} />,
      grow: 2,
      selector: (row) => row.accountName,
      sortable: true,
      omit: isAccountPaymentsDetailsPage,
    },
    {
      name: 'Status',
      cell: (row) => {
        let backgroundColor, textColor;

        switch (row.status) {
          case 'Pending':
            backgroundColor = theme.fn.themeColor('promote', 0);
            textColor = theme.fn.themeColor('promote', 5);
            break;
          case 'Approval Needed':
          case '2FA Required':
            backgroundColor = theme.fn.themeColor('tertiary', 0);
            textColor = theme.fn.themeColor('tertiary', 5);
            break;
          case 'Completed':
            backgroundColor = theme.colors.positive[0];
            textColor = theme.colors.positive[6];
            break;
          case 'Failed':
          case 'Canceled':
          case 'Denied':
            backgroundColor = theme.colors.critical[0];
            textColor = theme.colors.critical[4];
            break;
          default:
            backgroundColor = theme.fn.themeColor('neutral', 1);
            textColor = theme.fn.themeColor('neutral', 9);
        }

        return (
          <Badge
            styles={{
              root: {
                backgroundColor,
              },
            }}
          >
            <Text color={textColor}>{formatApiStrings(row.status)} </Text>
          </Badge>
        );
      },
    },
  ];
};

const getColumnsSm = (
  theme: MantineTheme,
  isAccountPaymentsDetailsPage: boolean,
): TableColumn<TablePayment>[] => {
  return [
    {
      grow: 3,
      sortable: true,
      name: 'Payment Details',
      selector: (row) => formatApiStrings(row.recipient) || '',
      cell: (row) => (
        <div>
          <div>{formatApiStrings(row.recipient)}</div>
          <div style={{ fontSize: '12px' }}>{row.dateSubmitted}</div>
        </div>
      ),
    },
    {
      name: 'Amount',
      selector: selectAmountColumn,
      cell: (row) => {
        const isUSDPayment = row.currency === 'USD';
        const pendingCompletedFailedStatuses = new Set([
          'Pending',
          'Completed',
          'Failed',
        ]);
        const isDomesticType = domesticPaymentTypes.has(row.type);
        const unicodeArrow = '\u2192';

        if (
          !isUSDPayment &&
          !isDomesticType &&
          !pendingCompletedFailedStatuses
        ) {
          return (
            <Tooltip
              styles={{
                tooltip: {
                  fontSize: '13px',
                  textAlign: 'center',
                  padding: '10px',
                },
              }}
              label={row.currency ? tooltipPaymentInfo(row.currency) : ''}
            >
              <div>
                {row.estimatedAmount} <FaInfo size="10" /> &rarr; {row.fxAmount}{' '}
                {row.currency}
              </div>
            </Tooltip>
          );
        } else {
          return isDomesticType
            ? row.amount
            : `${row.amount} ${unicodeArrow} ${row.fxAmount} ${row.currency}`;
        }
      },
      format: (row) => row.amount,
      sortable: true,
      compact: true,
    },
    {
      name: 'Type',
      selector: (row) => formatApiStrings(row.type),
      sortable: true,
      compact: true,
    },
    {
      name: 'Sender',
      selector: (row) => row.requestedBy ?? 'N/A',
      sortable: true,
    },
    {
      name: 'Account',
      selector: (row) => row.accountName,
      sortable: true,
      grow: 3,
      omit: isAccountPaymentsDetailsPage,
    },
    {
      name: 'Status',
      cell: (row) => {
        let backgroundColor, textColor;

        switch (row.status) {
          case 'Pending':
            backgroundColor = theme.fn.themeColor('promote', 0);
            textColor = theme.fn.themeColor('promote', 5);
            break;
          case 'Approval Needed':
          case '2FA Required':
            backgroundColor = theme.fn.themeColor('tertiary', 0);
            textColor = theme.fn.themeColor('tertiary', 5);
            break;
          case 'Completed':
            backgroundColor = theme.colors.positive[0];
            textColor = theme.colors.positive[6];
            break;
          case 'Failed':
          case 'Canceled':
          case 'Denied':
            backgroundColor = theme.colors.critical[0];
            textColor = theme.colors.critical[4];
            break;
          default:
            backgroundColor = theme.fn.themeColor('neutral', 1);
            textColor = theme.fn.themeColor('neutral', 9);
        }

        return (
          <Badge
            styles={{
              root: {
                backgroundColor,
              },
            }}
          >
            <Text color={textColor}>{formatApiStrings(row.status)} </Text>
          </Badge>
        );
      },
    },
  ];
};

// Banking Payments in table format
const PaymentsTable = ({ recipientName }: { recipientName?: string }) => {
  const location = useLocation();
  const { pathname } = location;
  const { id, paymentId = '', recipientId } = useParams();
  const navigate = useNavigate();
  const theme = useMantineTheme();
  const isMobile = useMediaQuery('(max-width: 767px)');
  const [showFilters, setShowFilters] = useState(true);
  const { activeFiltersArray, removeFilter, addFilter } = usePaymentFilters();

  const isAccountPaymentsDetailsPage = pathname.includes(
    '/banking/account-details/',
  );

  const accountId = isAccountPaymentsDetailsPage ? id : '';

  const params = {
    ...(accountId && { depositId: accountId }),
    ...(recipientId && { recipientId }),
  };
  const {
    users,
    payments,
    intlPayments,
    depositAccounts,
    isError,
    loading,
    refetch,
  } = usePayments(params);

  const columnsSm = getColumnsSm(theme, isAccountPaymentsDetailsPage);
  const columns = getColumns(theme, isAccountPaymentsDetailsPage);

  useEffect(() => {
    if (recipientName) {
      handleSearchChange(recipientName);
    } else {
      handleSearchChange('');
    }
  }, []);

  const handleSearchChange = (value: string) => {
    if (!value) {
      removeFilter('search');
      return;
    }

    addFilter('search', {
      key: 'search',
      filterValue: value,
      label: `Includes ${value}`,
      showChip: false,
      fn: (transaction) => {
        const normalizedFilterText = value.toLowerCase().trim();
        return Object.values(transaction)
          .filter(isTruthyString)
          .map((v) => v.toLowerCase())
          .some((v) => v.includes(normalizedFilterText));
      },
    });
  };

  const filteredPayments = generateRows({
    users,
    payments,
    intlPayments,
    depositAccounts,
    accountId,
  }).filter((t) => activeFiltersArray.every((f) => f.fn(t)));

  const openPayment = filteredPayments.find((row) => row.id === paymentId);

  if (isError) {
    return (
      <GenericError>
        <p>{'Something went wrong'}</p>
        <Button variant="light" onClick={() => refetch()} mt={20}>
          Please try again
        </Button>
      </GenericError>
    );
  }

  return (
    <>
      {openPayment && <PaymentDetailsModal openPayment={openPayment} />}
      <TabTableLayout
        leftHeaderContent={
          <FlexbaseInput
            w={isMobile ? '100%' : '320px'}
            placeholder="Search payments"
            onChange={(e) => handleSearchChange(e.target.value)}
            defaultValue={recipientName || undefined}
            icon={<SearchIcon width={20} height={20} />}
          />
        }
        borderBottomSolid={false}
        rightHeaderContent={
          <PaymentHeader {...{ setShowFilters, showFilters }} />
        }
        table={
          <>
            <ExpandingFilterBar
              showFilters={showFilters}
              filters={filters}
              activeFilters={activeFiltersArray}
              onRemoveActiveFilterClick={removeFilter}
            />
            <FlexbaseTable
              columns={isMobile ? columnsSm : columns}
              data={filteredPayments}
              pagination={filteredPayments && filteredPayments?.length > 8}
              onRowClicked={(row) => navigate(`${row.id}`)}
              isFetchingData={loading}
              noDataComponent={
                <Text size={24} fw={500} mt="lg">
                  No payments available for this filter
                </Text>
              }
            />
          </>
        }
      />
    </>
  );
};

export default PaymentsTable;
