import { Anchor, Box, Button, Image, Text } from '@mantine/core';
import { ContainerWithFooter } from 'components/footer/footer-container';
import { useMediaQuery } from '@mantine/hooks';
import { CheckCircleGreen } from '../../../assets/svg';
import { approveStyles } from './submitting-screen-styles';
import { CreditStatusBox } from './credit-status-box';
import { BankingStatusBox } from './banking-status-box';
import { StatusBox } from './status-box';
import { useRouteSectionContext } from 'providers/route-context';
import { ApplicationStatus } from '../../../recoil-state/application/product-onboarding.models';
import { BankingApplicationStatus } from '@services/flexbase/banking.model';
import { useLogout } from '@services/logout/logout-hook';
import FlexIconLink from '@common/icons/flex-icon-link';
import { OptedProduct } from '../../../recoil-state/application/product-onboarding';

type Props = {
  graphic?: string;
  id: string;
  endScreenFor: 'user' | 'owner' | 'applicant' | 'officer';
  productStatus: ApplicationStatus;
  bankingStatus: BankingApplicationStatus;
  hasOutstandingOwners: boolean;
  hasPromoCode: boolean;
  applyingForProducts: OptedProduct[];
};

const OnboardingSubmittingScreen = ({
  endScreenFor,
  graphic,
  id,
  productStatus,
  bankingStatus,
  hasOutstandingOwners,
  hasPromoCode,
  applyingForProducts,
}: Props) => {
  const { classes } = approveStyles();
  const isTooSmall = useMediaQuery('(max-width: 400px)');
  const { setShowRoutesFor } = useRouteSectionContext();
  const logout = useLogout();

  let title = 'Thank you for applying';

  if (endScreenFor === 'user') {
    title = 'Information submitted';
  }

  return (
    <div style={{ position: 'relative', overflow: 'hidden' }}>
      <ContainerWithFooter
        withFooter={false}
        restrictFullScreen={true}
        classNames={{ container: classes.screen }}
      >
        <div className={classes.header}>
          <FlexIconLink width={68} />
          <Text className={classes.headerText}>
            Questions? Contact {isTooSmall ? 'us' : 'support'} at{' '}
            <Anchor
              c="primarySecondarySuccess.2"
              href="mailto:support@flex.one"
            >
              support@flex.one
            </Anchor>
          </Text>
        </div>
        <div className={classes.container} id={id}>
          <div className={classes.titleGroup}>
            <CheckCircleGreen />
            <Text className={classes.title}>{title}</Text>
          </div>
          <Box className={classes.statusBoxes} mt="xl">
            {endScreenFor === 'applicant' && (
              <>
                {applyingForProducts.includes('BANKING') && (
                  <BankingStatusBox
                    appStatus={productStatus}
                    bankingStatus={bankingStatus}
                  />
                )}
                {applyingForProducts.includes('CREDIT') && (
                  <CreditStatusBox
                    productStatus={productStatus}
                    hasOutstandingOwners={hasOutstandingOwners}
                    hasPromoCode={hasPromoCode}
                  />
                )}
              </>
            )}
            {endScreenFor === 'owner' && (
              <StatusBox
                title="Information Submitted"
                description="Your information has been successfully submitted to Flex. Once any additional beneficial owners login and provide their information the application will be processed, and the primary applicant will receive a decision."
              >
                <Button onClick={() => logout()}>Close application</Button>
              </StatusBox>
            )}
            {endScreenFor === 'officer' && (
              <StatusBox
                title="Information Submitted"
                description="Your information has been successfully submitted to Flex. Once any additional officers login and provide their information the application will be processed, and the primary applicant will receive a decision."
              >
                <Button onClick={() => logout()}>Close application</Button>
              </StatusBox>
            )}
            {endScreenFor === 'user' && (
              <StatusBox
                title="Welcome to Flex"
                description="You have successfully provided your information and now have access to Flex."
              >
                <Button onClick={() => setShowRoutesFor('main')}>
                  Access app
                </Button>
              </StatusBox>
            )}
          </Box>
        </div>
      </ContainerWithFooter>
      {graphic && (
        <div className={classes.graphic}>
          <Image src={graphic} width={'100%'} />
        </div>
      )}
    </div>
  );
};

export default OnboardingSubmittingScreen;
