import { useQuery } from '@tanstack/react-query';
import { flexbaseOnboardingClient } from '../services/flexbase-client';
import { Issuer } from '@services/flexbase/credit.model';

export const useGetCompanyEarlyPayProjectedDueAmounts = (companyId: string,  issuer?: Issuer) => {
  return useQuery({
    queryKey: ['earlyPayProjected', companyId, issuer],
    queryFn: () => flexbaseOnboardingClient.getProjectedEarlyPayDue(companyId, issuer),
  });
};
