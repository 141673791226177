import { useOnboardingStyles } from '../../onboarding.styles';
import { OwnerType } from '../../../../types/onboarding-info';
import { Box, Flex, Text } from '@mantine/core';
import { TrashIconNew } from '../../../../assets/svg';

type Props = {
  officer: OwnerType;
  onDeleteClick: () => void;
};

const OfficerCard = ({ officer, onDeleteClick }: Props) => {
  const { classes, cx } = useOnboardingStyles();

  return (
    <Box mb="lg" className={classes.cardBeneficialOwners}>
      <div className={classes.cardTextContent}>
        <Text c="neutral.5" fz={14} fw={500}>
          Name and job title
        </Text>
        <Flex>
          <Text c="primarySecondarySuccess.9" fw={500}>
            {officer.firstName} {officer.lastName} | {officer.jobTitle}
          </Text>
          <TrashIconNew
            className={cx(classes.actionButton, classes.autoMarginLeft)}
            onClick={() => onDeleteClick()}
          />
        </Flex>
      </div>
    </Box>
  );
};

export default OfficerCard;
