import { useState } from 'react';
import {
  Button,
  Radio,
  Group,
  useMantineTheme,
  Select,
  TextInput,
  Alert,
} from '@mantine/core';

import { useStyles, CustomMantineStyles } from '../../styles';
import { formatCurrency } from 'utilities/formatters';
import AccountSelection from 'areas/payments/components/account-selection';
import {
  AccountProps,
  DepositAccount,
} from 'areas/banking/move-funds/move-funds.model';
import { RedAlertIcon } from 'assets/svg';
import { checkSpendingLimits } from '../util';
import { IssueDebitCardSteps } from './issue-debit-card';
import { useIssueDebitCard } from '@queries/use-debit-cards';
import TwoFactorAuthSection from '../two-factor/two-factor-auth-section';
import { IssueDebitCard } from '@services/flexbase/banking.model';

const setErrorMessageIssuingCard = (error: string) => {
  let errorMessage = '';
  switch (true) {
    case error.includes('limit reached for Company'):
      errorMessage =
        'Your company has reached the maximum limit for virtual/physical cards.';
      break;

    case error.includes('Terms of Service'):
      errorMessage =
        'To issue the card, the user must have accepted the Terms of Service.';
      break;
    case error.includes('a Customer Token is required'):
      errorMessage = 'Please enter the 6 digit SMS sent to verify your request';
      break;
    case error.includes('Bearer token is invalid or expired'):
      errorMessage = 'Bearer token is invalid or expired.';
      break;

    default:
      errorMessage =
        'Unable to issue card. Please contact support or try again later.';
      break;
  }
  return errorMessage;
};

type SelectItemProps = {
  label: string;
  value: string;
};

type Props = {
  form: any;
  appType: string;
  employees: SelectItemProps[];
  setStep: (x: number) => void;
  bankingAccounts: DepositAccount[];
};

const DebitCardForm = ({
  form,
  setStep,
  appType,
  employees,
  bankingAccounts,
}: Props) => {
  const theme = useMantineTheme();
  const { classes } = useStyles();
  const [needsAuth, setNeedsAuth] = useState(false);
  const { mutate, isPending: issueCardLoading, error } = useIssueDebitCard();
  const [selectedAccount, setSelectedAccount] = useState<DepositAccount>(
    bankingAccounts[0],
  );

  const errorMessage = error ? JSON.parse(error.message).error : null;

  const onInputChange = (
    input: string,
    fieldValue: 'purchaseLimit' | 'withdrawalLimit',
  ) => {
    let currentValue = input.replace(/[^\d]/g, '');

    if (currentValue.length < 2) {
      currentValue = `0.0${currentValue}`;
    } else if (currentValue.length === 2) {
      currentValue = `0.${currentValue}`;
    } else {
      currentValue =
        currentValue.slice(0, currentValue.length - 2) +
        '.' +
        currentValue.slice(-2);
    }

    form.setFieldValue(fieldValue, Number(currentValue));
  };

  const selectAccount = (acct: DepositAccount) => {
    setSelectedAccount(acct);
    form.setFieldValue('accountId', acct.id);
  };

  let cardLimits: {
    dailyPurchase?: number;
    dailyWithdrawal?: number;
    monthlyPurchase?: number;
    monthlyWithdrawal?: number;
  };
  switch (form.values.limitType) {
    case 'daily':
      cardLimits = {
        dailyPurchase: Math.round(form.values.purchaseLimit * 100),
      };
      break;

    case 'monthly':
      cardLimits = {
        monthlyPurchase: Math.round(form.values.purchaseLimit * 100),
      };
      break;

    default:
      cardLimits = {
        monthlyPurchase: Math.round(form.values.purchaseLimit * 100),
      };
      break;
  }

  const issueDebitCard = async () => {
    if (
      form.values.limitType !== 'unlimited' &&
      [null, 0].includes(form.values.purchaseLimit)
    ) {
      return;
    }

    const validationResult = form.validate();
    if (!validationResult.hasErrors) {
      const userId = form.values.userId;

      const cardForm: IssueDebitCard = {
        toUser: { id: userId },
        cardSubtype: form.values.cardSubtype,
        depositAccountId: selectedAccount.id,
        cardName: form.values.cardName !== '' ? form.values.cardName : null,
        issuer: 'unit-co',
        type: form.values.cardSubtype.includes('Virtual')
          ? 'virtual'
          : 'physical',
        ...(form.values.limitType !== 'unlimited' && { limits: cardLimits }),
      };

      mutate(cardForm, {
        onSuccess: (data) => {
          const { card } = data;
          form.setFieldValue('cardId', card.id);
          setStep(IssueDebitCardSteps.SUCCESS);
        },
        onError: (err) => {
          console.error('Unable to create the card', err);
          const errorMessages = [
            'a Customer Token is required',
            'Bearer token is invalid or expired',
          ];

          const { error: errorMsg = '' } = JSON.parse(err.message);
          if (errorMessages.some((message) => errorMsg.includes(message))) {
            setNeedsAuth(true);
          }
        },
      });
    }
  };

  const employeesSortedByLastName = [...employees].sort((a, b) => {
    const aName = a.label.split(' ');
    const bName = b.label.split(' ');
    return aName[aName.length - 1].localeCompare(bName[bName.length - 1]);
  });

  return (
    <div className={classes.card}>
      <div className="move_funds">
        <span>Create a new card</span>
        <Select
          id="team-member"
          mt={25}
          data={employeesSortedByLastName}
          dropdownPosition="bottom"
          placeholder="Select or search"
          label="Team member"
          {...form.getInputProps('userId')}
          searchable
          data-testid="team-member"
        />

        <div style={{ marginTop: '25px', position: 'relative' }}>
          <AccountSelection
            label="Flex account"
            accounts={bankingAccounts}
            currentAccount={selectedAccount}
            data-testid="deposit-account"
            onAccountChange={(c: AccountProps) =>
              selectAccount(c as DepositAccount)
            }
          />
        </div>

        <TextInput
          mt={25}
          label="Card nickname"
          {...form.getInputProps('cardName')}
          placeholder="e.g. nickname lunch card"
          data-testid="nickname"
        />

        <Radio.Group
          mt={25}
          spacing={100}
          {...form.getInputProps('cardSubtype')}
          classNames={{
            label: CustomMantineStyles(
              theme.fn.themeColor('primarySecondarySuccess', 2),
            ).debitCard.radioLabel,
          }}
          color={theme.fn.themeColor('primarySecondarySuccess', 2)}
        >
          <Group>
            <Radio
              value={
                appType === 'businessApplication'
                  ? 'businessDebitCard'
                  : 'individualDebitCard'
              }
              label="Physical"
              styles={{
                radio: CustomMantineStyles(
                  theme.fn.themeColor('primarySecondarySuccess', 2),
                ).debitCard.radio,
              }}
              data-testid="physical-card"
            />
            <Radio
              value={
                appType === 'businessApplication'
                  ? 'businessVirtualDebitCard'
                  : 'individualVirtualDebitCard'
              }
              label="Virtual"
              styles={{
                radio: CustomMantineStyles(
                  theme.fn.themeColor('primarySecondarySuccess', 2),
                ).debitCard.radio,
              }}
              data-testid="virtual-card"
            />
          </Group>
        </Radio.Group>

        <Select
          label="Spending limit type"
          {...form.getInputProps('limitType')}
          data={[
            { value: 'daily', label: 'Daily' },
            { value: 'monthly', label: 'Monthly' },
            { value: 'unlimited', label: 'Unlimited' },
          ]}
          styles={{ root: { marginTop: '25px !important' } }}
        />

        {form.values.limitType !== 'unlimited' && (
          <TextInput
            mt={25}
            value={formatCurrency(form.values.purchaseLimit || '0')}
            onChange={(v) =>
              onInputChange(v.currentTarget.value, 'purchaseLimit')
            }
            label={`${
              form.values.limitType === 'daily' ? 'Daily' : 'Monthly'
            } spending limit`}
            error={checkSpendingLimits(
              form.values.purchaseLimit,
              form.values.limitType,
              selectedAccount.tier,
            )}
          />
        )}

        {needsAuth && <TwoFactorAuthSection onSuccess={issueDebitCard} />}
        {/* do not display error message if we ask for 2FA */}
        {errorMessage &&
          !errorMessage.includes('expired') &&
          !errorMessage.includes('Customer Token is required') && (
            <Alert my={15} color="red" icon={<RedAlertIcon />}>
              {setErrorMessageIssuingCard(errorMessage)}
            </Alert>
          )}

        <div
          className="row-content"
          style={{
            justifyContent:
              form.values.cardType === 'virtual' ? 'space-between' : 'flex-end',
          }}
        >
          {form.values.cardType === 'virtual' && (
            <div style={{ maxWidth: '60%' }}>
              <p>The card will be activated within a few moments.</p>
            </div>
          )}
          <div>
            <Button
              mb={15}
              variant="light"
              onClick={issueDebitCard}
              loading={issueCardLoading}
              data-testid="submit-card-creation"
            >
              Create card
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DebitCardForm;
