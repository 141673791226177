import { TagDelta } from 'areas/banking/delta';
import { Group } from '@mantine/core';
import { formatCurrency } from '@utilities/formatters';
import { AccountTransaction } from '../components/transactions-summary/transactions-summary';
import { filterByDirection } from '@utilities/filter/filter-transactions-by-direction';

type TransactionsOverviewBadgesProps = {
  transactions?: AccountTransaction[];
  isReserveAccount: boolean;
};

const TransactionsOverviewBadges = ({
  transactions,
  isReserveAccount,
}: TransactionsOverviewBadgesProps) => {
  if (!transactions) return null;

  const sumByAmount = (txns: AccountTransaction[]) => {
    return txns.reduce((acc, t) => acc + Number(t.amount), 0);
  };

  const creditTransactionTotal = sumByAmount(
    filterByDirection(transactions, 'Credit'),
  );
  const debitTransactionTotal = sumByAmount(
    filterByDirection(transactions, 'Debit'),
  );

  return (
    <Group spacing="xs">
      <TagDelta
        tone={'positive'}
        change={'increase'}
      >{`Money in: ${formatCurrency(creditTransactionTotal)}`}</TagDelta>
      {!isReserveAccount && (
        <TagDelta
          tone={'critical'}
          change={'decrease'}
        >{`Money out: ${formatCurrency(debitTransactionTotal)}`}</TagDelta>
      )}
    </Group>
  );
};

export default TransactionsOverviewBadges;
