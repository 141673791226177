import { flexbaseOnboardingClient } from '@services/flexbase-client';
import { useQuery } from '@tanstack/react-query';

export const usePersonalGuaranty = () => {
  return useQuery({
    queryKey: ['personal_guaranty'],
    queryFn: async () => {
      return await flexbaseOnboardingClient.getPersonalGuaranty();
    },
    staleTime: Infinity,
  });
};
