import TableSummary from '@common/table/summary/table-summary';
import {
  Badge,
  Box,
  Button,
  Group,
  Progress,
  Stack,
  Text,
} from '@mantine/core';
import { formatCurrency, formatCents } from '@utilities/formatters';
import { TableColumn } from 'react-data-table-component';
import { FlexbaseTable } from '@common/table';
import { getMonthDayYear, sortDateFromSQL } from '@utilities/dates/dates';
import BillpayPaymentsTableHeader from './billpay-payments-table-header';
import { useMemo } from 'react';
import { useBillpayPaymentsFilters } from './use-billpay-payments-filters';
import { useOverviewStyles } from './styles';
import { getBillPayStatusDisplay } from 'areas/billpay/bills/util/status';
import { BillPayStatus } from 'types/bill-pay';
import useModal from '@common/modal/modal-hook';
import CardRepaymentModal from 'areas/credit/repayment/card-repayment-modal';
import DrawdownDetailsModal from './drawdown-details-modal';
import { useIsMobile } from '@utilities/custom-hooks/use-is-mobile';
import { useCreditLines } from '@queries/use-credit-lines';

// This type is temporary while the corresponding endpoint is being made.
export type BillpayCreditPaymentsTable = {
  id: number;
  date: string;
  type: string;
  category: string;
  amount: string;
  status: BillPayStatus;
  vendor: string;
  documentId?: string;
};

// Fake data until official endpoint exists
const mockTableData: BillpayCreditPaymentsTable[] = [
  {
    id: 1,
    date: '2024-11-01',
    type: 'Drawdown',
    category: 'Utilities',
    amount: '150000',
    status: 'succeeded',
    vendor: 'Globex Corporation, LLC.',
    documentId: '5b487a01-b0d6-43cc-92d9-0022ac907607',
  },
  {
    id: 2,
    date: '2024-10-28',
    type: 'Fee',
    category: 'Groceries',
    amount: '-7500',
    status: 'succeeded',
    vendor: 'Globex Corporation, LLC.',
    documentId: '5b487a01-b0d6-43cc-92d9-0022ac907607',
  },
  {
    id: 3,
    date: '2024-10-15',
    type: 'Drawdown',
    category: 'Rent',
    amount: '2500000',
    status: 'succeeded',
    vendor: 'Globex Corporation, LLC.',
    documentId: '5b487a01-b0d6-43cc-92d9-0022ac907607',
  },
  {
    id: 4,
    date: '2024-09-30',
    type: 'Drawdown',
    category: 'Travel',
    amount: '300000',
    status: 'succeeded',
    vendor: 'Globex Corporation, LLC.',
    documentId: '5b487a01-b0d6-43cc-92d9-0022ac907607',
  },
  {
    id: 5,
    date: '2024-09-15',
    type: 'Fee',
    category: 'Subscriptions',
    amount: '10000',
    status: 'succeeded',
    vendor: 'Globex Corporation, LLC.',
    documentId: '5b487a01-b0d6-43cc-92d9-0022ac907607',
  },
];

const BillPayCreditOverview = () => {
  const { classes } = useOverviewStyles();
  // This will be the hook to obtain the data
  const { data } = useCreditLines();
  const { activeFiltersArray } = useBillpayPaymentsFilters();
  const isMobile = useIsMobile();
  const { openRightModal, openFullModal, closeAllModals } = useModal();

  const billpayCreditLine = useMemo(
    () => data?.lines.find((line) => line.issuer === 'billpay'),
    [data],
  );

  const handleCloseModal = () => {
    closeAllModals();
  };

  const handlePayNow = () => {
    // Update modal to use bill pay credit
    if (isMobile) {
      openFullModal(<CardRepaymentModal closeModal={handleCloseModal} />);
    } else {
      openRightModal(<CardRepaymentModal closeModal={handleCloseModal} />);
    }
  };

  const tableData = [
    {
      title: 'Available bill pay credit',
      balance: formatCents(billpayCreditLine?.available || 0),
      footer: (
        <Stack spacing="xxs">
          <Progress
            size="md"
            value={
              billpayCreditLine?.limit
                ? (billpayCreditLine?.balance / billpayCreditLine?.limit) * 100
                : 100
            }
            color="primarySecondarySuccess.5"
          />
          <Group position="apart">
            <Text size="sm" color="neutral.8">
              {formatCurrency(formatCents(billpayCreditLine?.balance || 0))}
            </Text>
            <Text size="sm" color="neutral.8">
              of {formatCurrency(formatCents(billpayCreditLine?.limit || 0))}{' '}
              limit
            </Text>
          </Group>
        </Stack>
      ),
    },
    {
      title: 'Scheduled bills',
      balance: formatCents(500000),
      footer: `${5} bills`,
    },
    {
      title: 'Next payment',
      balance: formatCents(1000000),
      footer: 'Due Dec 1, 2024',
      action: (
        <Button onClick={handlePayNow} size="sm">
          Pay now
        </Button>
      ),
    },
  ];

  const columns: TableColumn<BillpayCreditPaymentsTable>[] = [
    {
      id: 'date',
      name: 'Date',
      selector: (payment) => payment.date,
      format: (payment) => getMonthDayYear(payment.date),
      sortFunction: (a, b) => sortDateFromSQL(a.date, b.date),
      sortable: true,
    },
    {
      id: 'type',
      name: 'Type',
      sortable: true,
      selector: (payment) => payment.type ?? '',
    },
    {
      id: 'category',
      name: 'Category',
      selector: (payment) => payment.category ?? '',
      sortable: true,
    },
    {
      id: 'amount',
      name: 'Amount',
      selector: (payment) => payment.amount,
      format: (payment) => formatCurrency(parseFloat(payment.amount) / 100),
      sortable: true,
    },
    {
      id: 'status',
      name: 'Status',
      cell: (payment) => {
        const { displayStatus, backgroundColor, textColor } =
          getBillPayStatusDisplay(payment.status);
        return (
          <Badge
            styles={{
              root: {
                backgroundColor,
              },
            }}
          >
            <Text color={textColor}>{displayStatus} </Text>
          </Badge>
        );
      },
      selector: (payment) => payment.status,
      sortable: true,
    },
  ];

  const filteredPayments: BillpayCreditPaymentsTable[] = useMemo(() => {
    if (!mockTableData) return [];
    return (mockTableData ?? []).filter((cc) =>
      activeFiltersArray.every((filter) => filter.fn(cc)),
    );
  }, [mockTableData, activeFiltersArray]);

  const handleRowClicked = (row: BillpayCreditPaymentsTable) => {
    if (isMobile) {
      openFullModal(
        <DrawdownDetailsModal details={row} closeModal={handleCloseModal} />,
      );
    } else {
      openRightModal(
        <DrawdownDetailsModal details={row} closeModal={handleCloseModal} />,
      );
    }
  };

  return (
    <>
      <TableSummary summaryData={tableData} isError={false} isLoading={false} />
      <Box className={classes.card} mb="lg">
        <BillpayPaymentsTableHeader />
        <FlexbaseTable
          columns={columns}
          data={filteredPayments}
          onRowClicked={handleRowClicked}
        />
      </Box>
    </>
  );
};

export default BillPayCreditOverview;
