import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useEffect, useState } from 'react';
import { Box, Button, LoadingOverlay, Text } from '@mantine/core';
import { showNotification } from '@mantine/notifications';

import { useStyles } from './add-funds';
import { ChevronDownIcon, SafeBoxIcon } from 'assets/svg';
import { PlaidAccount } from '../move-funds/move-funds.model';
import { UserIdState } from 'areas/onboarding/onboarding-form.state';
import { FinancialInstitution } from 'types/onboarding-info';
import flexbaseClient, {
  flexbaseBankingClient,
} from 'services/flexbase-client';
import PlaidContext, { PlaidContextValue } from 'providers/plaid-context';
import {
  PlaidLinkOnSuccess,
  PlaidLinkOnSuccessMetadata,
  usePlaidLink,
} from 'react-plaid-link';
import {
  ApplicationState,
  getProductOnboardingStatus,
} from 'recoil-state/application/product-onboarding';

type Props = {
  plaidAccounts: PlaidAccount[];
  onLinkAccount: (x: boolean) => void;
  onReLinkAccount: (accounts: FinancialInstitution[]) => void;
};

type Token = {
  linkToken: string;
  bankName: string;
};

const ConnectBankAccount = ({
  onLinkAccount,
  plaidAccounts,
  onReLinkAccount,
}: Props) => {
  const userId = useRecoilValue(UserIdState);
  const [loading, setLoading] = useState(false);
  const [tokens, setTokens] = useState<Token[]>([]);
  const [openPlaid, setOpenPlaid] = useState(false);
  const { classes, cx } = useStyles({ amount: 0 });
  const setCompany = useSetRecoilState(ApplicationState);

  // yes, this is the same as the one in plaid-auto-open.tsx
  // TODO: refactor this into a shared hook
  // open a sequence of Plaid re-linking modal to prompt for updating individual institution links
  const getLinkTokens = async () => {
    setLoading(true);

    // fetch the unlinked external accounts, deduped by the name of the institution
    const unlinkedAccounts = plaidAccounts.reduce(
      (unlinkedAccts, { unlinked, id, bankName, userId: _userId }) => {
        if (unlinked && userId === _userId) {
          unlinkedAccts[bankName || ''] = id;
        }

        return unlinkedAccts;
      },
      {} as Record<string, string>,
    );

    // generate plaid link update tokens for each unlinked external account
    const linkTokenRequests = Object.entries(unlinkedAccounts).map(
      ([bankName, id]) =>
        flexbaseBankingClient.relinkPlaidAccount({ plaidTokenId: id }).then(
          (response) => ({
            bankName,
            success: true,
            linkToken: response.response.link_token,
          }),
          () => {
            return { bankName, success: false, linkToken: '' };
          },
        ),
    );

    await Promise.all(linkTokenRequests).then((results) => {
      // divide up responses into successes and failures
      const successes = results.filter(({ success }) => success);
      const failures = results.filter(({ success }) => !success);

      // notify users of failures without blocking
      for (const failure of failures) {
        showNotification({
          title: 'Failure',
          message: `Unable to get link token for ${failure.bankName}. Please contact support if the issue persists.`,
          color: 'red',
        });
      }

      // handle any successes remaining
      if (successes.length) {
        PlaidContext.appContext = PlaidContextValue.STALE;

        const newTokens = successes.map(({ linkToken, bankName }) => ({
          linkToken: linkToken,
          bankName,
        }));

        setTokens(newTokens);
        setOpenPlaid(true);
      }
    });

    setLoading(false);
  };

  const onSuccess: PlaidLinkOnSuccess = async (
    publicToken: string,
    metadata: PlaidLinkOnSuccessMetadata,
  ) => {
    setOpenPlaid(false);
    setLoading(true);
    PlaidContext.clearPlaidContext();
    const result = await flexbaseClient.exchangePlaidPublicToken(
      publicToken,
      metadata,
    );

    // strip the processed token from the queue
    const [processedToken, ...nextTokens] = tokens;
    const bank = processedToken?.bankName ?? 'Bank';

    // let the user know how it went
    if (result.success) {
      showNotification({
        title: 'Success!',
        message: `${bank} linked.`,
        color: 'flexbase-teal',
      });
    } else {
      showNotification({
        title: 'Failure',
        message: `Unable to link ${bank}.`,
        color: 'red',
      });
    }

    // if there are no more tokens, update company state and we're done
    if (!nextTokens.length) {
      const { company } = await getProductOnboardingStatus();
      setCompany((prev) => ({ ...prev, company }));
      onReLinkAccount(company.financialInstitutions);
    } else {
      // reset the link token queue until it's empty
      setTokens(nextTokens);
    }
    setLoading(false);
  };

  const { ready, open } = usePlaidLink({
    onSuccess: onSuccess,
    token: tokens[0]?.linkToken ?? null,
  });

  useEffect(() => {
    if (tokens.length && openPlaid && ready) {
      open();
    }
  }, [tokens, openPlaid, ready]);

  return (
    <Box p={20} className={classes.target}>
      {loading && <LoadingOverlay visible={loading} />}

      {!plaidAccounts.length ? (
        <Box
          onClick={() => onLinkAccount(true)}
          className={cx(classes.cursor, classes.rowContent)}
        >
          <div className={classes.flexContent}>
            <div className={classes.IconSection}>
              <SafeBoxIcon width={24} height={24} />
            </div>
            <Text size={14}>Connect a bank account</Text>
          </div>
          <ChevronDownIcon />
        </Box>
      ) : (
        <div>
          <Text size={14}>
            If you&apos;d like to transfer funds from an external account,
            please make sure to relink your account(s).
          </Text>
          <Button fullWidth mt="lg" variant="light" onClick={getLinkTokens}>
            Re-link accounts
          </Button>
        </div>
      )}
    </Box>
  );
};

export default ConnectBankAccount;
