import { capitalizeOnlyFirstLetter } from './format-strings';
import { mapTransactionStatusToBusinessStatus } from '../../services/flexbase/flexbase-onboarding-client';
import { CreditTransactionsTableRow } from '../../areas/credit/components/credit-transactions/credit-transactions-table-helpers';

const parseUnitTransactionStatus = (status: string | null) => {
  if (status === 'approved') {
    return 'Pending';
  }
  return capitalizeOnlyFirstLetter(status || '');
};

export const formatCreditTransactionStatus = (
  row: CreditTransactionsTableRow,
) => {
  return row.lineOfCredit === 'unit'
    ? parseUnitTransactionStatus(row.status)
    : mapTransactionStatusToBusinessStatus(row);
};
