import { FlexAccountInfo } from '../../cash-management/treasury-management.state';
import type { DepositAccount as BaseDepositAccount } from '../../../services/flexbase/banking.model';

/**
 * PlaidAccount is the shape of the response from the companyAccounts endpoint
 */
export type PlaidAccount = {
  plaidOrDeposit: 'plaid';
  accessToken?: string;
  account: string;
  accountId: string;
  accountName: string;
  accountType?: string;
  active: boolean;
  bankName?: string;
  companyId?: string;
  id: string;
  institutionId?: string;
  itemId?: string;
  last4?: string;
  logoUrl?: string;
  officialName?: string;
  ownerInfo?: OwnerInfo;
  routing?: string;
  stripeBankAccountToken?: string;
  unitProcessorToken?: string;
  unlinked: boolean;
  userId?: string;
  tenantId?: string;
  available?: string;
};

type OwnerInfo = {
  address?: Address;
  email?: string;
  name?: string;
  phone?: string;
};

type Address = {
  city: string;
  country: string;
  line1: string;
  postalCode: string;
  state: string;
};

export type Tier = 0 | 1 | 2 | 3 | 4;
/**
 * DepositAccount is the shape of the response from the banking/deposits endpoint
 **/
export type DepositAccount = {
  plaidOrDeposit: 'deposit';
  tier: Tier;
} & BaseDepositAccount;

/**
 * generalized account properties for both Plaid and Deposit accounts
 */
export type AccountProps = DepositAccount | PlaidAccount | FlexAccountInfo;

export const AUTHORIZATIONS = {
  achDebit: {
    contents:
      'By clicking "Transfer", I authorize Flex to immediately debit the amount above from my bank account. Once I authorize this payment, I understand there cannot be any changes or corrections. Transactions made after 5:00 PM EST or on a weekend or holiday will be processed the next business day. It is recommended that you print a copy of this authorization and maintain it for your records.',
  },
  default: {
    contents:
      'By clicking "Transfer", I authorize Flex to initiate the transaction detailed above.',
  },
};
