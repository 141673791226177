import { PaymentWithInvoiceNumber } from 'areas/billpay/payments/payments';
import { PaymentAmountFilter } from './payment-amount-filter';
import { PaymentInvoiceStatusFilter } from './payment-invoice-status-filter';
import { PaymentVendorFilter } from './payment-vendor-filter';
import { PaymentMethodFilter } from './payment-method-filter';
import { PaymentCreateOnFilter } from './payment-create-on-filter';
import { PaymentDateRangeFilter } from './payment-date-filter';

export const filterPayments = (payments: PaymentWithInvoiceNumber[]) => [
  {
    key: 'type',
    header: 'Payment method',
    component: <PaymentMethodFilter />,
  },
  {
    key: 'date',
    header: 'Created on',
    component: <PaymentCreateOnFilter />,
  },
  {
    key: 'paymentDate',
    header: 'Payment date',
    component: <PaymentDateRangeFilter />,
  },
  {
    key: 'status',
    header: 'Invoice status',
    component: <PaymentInvoiceStatusFilter />,
  },
  {
    key: 'amount',
    header: 'Amount',
    component: <PaymentAmountFilter />,
  },
  {
    key: 'recipient',
    header: 'Recipient',
    component: <PaymentVendorFilter payments={payments} />,
  },
];
