import { Button, Flex, Grid, Modal, Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useCreateDepositAccount } from '../../../queries/use-deposit-accounts';

type Props = {
  addModal: boolean;
  setAddModal: (set: boolean) => void;
};

const NewAccountModal = ({ addModal, setAddModal }: Props) => {
  const { mutate } = useCreateDepositAccount();
  const form = useForm({
    initialValues: {
      isDefault: false,
      nickName: undefined,
    },
  });

  const handleCloseModal = () => {
    setAddModal(false);
  };

  const addDepositAccount = () => {
    mutate({ ...form.values });
    form.reset();
    setAddModal(false);
  };

  return (
    <Modal
      opened={addModal}
      onClose={handleCloseModal}
      title="Open new Flex bank account"
      centered
      styles={{
        header: { fontSize: 20 },
      }}
    >
      <Grid>
        <Grid.Col span={12}>
          <form onSubmit={form.onSubmit(addDepositAccount)}>
            <Text fw={400} size={14} color="neutral.7">
              Your account will be immediately created and ready for use.
            </Text>
            <TextInput
              mt={30}
              mb={20}
              label="Account nickname"
              placeholder="Write a nickname"
              {...form.getInputProps('nickName')}
            ></TextInput>
            <Flex justify="flex-end" gap={20}>
              <Button variant="outline" onClick={handleCloseModal}>
                Cancel
              </Button>
              <Button variant="light" type="submit">
                Add
              </Button>
            </Flex>
          </form>
        </Grid.Col>
      </Grid>
    </Modal>
  );
};

export default NewAccountModal;
