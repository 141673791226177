import { forwardRef, useState, useEffect, CSSProperties } from 'react';
import { Box, extractSystemStyles, createStyles } from '@mantine/core';

type HiddenInputProps = {
  name: string;
  value: Promise<string>;
  style?: CSSProperties;
  classNames?: any;
  styles?: any;
  sx?: any;
  onDataResolved: (name: string, resolvedValue: string) => void;
};

export const _HiddenInput = forwardRef<HTMLInputElement, HiddenInputProps>(
  (props, ref) => {
    const { style, sx, value, name, ...others } = props;

    const { classes, cx } = useStyles();

    const { systemStyles, rest } = extractSystemStyles(others);

    const [resolvedValue, setResolvedValue] = useState<string | null>(null);

    // When the promise is resolved, update the resolvedValue state
    useEffect(() => {
      value.then((resolved) => {
        setResolvedValue(resolved);
        props.onDataResolved(name, resolved);
      });
    }, [value]);

    return (
      <Box
        component="input"
        type="hidden"
        ref={ref}
        className={cx(classes.input)}
        {...systemStyles}
        {...rest}
        sx={sx}
        style={style}
        value={resolvedValue || ''}
        name={name}
      />
    );
  },
);

const useStyles = createStyles(() => ({
  input: {
    display: 'none',
  },
}));

_HiddenInput.displayName = 'HiddenInput';

export default _HiddenInput;
