import { Box, TextInput } from '@mantine/core';
import { isTruthyString } from 'utilities/validators/validate-string';
import { SearchIcon } from 'assets/svg';
import { useBillpayPaymentsFilters } from './use-billpay-payments-filters';

const BillpayPaymentsTableHeader = () => {
  const { getFilterByKey, removeFilter, addFilter } =
    useBillpayPaymentsFilters();

  const onSearchChange = (value: string) => {
    if (!value) {
      removeFilter('search');
      return;
    }

    addFilter('search', {
      key: 'search',
      filterValue: value,
      label: `Includes ${value}`,
      showChip: false,
      fn: (transaction) => {
        const normalizedFilterText = value.toLowerCase().trim();
        return Object.values(transaction)
          .filter(isTruthyString)
          .map((v) => v.toLowerCase())
          .some((v) => v.includes(normalizedFilterText));
      },
    });
  };

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        gap: theme.spacing.md,
        justifyContent: 'space-between',
        flexWrap: 'wrap',
      })}
      mb="md"
    >
      <Box
        sx={(theme) => ({
          display: 'flex',
          gap: theme.spacing.md,
          [theme.fn.smallerThan('sm')]: {
            flexDirection: 'column',
            width: '100%',
          },
        })}
      >
        <TextInput
          placeholder="Search bill payments"
          w={368}
          value={getFilterByKey('search')?.filterValue || ''}
          onChange={(e) => onSearchChange(e.target.value)}
          icon={<SearchIcon width={20} height={20} />}
        />
      </Box>
    </Box>
  );
};

export default BillpayPaymentsTableHeader;
