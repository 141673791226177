import { createStyles } from '@mantine/core';

export const useConfirmAndReviewStyles = createStyles((theme) => ({
  selectList: {
    padding: 8,
    zIndex: 999,
    marginTop: 9,
    width: '100%',
    maxHeight: '250px',
    overflow: 'scroll',
    position: 'absolute',
    backgroundColor: 'white',
    boxShadow: theme.shadows.sm,
    borderRadius: theme.defaultRadius,
  },
  paymentInfo: {
    padding: '15px',
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
    backgroundColor: '#FAFAFA',
    color: '#4B4B4B',
  },
  infoRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  repayment: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'start',
    width: '100%',
  },
  repaymentReview: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'start',
    width: '100%',
  },
  title: {
    fontFamily: 'PP Neue Montreal',
    fontWeight: 400,
    fontSize: '28px',
    lineHeight: '34px',
  },
  subtitle: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '23px',
    color: '#4B4B4B',
    fontFamily: 'PP Neue Montreal',
  },
  inputTitle: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '19px',
    color: '#4B4B4B',
    marginBottom: 8,
  },
  paymentAmtBold: {
    fontWeight: 400,
    fontSize: '40px',
    lineHeight: '16px',
    margin: '24px 0',
  },
  amountSection: {
    height: '40px',
  },
  numberInput: {
    marginBottom: 8,
  },
  maxPaymentButton: {
    height: '30px',
    fontSize: '10px',
    position: 'relative', // Have to do this due to limitations with the Mantine 'rightSection'
    right: '50px',
  },
  menuItem: {
    fontWeight: 500,
    color: '#4B4B4B',
  },
  currentBalance: {
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '15px',
    color: '#4B4B4B',
    margin: '8px 0px',
  },
  currentBalanceError: {
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '15px',
    color: theme.fn.themeColor('critical', 2),
    margin: '8px 0px',
  },
  amountChips: {
    justifyContent: 'start',
    flexWrap: 'nowrap',
    margin: '8px 0',
    gap: 8,
  },
  chipLabel: {
    fontWeight: 500,
    fontSize: 12,
    '&[data-checked]:not([data-disabled])': {
      outline: theme.fn.primaryColor(),
      border: `1px solid ${theme.fn.primaryColor()}`,
    },
  },
  chipIcon: { color: theme.fn.primaryColor() },
  bank: {
    height: '79px',
    border: 'solid 1px #E6E7E9',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 8,
  },
  bankReview: {
    height: 79,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 8,
  },
  fbIcon: {
    height: '38px',
    width: '38px',
    maxWidth: 38,
    background: '#FFEEEC',
    borderRadius: 8,
    marginLeft: 20,
  },
  bankText: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '21px',
    color: '#4B4B4B',
    marginLeft: 20,
  },
  confirm: {
    fontSize: '16px',
    borderRadius: '8px',
    height: '40px',
  },
  goBack: {
    fontSize: '16px',
    borderRadius: '8px',
    backgroundColor: '#FFFFFF',
    border: '1px solid #CBCBCB',
    color: '#5F5F5F',
    '&:not([data-disabled]):hover': {
      backgroundColor: '#FFFFFF',
    },
    height: '40px',
  },
  utc: {
    color: '#11152A',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '140%',
  },
  authorize: {
    color: '#11152A',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '140%',
    textAlign: 'left',
  },
  reviewAmount: {
    marginTop: 24,
  },
  reviewPayFrom: {
    marginTop: 24,
  },
  iconAvatar: {
    backgroundColor: '#ffffff',
  },
}));
