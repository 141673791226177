import { Box, Text, useMantineTheme } from '@mantine/core';

const ErrorPill = ({ message }: { message: string }) => {
  const theme = useMantineTheme();
  return (
    <Box
      bg={theme.colors.critical[0]}
      px="xs"
      sx={{
        border: `1px solid ${theme.colors.critical[3]}`,
        borderRadius: theme.radius.sm,
      }}
    >
      <Text size="xs" color={theme.colors.critical[6]}>
        {message}
      </Text>
    </Box>
  );
};

export default ErrorPill;
