import { useEffect, useMemo } from 'react';
import {
  Avatar,
  Badge,
  Box,
  Progress,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { useStyles } from '../styles';
import useModal from 'components/modal/modal-hook';
import FlexbaseTable from 'components/table/flexbase-table';
import { getInitialsOfNames } from 'utilities/extensions/object';
import { formatCurrency } from '@utilities/formatters';
import { IoMdInfinite } from 'react-icons/io';
import { TableColumn } from 'react-data-table-component';
import { capitalizeOnlyFirstLetter } from '@utilities/formatters/format-strings';
import getPaddedAccountMask from '@utilities/formatters/get-padded-account-mask';
import { CreditCard } from '@services/flexbase/card.model';
import { useRecoilValue } from 'recoil';
import {
  ApplicationState,
  IsAccountant,
} from '../../../recoil-state/application/product-onboarding';
import { useDisclosure } from '@mantine/hooks';
import { CardTableRow } from './card-table-row.model';
import { ActiveFiltersReturnType } from '@common/filter/filters';
import { UseQueryResult } from '@tanstack/react-query';
import { useMatchMedia } from '@utilities/url/window-helpers';

type CardsTableProps = {
  dataQuery: UseQueryResult<CreditCard[], Error>;
  filterHook: ActiveFiltersReturnType<CardTableRow>;
  getDetailsModal: (card: CreditCard) => JSX.Element;
  getTableHeader: (cards: CreditCard[]) => JSX.Element;
};

export const CardsTable = ({
  dataQuery,
  filterHook,
  getTableHeader,
  getDetailsModal,
}: CardsTableProps) => {
  const isAccountant = useRecoilValue(IsAccountant);
  const modal = useModal();
  const theme = useMantineTheme();
  const isMobile = useMatchMedia(`(max-width: ${theme.breakpoints.sm})`);
  const { classes } = useStyles();
  const [clearSelectedRowsFlag, { toggle: clearSelectedRows }] =
    useDisclosure();

  const { activeFiltersArray } = filterHook;
  const { data: cardsData, isPending: isPendingCards } = dataQuery;

  const { personId } = useRecoilValue(ApplicationState);

  const allCards = useMemo(() => cardsData || [], [cardsData]);
  const tableData: CardTableRow[] = useMemo(() => {
    // TODO - fix this to amountSpendThisPeriod once API provides support for it.
    return allCards
      .map((cc) => {
        const amountSpentThisInterval = cc.toDateSpend?.toDateSpend ?? 0;
        const amountSpentThisMonth = cc.monthToDateSpends?.mtdSpend ?? 0;
        const utilization = cc.expensesTypes?.amount
          ? Math.round(
              (amountSpentThisInterval / cc.expensesTypes.amount) * 100,
            )
          : 0;

        return {
          ...cc,
          amountSpentThisMonth: amountSpentThisMonth,
          utilization,
        };
      })
      .filter((cc) => activeFiltersArray.every((filter) => filter.fn(cc)));
  }, [allCards, activeFiltersArray]);
  const handleRowClicked = (card: CreditCard) => {
    const isOwnCard = card.userId === personId;

    // accountants can only view details for their own cards
    if (isAccountant && !isOwnCard) {
      return;
    }

    isMobile
      ? modal.openFullModal(getDetailsModal(card))
      : modal.openRightModalNoOpacity(getDetailsModal(card));
  };

  const cardStatus: Record<string, string> = {
    active: 'Active',
    suspended: 'Frozen',
    issued: 'Pending',
    requested: 'Pending',
    terminated: 'Canceled',
  };

  const badgeColor: Record<string, string> = {
    issued: '#fff',
    requested: '#fff',
    active: '#EEEEF3',
    terminated: '#EEEEF3',
    suspended: 'rgba(48, 44, 255, 0.1)',
  };

  const columns: TableColumn<CardTableRow>[] = [
    {
      name: 'hidden_date',
      selector: (row) => row.createdAt,
      omit: true,
    },
    {
      name: 'Cardholder',
      cell: (row) => (
        <>
          {screen.width > 767 && (
            <Avatar radius="34px" className={classes.cardAvatar}>
              {getInitialsOfNames(row.holder)}
            </Avatar>
          )}
          <div className={classes.holderField}>{row.holder}</div>
          <Badge
            className={classes.badge}
            styles={{
              root: {
                border:
                  row.status === 'issued' || row.status === 'requested'
                    ? '1px solid #EEEEF3'
                    : 'unset',
                backgroundColor: badgeColor[row.status],
              },
              inner: { color: '#000 !important' },
            }}
          >
            {cardStatus[row.status]}
          </Badge>
        </>
      ),
      selector: (row) => row.holder,
      sortable: true,
      width: '350px',
    },
    {
      name: 'Card Name',
      selector: (row) => row.cardName,
      sortable: true,
    },
    {
      name: 'Credit Card',
      cell: (row) => (
        <Text>
          {row.status !== 'issued' && row.status !== 'requested' && row.last4
            ? getPaddedAccountMask(row.last4, 4)
            : ''}
        </Text>
      ),
      selector: (row) => row.last4,
      sortable: true,
    },
    {
      name: 'Spent This Month',
      cell: (row) => <div>{formatCurrency(row.amountSpentThisMonth ?? 0)}</div>,
      selector: (row) => row.amountSpentThisMonth ?? 0,
      sortable: true,
    },
    {
      name: 'Type',
      selector: (row) => capitalizeOnlyFirstLetter(row.cardType),
      sortable: true,
    },
    {
      name: 'Card Utilization',
      cell: (card) => {
        let utilization;
        if (card.expensesTypes?.interval) {
          utilization = (
            <div
              style={{ display: 'flex', width: '100%', alignItems: 'center' }}
            >
              <Progress
                style={{ width: '75%' }}
                value={card?.utilization || 0}
                color={theme.fn.primaryColor()}
              />{' '}
              <div className={classes.utilizationField}>
                {card?.utilization || 0}%
              </div>
            </div>
          );
        } else {
          utilization = (
            <div>
              <IoMdInfinite size={30} />
            </div>
          );
        }
        return utilization;
      },

      selector: (row) => row.utilization ?? 0,
      sortable: true,
    },
  ];

  useEffect(() => {
    // Table doesn't update the selection if data changes
    // so just clear the selection
    clearSelectedRows();
  }, [tableData]);

  return (
    <Box
      sx={{
        maxWidth: '1307.5px',
        margin: 'auto',
      }}
    >
      <div className={classes.cardsContainer} data-testid="cards-table">
        {getTableHeader(allCards)}

        <FlexbaseTable
          data={tableData}
          columns={columns}
          onRowClicked={handleRowClicked}
          defaultSortAsc={false}
          defaultSortFieldId="1"
          isFetchingData={isPendingCards}
          clearSelectedRows={clearSelectedRowsFlag}
        />
      </div>
    </Box>
  );
};
