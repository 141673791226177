import * as pdfjs from 'pdfjs-dist/legacy/build/pdf.mjs';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.mjs`;

async function _getPdfThumbnailUrl(pdf: pdfjs.PDFDocumentProxy) {
  try {
    const page = await pdf.getPage(1);
    const canvas = document.createElement('canvas');
    const canvasContext = canvas.getContext('2d');

    if (canvasContext) {
      const viewport = page.getViewport({ scale: 1 });
      canvas.width = viewport.width;
      canvas.height = viewport.height;

      await page.render({ canvasContext, viewport }).promise;

      return canvas.toDataURL();
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
}

export async function getPdfThumbnailUrlFromUrl(url: string) {
  const response = await fetch(url);
  const pdfData = await response.arrayBuffer();
  const pdf = await pdfjs.getDocument(pdfData).promise;

  return _getPdfThumbnailUrl(pdf);
}
