import { SimpleSegmentSelectedFilter } from '@common/filter/simple-segmented.filter';
import {
  useChargeCardsModalFilters,
  useCreditCardsModalFilters,
} from '../use-credit-cards-filters';
import { ActiveFiltersReturnType } from '@common/filter/filters';
import { CardTableRow } from '../../card-table-row.model';

const STATUS_OPTIONS = [
  {
    label: 'Active',
    filterCriteria: 'active',
    key: 'active',
  },
  {
    label: 'Pending activation',
    filterCriteria: 'issued',
    key: 'pending',
  },
  {
    label: 'Canceled',
    filterCriteria: 'terminated',
    key: 'terminated',
  },
  {
    label: 'Frozen',
    filterCriteria: 'suspended',
    key: 'suspended',
  },
];

type StatusFilterProps = {
  filterHook: ActiveFiltersReturnType<CardTableRow>;
};

const StatusFilter = ({ filterHook }: StatusFilterProps) => {
  return (
    <SimpleSegmentSelectedFilter
      label="Status"
      options={STATUS_OPTIONS}
      filterKey="status"
      filterHook={filterHook}
      includesValue={(row) => row.status}
    />
  );
};

export const CreditCardStatusFilter = () => {
  const filterHook = useCreditCardsModalFilters();

  return <StatusFilter filterHook={filterHook} />;
};

export const ChargeCardStatusFilter = () => {
  const filterHook = useChargeCardsModalFilters();

  return <StatusFilter filterHook={filterHook} />;
};
