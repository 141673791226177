import { flexbaseOnboardingClient } from '../services/flexbase-client';
import { useQuery } from '@tanstack/react-query';

const QUERY_KEY = 'paymentScheduleData';

export const useGetPaymentScheduleData = (companyId: string) => {
  return useQuery({
    queryKey: [QUERY_KEY, companyId],
    queryFn: async () => {
      return await flexbaseOnboardingClient.getEstBillings(companyId, {
        count: 4,
      });
    },
    meta: {
      errorMessage: 'Unable to get the estimated payment schedule  data',
    },
  });
};
