import { LoadingOverlay } from '@mantine/core';
import { useRecoilValue } from 'recoil';
import { RegistrationProductsState } from '../../../../recoil-state/application/product-onboarding';
import { ProductApplicationRoutes } from '../../onboarding.constants';
import { useNavigate } from 'react-router-dom';
import { useOnboardingStatusContext } from '../../../../providers/onboarding-status-context';
import { useEffect } from 'react';

export const ContinueWithApplicationStep = () => {
  const registeredForProducts = useRecoilValue(RegistrationProductsState);
  const { status } = useOnboardingStatusContext();
  const navigate = useNavigate();

  const handleFormSubmit = () => {
    let applicationUrl: string;

    if (registeredForProducts.length > 0) {
      if (registeredForProducts.every((p) => p === 'BANKING')) {
        applicationUrl = ProductApplicationRoutes.BANKING_ONLY;
      } else if (registeredForProducts.every((p) => p === 'CREDIT')) {
        applicationUrl = ProductApplicationRoutes.CREDIT_ONLY;
      } else {
        applicationUrl = ProductApplicationRoutes.COMBINED_CREDIT_BANKING;
      }
    } else {
      // fallback to product status
      const creditIncomplete =
        status.productStatus.credit.status === 'incomplete';
      const bankingIncomplete =
        status.productStatus.banking.status === 'incomplete';
      if (creditIncomplete && bankingIncomplete) {
        applicationUrl = ProductApplicationRoutes.COMBINED_CREDIT_BANKING;
      } else if (bankingIncomplete) {
        applicationUrl = ProductApplicationRoutes.BANKING_ONLY;
      } else if (creditIncomplete) {
        applicationUrl = ProductApplicationRoutes.CREDIT_ONLY;
      } else {
        applicationUrl = ProductApplicationRoutes.COMBINED_CREDIT_BANKING;
      }
    }

    navigate(applicationUrl);
  };

  useEffect(() => {
    handleFormSubmit();
  }, []);

  return <LoadingOverlay visible={true} />;
};
