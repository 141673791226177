import { Flex, Text } from '@mantine/core';
import { formatCurrency } from '@utilities/formatters';
import { ReactNode } from 'react';

type Props = {
  spendPlanId?: string;
  indicator: { icon: ReactNode; title: string; amount?: number };
};

const TileIndicators = ({ spendPlanId, indicator }: Props) => {
  return (
    <Flex
      direction="row"
      align={'center'}
      justify={spendPlanId ? '' : 'space-between'}
    >
      <Flex direction="row" align={'center'}>
        {indicator.icon}
        <Text
          mr="4px"
          ml="4px"
          fz={spendPlanId ? '10px' : '16px'}
          fw={spendPlanId ? 600 : 400}
          lh={spendPlanId ? '16px' : '24px'}
          lts="0.05px"
          c="neutral.7"
        >
          {indicator.title}
        </Text>
      </Flex>
      <Text
        fz={spendPlanId ? '12px' : '16px'}
        lh={spendPlanId ? '16px' : '24px'}
        c="neutral.7"
        fw={spendPlanId ? 400 : 500}
      >
        {formatCurrency(indicator?.amount || 0)}
      </Text>
    </Flex>
  );
};

export default TileIndicators;
