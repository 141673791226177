import { useQuery } from '@tanstack/react-query';
import { flexbaseOnboardingClient } from '../services/flexbase-client';

const QUERY_KEY = 'creditLines';

export const useCreditLines = () => {
  return useQuery({
    queryKey: [QUERY_KEY, 'lines'],
    queryFn: async () => {
      return await flexbaseOnboardingClient.getLinesOfCredit();
    },
    meta: {
      errorMessage: 'Could not retrieve card programs at this time.',
    },
  });
};

export const useCreditLinesTransactions = (lineOfCreditId: string) => {
  return useQuery({
    queryKey: [QUERY_KEY, 'transactions', lineOfCreditId],
    queryFn: async () => {
      return await flexbaseOnboardingClient.getLinesOfCreditTransactions(
        lineOfCreditId,
      );
    },
    meta: {
      errorMessage: 'Could not retrieve credit line transactions.',
    },
    enabled: !!lineOfCreditId,
  });
};
