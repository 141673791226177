import { Box, Button, Skeleton, Text } from '@mantine/core';
import { useDetailsStyles } from './details-styles';
import DetailsHeader from '../details-header/details-header';
import { capitalizeOnlyFirstLetter } from '@utilities/formatters';
import { formatDatetime } from 'utilities/formatters/format-datetime';
import GetPaddedAccountMask from 'utilities/formatters/get-padded-account-mask';

type Props = {
  createdAt: string;
  total: string;
  employee: string;
  type: string;
  status: string;
  paymentType: string;
  last4: string;
  sourceType?: string;
  sourceAccountName?: string;
  closeModal: () => void;
  paymentId?: string;
  isChargePay: boolean;
};

const PaymentDetails = ({
  total,
  createdAt,
  closeModal,
  employee,
  status,
  type,
  paymentType,
  sourceType,
  last4,
  sourceAccountName,
  paymentId,
  isChargePay,
}: Props) => {
  const { classes } = useDetailsStyles();
  const pdfLink = isChargePay ? `/charge-card/receipt/${paymentId}` : `/payment/card-receipt/${paymentId}`

  return (
    <div className={classes.baseContainer}>
      <DetailsHeader
        closeModal={closeModal}
        {...{ createdAt, total, type, status }}
      />
      <Box className={classes.section} h={'100%'}>
        <div className={classes.infoRow}>
          <div>
            <Text className={classes.infoTitle}>Paid by</Text>
            <Text className={classes.infoValue}>{employee}</Text>
          </div>
          <div>
            <Text className={classes.infoTitle}>Status</Text>
            {status ? (
              <Text className={classes.infoValue}>{status}</Text>
            ) : (
              <Skeleton
                className={classes.infoValue}
                height={20}
                width={100}
                radius="xl"
              />
            )}
          </div>
        </div>

        <div className={classes.infoRow}>
          <div className={classes.infoCluster}>
            <Text className={classes.infoTitle}>Date Created</Text>
            <Text className={classes.infoValue}>
              {formatDatetime(createdAt)}
            </Text>
          </div>
          <div className={classes.infoCluster}>
            <Text className={classes.infoTitle}>Type</Text>
            {paymentType ? (
              <Text className={classes.infoValue}>{paymentType}</Text>
            ) : (
              <Skeleton
                className={classes.infoValue}
                height={20}
                width={200}
                radius="xl"
              />
            )}
          </div>
        </div>
        <div className={classes.infoRow}>
          {sourceType !== 'Flex Early Pay Rebate' && (
            <div className={classes.infoCluster}>
              <Text className={classes.infoTitle}>Paid from</Text>
              {sourceAccountName && (
                <Text className={classes.infoValue}>{sourceAccountName}</Text>
              )}
              <Text className={classes.infoValue}>
                {last4
                  ? GetPaddedAccountMask(last4, 4)
                  : capitalizeOnlyFirstLetter(sourceType || '')}
              </Text>
            </div>
          )}
          {paymentId && (
            <div className={classes.infoCluster}>
              <Text className={classes.infoTitle}>Payment receipt</Text>
              <Button
                w={150}
                mt={10}
                onClick={() =>
                  window.open(pdfLink, '_blank')
                }
              >
                {' '}
                Download PDF{' '}
              </Button>
            </div>
          )}
        </div>
      </Box>
    </div>
  );
};

export default PaymentDetails;
