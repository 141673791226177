import useModal from '@common/modal/modal-hook';
import { Box, Card, Flex, Text, useMantineTheme } from '@mantine/core';
import { VscChromeClose } from 'react-icons/vsc';
type RowDetailsProps<T> = {
  exampleRow: T;
};

//  -- ROW DETAILS MODAL --
export default function RowDetails<T>({ exampleRow }: RowDetailsProps<T>) {
  const modal = useModal();
  const theme = useMantineTheme();
  return (
    <Box>
      {/* Header */}
      <Flex
        justify="space-between"
        align="center"
        p="xxl"
        bg="primarySecondarySuccess.8"
      >
        <Text size="xl" color="neutral.0">
          Example Item
        </Text>
        <VscChromeClose
          size={24}
          color={theme.colors.neutral[0]}
          onClick={modal.closeAllModals}
          style={{ cursor: 'pointer' }}
        />
      </Flex>
      {/* Body */}
      <Box p="md">
        <Card>
          <pre>
            <code>{JSON.stringify(exampleRow, null, 2)}</code>
          </pre>
        </Card>
      </Box>
    </Box>
  );
}
