import { useForm } from '@mantine/form';
import { formatZip } from '@utilities/formatters/format-address';
import { validateRequired, ValidateRoutingNumber } from '@utilities/validators';
import {
  DomesticWireRecipient,
  IndividualOrCompany,
} from 'areas/payments/components/send-payment/international-payments/util/types';

export const useDomesticWireRecipientForm = (
  initialValues: DomesticWireRecipient,
  recipientType: IndividualOrCompany,
) => {
  const form = useForm<DomesticWireRecipient>({
    initialValues: {
      ...initialValues,
      recipientType,
      type: 'wire',
      country: 'US',
    },
    validate: {
      routingNumber: (value) =>
        ValidateRoutingNumber(value)
          ? null
          : 'Routing number must be 9 digits and match an existing bank routing number.',
      accountNumber: (value) =>
        !!value && value.length <= 17 ? null : 'Account number is required.',
      address: (value) =>
        validateRequired(value) ? null : 'Address is required.',
      city: (value) => (validateRequired(value) ? null : 'City is required.'),
      state: (value) => (validateRequired(value) ? null : 'State is required.'),
      postalCode: (value) =>
        validateRequired(value) ? null : 'Postal code is required.',

      country: (value) =>
        validateRequired(value) ? null : 'Country code is required.',
    },
    transformValues: (values) => ({
      ...values,
      postalCode: formatZip(values.postalCode || ''),
    }),
  });

  return form;
};
