import { Box, Loader, useMantineTheme } from '@mantine/core';
import { DownloadIcon } from 'assets/svg';
import { statementsStyles } from './styles';
import { platformClient } from '@services/platform/platform-client';
import { useState } from 'react';
import { showNotification } from '@mantine/notifications';
import { IStatements } from 'types/statements';

type Props = {
  companyId: string;
  accountId: string;
  statement: IStatements;
  hidePdfButton: boolean;
};

const PdfCell = ({ companyId, accountId, statement, hidePdfButton }: Props) => {
  const { classes } = statementsStyles();
  const theme = useMantineTheme();
  const [loading, setLoading] = useState(false);

  const getMonthlyPdf = async () => {
    try {
      setLoading(true);
      const pdf = await platformClient.getMonthlyStatementPdf(
        companyId,
        accountId,
        { endDate: statement.before, startDate: statement.after },
      );
      if (pdf) {
        const file = window.URL.createObjectURL(pdf);
        const a = document.createElement('a');
        a.href = file;
        window.open(a.href);
      }
    } catch (error) {
      console.error('Unable to retrieve statement at this time', error);
      showNotification({
        title: 'Error',
        message: 'Unable to retrieve statement at this time',
        color: 'failure',
      });
    } finally {
      setLoading(false);
    }
  };

  return hidePdfButton ? (
    <div className={classes.contentCenter}>
      <Box className={classes.rectangleRounded}></Box>
    </div>
  ) : (
    <div onClick={() => getMonthlyPdf()} className={classes.statementRow}>
      {loading ? (
        <Loader
          size={'sm'}
          color={theme.fn.themeColor('primarySecondarySuccess', 3)}
        />
      ) : (
        <DownloadIcon />
      )}
    </div>
  );
};

export default PdfCell;
