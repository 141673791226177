import { Box, createStyles, Group, Text } from '@mantine/core';
import { CloseIcon } from '../../assets/svg';
import { FilterData } from './filters';

type FilterChipsProps<T> = {
  filterArray: FilterData<T>[];
  onRemoveFilter?: (filterKey: string) => void;
  onClearFilters?: () => void;
};

export const FilterChips = function <T>({
  filterArray,
  onRemoveFilter,
  onClearFilters,
}: FilterChipsProps<T>) {
  const { classes } = useStyles();
  const filterChips = filterArray.filter((f) => f.showChip);

  const handleRemove = (filterKey: string) => {
    onRemoveFilter?.(filterKey);
  };

  const handleClear = () => {
    onClearFilters?.();
  };

  return (
    filterChips.length > 0 && (
      <Group spacing={16} mih={30} mb="md">
        {filterChips.map((p) => (
          <Box
            key={p.key}
            className={classes.filterChip}
            onClick={() => handleRemove(p.key)}
          >
            <Text color="black" fz={14} fw={500}>
              {p.label}
            </Text>

            <CloseIcon color="black" style={{ marginLeft: 'auto' }} />
          </Box>
        ))}

        <Text className={classes.clearFilters} onClick={handleClear}>
          Clear filters
        </Text>
      </Group>
    )
  );
};

const useStyles = createStyles((t) => ({
  filterChip: {
    height: 30,
    borderRadius: t.radius.xs,
    border: `1px solid ${t.fn.themeColor('neutral', 3)}`,
    backgroundColor: t.fn.themeColor('neutral', 2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    padding: `0px ${t.spacing.md}`,
    gap: 4,
    [t.fn.smallerThan('md')]: {
      height: 'auto',
      padding: `0px ${t.spacing.xl}`,
    },
  },
  clearFilters: {
    cursor: 'pointer',
    color: t.fn.themeColor('primarySecondarySuccess', 2),
  },
}));
