import { useForm } from '@mantine/form';
import { validateRequired } from '@utilities/validators/validate-required';
import { isInternationalWire } from '../util';
import {
  AvailableCurrencies,
  IndividualOrCompany,
  InternationalPayMethod,
  InternationalRecipient,
} from '../util/types';
import { isEqual } from 'underscore';

const getInitialInternationalRecipientFormValues = (
  recipientName: string,
  recipientId: string,
  method: InternationalPayMethod,
) => ({
  name: recipientName,
  recipientId,
  nickName: '',
  accountHolder: '',
  recipientType: 'individual' as IndividualOrCompany,
  type: method,
  currency: isInternationalWire(method)
    ? (undefined as unknown as AvailableCurrencies)
    : 'USD',
  countryOfRecipientsBank: '',
  countryOfRecipient: '',
});

const isVowel = (char: string) => {
  return ['a', 'e', 'i', 'o', 'u'].includes(char.toLowerCase());
};

const validateField = (value: string | undefined, valueType: string) => {
  return validateRequired(value)
    ? null
    : `Please select ${
        isVowel(valueType.charAt(0)) ? 'an' : 'a'
      } ${valueType}.`;
};

export const useInternationalRecipientForm = (
  recipientName: string,
  recipientId: string,
  method: InternationalPayMethod,
) => {
  const form = useForm<InternationalRecipient>({
    initialValues: getInitialInternationalRecipientFormValues(
      recipientName,
      recipientId,
      method,
    ),
    validate: {
      name: (val) => validateField(val, 'nickname'),
      accountHolder: (val) => validateField(val, 'account holder'),
      currency: (val) => validateField(val, 'currency'),
      countryOfRecipientsBank: (val) => validateField(val, 'country'),
      countryOfRecipient: (val) => validateField(val, 'country'),
    },
  });

  return form;
};

export const getNewInitialInternationalRecipientFormValues = (
  recipientName: string,
  method: InternationalPayMethod,
  recipientType: IndividualOrCompany,
) => ({
  name: recipientName,
  nickName: '',
  accountHolder: '',
  recipientType,
  type: method,
  currency: isInternationalWire(method)
    ? (undefined as unknown as AvailableCurrencies)
    : 'USD',
  countryOfRecipientsBank: '',
  countryOfRecipient: '',
});

// TODO: rename + remove function above upon migrating to new 'add recipient' flow, `recipientId` should not be part of form values

type InitialIntlRecipientFormValues = {
  name: string;
  method: InternationalPayMethod;
  type: IndividualOrCompany;
};

type IntlRecipientFormValues =
  | InternationalRecipient
  | InitialIntlRecipientFormValues;

const isInitialIntlRecipientFormValues = (
  args: IntlRecipientFormValues,
): args is InitialIntlRecipientFormValues => {
  return isEqual(Object.getOwnPropertyNames(args), ['name', 'method', 'type']);
};

export const useNewInternationalRecipientForm = (
  args: IntlRecipientFormValues,
) => {
  const form = useForm<InternationalRecipient>({
    initialValues: isInitialIntlRecipientFormValues(args)
      ? getNewInitialInternationalRecipientFormValues(
          args.name,
          args.method,
          args.type,
        )
      : args,
    validate: {
      accountHolder: (val) => validateField(val, 'account holder'),
      currency: (val) => validateField(val, 'currency'),
      countryOfRecipientsBank: (val) => validateField(val, 'country'),
      countryOfRecipient: (val) => validateField(val, 'country'),
    },
  });

  return form;
};
