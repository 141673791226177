import ErrorIcon from '@common/error/error-icon';
import WizardErrorCard from '@common/error/wizard-error-card';
import { Button } from '@mantine/core';
import { InvoiceWizardError } from '../invoice-wizard';

type PaymentDetailsStepErrorProps = {
  submissionError: InvoiceWizardError;
  onClear: () => void;
};

const InvoiceWizardStepError = ({
  submissionError,
  onClear,
}: PaymentDetailsStepErrorProps) => {
  const handleRetry = () => {
    onClear();
  };

  return (
    <WizardErrorCard
      icon={<ErrorIcon />}
      title="Sorry about that..."
      subtitle={submissionError.message}
    >
      <Button size="sm" onClick={handleRetry}>
        Try again
      </Button>
    </WizardErrorCard>
  );
};

export default InvoiceWizardStepError;
