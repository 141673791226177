import { useState } from 'react';
import { useMediaQuery } from '@mantine/hooks';
import { Grid, UnstyledButton, Progress } from '@mantine/core';

import {
  useIssueDebitCard,
  useUpdateDebitCardStatus,
} from '@queries/use-debit-cards';
import { CloseIcon } from 'assets/svg';
import { IAddress } from 'types/user-info';
import ChooseReason from './steps/choose-reason';
import useModal from 'components/modal/modal-hook';
import { useStyles } from './cancel-debit-card-styles';
import FlexIconLink from '@common/icons/flex-icon-link';
import FlexbaseStepper from 'components/stepper/stepper';
import { CardByUser, IssueDebitCard } from 'services/flexbase/banking.model';
import ConfirmCancellation from './steps/confirm-cancellation';
import CancelDebitCardError from './steps/cancel-debit-card-error';
import ConfirmShippingAddress from './steps/confirm-shipping-address';
import ModalSuccess from 'areas/team-members/modal-success/modal-success';

type Props = {
  card: CardByUser;
  isChargeCard: boolean;
  setCardStatus: (status: string) => void;
};

enum Step {
  CHOOSE_REASON,
  CONFIRM,
  CONFIRM_ADDRESS,
  SUCCESS,
  ERROR,
}

const CancelDebitCard = ({ card, isChargeCard, setCardStatus }: Props) => {
  const { classes } = useStyles();
  const { closeAllModals } = useModal();
  const [isSelected, setIsSelected] = useState('');
  const [needsAuth, setNeedsAuth] = useState(false);
  const [step, setStep] = useState(Step.CHOOSE_REASON);
  const [issueNewCard, setIssueNewCard] = useState('yes');
  const useMobileView = useMediaQuery('(max-width: 767px)');
  const { mutate: mutateIssueCard, isPending: isPendingIssueCard } =
    useIssueDebitCard();
  const { mutate: mutateCardStatus, isPending: isPendingCardStatus } =
    useUpdateDebitCardStatus();

  const loading = isPendingIssueCard || isPendingCardStatus;

  const updateCardStatus = (address?: IAddress) => {
    mutateCardStatus(
      {
        cardId: card.id,
        status: 'cancelled',
      },
      {
        onSuccess: (data) => {
          const cardUpdated = data.card;
          setCardStatus(cardUpdated.status);
          if (issueNewCard === 'yes' && address) {
            issueCard(address);
          } else {
            setStep(Step.SUCCESS);
          }
        },
        onError: (error) => {
          console.error('Error updating card status', error);
          const errorMessages = [
            'a Customer Token is required',
            'Bearer token is invalid or expired',
          ];
          const { error: errorMsg = '' } = error
            ? JSON.parse(error.message)
            : {};
          if (errorMessages.some((message) => errorMsg.includes(message))) {
            setNeedsAuth(true);
          } else {
            setStep(Step.ERROR);
          }
        },
      },
    );
  };

  const cancelCard = () => {
    if (issueNewCard === 'no') {
      updateCardStatus();
    } else {
      setStep(Step.CONFIRM_ADDRESS);
    }
  };

  const issueCard = (address: IAddress) => {
    const cardForm: IssueDebitCard = {
      type: card.cardType.toLowerCase() as 'physical' | 'virtual',
      toUser: { id: card.userId },
      depositAccountId: card.depositAccountId,
      cardName: card.cardName ?? null,
      cardSubtype: card.cardSubtype,
      issuer: 'unit-co',
      shippingAddress: {
        address: address.line1,
        addressLine2: address.line2,
        city: address.city,
        state: address.state,
        postalCode: address.postalCode,
        country: address.country ?? 'US',
      },
      ...(card.expensesTypes && Object.keys(card.expensesTypes).length > 0
        ? { limits: card.expensesTypes }
        : {}),
    };

    mutateIssueCard(cardForm, {
      onSuccess: () => setStep(Step.SUCCESS),
      onError: (error) => {
        console.error('Error issuing a new card', error);
        setStep(Step.ERROR);
      },
    });
  };

  let comp;
  switch (step) {
    case Step.CHOOSE_REASON:
      comp = (
        <ChooseReason
          isChargeCard={isChargeCard}
          {...{ card, isSelected, setIsSelected }}
          nextStep={() => setStep(Step.CONFIRM)}
          onBack={closeAllModals}
        />
      );
      break;
    case Step.CONFIRM:
      comp = (
        <ConfirmCancellation
          isChargeCard={isChargeCard}
          {...{ card, loading, issueNewCard, setIssueNewCard, needsAuth }}
          nextStep={cancelCard}
          onBack={() => setStep(Step.CHOOSE_REASON)}
        />
      );
      break;
    case Step.CONFIRM_ADDRESS:
      comp = (
        <ConfirmShippingAddress
          isChargeCard={isChargeCard}
          {...{ card, loading, needsAuth }}
          onConfirmAddress={(address: IAddress) => updateCardStatus(address)}
          onBack={() => setStep(Step.CONFIRM)}
        />
      );
      break;
    case Step.SUCCESS:
      comp = (
        <ModalSuccess
          backTo="debit cards"
          closeModal={closeAllModals}
          title={
            issueNewCard === 'yes'
              ? 'The new card is on the way'
              : 'The card has been cancelled'
          }
          description={
            issueNewCard === 'yes'
              ? 'We’ve deactivated the old debit card, and the new card will arrive within the next 4-6 days.'
              : undefined
          }
        />
      );
      break;
    case Step.ERROR:
      comp = <CancelDebitCardError onClick={closeAllModals} />;
      break;
  }

  if (useMobileView) {
    return (
      <div className={classes.cancelDebitCard}>
        <UnstyledButton className="close-button" onClick={closeAllModals}>
          <CloseIcon color="#979797" />
        </UnstyledButton>
        {step < Step.SUCCESS && (
          <Progress radius="sm" value={((step + 1) * 100) / 3} />
        )}
        <div className="content">{comp}</div>
      </div>
    );
  }

  return (
    <Grid className={classes.cancelDebitCard}>
      <Grid.Col span={3}>
        <FlexIconLink width={90} />
        {step < Step.SUCCESS && (
          <FlexbaseStepper
            activeStep={step}
            stepsData={
              issueNewCard === 'yes'
                ? ['Choose Reason', 'Confirm', 'Confirm Address']
                : ['Choose Reason', 'Confirm']
            }
          />
        )}
      </Grid.Col>
      <Grid.Col className="content" span={6}>
        <div className={classes.card}>{comp}</div>
      </Grid.Col>
      <Grid.Col className="close" span={3}>
        <UnstyledButton onClick={closeAllModals}>
          <CloseIcon width={34} color="#979797" />
        </UnstyledButton>
      </Grid.Col>
    </Grid>
  );
};

export default CancelDebitCard;
