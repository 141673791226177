import { Flex, Image, rem, Text, Box } from '@mantine/core';
import { useMatchMedia } from '@utilities/url/window-helpers';
import ConciergeImage from 'assets/images/concierge.svg';

const ConciergeRequestsBox = () => {
  const isMobileScreenSize = useMatchMedia('(max-width: 767px)');
  const conciergeRequest = [
    {
      title: 'Dinner at Dorsia for two',
      description: 'Lorem ipsum dolor sit amet.',
      image: ConciergeImage,
    },

    {
      title: 'Dinner at Dorsia for two',
      description: 'Lorem ipsum dolor sit amet.',
      image: ConciergeImage,
    },
    {
      title: 'Dinner at Dorsia for two',
      description: 'Lorem ipsum dolor sit amet.',
      image: ConciergeImage,
    },
  ];
  return (
    <Box mt={isMobileScreenSize ? 10 : 150}>
      <Text fz={rem(20)} fw={500}>
        {' '}
        Examples of concierge requests{' '}
      </Text>
      <Flex
        justify={'space-between'}
        direction={isMobileScreenSize ? 'column' : 'row'}
      >
        {conciergeRequest.map((item) => {
          return (
            <Box
              sx={(theme) => ({
                backgroundColor: '#F2F2F3',
                borderRadius: theme.radius.md,
                marginTop: isMobileScreenSize ? 15 : 0,
              })}
              key={item.title}
              p={24}
              w={rem(418.67)}
            >
              <Image src={ConciergeImage} w={rem(370.67)} />
              <Text fz={rem(20)} mt={12}>
                {' '}
                {item.title}{' '}
              </Text>
              <Text fz={rem(14)}> {item.description} </Text>
            </Box>
          );
        })}
      </Flex>
    </Box>
  );
};

export default ConciergeRequestsBox;
