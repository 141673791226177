import { PropsWithChildren } from 'react';
import { CreateWizard, useCreateWizard } from './create-wizard';
import { Divider } from '@mantine/core';

type Props = PropsWithChildren<{
  hideBack?: boolean;
  hideNext?: boolean;
}>;

/**
 * You can make a custom UI wrapper for your steps.
 *
 * This one just extends the default <CreateWizard.Step> with some added elements.
 *
 * Here we check if the current step has some metadata, if so, render it.
 */
export const CustomWizardStepLayout = ({
  children,
  hideBack = false,
  hideNext = false,
}: Props) => {
  const { currentStep } = useCreateWizard();

  return (
    <CreateWizard.Step hideBack={hideBack} hideNext={hideNext}>
      {children}

      {currentStep?.metadata?.someHint ? (
        <>
          <Divider my={16} />

          {currentStep.metadata.someHint}
        </>
      ) : null}
    </CreateWizard.Step>
  );
};
