import { Box, createStyles, Flex, Group, Stack, Text } from '@mantine/core';
import { formatCurrency } from '@utilities/formatters';
import { ReactNode } from 'react';

type TableSummaryCardProps = {
  title: string;
  balance: number;
  error: boolean;
  footer?: string | ReactNode;
  isLast?: boolean;
  action?: ReactNode;
};

const TableSummaryCard = ({
  title,
  balance,
  footer,
  error,
  isLast,
  action,
}: TableSummaryCardProps) => {
  const { classes, theme } = useStyles();

  return (
    <Group
      h={'100%'}
      p="xl"
      align="flex-start"
      sx={{
        borderRight: isLast ? 'none' : `2px solid ${theme.colors.neutral[3]}`,
      }}
      position="apart"
    >
      <Stack spacing={0} className={classes.bodyStack}>
        <Text size="sm" weight={500}>
          {title}
        </Text>
        {error ? (
          <Flex mt="2rem">
            <Box className={classes.placeholderLineStyle}></Box>
            <Box className={classes.placeholderLineStyle}></Box>
          </Flex>
        ) : (
          <>
            <Text size="3rem">{formatCurrency(balance)}</Text>
            {footer && <TableSummaryCardFooter footer={footer} />}
          </>
        )}
      </Stack>
      {action}
    </Group>
  );
};

const TableSummaryCardFooter = ({ footer }: { footer: string | ReactNode }) => {
  if (typeof footer === 'string') {
    return (
      <Text size="sm" color={'neutral.7'}>
        {footer}
      </Text>
    );
  }
  return footer;
};

const useStyles = createStyles((theme) => ({
  placeholderLineStyle: {
    height: '0.3rem',
    width: '1rem',
    backgroundColor: theme.colors.neutral[9],
    marginBottom: '0.5rem',
    marginLeft: '0.1rem',
  },
  bodyStack: {
    flex: 1,
  },
}));

export default TableSummaryCard;
