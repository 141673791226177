import { Text, useMantineTheme } from '@mantine/core';
import { useGetPaymentScheduleData } from '@queries/use-credit-payments-schedule-chart';
import { formatCurrency } from '@utilities/formatters';
import { CompanyIdState } from 'areas/onboarding/onboarding-form.state';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  ReferenceLine,
  Tooltip,
} from 'recharts';
import { useRecoilValue } from 'recoil';

type CustomShapeProps = {
  cx?: string | number;
  cy?: string | number;
  payload?: {
    x: string;
    y: string;
    radius: number;
  };
};

const CustomShape = ({ cx, cy, payload }: CustomShapeProps) => {
  const theme = useMantineTheme();
  const { y, x, radius } = payload || {};
  if (y !== '0.00') {
    return (
      <g>
        <circle
          cx={cx}
          cy={cy}
          r={x === 'Today' ? 0 : radius ? radius * 1.5 : 0}
          fill={theme.colors.tertiary[1]}
          opacity={0.7}
        />
        <circle
          cx={cx}
          cy={cy}
          r={x === 'Today' ? 0 : radius}
          fill={theme.colors.primarySecondarySuccess[6]}
        />
      </g>
    );
  } else {
    return null;
  }
};

const ScheduleChart = () => {
  const theme = useMantineTheme();
  const companyId = useRecoilValue(CompanyIdState);
  const { data } = useGetPaymentScheduleData(companyId);

  const chartData = useMemo(() => {
    const maxAmount = Math.max(
      ...(data?.estBills.map((item) => parseFloat(item.minimumDue)) || []),
    );
    const minAmount = Math.min(
      ...(data?.estBills.map((item) => parseFloat(item.minimumDue)) || []),
    );

    const formattedData = data?.estBills.map((item) => {
      const amountNumber = parseFloat(item.minimumDue);

      const maxRadius = 29;
      const minRadius = 19;

      let radius;

      if (amountNumber === 0) {
        radius = 0;
      } else if (maxAmount === minAmount) {
        radius = minRadius;
      } else {
        radius =
          minRadius +
          ((amountNumber - minAmount) / (maxAmount - minAmount)) *
            (maxRadius - minRadius);
      }
      return {
        x: DateTime.fromISO(item.billDate).toFormat('LLL dd'),
        y: item.minimumDue,
        radius: radius,
      };
    });
    if (data) {
      return [{ x: 'Today', y: '0' }, ...(formattedData || [])];
    }
    return [];
  }, [data]);

  const maxValue = chartData.reduce((max, item) => {
    const yValue = parseFloat(item.y);
    return yValue > max ? yValue : max;
  }, -Infinity);

  return (
    <div>
      <Text ml={30} mt={15}>
        {' '}
        Current amount due{' '}
      </Text>
      <ResponsiveContainer width="100%" height={350}>
        <ScatterChart
          margin={{
            top: 50,
            right: 20,
            bottom: 10,
            left: 20,
          }}
        >
          <ReferenceLine
            x="Today"
            strokeDasharray="3 3"
            stroke={theme.colors.neutral[9]}
          />
          <CartesianGrid x={0} horizontal={false} vertical={false} />
          {chartData?.map((item, index) => {
            if (item.x === 'Today') {
              return null;
            } else {
              return (
                <ReferenceLine
                  key={index}
                  x={item.x}
                  strokeDasharray="3 3"
                  stroke={theme.colors.neutral[3]}
                  label={item.y && formatCurrency(item.y)}
                />
              );
            }
          })}
          <XAxis
            dataKey="x"
            axisLine={{ stroke: theme.colors.neutral[3] }}
            tickLine={false}
            tick={{ fontSize: 14 }}
          />
          <YAxis hide={true} type="number" dataKey="y" domain={[0, maxValue]} />
          <Tooltip
            cursor={{ strokeDasharray: '3 3' }}
            formatter={(value) => [formatCurrency(Number(value as string))]}
          />
          <Scatter data={chartData} shape={<CustomShape />}></Scatter>
        </ScatterChart>
      </ResponsiveContainer>
      <Text align="center" ml={30} mb={15} mt={15} fz="sm">
        Billing dates
      </Text>
    </div>
  );
};

export default ScheduleChart;
