import { createMultiStepFormRouteContext } from '../../../providers/multi-step-form-provider';

export type RedemptionFormState = {
  fullPointsRedemption: boolean;
  points: number;
  cashBack: number;
  redemptionAccountId: string;
  error: string | null;
  loading: boolean;
};

export const [RedemptionProvider, useRedemptionMultistepFormContext] =
  createMultiStepFormRouteContext<RedemptionFormState>();
