import { createStyles, rem } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
    
  earlyPayContainer: {
    position: 'relative',
    borderRadius: theme.defaultRadius,
    border: `1px solid ${theme.fn.themeColor('neutral', 1)}`,
    minWidth: '26.25rem',
    height: '12.5rem',
    overflow: 'hidden',
    '@media(max-width: 767px)': {
      width: '100%',
      minWidth: 'unset',
    },
  },
  widgetContainer: {
    display: 'flex',
    marginBottom:'2rem',
    width: '100%',
    '@media(max-width: 767px)': {
      flexDirection: 'column',
      gap: 24,
    },
    gap: rem(16),
  },
  widget: {
    overflow: 'hidden',
    '@media(min-width: 767px)': {
      flex: 1,
    },
  },

}));
