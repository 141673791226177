import { useQuery } from '@tanstack/react-query';
import { flexbaseOnboardingClient } from '../services/flexbase-client';

const QUERY_KEY = 'plaidCompanyAccounts';
const BALANCES_QUERY_KEY = 'balancesPlaidCompanyAccounts';

export const usePlaidCompanyAccounts = (enabled?: boolean) => {
  return useQuery({
    queryKey: [QUERY_KEY],
    queryFn: async () => {
      return await flexbaseOnboardingClient.getPlaidCompanyAccounts();
    },
    meta: {
      errorMessage: 'Unable to retrieve external accounts.',
    },
    enabled: enabled ?? true,
  });
};

export const useBalancesPlaidCompanyAccounts = (
  isEnabled: boolean,
  asis: boolean,
) => {
  return useQuery({
    queryKey: [BALANCES_QUERY_KEY, asis],
    queryFn: async () => {
      return await flexbaseOnboardingClient.getLinkedAccountBalances(asis);
    },
    meta: {
      errorMessage: 'Unable to retrieve balances for external accounts.',
    },
    enabled: isEnabled,
  });
};
