import { atom, selector } from 'recoil';
import { OnboardingCompany, OnboardingUser } from '../../types/onboarding-info';
import { CompanySimplifiedOwner } from '../../types/company';
import { ApplicationState } from '../../recoil-state/application/product-onboarding';

export const LoadingState = atom<boolean>({
  key: 'loading',
  default: false,
});

export const CompanyIdState = selector<string>({
  key: 'comp_id',
  get: ({ get }) => {
    const { company } = get(ApplicationState);
    return company?.id || '';
  },
});

export const UserIdState = selector<string>({
  key: 'user_id',
  get: ({ get }) => {
    const { user } = get(ApplicationState);
    return user?.id || '';
  },
});

export const getMainOwner = (
  company: OnboardingCompany,
  user: OnboardingUser,
): CompanySimplifiedOwner => {
  const userOwnerIndex = company.owners?.findIndex((o) => o.id === user.id);

  let mainOwner: CompanySimplifiedOwner = {
    id: '',
    percent: 0,
    lastName: '',
    title: '',
    firstName: '',
    email: '',
  };

  if (userOwnerIndex > -1) {
    const userOwner = company.owners[userOwnerIndex];
    mainOwner = {
      id: userOwner.id || '',
      percent: userOwner.ownershipPct || 0,
      title: userOwner.jobTitle || '',
      firstName: userOwner.firstName || '',
      lastName: userOwner.lastName || '',
      email: userOwner.email || '',
    };
  } else if (company.owners?.length > 0) {
    mainOwner = company.owners.reduce<CompanySimplifiedOwner>(
      (prev, currentValue) => {
        const ownershipPercent = currentValue.ownershipPct ?? 0;
        if (ownershipPercent > prev.percent) {
          return {
            id: currentValue.id || '',
            firstName: currentValue.firstName || '',
            lastName: currentValue.lastName || '',
            percent: ownershipPercent,
            title: currentValue.jobTitle || '',
            email: currentValue.email || '',
          };
        }
        return prev;
      },
      mainOwner,
    );
  }

  return mainOwner;
};
