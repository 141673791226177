import {
  BankingIcon,
  BankingIconActive,
  CreditIcon,
  CreditIconActive,
  DocumentsIcon,
  DocumentsIconActive,
  EliteLogo,
  InsuranceIcon,
  InsuranceIconActive,
  PaymentIcon,
  PaymentIconActive,
  SettingsIconNew,
  SettingsIconNewActive,
  SpendPlanIcon,
  TeamsIcon,
  TeamsIconActive,
  TreasuryIcon,
  TreasuryIconActive,
} from '../../assets/svg';
import { ReactElement, useMemo } from 'react';
import { IconType } from 'react-icons';
import { UserRole } from '../../types/user-info';
import {
  useBillPayCreditFlag,
  useBillPayFeatureFlag,
  useSpendPlansFeatureFlag,
} from '@utilities/feature-flags';
import {
  ApplicationState,
  OptedProduct,
} from '../../recoil-state/application/product-onboarding';
import { useCreditLines } from '@queries/use-credit-lines';
import { useRecoilValue } from 'recoil';
import { Line } from '@services/flexbase/credit.model';
import { ApplicationStatus } from 'recoil-state/application/product-onboarding.models';

/**
 * Hack to allow users with approved credit but limit = 0 to access credit page.
 */
function isLineOfCreditClosed(line: Line, productStatus: ApplicationStatus) {
  let isClosed = line.status === 'Closed';

  const appApproved = productStatus.credit.appStatus === 'approved';
  const creditLimit = parseFloat(productStatus.credit.creditLimit ?? '-1');
  const validLimit = !isNaN(creditLimit) && creditLimit >= 0;

  if (appApproved && validLimit) {
    isClosed = false;
  }

  return isClosed;
}

const useActiveCreditPrograms = () => {
  const { data } = useCreditLines();
  const { productStatus } = useRecoilValue(ApplicationState);
  const billPayCreditEnabled = useBillPayCreditFlag();

  const linesOfCredit = Array.isArray(data?.lines) ? data.lines : [];

  const subRouteLabel = {
    lithic: 'Net-60 card',
    unit: 'Charge card',
    billpay: 'Bill pay credit',
  };
  const subRouteLocation = {
    lithic: '/cards',
    unit: '/charge-card',
    billpay: '/bill-pay-credit',
  };

  const activeCreditPrograms = useMemo(() => {
    const filteredPrograms = linesOfCredit
      .filter((item) => !isLineOfCreditClosed(item, productStatus))
      .filter((item) => item.issuer !== 'billpay' || billPayCreditEnabled)
      .map((item) => ({
        label: subRouteLabel[item.issuer] || 'Unknown',
        location: subRouteLocation[item.issuer] || '/',
      }));
    return filteredPrograms;
  }, [linesOfCredit]);

  return activeCreditPrograms;
};

export type SubMenuOption = {
  label: string;
  location: string;
  disabled?: boolean;
};

export type MenuOption = {
  label: string;
  location: string;
  icon: ReactElement<IconType>;
  disabled?: boolean;
  subRoutes?: SubMenuOption[];
  onClick?: () => void;
  unclickable?: boolean;
  devOnly?: boolean;
  adminOnly?: boolean;
  rolesAllowed?: UserRole[];
  activeIcon: ReactElement<IconType>;
  product: OptedProduct | null;
};

const getNavbarOptions = (
  featureFlags: Record<string, boolean>,
  linesOfCreditSubRoutes: SubMenuOption[],
): MenuOption[] => [
  {
    label: 'Treasury Management',
    location: '/accounts',
    icon: <TreasuryIcon style={{ marginLeft: '2px', marginBottom: '3px' }} />,
    activeIcon: (
      <TreasuryIconActive style={{ marginLeft: '2px', marginBottom: '3px' }} />
    ),
    product: 'TREASURY',
  },
  {
    label: 'Credit',
    location: '/cards',
    icon: <CreditIcon />,
    activeIcon: <CreditIconActive />,
    product: 'CREDIT',
    subRoutes:
      linesOfCreditSubRoutes.length > 0 ? linesOfCreditSubRoutes : undefined,
  },
  {
    label: 'Banking',
    location: '/banking',
    icon: <BankingIcon />,
    rolesAllowed: ['ADMIN', 'ACCOUNTANT'],
    activeIcon: <BankingIconActive />,
    product: 'BANKING',
  },
  {
    label: 'Spend plans',
    location: '/spend-plans',
    icon: <SpendPlanIcon />,
    activeIcon: <SpendPlanIcon />,
    product: null,
    disabled: !featureFlags.spendPlansEnabled,
  },
  {
    label: 'Payments & Bill Pay',
    location: '',
    icon: <PaymentIcon />,
    rolesAllowed: ['ADMIN', 'ACCOUNTANT', 'COMPTROLLER'],
    activeIcon: <PaymentIconActive />,
    product: 'BANKING',
    subRoutes: [
      {
        label: 'Payments',
        location: '/payments',
      },
      {
        label: 'Bill pay',
        location: '/bill-pay',
        disabled: !featureFlags.billPayEnabled,
      },
    ],
  },
  {
    label: 'Insurance',
    location: '/insurance',
    icon: <InsuranceIcon />,
    activeIcon: <InsuranceIconActive />,
    disabled: true,
    unclickable: true,
    product: null, // We don't yet have a specific insurance product.
  },
  {
    label: 'Points and Rewards',
    location: '/benefits-and-rewards',
    disabled: true,
    icon: <EliteLogo />,
    rolesAllowed: ['ADMIN'],
    activeIcon: <EliteLogo />,
    product: null,
  },
];

/**
 * Modify this to add feature-flagged menu options
 */
export const useTopNavbarOptions = () => {
  const spendPlansEnabled = useSpendPlansFeatureFlag();
  const billPayEnabled = useBillPayFeatureFlag();
  const billPayCreditEnabled = useBillPayCreditFlag();

  const activeCreditPrograms = useActiveCreditPrograms();

  const NAVBAR_OPTIONS = getNavbarOptions(
    {
      spendPlansEnabled,
      billPayEnabled,
      billPayCreditEnabled,
    },
    activeCreditPrograms,
  );

  return NAVBAR_OPTIONS;
};

export const BOTTOM_NAVBAR_OPTIONS: MenuOption[] = [
  {
    label: 'Documents',
    location: '/documents',
    icon: <DocumentsIcon />,
    activeIcon: <DocumentsIconActive />,
    product: null,
    adminOnly: true,
    devOnly: true,
  },
  {
    label: 'Team',
    location: '/team',
    icon: <TeamsIcon />,
    activeIcon: <TeamsIconActive />,
    adminOnly: true,
    product: null,
  },
  {
    label: 'Settings',
    location: '/settings',
    icon: <SettingsIconNew />,
    activeIcon: <SettingsIconNewActive />,
    product: null,
  },
];
