import {
  InvoiceWizard,
  PaymentSource,
  useInvoiceWizard,
} from '../../invoice-wizard';
import ReviewBodyBankingAccount from './review-body-banking-account';
import ReviewBodyLoc from './review-body-loc';

const ReviewPayStep = () => {
  const { state } = useInvoiceWizard();
  const { sendFrom } = state.paymentInfo || {};
  const { recipientAccount, isActionDisabled } = state || {};
  const recipientName =
    state.recipient.name ??
    `${state.recipient.firstName} ${state.recipient.lastName}`;
  return (
    <InvoiceWizard.Step hideBack hideNext>
      {state.paymentInfo?.paymentSource === PaymentSource.ACCOUNT && (
        <ReviewBodyBankingAccount
          recipientAccount={recipientAccount}
          recipientName={recipientName}
          sendFrom={sendFrom}
          sendOnDate={state.paymentInfo!.sendOn}
          invoiceTotalFormatted={state.invoiceTotal?.formatted ?? ''}
          memo={state.memo}
        />
      )}
      {state.paymentInfo?.paymentSource === PaymentSource.LOC && (
        <ReviewBodyLoc
          recipientAccount={recipientAccount}
          recipientName={recipientName}
          sendFrom={sendFrom}
          sendOnDate={state.paymentInfo!.sendOn}
          invoiceTotal={state.invoiceTotal}
          memo={state.memo}
          description={state.description}
          isActionDisabled={isActionDisabled ?? false}
        />
      )}
    </InvoiceWizard.Step>
  );
};

ReviewPayStep.stepId = 'review-pay';

export default ReviewPayStep;
