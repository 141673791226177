import useModal from '@common/modal/modal-hook';
import {
  Box,
  Button,
  Group,
  Stack,
  Text,
  createStyles,
  useMantineTheme,
} from '@mantine/core';
import { Fragment, ReactNode, useEffect, useRef } from 'react';
import { VscChromeClose } from 'react-icons/vsc';
import { ActiveFiltersReturnType } from './filters';
import { useMatchMedia } from '@utilities/url/window-helpers';

export type FilterConfig = {
  header: string;
  icon?: ReactNode;
  key: string;
  component: ReactNode;
};

type Props = {
  filters: FilterConfig[];
  onSave: () => void;
  onCancel: () => void;
};

export const FilterModal = ({ filters, onSave, onCancel }: Props) => {
  const { classes, theme } = useStyles();

  const filterMap = filters.reduce<Record<string, ReactNode>>((p, current) => {
    p[current.key] = current.component;
    return p;
  }, {});

  const handleApply = () => onSave();
  const handleCancel = () => onCancel();
  const handleClose = () => onCancel();

  return (
    <>
      <Group className={classes.headerRow1}>
        <Stack spacing="xs">
          <Text className={classes.title}>Filters</Text>
        </Stack>
        <button className={classes.closeButton} onClick={handleClose}>
          <VscChromeClose size={34} color="#FFFF" />
        </button>
      </Group>
      <Box
        sx={{
          width: '100%',
          backgroundColor: theme.fn.themeColor('neutral', 2),
        }}
        p={35}
      >
        {filters.map((f) => (
          <Fragment key={f.key}>
            <Text fw={500}>{f.header}</Text>
            <Box mt={'0.5rem'} mb={'1rem'} w="100%">
              {filterMap[f.key]}
            </Box>
          </Fragment>
        ))}
        <Box mt="3rem">
          <Group className={classes.floatingButtonGroup}>
            <Button
              classNames={{ root: classes.btnCancel }}
              variant="outline"
              onClick={handleCancel}
              bg="neutral.0"
            >
              Cancel
            </Button>
            <Button onClick={handleApply}>Apply filters</Button>
          </Group>
        </Box>
      </Box>
    </>
  );
};

type UseFilterModalProps<T> = {
  filterHook: ActiveFiltersReturnType<T>;
  modalFilterHook: ActiveFiltersReturnType<T>;
};

/**
 * Custom utility hook to handle opening, saving, and canceling the FilterModal.
 *
 * @returns The original filterHook values, along with a utility function to open the FilterModal.
 *
 * @example
 * const filterHook = useMyTableFilters();
 * const modalFilterHook = useMyModalFilters();
 * const {
 *   getFilterByKey,
 *   openResponsiveFilterModal,
 * } = useFilterModal({ filterHook, modalFilterHook });
 *
 * const searchValue = getFilterByKey('search').filterValue;
 *
 * const filters: FilterConfig[] = [
 *   // your filter configs
 * ];
 *
 * const handleOpen = () => {
 *   openResponsiveFilterModal(filters);
 * };
 */
export const useFilterModal = function <T>({
  filterHook,
  modalFilterHook,
}: UseFilterModalProps<T>) {
  const theme = useMantineTheme();
  const isMobile = useMatchMedia(`(max-width: ${theme.breakpoints.md})`);
  const { openFullModal, openRightModal, closeAllModals } = useModal();
  const {
    activeFilters: activeTableFilters,
    applyAllFilters: applyToTableFilters,
  } = filterHook;
  const {
    activeFilters: activeModalFilters,
    applyAllFilters: applyToModalFilters,
  } = modalFilterHook;

  /**
   * Any props passed to a Modal are basically snapshots of the values during
   * initial render. In order for any of the handlers to access current values,
   * we need to store them in a ref.
   */
  const activeModalFiltersRef = useRef(activeModalFilters);

  useEffect(() => {
    activeModalFiltersRef.current = activeModalFilters;
  }, [activeModalFilters]);

  const handleModalSave = () => {
    // apply the updated modal filters to the table filters
    applyToTableFilters(activeModalFiltersRef.current);
    closeAllModals();
  };

  const handleModalCancel = () => {
    closeAllModals();
  };

  /**
   * Opens the FilterModal by calling openRightModal() (desktop) or openFullModal (mobile).
   *
   * Override the default save/cancel handlers by passing in options.
   *
   * @param [options.onSave] Optional. Handle the save event.
   * @param [options.onCancel] Optional. Handle the cancel event.
   */
  const openResponsiveFilterModal = (
    filters: FilterConfig[],
    options: {
      onSave?: () => void;
      onCancel?: () => void;
    } = {},
  ) => {
    // initialize the modal filters to the current filter state
    applyToModalFilters(activeTableFilters);

    const openFn = isMobile ? openFullModal : openRightModal;

    return openFn(
      <FilterModal
        filters={filters}
        onSave={options.onSave ?? handleModalSave}
        onCancel={options.onCancel ?? handleModalCancel}
      />,
    );
  };

  return {
    ...filterHook,
    openResponsiveFilterModal,
  };
};

const useStyles = createStyles((theme) => ({
  headerRow1: {
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 35,
    backgroundColor: theme.fn.themeColor('primarySecondarySuccess', 8),
    '> div:last-of-type': {
      marginRight: '-11px',
    },
    flexWrap: 'nowrap',
  },
  closeButton: {
    display: 'flex',
    width: '34px',
    boxSizing: 'content-box',
    flexDirection: 'column',
    color: theme.fn.themeColor('neutral', 5),
    cursor: 'pointer',
    borderRadius: '8px',
    alignItems: 'center',
    backgroundColor: 'unset',
    border: '1px solid transparent',
    '&:focus': {
      outline: 'none',
    },
  },
  title: {
    height: '43px',
    color: theme.fn.themeColor('neutral', 0),
    fontFamily: theme.fontFamily,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '2.25rem',
  },
  floatingButtonGroup: {
    position: 'fixed',
    bottom: '1rem',
    right: '20px',
    justifyContent: 'right',
  },
  btnCancel: {
    ':hover': {
      background: 'white !important',
    },
  },
}));
