import { IsFlexHttpError } from '../../services/platform/models/authorize.models';

export const roleDescriptions = {
  adminLimited:
    'All admin functionality except issuing debit cards and transferring funds within Flex Banking. Limited access Admins can submit requests to send payments from Flex Banking.',
  adminFull:
    'Full account access; open bank accounts, invite and manage users, manage debit and Credit cards, make payments on credit card. Can transfer funds, send domestic ACH/wires, and approve requests to transfer funds and send domestic ACH/wires, update all user/role settings, manage integrations.',
  employee:
    'Can view transactions on credit cards issued to them, can freeze or unfreeze their cards, dispute transactions, upload receipts and add memos.',
  bookKeeper:
    'Can view all account transactions, download statements, view memos, download receipts. Cannot make any transfers or transactions.',
};

export const PHONE_NOT_SUPPORTED_ERROR_COPY =
  'This phone number is not supported. We only accept mobile phone numbers from the US and Canada. Contact our support team at support@flex.one if you wish to use a VOIP or international phone number.';

function tryParseError(error: any): Record<string, any> | null {
  if (IsFlexHttpError(error)) {
    return error.errors ?? null;
  }

  if (error.text) {
    try {
      const parsedError = JSON.parse(error.text);
      return (parsedError.errors as Record<string, any>) ?? null;
    } catch {
      return null;
    }
  }

  return null;
}

export function isPhoneNotSupportedError(e: unknown): boolean {
  const errors = tryParseError(e);

  return (
    errors !== null &&
    Object.values(errors).some(
      (err) =>
        typeof err === 'string' &&
        err.toLowerCase().includes('unsupported phone number'),
    )
  );
}
