import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  container: {
    padding: '1.5rem',
    color: theme.colors.blackish[0],
    borderRadius: theme.defaultRadius,
    border: `1px solid ${theme.fn.themeColor('neutral', 1)}`,
    backgroundColor: theme.fn.themeColor('neutral', 0),
    'table:not(.main_account) > tbody > tr:hover': {
      backgroundColor: '#FFEEEC',
    },
  },
  pdfTableHeader: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    fontWeight: 500,
  },
  blurryTextStyle: {
    filter: 'blur(4px)',
  },
}));
