import { Box, Card, Text } from '@mantine/core';
import { SampleTable } from './components/table';

export default function SampleTableContainer() {
  return (
    <Box bg="neutral.2" p="md" h="100vh">
      <Text size="xl" mb="md">
        Example Flexbase Table Setup
      </Text>
      <Text size="sm" mb="md">
        Here are the main pieces of a Flexbase table:
        <ul>
          <li>
            <b>Header (table-header.tsx)</b> - includes search, filters, and
            action buttons
          </li>
          <li>
            <b>Table (table.tsx)</b> - the table rows and columns themselves
          </li>
          <li>
            <b>Data (use-example-data.tsx)</b> - hook to fetch the data that we
            need displayed in the table
          </li>
          <li>
            <b>Filters (use-example-filters.tsx)</b> - hook that contains the
            filters state applied to the table
          </li>
          <li>
            <b>Row Details (row-details.tsx)</b> - the contents of the modal
            that pops up when you click on a row
          </li>
        </ul>
        The idea is to use these as a starting point and customize it to your
        feature needs, trying to keep the code as clean and modular as possible.
      </Text>
      <Text size="sm" mb="md">
        More details / documentation in the code (areas/cheat-sheet/table)! 🤠
      </Text>
      <Card bg="neutral.0" p="md" withBorder>
        <SampleTable />
      </Card>
    </Box>
  );
}
