import TabTableLayout from '@common/layouts/tab-table-layout';
import { Button, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { SearchIcon } from 'assets/svg';
import FlexbaseInput from 'components/input/flexbase-input';
import useModal from 'components/modal/modal-hook';
import { FlexbaseTable } from 'components/table';
import { DateTime } from 'luxon';
import { useMemo, useState } from 'react';
import { TableColumn } from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { UserInfoState } from 'types/user-info';
import RecipientActions from '../components/recipients/recipient-actions';
import ViewRecipientModal from '../components/recipients/view-recipient';
import { useRecipientDetailsFeatureFlag } from '@utilities/feature-flags';
import { useGetAllRecipients } from '@queries/use-recipients';
import { Recipient } from 'types/recipient';

const Recipients = () => {
  const { openModal } = useModal();
  const theme = useMantineTheme();
  const useMobileView = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
  const [filterText, setFilterText] = useState('');
  const user = useRecoilValue(UserInfoState);
  const navigate = useNavigate();
  const {
    data,
    isLoading: isLoadingCounterparties,
    refetch,
  } = useGetAllRecipients();

  const recipientDetailsFeatureFlagIsOn = useRecipientDetailsFeatureFlag();
  const recipients = data?.recipients ?? [];
  const filteredRecipients =
    recipients.filter((recipient) => recipient.status === 'active') ?? [];

  const filteredTableData = useMemo(() => {
    const normalizedFilterText = filterText.trim().toLowerCase();

    return filteredRecipients
      .filter((d) => d.name.toLowerCase().includes(normalizedFilterText))
      .sort((a, b) => b.paymentsCount - a.paymentsCount);
  }, [filterText, filteredRecipients]);

  const getLastUsedValue = (row: Recipient) => {
    const isValidCheck = (dateT: string | null) => {
      if (!dateT) return null;

      return DateTime.fromSQL(dateT).isValid ? DateTime.fromSQL(dateT) : null;
    };

    const lastUSed = isValidCheck(row.lastUsedAt);

    return lastUSed;
  };

  const getLastPaidValueSelector = (row: Recipient) => {
    const lastUsedValue = getLastUsedValue(row);
    return lastUsedValue?.toString() ?? '';
  };

  const formatLastPaidValue = (row: Recipient) => {
    const lastUsedValue = getLastUsedValue(row);
    return lastUsedValue?.toFormat('LLL d, yyyy').toString() ?? '-';
  };

  const columns: TableColumn<Recipient>[] = [
    {
      name: 'Name',
      sortable: true,
      sortFunction: (a, b) => a?.name.localeCompare(b?.name),
      selector: (row) =>
        row.type === 'company' ? row.name : `${row.firstName} ${row.lastName}`,
      grow: 2,
    },
    {
      name: 'Last paid',
      selector: getLastPaidValueSelector,
      format: formatLastPaidValue,
      sortable: true,
      style: {
        fontSize: useMobileView ? '12px' : '14px',
      },
      grow: 2,
    },
    {
      name: 'Actions',
      cell: (row) => <RecipientActions refetch={refetch} recipient={row} />,
      compact: true,
    },
  ];

  const handleAddRecipient = () => {
    navigate('/recipients/new');
  };

  const handleRowClicked = (row: Recipient) => {
    if (recipientDetailsFeatureFlagIsOn) {
      const recipientId = row.id;
      navigate(`/recipient-details/${recipientId}`);
    } else {
      openModal(row.name, <ViewRecipientModal recipient={row} />);
    }
  };

  return (
    <TabTableLayout
      leftHeaderContent={
        <FlexbaseInput
          w={useMobileView ? '100%' : '300px'}
          placeholder="Search recipients"
          onChange={(e) => setFilterText(e.target.value)}
          icon={<SearchIcon width={20} height={20} />}
        />
      }
      rightHeaderContent={
        user.roles.includes('ADMIN') && (
          <Button
            onClick={handleAddRecipient}
            data-testid={'add-recipient'}
            variant="light"
          >
            Add Recipient
          </Button>
        )
      }
      table={
        <FlexbaseTable
          columns={columns}
          data={filteredTableData}
          pagination={filteredTableData && filteredTableData.length > 8}
          onRowClicked={handleRowClicked}
          isFetchingData={isLoadingCounterparties}
        />
      }
    />
  );
};

export default Recipients;
