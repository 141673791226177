import FlexbaseMainLayout from 'components/layouts/main';
import FlexbaseDefaultHeader from '@common/header/flexbase-header';
import RewardsBanner from './rewards-banner';
import BenefitsList from './benefits-list';
import { useEliteFeatureFlag } from '@utilities/feature-flags';
import { Navigate } from 'react-router-dom';

const BenefitsAndRewardsDashboard = () => {
  const isEliteFeatureFlag = useEliteFeatureFlag();
  if (!isEliteFeatureFlag) {
    return <Navigate to="/" />;
  }
  return (
    <FlexbaseMainLayout>
      <FlexbaseDefaultHeader title="Membership Benefits" />
      <RewardsBanner></RewardsBanner>
      <BenefitsList></BenefitsList>
    </FlexbaseMainLayout>
  );
};
export default BenefitsAndRewardsDashboard;
