import { createStyles } from '@mantine/core';

type Props = {
  rotateArrow?: boolean;
};

export const useStyles = createStyles((theme, { rotateArrow }: Props) => ({
  sendToBox: {
    height: '80px',
    display: 'flex',
    alignItems: 'center',
    border: `1px solid ${theme.fn.themeColor('neutral', 3)}`,
    borderRadius: theme.defaultRadius,
    padding: '0px 14px',
    gap: '0px 16px',
    cursor: 'pointer',
    background: theme.fn.themeColor('neutral', 0),
  },
  initials: {
    backgroundColor: theme.fn.themeColor('primarySecondarySuccess', 0),
    borderRadius: '100%',
    width: '40px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  optionText: {
    color: theme.fn.primaryColor(),
    fontSize: '16px',
    fontWeight: 400,
    maxWidth: '300px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '@media(max-width: 400px)': {
      whiteSpace: 'normal',
    },
  },
  optionInfoContainer: {
    color: theme.fn.themeColor('neutral', 6),
    fontSize: '14px',
    fontWeight: 400,
  },
  arrowIcon: {
    marginLeft: 'auto',
    transition: '0.2s ease-out',
    transform: rotateArrow ? 'rotate(-180deg)' : 'rotate(0deg)',
  },
  listItem: {
    '&:hover': {
      backgroundColor: theme.fn.themeColor('neutral', 2),
      '#icon': {
        backgroundColor: theme.fn.themeColor('neutral', 0),
      },
    },
  },
}));
