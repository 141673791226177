import FlexbaseDefaultHeader from '@common/header/flexbase-header';
import SkeletonLoading from 'components/loading/skeleton-loading';
import { NavTab } from 'components/tabs/nav-tabs';
import { useGetApplicationStatus } from 'queries/use-application';
import { useNavigate, useParams } from 'react-router-dom';
import {
  BankingApplicationStatus,
  GetApplicationStatusAwaitingDocumentsResponse,
} from 'services/flexbase/banking.model';
import { NavTabContainer } from '@common/tabs/nav-tab-container';
import BankingAccount from './accounts/banking-accounts';
import BankingTransactions from './banking-transactions/banking-transactions';
import ApplicationStatus from './components/application-status/application-status';
import DebitCards from './debit-cards/debit-cards';
import ExternalAccounts from './external-accounts/external-accounts';
import Statements from './statements/statements';
import { useStyles } from './styles';
import GenericError from './utils/generic-error';
import { useRecoilValue } from 'recoil';
import { ApplicationState } from '../../recoil-state/application/product-onboarding';

const Banking = () => {
  const { classes } = useStyles();
  const { tab } = useParams();
  const navigate = useNavigate();
  const { excludedProducts } = useRecoilValue(ApplicationState);

  const { isError, isLoading, data } = useGetApplicationStatus();
  let applicationStatus: BankingApplicationStatus = 'Unused';
  let appType = '';
  let documentsLink = '';

  if (excludedProducts.includes('BANKING')) {
    if (!excludedProducts.includes('CREDIT')) {
      navigate('/cards', { replace: true });
    }
    return false;
  }

  if (data?.success) {
    applicationStatus = data.status;
    appType = data.type;
    if (data.status === 'AwaitingDocuments') {
      const awaitingDocsResponse =
        data as GetApplicationStatusAwaitingDocumentsResponse;
      documentsLink = awaitingDocsResponse.url || '';
    }
  }

  const disabledTabs = [
    'PendingReview',
    'Denied',
    'Canceled',
    'Pending',
    'Incomplete',
    'AwaitingDocuments',
    'Unused',
    'Ready',
    undefined,
  ].includes(applicationStatus);

  const tabs: NavTab[] = [
    { label: 'Accounts', route: '/banking/accounts' },
    {
      label: 'Transactions',
      route: '/banking/transactions',
      disabled: disabledTabs,
    },
    {
      label: 'Debit cards',
      route: '/banking/debit-cards',
      disabled: disabledTabs,
    },
    {
      label: 'Statements',
      route: '/banking/statements',
      disabled: disabledTabs,
    },
    {
      label: 'External accounts',
      route: '/banking/linked-accounts',
      disabled: disabledTabs,
    },
  ];

  const getTabContent = () => {
    switch (tab) {
      case 'transactions':
        return <BankingTransactions />;
      case 'statements':
        return <Statements />;
      case 'debit-cards':
        return <DebitCards {...{ appType }} />;
      case 'linked-accounts':
        return <ExternalAccounts />;
      default:
        return <BankingAccount />;
    }
  };

  let children;
  if (applicationStatus) {
    children =
      applicationStatus === 'Approved' ? (
        <NavTabContainer tabs={tabs}>{getTabContent()}</NavTabContainer>
      ) : (
        <ApplicationStatus {...{ documentsLink, applicationStatus }} />
      );
  }

  if (isError) {
    children = (
      <GenericError>
        <p>We are unable to retrieve the account information at the moment.</p>
      </GenericError>
    );
  }
  if (isLoading) {
    children = <SkeletonLoading withTabs />;
  }

  return (
    <div className={classes.mainContainer}>
      <FlexbaseDefaultHeader
        title={applicationStatus === 'Approved' ? 'Banking' : undefined}
      />
      {children}
    </div>
  );
};

export default Banking;
