import { Avatar, Box, Collapse, Text } from '@mantine/core';
import { DropdownIcon, ArrowIcon } from '../../../assets/svg';
import useStyles from '../navbar.styles';
import useModal from '@common/modal/modal-hook';
import AccountsModal from './accounts-modal';
import { useMultiAccount } from './multi-account-hook';
import { useMultiAccountFeatureFlag } from '@utilities/feature-flags';
import AccountsGroup from './accounts-group';

type Props = {
  opened: boolean;
  toggle: () => void;
};

const CompaniesDropdown = ({ opened, toggle }: Props) => {
  const { classes } = useStyles({});
  const modal = useModal();
  const enabled = useMultiAccountFeatureFlag();

  const { currentAccount, filteredAccounts, setBusinessId } = useMultiAccount();

  const showModal = () => {
    modal.openCenterModalUnstyled(
      <AccountsModal closeModal={modal.closeAllModals} />,
    );
  };

  const onAccountClick = (id: string) => {
    if (setBusinessId) {
      setBusinessId(id);
    }
  };

  return (
    <div className={classes.accountContainer}>
      <div
        className={classes.companyContainer}
        style={{
          cursor: (filteredAccounts?.length || 0) > 0 ? 'pointer' : '',
          width: '100%',
        }}
        onClick={() => {
          if ((filteredAccounts?.length || 0) > 0) {
            toggle();
          }
        }}
      >
        <Box>
          <Avatar size={'md'} className={classes.accountIcon}>
            {currentAccount?.name[0].toUpperCase()}
          </Avatar>
        </Box>
        <div className={classes.nameSection}>
          <Text
            lineClamp={2}
            h={20}
            mr={7}
            w={180}
            className={classes.selectedCompanyName}
          >
            {currentAccount?.name}
          </Text>
          {(filteredAccounts?.length || 0) > 0 &&
            enabled &&
            (opened ? (
              <ArrowIcon width="16px" />
            ) : (
              <DropdownIcon width="16px" />
            ))}
        </div>
      </div>
      {enabled && (
        <Collapse in={opened} transitionDuration={400}>
          <Box mt={8} mb={20} mr={40} ml={40}>
            <Text c="neutral.4" size={'xs'} mb={8} fw={600} lts={1}>
              Switch accounts
            </Text>
            <div>
              {filteredAccounts?.map((item) => {
                return (
                  <AccountsGroup key={item.id} {...{ item, onAccountClick }} />
                );
              })}
            </div>
            {filteredAccounts && filteredAccounts?.length >= 5 && (
              <div style={{ cursor: 'pointer' }} onClick={showModal}>
                <Text c="primarySecondarySuccess.1" size={'xs'} align="center">
                  Manage accounts
                </Text>
              </div>
            )}
          </Box>
        </Collapse>
      )}
    </div>
  );
};
export default CompaniesDropdown;
