import { atom } from 'recoil';
import {
  ActiveFiltersReturnType,
  createUseFiltersHook,
  FilterFnMap,
} from '@common/filter/filters';
import { ExampleItem, ExampleItemStatus } from '../data/use-example-data';
import { DateRangeFilter } from '@common/filter/date-range.filter';
import { Interval, DateTime } from 'luxon';
import { filterDirectionToLabel } from '@common/filter/amount-filter';
import { formatCurrency } from '@flexbase-eng/web-components';
import { QuantityFilter } from '@common/filter/quantity-filter';
import { SimpleSegmentSelectedFilter } from '@common/filter/simple-segmented.filter';
import { formatApiStrings } from '@utilities/formatters/format-api-strings';
import { MultiSelect } from '@mantine/core';
import { uniq } from 'underscore';
import { useMemo } from 'react';

// -- FILTER STATE ATOMS --

const ExampleItemsFilterState = atom<FilterFnMap<ExampleItem>>({
  key: 'example_items_filter_state',
  default: {},
});

const ExampleItemsFilterStateModal = atom<FilterFnMap<ExampleItem>>({
  key: 'example_items_filter_state_modal',
  default: {},
});

// -- FILTER HOOKS --

// returns the current filter state for the table
export function useExampleItemsFilters(): ActiveFiltersReturnType<ExampleItem> {
  return createUseFiltersHook<ExampleItem>(ExampleItemsFilterState);
}
// returns the filter state being selected in the modal
export function useExampleItemsModalFilters(): ActiveFiltersReturnType<ExampleItem> {
  return createUseFiltersHook<ExampleItem>(ExampleItemsFilterStateModal);
}

//  -- FILTER MODAL COMPONENTS --

// example date range filter
export const DueDateFilter = () => {
  const dueDateFilters = useExampleItemsModalFilters();
  return (
    <DateRangeFilter
      label="Due Date"
      filterHook={dueDateFilters}
      filterFn={(startDate, endDate, item) => {
        const dateRange = Interval.fromDateTimes(startDate, endDate);
        return dateRange.contains(DateTime.fromISO(item.dueDate));
      }}
    />
  );
};

// example currency amount filter
export const InvoiceAmountFilter = () => {
  const { addFilter, getFilterByKey } = useExampleItemsModalFilters();
  return (
    <QuantityFilter
      value={
        getFilterByKey('amount')?.filterValue || {
          amount: '',
          direction: 'equal',
        }
      }
      onChange={(val) => {
        const amountInCents = Math.round(parseFloat(val.amount) * 100);
        addFilter('amount', {
          label: `Amount: ${filterDirectionToLabel(
            val.direction,
          )} ${formatCurrency(val.amount)}`,
          key: 'amount',
          fn: (row) => {
            const amountNum = Math.abs(Number(row.total));
            const filterNum = amountInCents;

            switch (val.direction) {
              case 'greaterThan':
                return amountNum > filterNum;
              case 'lessThan':
                return amountNum < filterNum;
              case 'equal':
              default:
                return amountNum === filterNum;
            }
          },
          filterValue: val,
          showChip: true,
        });
      }}
    />
  );
};

// example segmented select filter
export const StatusFilter = () => {
  const filters = useExampleItemsModalFilters();
  const statusFilterOptions = Object.values(ExampleItemStatus).map(
    (status) => ({
      label: formatApiStrings(status),
      filterCriteria: [status],
      key: status,
    }),
  );
  return (
    <SimpleSegmentSelectedFilter
      filterHook={filters}
      filterKey="status"
      options={statusFilterOptions}
      label="Status"
      includesValue={(row) => row.status}
    />
  );
};

// example dropdown filter
export const RecipientFilter = ({ items }: { items: ExampleItem[] }) => {
  const { addFilter, removeFilter, getFilterByKey } =
    useExampleItemsModalFilters();

  // get the unique list of recipients
  const who = useMemo(() => {
    if (items) {
      return uniq(items.map((t) => t.recipientName).filter((t) => !!t));
    }
    return [];
  }, [items]);

  return (
    <MultiSelect
      data={who}
      placeholder="Recipient"
      searchable
      nothingFound="Nothing found"
      value={getFilterByKey('recipient')?.filterValue || []}
      onChange={(val) => {
        if (val.length > 0) {
          addFilter('recipient', {
            key: 'recipient',
            filterValue: val,
            fn: (row) => val.includes(row.recipientName),
            label: `Recipient: ${val.join(', ')}`,
            showChip: true,
          });
        } else {
          removeFilter('recipient');
        }
      }}
    />
  );
};

// -- SECTIONS FOR THE FILTER MODAL --

// sections that will be displayed in the filter modal
export const filterModalSections = (items: ExampleItem[]) => [
  {
    key: 'dueDate',
    header: 'Due Date',
    component: <DueDateFilter />,
  },
  {
    key: 'amount',
    header: 'Amount',
    component: <InvoiceAmountFilter />,
  },
  {
    key: 'status',
    header: 'Status',
    component: <StatusFilter />,
  },
  {
    key: 'recipient',
    header: 'Recipient',
    component: <RecipientFilter items={items} />,
  },
];
