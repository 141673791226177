import { EditIcon } from 'assets/svg';
import {
  StateUpdater,
  createMultiStepFormDynamicContext,
} from 'providers/multi-step-form-provider';
import {
  createContext,
  Fragment,
  MouseEventHandler,
  MutableRefObject,
  PropsWithChildren,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';
import {
  formStepToWizardStepConfig,
  WizardFormStep,
  WizardLabelsConfig,
  WizardStepConfig,
  wizardStepConfigToFormStep,
} from './wizard.types';
import { Box, Button, Group, Space, Stack, rem } from '@mantine/core';
import { WizardStep } from './components/wizard-step';
import { useWizardStyles } from './wizard.styles';
import { useWizardMobile } from './utils/use-wizard-mobile';
import { useScrollIntoView } from '@mantine/hooks';
import { Easing, Easings, PREVIEW_STACK_PY } from './wizard.constants';
import { FlexWizard } from './components/flex-wizard';

type Awaitable<T> = T | Promise<T>;

type MultiScrollIntoViewParams = {
  duration: number;
  easing: Easing;
  offset: number;
};

export const useMultiScrollIntoView = function <
  TScrollTargetId extends string = string,
>({ duration, easing, offset }: MultiScrollIntoViewParams) {
  const targetsRef = useRef<Record<string, any>>({});
  const { scrollableRef, targetRef, scrollIntoView } = useScrollIntoView({
    duration,
    easing: Easings[easing],
    offset,
  });

  return {
    addTargetRef(id: TScrollTargetId) {
      return <TElement extends HTMLElement>(refNode: TElement | null) => {
        targetsRef.current[id] = refNode;
      };
    },
    scrollToTarget(id: TScrollTargetId) {
      const target = targetsRef.current[id];

      if (target) {
        targetRef.current = target;

        scrollIntoView();
      }
    },
    scrollableRef,
  };
};

/**
 * Maps a step ID to a handler.
 */
type StepHandlerMap = Partial<Record<string, () => unknown>>;

type WizardContextType<TFormState> = {
  header: ReactNode | undefined;
  body: ReactNode | undefined;
  footer: ReactNode | undefined;
  labels: WizardLabelsConfig;
  showProgressBar: boolean;
  previewRef: MutableRefObject<null>;
  previewStepsRef: MutableRefObject<Record<string, any>>;
  onStepChangeRef: MutableRefObject<
    (
      from: WizardStepConfig<TFormState>,
      to: WizardStepConfig<TFormState>,
    ) => Awaitable<boolean>
  >;
  eventHandlersRef: MutableRefObject<
    Record<'next' | 'back', StepHandlerMap> &
      Partial<Record<string, StepHandlerMap>>
  >;

  onCancelRef: MutableRefObject<(() => void) | undefined>;
  scrollToPreview: (id: string) => void;
  addPreviewScrollTarget: (
    id: string,
  ) => <T extends HTMLElement>(ref: T | null) => void;
};

/**
 * Creates a Wizard component and useWizard hook to render a Wizard view. 🧙
 *
 * Pass in a step config and an initial state and the wizard handles the state control
 *
 * The Wizard uses a default layout of header + progress bar + body (no footer) with a step panel on the left and preview panel on the right.
 *
 * The header/body/footer can individually be customized by passing in a ReactNode as props to the <Wizard /> component.
 *
 * For complete control, you can pass children to the <Wizard /> element to control the entire UI, but with access to wizard state/functions.
 *
 * Use the useWizard hook to get access to state and navigation functions.
 *
 * Useful useWizard functions:
 * - setState(updater: T | (prev: T) => T) - Store data in the internal wizard state, and access it with `state`
 * - onNext() - Add a handler for 'Next' button clicks. `onBack` is provided as well.
 * - onEvent(name: string, handler: Function) - Add a handler for a specific event. Only applies to the current step, and only one is set at a time. `onNext` and `onBack` are wrappers around this.
 * - triggerEvent(name: string) - Triggers the event handler for the given event. Useful for creating your own navigation buttons in the footer or elsewhere.
 *
 * The Wizard provides some built-in components when customizing:
 * - Wizard.Step: A step layout with a Back and Continue button at the bottom.
 * - Wizard.DefaultHeader: A header component that displays the Flex logo and a Cancel button. This is the default header.
 * - Wizard.DefaultFooter: A footer component with a Back and Continue button.
 * - Wizard.Preview: Renders the entire preview panel.
 * - Wizard.StepWithAutoScrollPreview: A body component with step and preview panels side by side. This is the default body.
 *
 * @example
 * <caption>Basic implementation</caption>
 * type MyState = {
 *   name: string;
 *   age: number;
 * };
 *
 * const [
 *   MyWizard,
 *   useMyWizard,
 * ] = createWizard<MyState>();
 *
 * const StepOne = () => {
 *   const { onNext, onBack, goToNextStep, goToPreviousStep } = useMyWizard();
 *
 *   // handle when 'Next' button is clicked
 *   onNext(() => {
 *     // validate form, make requests, etc.
 *     goToNextStep();
 *   });
 *
 *   // similar to onNext
 *   onBack(() => {
 *     goToPreviousStep();
 *   });
 *
 *   return <MyWizard.Step>
 *     <TextInput />
 *   </MyWizard.Step>;
 * };
 *
 * const MyPage = () => {
 *   const navigate = useNavigate();
 *
 *   return <MyWizard
 *     steps={[
 *       {
 *         id: 'step-1',
 *         title: 'Step One',
 *         element: <StepOne />,
 *         previewElement: <> ... </>,
 *       },
 *       {
 *         ...
 *       },
 *     ]}
 *     initialState={{ name: '', age: 0 }}
 *     onCancel={() => navigate.to('/')}
 *   />;
 * };
 *
 * @example
 * <caption>Customize the header/body/footer in the default layout by passing in props.</caption>
 * <MyWizard
 *  steps={steps}
 *  header={<MyWizard.DefaultHeader />}
 *  body={<MyWizard.StepWithAutoScrollPreview />}
 *  footer={<CustomFooter />}
 * />
 *
 * @example
 * <caption>Full customization by passing in a child node. header/body/footer props are ignored.</caption>
 * <MyWizard>
 *   <MyWizard.DefaultHeader />
 *   <MyWizard.Progress />
 *   <div>
 *     Hello world
 *   </div>
 *   <MyWizard.DefaultFooter />
 * </MyWizard>
 *
 * @example
 * <caption>Custom events used in a custom footer</caption>
 * const MyStep = () => {
 *   const { onEvent, onNext, goToNextStep } = useWizard();
 *
 *   onEvent('save_draft', () => { ... });
 *   onNext(() => goToNextStep());
 *
 *   return </>;
 * };
 *
 * const MyFooter = () => {
 *   const { triggerEvent } = useWizard();
 *   const handleDraft = () => triggerEvent('save_draft');
 *   const handleNext = () => triggerEvent('next');
 *
 *   return <>
 *     <Button onClick={handleDraft}>
 *       Save draft
 *     </Button>
 *
 *     <Button onClick={handleNext}>
 *       Next
 *     </Button>
 *   </>;
 * };
 */
export function createWizard<
  TFormState,
  TStepMeta extends Record<string, unknown> = Record<string, unknown>,
>() {
  const [FormProvider, useFormContext] = createMultiStepFormDynamicContext<
    TFormState,
    WizardFormStep<TFormState, TStepMeta>
  >();

  const WizardContext = createContext<WizardContextType<TFormState> | null>(
    null,
  );

  function useWizardContext() {
    const ctx = useContext(WizardContext);

    if (!ctx) {
      throw new Error(
        'useWizardContext must be called within a WizardProvider',
      );
    }

    return ctx;
  }

  type WizardProviderProps = PropsWithChildren<{
    steps: WizardFormStep<TFormState, TStepMeta>[];
    labels: WizardLabelsConfig;
    progressBar: boolean;
    header: ReactNode | undefined;
    body: ReactNode | undefined;
    footer: ReactNode | undefined;
    onCancel?: () => void;
  }>;

  function WizardProvider({
    children,
    header,
    body,
    footer,
    steps,
    labels,
    progressBar: showProgressBar,
    onCancel,
  }: WizardProviderProps) {
    const onStepChangeRef = useRef<() => Awaitable<boolean>>(() => true);
    const onCancelRef = useRef<(() => void) | undefined>(onCancel);
    const previewStepsRef = useRef<Record<string, any>>({});
    const eventHandlersRef = useRef<
      Record<'next' | 'back', StepHandlerMap> &
        Partial<Record<string, StepHandlerMap>>
    >({
      back: {},
      next: {},
    });

    onCancelRef.current = onCancel;

    const { scrollableRef, addTargetRef, scrollToTarget } =
      useMultiScrollIntoView({
        duration: 350,
        easing: 'InOutCubic',
        offset: PREVIEW_STACK_PY,
      });

    const scrollToPreview = (id: string) => {
      if (steps.length > 1) {
        scrollToTarget(id);
      }
    };

    const wizardValue: WizardContextType<TFormState> = {
      labels,
      showProgressBar,
      previewRef: scrollableRef,
      previewStepsRef,
      eventHandlersRef,
      onStepChangeRef,
      onCancelRef,
      header,
      body,
      footer,
      scrollToPreview,
      addPreviewScrollTarget: addTargetRef,
    };

    return (
      <WizardContext.Provider value={wizardValue}>
        {children}
      </WizardContext.Provider>
    );
  }

  type WizardPreviewSectionProps = {
    title?: ReactNode;
    step: WizardFormStep<TFormState, TStepMeta>;
    onClick: MouseEventHandler<HTMLDivElement>;
  };

  function WizardPreviewSection({ step, onClick }: WizardPreviewSectionProps) {
    const { classes, cx } = useWizardStyles();
    const { visitedSteps, currentStep } = useFormContext();

    const hasVisited = visitedSteps[step.metadata.id];
    const isPreviewForCurrentStep =
      currentStep?.metadata.id === step.metadata.id;
    const className = hasVisited
      ? cx(classes.previewSection, classes.previewSectionVisited)
      : cx(classes.previewSection);
    const editIconSize = 16;

    const enableClickToEdit = hasVisited && !isPreviewForCurrentStep;

    const handleClick: MouseEventHandler<HTMLDivElement> = (e) => {
      if (enableClickToEdit) {
        onClick(e);
      }
    };

    return (
      <Stack
        spacing={4}
        py={PREVIEW_STACK_PY}
        className={className}
        onClick={handleClick}
      >
        {enableClickToEdit ? (
          <Stack align="center" className="edit" sx={{ zIndex: 20 }}>
            <EditIcon height={editIconSize} width={editIconSize} />
          </Stack>
        ) : (
          <Space w={editIconSize} />
        )}
        <Box sx={{ zIndex: 10 }}>{step.metadata.previewElement}</Box>
      </Stack>
    );
  }

  function WizardPreview() {
    const wizard = useWizardContext();
    const { stepList, visitedSteps, currentStep, goToStep } = useFormContext();
    const { classes } = useWizardStyles();

    const [previewSteps, lastStep] = useMemo(() => {
      if (!stepList) {
        return [[], undefined];
      }

      const stepsWithPreviews = [
        ...stepList.filter((step) => step.metadata.previewElement),
      ];
      const lastStepWithPreview = stepsWithPreviews.pop();

      return [stepsWithPreviews, lastStepWithPreview];
    }, [stepList]);

    const handlePreviewStepClick = async (
      step: WizardFormStep<TFormState, TStepMeta>,
    ) => {
      const { id } = step.metadata;
      const hasVisited = visitedSteps[id];

      const fromStep = formStepToWizardStepConfig(currentStep);
      const toStep = formStepToWizardStepConfig(step);

      if (
        hasVisited &&
        fromStep &&
        toStep &&
        (await wizard.onStepChangeRef.current(fromStep, toStep))
      ) {
        goToStep(id, undefined, { fromPreview: true });
      }
    };

    return (
      <Stack className={classes.previewStack} px={20} py={PREVIEW_STACK_PY}>
        {previewSteps.map((step) => {
          return (
            <Box
              key={step.metadata.id}
              ref={wizard.addPreviewScrollTarget(step.metadata.id)}
            >
              <WizardPreviewSection
                step={step}
                onClick={() => handlePreviewStepClick(step)}
              />
            </Box>
          );
        })}

        {lastStep ? (
          <Box
            ref={wizard.addPreviewScrollTarget(lastStep.metadata.id)}
            mih={`calc(100% + ${rem(PREVIEW_STACK_PY)})`}
          >
            <WizardPreviewSection
              step={lastStep}
              onClick={() => handlePreviewStepClick(lastStep)}
            />
          </Box>
        ) : null}
      </Stack>
    );
  }

  function WizardStepWithAutoScrollPreview() {
    const isMobile = useWizardMobile();
    const { previewRef } = useWizardContext();
    const { stepList, currentStep } = useFormContext();
    const { cx, classes } = useWizardStyles();

    const hasPreviewSteps = stepList.some((s) => !!s.metadata.previewElement);

    return (
      <Group className={classes.body}>
        <Box
          className={classes.contentPanel}
          maw={hasPreviewSteps ? 400 : '100%'}
        >
          {currentStep?.element}
        </Box>

        {isMobile || !hasPreviewSteps ? null : (
          <Box
            ref={previewRef}
            className={cx(classes.previewPanel, classes.scrollContainer)}
          >
            <Box className={classes.scrollContent}>
              <WizardPreview />
            </Box>
          </Box>
        )}
      </Group>
    );
  }

  type WizardDefaultHeaderProps = {
    onCancel?: () => void;
  };

  function WizardDefaultHeader({ onCancel }: WizardDefaultHeaderProps) {
    const handleCancel = () => {
      onCancel?.();
    };

    return (
      <FlexWizard.Header
        actions={
          <Button variant="subtle" onClick={handleCancel}>
            Cancel
          </Button>
        }
      />
    );
  }

  type WizardDefaultFooterProps = {
    labels?: Partial<WizardLabelsConfig>;
    backLoading?: boolean;
    nextLoading?: boolean;
    onBack?: () => void;
    onNext?: () => void;
  };

  function WizardDefaultFooter({
    labels,
    backLoading = false,
    nextLoading = false,
    onBack,
    onNext,
  }: WizardDefaultFooterProps) {
    const { labels: wizardLabels, eventHandlersRef } = useWizardContext();
    const { onBack: wizardOnBack, onNext: wizardOnNext } = useWizard();
    const {
      hasPreviousStep,
      hasNextStep,
      currentStep,
      goToPreviousStep,
      goToNextStep,
    } = useFormContext();
    const isMobile = useWizardMobile();

    onBack && wizardOnBack(() => onBack());
    onNext && wizardOnNext(() => onNext());

    const handleBack = hasPreviousStep
      ? () => {
          const handler =
            currentStep &&
            eventHandlersRef.current.back[currentStep.metadata.id];

          if (handler) {
            return handler();
          }

          goToPreviousStep();
        }
      : undefined;

    const handleNext = hasNextStep
      ? () => {
          const handler =
            currentStep &&
            eventHandlersRef.current.next[currentStep.metadata.id];

          if (handler) {
            return handler();
          }

          goToNextStep();
        }
      : undefined;

    const btnLabels = {
      ...wizardLabels,
      ...labels,
    };

    return (
      <>
        <FlexWizard.Divider />

        <Group
          p="lg"
          position="apart"
          sx={{
            flexDirection: isMobile ? 'column-reverse' : undefined,
          }}
        >
          {hasPreviousStep ? (
            <Button
              variant="outline"
              leftIcon={<FiArrowLeft />}
              onClick={handleBack}
              loading={backLoading}
              disabled={backLoading}
              miw={isMobile ? '100%' : undefined}
            >
              {btnLabels.back || 'Back'}
            </Button>
          ) : isMobile ? null : (
            <Space />
          )}

          <Button
            variant="default"
            rightIcon={<FiArrowRight />}
            onClick={handleNext}
            loading={nextLoading}
            disabled={nextLoading}
            miw={isMobile ? '100%' : undefined}
          >
            {btnLabels.next || 'Continue'}
          </Button>
        </Group>
      </>
    );
  }

  type WizardLayoutProps = PropsWithChildren;

  function WizardLayout({ children }: WizardLayoutProps) {
    const [isInitialMount, setIsInitialMount] = useState(true);
    const wizard = useWizardContext();
    const { currentStep, progress } = useFormContext();

    const childrenDefined = children !== undefined;
    const headerDefined = wizard.header !== undefined;
    const bodyDefined = wizard.body !== undefined;
    const footerDefined = wizard.footer !== undefined;

    const currentStepId = currentStep?.metadata.id;

    const handleCancel = () => {
      wizard.onCancelRef.current?.();
    };

    useEffect(() => {
      if (currentStepId) {
        // don't scroll on initial mount
        if (isInitialMount) {
          setIsInitialMount(false);
          return;
        }

        if (currentStep.metadata.disableScrollToPreview) {
          return;
        }

        wizard.scrollToPreview(currentStepId);
      }
    }, [currentStepId]);

    // passing children overrides the entire layout
    if (childrenDefined) {
      return <FlexWizard>{children}</FlexWizard>;
    }

    return (
      <FlexWizard>
        {headerDefined ? (
          wizard.header
        ) : (
          <WizardDefaultHeader onCancel={handleCancel} />
        )}

        {wizard.showProgressBar ? (
          <FlexWizard.Progress value={progress} />
        ) : (
          <FlexWizard.Divider />
        )}

        <FlexWizard.Body>
          {bodyDefined ? wizard.body : <WizardStepWithAutoScrollPreview />}
        </FlexWizard.Body>

        {footerDefined ? (
          <FlexWizard.Footer>{wizard.footer}</FlexWizard.Footer>
        ) : null}
      </FlexWizard>
    );
  }

  type WizardProps = PropsWithChildren<{
    initialState: TFormState;
    steps: WizardStepConfig<TFormState, TStepMeta>[];
    labels?: Partial<WizardLabelsConfig>;
    header?: ReactNode;
    body?: ReactNode;
    footer?: ReactNode;

    /**
     * Whether or not to display the progress bar.
     * Default: true
     */
    progressBar?: boolean;

    /**
     * Wraps the wizard content so that any components in the wrapper have access to the wizard context.
     *
     * @example
     * <Wizard
     *   wrapper={({ children }) => {
     *     return <MyProvider> // MyProvider can now use useWizardContext()
     *       {children}
     *     </MyProvider>;
     *   }}
     * />
     */
    wrapper?: (props: PropsWithChildren) => ReactNode;
    onCancel?: () => void;
  }>;

  function Wizard({
    children,
    steps,
    initialState,
    labels,
    progressBar = true,
    onCancel,
    wrapper: WrapperComponent = Fragment,
    header,
    body,
    footer,
  }: WizardProps) {
    const wizardLabels = {
      back: 'Back',
      next: 'Continue',
      ...labels,
    };

    const wizardSteps = useMemo<WizardFormStep<TFormState, TStepMeta>[]>(
      () => steps.map(wizardStepConfigToFormStep),
      [steps],
    );

    return (
      <FormProvider steps={wizardSteps} initialState={initialState}>
        <WizardProvider
          steps={wizardSteps}
          labels={wizardLabels}
          progressBar={progressBar}
          onCancel={onCancel}
          header={header}
          body={body}
          footer={footer}
        >
          <WrapperComponent>
            <WizardLayout>{children}</WizardLayout>
          </WrapperComponent>
        </WizardProvider>
      </FormProvider>
    );
  }

  type WizardStepProps = PropsWithChildren<{
    labels?: Partial<WizardLabelsConfig>;
    hideBack?: boolean;
    hideNext?: boolean;
    backLoading?: boolean;
    nextLoading?: boolean;
    nextDisabled?: boolean;
    onBack?: () => void;
    onNext?: () => void;
  }>;

  Wizard.Step = function Step({
    children,
    labels,
    hideBack,
    hideNext,
    backLoading,
    nextLoading,
    nextDisabled,
    onBack,
    onNext,
  }: WizardStepProps) {
    const { labels: wizardLabels, eventHandlersRef } = useWizardContext();
    const { onBack: wizardOnBack, onNext: wizardOnNext } = useWizard();
    const { currentStep, hasPreviousStep, goToPreviousStep, goToNextStep } =
      useFormContext();

    onBack && wizardOnBack(() => onBack());
    onNext && wizardOnNext(() => onNext());

    const handleBack = hasPreviousStep
      ? () => {
          const handler =
            currentStep &&
            eventHandlersRef.current.back[currentStep.metadata.id];

          if (handler) {
            return handler();
          }

          goToPreviousStep();
        }
      : undefined;

    const handleNext = () => {
      const handler =
        currentStep && eventHandlersRef.current.next[currentStep.metadata.id];

      if (handler) {
        return handler();
      }

      goToNextStep();
    };

    return (
      <WizardStep
        title={currentStep?.metadata.title}
        labels={{
          ...wizardLabels,
          ...labels,
        }}
        hideBack={hideBack || !hasPreviousStep}
        hideNext={hideNext}
        backLoading={backLoading}
        nextLoading={nextLoading}
        nextDisabled={nextDisabled}
        onBack={handleBack}
        onNext={handleNext}
      >
        {children}
      </WizardStep>
    );
  };

  Wizard.StepWithAutoScrollPreview = WizardStepWithAutoScrollPreview;
  Wizard.Preview = WizardPreview;
  Wizard.DefaultHeader = WizardDefaultHeader;
  Wizard.DefaultFooter = WizardDefaultFooter;

  function useWizard() {
    const isMobile = useWizardMobile();
    const wizard = useWizardContext();
    const {
      state,
      stepList,
      currentStep,
      currentStepIndex,
      navigationContext,
      setState,
      goToStep,
      goToNextStep,
      goToPreviousStep,
      getLastVisitedStep,
      ...rest
    } = useFormContext();

    const currentStepId = currentStep?.metadata.id;

    const wizardGoToStep = async (
      id: string,
      updater?: StateUpdater<TFormState>,
    ) => {
      const fromStep = formStepToWizardStepConfig(currentStep);
      const toStep = formStepToWizardStepConfig(
        stepList.find((step) => step.metadata.id === id),
      );

      if (
        fromStep &&
        toStep &&
        (await wizard.onStepChangeRef.current(fromStep, toStep))
      ) {
        goToStep(id, updater);
      }
    };

    const wizardGoToNextStep = async (updater?: StateUpdater<TFormState>) => {
      const fromStep = formStepToWizardStepConfig(currentStep);
      const nextStep = stepList[currentStepIndex + 1];
      const toStep = formStepToWizardStepConfig(nextStep);

      if (
        fromStep &&
        toStep &&
        (await wizard.onStepChangeRef.current(fromStep, toStep))
      ) {
        goToNextStep(updater);
      }
    };

    const wizardGoToPreviousStep = async (
      updater?: StateUpdater<TFormState>,
    ) => {
      const fromStep = formStepToWizardStepConfig(currentStep);
      const nextStep = stepList[currentStepIndex - 1];
      const toStep = formStepToWizardStepConfig(nextStep);

      if (
        fromStep &&
        toStep &&
        (await wizard.onStepChangeRef.current(fromStep, toStep))
      ) {
        goToPreviousStep(updater);
      }
    };

    const wizardGetLastVisitedStep = () => {
      return formStepToWizardStepConfig<TFormState, TStepMeta>(
        getLastVisitedStep(),
      );
    };

    const wizardScrollToPreviewTop = () => {
      wizard.scrollToPreview(stepList[0].metadata.id);
    };

    const onStepChange = (
      fn: (
        from: WizardStepConfig<TFormState>,
        to: WizardStepConfig<TFormState>,
      ) => Awaitable<boolean>,
    ) => {
      wizard.onStepChangeRef.current = fn;
    };

    const onBack = (fn: () => unknown) => {
      if (currentStepId) {
        wizard.eventHandlersRef.current.back[currentStepId] = fn;
      }
    };

    const onNext = (fn: () => unknown) => {
      if (currentStepId) {
        wizard.eventHandlersRef.current.next[currentStepId] = fn;
      }
    };

    const onEvent = (evName: string, fn: () => unknown) => {
      if (currentStepId) {
        const eventHandlers = wizard.eventHandlersRef.current[evName] || {};

        eventHandlers[currentStepId] = fn;

        wizard.eventHandlersRef.current[evName] = eventHandlers;
      }
    };

    const getEventHandler = (evName: string) => {
      let handler: (() => unknown) | undefined;

      if (currentStepId) {
        const eventHandlers = wizard.eventHandlersRef.current[evName];
        handler = eventHandlers?.[currentStepId];
      }

      return handler;
    };
    const triggerEvent = async (evName: string) => {
      const handler = getEventHandler(evName);

      return await handler?.();
    };

    return {
      ...rest,
      state,
      isMobile,
      currentStep: formStepToWizardStepConfig(currentStep),
      stepList: stepList.map(formStepToWizardStepConfig),
      isFinalStep:
        !!currentStep &&
        currentStep?.metadata?.id ===
          stepList?.[stepList?.length - 1]?.metadata?.id,
      navigatedFromPreview:
        !!currentStep &&
        !!navigationContext[currentStep.metadata.id]?.fromPreview,
      onBack,
      onNext,
      onEvent,
      getEventHandler,
      triggerEvent,
      scrollToPreviewStep: wizard.scrollToPreview,
      scrollToPreviewTop: wizardScrollToPreviewTop,
      goToStep: wizardGoToStep,
      goToNextStep: wizardGoToNextStep,
      goToPreviousStep: wizardGoToPreviousStep,
      getLastVisitedStep: wizardGetLastVisitedStep,
      setState,

      /**
       * Function to run before navigating to a different step
       */
      onStepChange,
    };
  }

  return [Wizard, useWizard] as const;
}
