import { atom } from 'recoil';
import {
  ActiveFiltersReturnType,
  createUseFiltersHook,
  FilterFnMap,
} from '@common/filter/filters';
import { PaymentWithInvoiceNumber } from './payments';

const BillpayPaymentsFilterState = atom<FilterFnMap<PaymentWithInvoiceNumber>>({
  key: 'billpay_payments_filter_state',
  default: {},
});

const BillpayPaymentsFilterStateModal = atom<
  FilterFnMap<PaymentWithInvoiceNumber>
>({
  key: 'billpay_payments_filter_state_modal',
  default: {},
});

export function useBillpayPaymentsFilters(): ActiveFiltersReturnType<PaymentWithInvoiceNumber> {
  return createUseFiltersHook<PaymentWithInvoiceNumber>(
    BillpayPaymentsFilterState,
  );
}

export function useBillpayPaymentsModalFilters(): ActiveFiltersReturnType<PaymentWithInvoiceNumber> {
  return createUseFiltersHook<PaymentWithInvoiceNumber>(
    BillpayPaymentsFilterStateModal,
  );
}
