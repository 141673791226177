import { useStyles } from '../../styles';
import { Text } from '@mantine/core';
import { BenefitsSuccessCheck } from '../../../../../assets/svg';

export const RedemptionSuccessContent = () => {
  const { classes } = useStyles();

  return (
    <div style={{ marginTop: '20px' }}>
      <div className={classes.reviewSuccessIconDiv}>
        <BenefitsSuccessCheck width={40} height={40} />
      </div>
      <Text className={classes.reviewSuccessText}>
        Success! $1,000 has been redeemed and will be deposited into your Flex
        checking account ending in 2234
      </Text>
      <Text className={classes.reviewSuccessSubtext}>
        Processing of your payment may take x business days
      </Text>
    </div>
  );
};
