import { useQuery } from '@tanstack/react-query';
import { flexbaseOnboardingClient } from '../services/flexbase-client';

const QUERY_KEY = ['onboarding_overview'];

export const useOnboardingOverview = () => {
  return useQuery({
    queryKey: [QUERY_KEY],
    queryFn: () => flexbaseOnboardingClient.getOnboardingOverview(),
  });
};
