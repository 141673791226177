import useModal from '@common/modal/modal-hook';
import {
  Anchor,
  Box,
  Container,
  Flex,
  Text,
  rem,
  useMantineTheme,
} from '@mantine/core';
import RecentActivityModal from '../../recent-activity-modal/recent-activity-modal';
import AvatarLabel from './avatar-label/avatar-label';
import { useGetSpendPlanRequests } from '../../../../../queries/use-spend-plans';
import { useMemo } from 'react';
import { useGetUsers } from '../../../../../queries/use-users';
import { usePlatformPersonContext } from '../../../../../providers/platform-person.context';
import {
  SpendPlanAdminView,
  SpendPlanView,
} from '@flexbase-eng/types/dist/accounting';

type Props = {
  spendPlan: SpendPlanAdminView | SpendPlanView;
};

const RecentActivity = ({ spendPlan }: Props) => {
  const spendPlanId = spendPlan.id;

  const modal = useModal();
  const themeColor = useMantineTheme();
  const { person } = usePlatformPersonContext();
  const { data: requests } = useGetSpendPlanRequests(
    person.accountId,
    spendPlanId,
  );

  const { data: users } = useGetUsers();

  const formattedData = useMemo(() => {
    return requests?.map((request) => {
      const userRequest = users?.find(
        (user) => user.id === request.requestedBy,
      );
      return {
        name: userRequest
          ? `${userRequest?.firstName} ${userRequest?.lastName}`
          : 'Member',
        amount: request.amount,
        description: request.description,
        requestId: request.id,
        requestedBy: request.requestedBy,
        status: request.status,
        reason: request.reason || '',
        decisionedBy: request.decisionedBy || '',
      };
    });
  }, [requests, users]);

  const handleClick = () => {
    modal.openRightModal(
      <RecentActivityModal
        accountId={person.accountId}
        spendPlan={spendPlan}
        requests={formattedData}
        closeModal={() => modal.closeAllModals()}
      />,
      themeColor.fn.themeColor('neutral', 2),
    );
  };

  return (
    <Container
      bg="neutral.0"
      p={20}
      h="100%"
      sx={(theme) => ({
        border: `1px solid ${theme.fn.themeColor('neutral', 3)}`,
        borderRadius: theme.defaultRadius,
      })}
    >
      <Flex align="center" justify="space-between">
        <Text fz={rem(20)}> Recent activity </Text>
        {(formattedData?.length || 0) !== 0 && (
          <Anchor
            onClick={handleClick}
            fz={rem(14)}
            c="primarySecondarySuccess.4"
          >
            View all
          </Anchor>
        )}
      </Flex>
      <Box mt={12}>
        {formattedData
          ?.map((item) => {
            return (
              <AvatarLabel
                onClick={handleClick}
                request={item}
                key={item.requestId}
              />
            );
          })
          .slice(0, 4)}
        {formattedData?.length === 0 && (
          <Text align="center" c="neutral.6" style={{ alignContent: 'center' }}>
            Fund requests from your team will show here
          </Text>
        )}
      </Box>
    </Container>
  );
};

export default RecentActivity;
