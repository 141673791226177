import { Alert, Button, Text } from '@mantine/core';
import { PlaidAccount } from '../move-funds/move-funds.model';
import { AlertTriangleIcon } from 'assets/svg';
import { useStyles } from './external-accounts.styles';
import { showNotification } from '@mantine/notifications';
import PlaidContext, { PlaidContextValue } from 'providers/plaid-context';
import { flexbaseBankingClient } from 'services/flexbase-client';
import { Token } from './external-accounts';

type Props = {
  accountsReqConn: PlaidAccount[];
  setLoading: (x: boolean) => void;
  setOpenPlaid: (x: boolean) => void;
  setTokens: (tokens: Token[]) => void;
};

const AccountsReconnectAlert = ({
  accountsReqConn,
  setLoading,
  setOpenPlaid,
  setTokens,
}: Props) => {
  const { classes } = useStyles();

  const getLinkTokens = async (bankName: string) => {
    setLoading(true);
    // Generate Plaid link update tokens for the specified bankName
    const linkTokenRequest = flexbaseBankingClient.relinkPlaidAccount({
      bankName,
    });

    linkTokenRequest
      .then((response) => {
        const linkToken = response.response.link_token;
        PlaidContext.appContext = PlaidContextValue.STALE;
        setTokens([{ linkToken, bankName }]);
        setOpenPlaid(true);
      })
      .catch(() => {
        showNotification({
          title: 'Failure',
          message: `Unable to get link token for ${bankName}. Please contact support if the issue persists.`,
          color: 'red',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <>
      {accountsReqConn.map((item) => (
        <Alert
          withCloseButton={false}
          bg={'rgba(239, 213, 171, 0.1)'}
          sx={(t) => ({ borderColor: t.fn.themeColor('tertiary', 1) })}
          key={item.id}
          mt="md"
        >
          <div className={classes.containerAlert}>
            <div className={classes.containerWarnMessage}>
              <AlertTriangleIcon />{' '}
              <Text ml="sm">
                We&apos;ve lost connection to your {item.bankName} bank
                accounts. Please reconnect to view your external accounts&apos;
                balances.
              </Text>{' '}
            </div>
            <Button
              variant="outline"
              className={classes.btnReconnect}
              onClick={() => getLinkTokens(item.bankName as string)}
            >
              Reconnect
            </Button>{' '}
          </div>
        </Alert>
      ))}
    </>
  );
};

export default AccountsReconnectAlert;
