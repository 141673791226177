import { SimpleSegmentSelectedFilter } from '@common/filter/simple-segmented.filter';
import { useBillpayPaymentsModalFilters } from 'areas/billpay/payments/use-billpay-payments-filters';

const TYPE_MULTISELECT_OPTIONS = [
  {
    label: 'ACH',
    filterCriteria: ['ach'],
    key: 'ach',
  },
  {
    label: 'WIRE',
    filterCriteria: ['wire'],
    key: 'wire',
  },
];

export const PaymentMethodFilter = () => {
  const PaymentInvoiceStatusFilters = useBillpayPaymentsModalFilters();

  return (
    <SimpleSegmentSelectedFilter
      filterHook={PaymentInvoiceStatusFilters}
      filterKey="type"
      options={TYPE_MULTISELECT_OPTIONS}
      label="Type"
      includesValue={(row) => row.type}
    />
  );
};
