import { Avatar, Badge, Flex, Text, useMantineTheme } from '@mantine/core';
import { FlexbaseTable } from '@common/table';
import { useMemo, useState } from 'react';
import { TableColumn } from 'react-data-table-component';
import { getInitialsOfNames } from '@utilities/extensions/object';
import { useMediaQuery } from '@mantine/hooks';
import {
  getDueInDays,
  getMonthDayYear,
  sortDateFromSQL,
} from '@utilities/dates/dates';
import { formatCurrency } from '@utilities/formatters/format-currency';
import { SkeletonLoading } from '@flexbase-eng/web-components';
import TableHeader from './table-header';
import {
  ExampleItem,
  ExampleItemStatus,
  useExampleData,
} from '../data/use-example-data';
import {
  filterModalSections,
  useExampleItemsFilters,
} from '../filters/use-example-filters';
import { formatApiStrings } from '@utilities/formatters/format-api-strings';
import GetPaddedAccountMask from '@utilities/formatters/get-padded-account-mask';
import useModal from '@common/modal/modal-hook';
import RowDetails from './row-details';
import { FiDownload } from 'react-icons/fi';
import { downloadCSV } from '@utilities/file-io/csv/csv-export';

export const SampleTable = () => {
  const theme = useMantineTheme();
  const modal = useModal();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);

  // fetch your data
  const { data, isLoading, isSuccess } = useExampleData();

  // get your active filters
  const { activeFiltersArray } = useExampleItemsFilters();

  // filter the data based on the active filters
  const filteredExampleItems: ExampleItem[] = useMemo(() => {
    if (!isSuccess) return [];
    return (data?.exampleItems ?? []).filter((it) =>
      activeFiltersArray.every((filter) => filter.fn(it)),
    );
  }, [data, activeFiltersArray]);

  // define the table's columns
  const columns: TableColumn<ExampleItem>[] = [
    {
      id: 'createdAt',
      name: 'Created at',
      selector: (invoice) => invoice.createdAt,
      format: (invoice) => getMonthDayYear(invoice.createdAt),
      sortFunction: (a, b) => sortDateFromSQL(a.createdAt, b.createdAt),
      sortable: true,
    },
    // dueDate column (example date column)
    {
      id: 'dueDate',
      name: 'Due date',
      selector: (invoice) => invoice.dueDate,
      format: (invoice) => getMonthDayYear(invoice.dueDate),
      sortFunction: (a, b) => sortDateFromSQL(a.dueDate, b.dueDate),
      sortable: true,
    },
    // recipient column (example avatar / recipient column)
    {
      id: 'recipientName',
      name: 'Recipient',
      cell: (row) => (
        <Flex align="center" data-tag="allowRowEvents">
          <Avatar data-tag="allowRowEvents" radius="xl" mr={15} w={45} h={45}>
            <Text data-tag="allowRowEvents">
              {getInitialsOfNames(row.recipientName ?? '')}
            </Text>
          </Avatar>
          <Text data-tag="allowRowEvents" sx={{ flex: 1 }}>
            {row.recipientName}
          </Text>
        </Flex>
      ),
      selector: (row) => row.recipientName ?? '',
      // an important or critical column can grow to fill more space than others if needed
      grow: isMobile ? 2 : 1.5,
      sortable: true,
    },
    // invoice number column (example text column)
    {
      id: 'invoiceNumber',
      name: 'Invoice number',
      selector: (invoice) => invoice.invoiceNumber ?? '',
      sortable: true,
    },
    // amount column (example currency column)
    {
      id: 'amount',
      name: 'Amount',
      selector: (invoice) => invoice.total,
      format: (invoice) => formatCurrency(invoice.total / 100),
      sortable: true,
    },
    // card number column (example masked number column)
    {
      id: 'cardNumber',
      name: 'Card number',
      selector: (invoice) => invoice.last4,
      format: (invoice) => GetPaddedAccountMask(invoice.last4, 4),
      sortable: true,
    },
    // dueIn column (example function column)
    {
      id: 'dueIn',
      name: 'Due in',
      selector: (invoice) => getDueInDays(invoice.dueDate),
      format: (invoice) => getDueInDays(invoice.dueDate),
      sortFunction: (a, b) => sortDateFromSQL(a.dueDate, b.dueDate),
      sortable: true,
    },
    // status column (example badge column)
    {
      name: 'Status',
      id: 'status',
      selector: (row) => row.status,
      cell: (row) => {
        const statusToColor = {
          [ExampleItemStatus.Requested]: theme.colors.red[1],
          [ExampleItemStatus.Drafted]: theme.colors.blue[1],
          [ExampleItemStatus.Approved]: theme.colors.green[1],
          [ExampleItemStatus.Paid]: theme.colors.yellow[1],
        };
        return (
          <Badge bg={statusToColor[row.status]}>
            {formatApiStrings(row.status)}
          </Badge>
        );
      },
      sortable: true,
    },
  ];

  // handle table row click (open a default row details modal)
  const handleRowClick = (exampleItem: ExampleItem) => {
    isMobile
      ? modal.openFullModal(<RowDetails exampleRow={exampleItem} />)
      : modal.openRightModal(<RowDetails exampleRow={exampleItem} />);
  };

  // state for the selected rows
  const [selectedRows, setSelectedRows] = useState<ExampleItem[]>([]);

  // handle changes in selected rows
  const handleSelectedRowsChange = ({
    selectedRows: selected,
  }: {
    selectedRows: ExampleItem[];
  }) => {
    setSelectedRows(selected);
  };

  // for CSV export: if there are selected rows, use them, otherwise use the filtered data
  const selectedDataToExport =
    selectedRows && selectedRows?.length > 0
      ? selectedRows
      : filteredExampleItems;

  // handle CSV export of selected / filtered rows
  const handleCSVExport = () => {
    if (!selectedDataToExport) return;
    downloadCSV({
      data: selectedDataToExport as object[],
      fileName: 'Example Items',
    });
  };

  // config for the actions in the bulk action bar
  const bulkActionsConfig = {
    actions: [
      // CSV export action
      {
        key: 'csv-export',
        label: 'Download Selected',
        icon: <FiDownload size={18} />,
        onClick: handleCSVExport,
      },
    ],
  };

  // if the data is loading, show skeleton
  if (isLoading) {
    return <SkeletonLoading />;
  }

  return (
    <>
      {/* `TableHeader` includes the components on top of the table: search, filters, and action buttons */}
      <TableHeader
        placeholder="Search"
        useFilters={useExampleItemsFilters}
        data={filteredExampleItems}
        filtersData={filterModalSections(filteredExampleItems)}
        selectedData={selectedDataToExport}
        onDownloadCsvClick={handleCSVExport}
      />
      {/* `FlexbaseTable` are the table rows and columns themselves */}
      <FlexbaseTable
        defaultSortFieldId="dueDate"
        columns={columns}
        data={filteredExampleItems}
        isFetchingData={isLoading}
        onRowClicked={handleRowClick}
        // include these if you want selectable rows
        selectableRows
        onSelectedRowsChange={handleSelectedRowsChange}
        bulkActionsConfig={bulkActionsConfig}
      />
    </>
  );
};
