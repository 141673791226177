import { SimpleSegmentSelectedFilter } from '@common/filter/simple-segmented.filter';
import {
  useChargeCardsModalFilters,
  useCreditCardsModalFilters,
} from '../use-credit-cards-filters';
import { ActiveFiltersReturnType } from '@common/filter/filters';
import { CardTableRow } from '../../card-table-row.model';

const TYPE_CARD_OPTIONS = [
  {
    label: 'Virtual',
    filterCriteria: 'VIRTUAL',
    key: 'virtual',
  },
  {
    label: 'Physical',
    filterCriteria: 'PHYSICAL',
    key: 'physical',
  },
];

type CardTypeFilterProps = {
  filterHook: ActiveFiltersReturnType<CardTableRow>;
};

const TypeFilter = ({ filterHook }: CardTypeFilterProps) => {
  return (
    <SimpleSegmentSelectedFilter
      filterHook={filterHook}
      filterKey="type"
      options={TYPE_CARD_OPTIONS}
      label="Type"
      includesValue={(row) => row.cardType}
    />
  );
};

export const CreditCardTypeFilter = () => {
  const filterHook = useCreditCardsModalFilters();

  return <TypeFilter filterHook={filterHook} />;
};

export const ChargeCardTypeFilter = () => {
  const filterHook = useChargeCardsModalFilters();

  return <TypeFilter filterHook={filterHook} />;
};
