import { convertToCurrency } from 'areas/credit/tabs/overview/utilities';
import { formatCurrency } from '@utilities/formatters';
import { Box, Flex, Text, useMantineTheme } from '@mantine/core';
import { useState } from 'react';
import {
  PieChart,
  Pie,
  ResponsiveContainer,
  Cell,
  Sector,
  Tooltip,
} from 'recharts';

const renderActiveShape = (props: any, total: number) => {
  const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } =
    props;
  return (
    <g>
      <text
        x={cx}
        y={cy}
        textAnchor="middle"
        dominantBaseline="middle"
        style={{ fontSize: '14px' }}
      >
        <tspan x={cx} dy="-0.3em" fontSize="1rem">{`$${convertToCurrency(
          Number(total),
        )}`}</tspan>
        <tspan x={cx} dy="1.2em" fontSize="0.875rem">
          Total
        </tspan>
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
    </g>
  );
};

type PropsTooltip = {
  payload: { name: string; value: number }[];
  total: number;
};

const CustomTooltip = ({
  payload = [{ name: '', value: 0 }],
  total,
}: PropsTooltip) => {
  if (payload && payload.length) {
    const data = payload[0].value || 0;
    const percentage = ((data / total) * 100).toFixed(2);
    return (
      <div
        style={{
          backgroundColor: 'white',
          border: '1px solid #5F5F5F',
          padding: '0px 5px 0px 5px',
          width: 160,
        }}
      >
        <p style={{ marginBottom: 0, fontSize: '12px' }}>{`${
          payload[0].name
        } : ${formatCurrency(payload[0].value)}`}</p>
        <p style={{ marginTop: 0, fontSize: '12px' }}>{`${percentage}%`}</p>
      </div>
    );
  }

  return null;
};

type Props = {
  dataAnnual: { name: string; value: number }[];
  total: number;
};

const SpendRechart = ({ dataAnnual, total }: Props) => {
  const theme = useMantineTheme();
  const [activeIndex, setActiveIndex] = useState(0);

  const COLORS = [
    theme.fn.themeColor('primarySecondarySuccess', 2),
    theme.fn.themeColor('primarySecondarySuccess', 8),
    theme.fn.themeColor('tertiary', 2),
    theme.fn.themeColor('promote', 2),
    theme.fn.themeColor('neutral', 7),
  ];

  const handleMouseMove = (_: any, index: number) => {
    setActiveIndex(index);
  };

  return (
    <>
      <Text pt={15} pl={20} fz={16} c="blackish.0">
        Money spent this year
      </Text>
      <Flex justify="space-between" h="100%">
        <ResponsiveContainer width="45%" height="100%">
          <PieChart width={8} height={8}>
            <Pie
              data={dataAnnual}
              activeShape={(props: any) => renderActiveShape(props, total)}
              activeIndex={activeIndex}
              cx="47%"
              cy="30%"
              innerRadius={52}
              outerRadius={60}
              dataKey="value"
              onMouseEnter={handleMouseMove}
            >
              {dataAnnual.map((_, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Tooltip
              position={{ x: 170, y: 40 }}
              contentStyle={{ fontSize: '12px', padding: '10px' }}
              isAnimationActive={false}
              content={<CustomTooltip payload={dataAnnual} total={total} />}
            />
          </PieChart>
        </ResponsiveContainer>
        <Box pt={25} mr={25}>
          {dataAnnual.map((entry, index) => (
            <Flex key={index} align="center">
              <div
                style={{
                  backgroundColor: COLORS[index % COLORS.length],
                  height: 15,
                  width: 15,
                  marginRight: 5,
                }}
              />
              <Text fz={14} c="neutral.7">
                {entry.name}
              </Text>
            </Flex>
          ))}
        </Box>
      </Flex>
    </>
  );
};

export default SpendRechart;
