import { CounterpartyRequest } from '../services/flexbase/banking.model';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { flexbaseBankingClient } from '../services/flexbase-client';

const QUERY_KEY = 'counterparties';

const createCounterpartyMutation = async (params: CounterpartyRequest) => {
  return await flexbaseBankingClient.createCounterparty(params);
};

export const useCreateCounterparty = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createCounterpartyMutation,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY],
      });
    },
  });
};
