import { UseFormReturnType } from '@mantine/form';
import { InternationalRecipient } from 'areas/payments/components/send-payment/international-payments/util/types';
import {
  AddRecipientWizard,
  useAddRecipientWizard,
} from '../../../../add-recipient-wizard';
import InitialRequirementsForm from './initial-requirements-form';

type Props = {
  form: UseFormReturnType<
    InternationalRecipient,
    (values: InternationalRecipient) => InternationalRecipient
  >;
};

const IntlPaymentForm = ({ form }: Props) => {
  const { goToNextStep, setState } = useAddRecipientWizard();

  const handleNext = () => {
    form.validate();
    if (form.isValid()) {
      goToNextStep();
      setState((prev) => ({
        ...prev,
        beneficiaryRequirementsFormValues: form.values,
      }));
    }
  };

  return (
    <AddRecipientWizard.Step onNext={handleNext}>
      <InitialRequirementsForm form={form} />
    </AddRecipientWizard.Step>
  );
};

export default IntlPaymentForm;
