import { uniq } from 'underscore';
import {
  _ApplicationState,
  ApplicationRequiredFields,
  OnboardingRouteNames,
} from 'recoil-state/application/product-onboarding.models';
import { OptedProduct } from 'recoil-state/application/product-onboarding';

type RequirementArrayPropertyName = Extract<
  keyof _ApplicationState,
  | 'requiredTreasury'
  | 'requiredBanking'
  | 'requiredInternationalPayments'
  | 'requiredCredit'
  | 'requiredStripeCredit'
>;

export class StepNode {
  routeName: OnboardingRouteNames;
  requirements: string[] = [];
  order = 0;
  branches: Record<string, StepNode[]> = {};
  adminOnly: boolean;
  mapToBranch?: (state: _ApplicationState) => string;

  constructor(options: {
    routeName: OnboardingRouteNames;
    mapToBranch?: (s: _ApplicationState) => string;
    requirements: string[];
    order?: number;
    adminOnly?: boolean;
  }) {
    this.routeName = options.routeName;
    this.mapToBranch = options.mapToBranch;
    this.requirements = options.requirements;
    this.order = options.order ?? 0;
    this.adminOnly = options.adminOnly ?? false;
  }
}

class StepBuilder extends StepNode {
  branches: Record<string, StepBuilder[]> = {};

  constructor(options: {
    routeName: OnboardingRouteNames;
    mapToBranch?: (s: _ApplicationState) => string;
    order?: number;
    adminOnly?: boolean;
  }) {
    super({
      routeName: options.routeName,
      mapToBranch: options.mapToBranch,
      order: options.order,
      requirements: [],
      adminOnly: options.adminOnly,
    });
  }

  /**
   * Adds a step to one of this step's branches.
   *
   * If the branch doesn't exist, it will be created.
   */
  addStepToBranch(branchName: string, step: StepBuilder) {
    if (!this.branches[branchName]) {
      this.branches[branchName] = [];
    }

    this.branches[branchName].push(step);
  }

  addRequirement(r: string) {
    this.requirements.push(r);
  }

  build() {
    const step = new StepNode({
      routeName: this.routeName,
      mapToBranch: this.mapToBranch,
      requirements: this.requirements,
      order: this.order,
      adminOnly: this.adminOnly,
    });

    Object.entries(this.branches).forEach(([branchName, branchSteps]) => {
      step.branches[branchName] = branchSteps.map((s) => s.build());
    });

    return step;
  }
}

export class ApplicationConfiguration {
  private _products: OptedProduct[];
  private _steps: StepNode[];
  private _endRoute: OnboardingRouteNames | undefined;
  private _requirementArrayNames: RequirementArrayPropertyName[];
  private _allSteps: StepNode[];

  get products() {
    return this._products;
  }

  get steps() {
    return this._steps;
  }

  get endRoute() {
    return this._endRoute;
  }

  get requirementArrayNames() {
    return this._requirementArrayNames;
  }

  constructor(
    products: OptedProduct[],
    steps: StepNode[],
    requirementArrayNames: RequirementArrayPropertyName[],
    endRoute: OnboardingRouteNames | undefined,
  ) {
    this._products = products;
    this._steps = steps;
    this._allSteps = flattenAllBranches(steps);
    this._requirementArrayNames = requirementArrayNames;
    this._endRoute = endRoute;
  }

  buildRouteList(status: _ApplicationState) {
    const flattened = flattenBranchesFromStatus(this._steps, status).map(
      (step, idx) => ({
        ...step,
        order: idx + 1,
      }),
    );

    return flattened;
  }

  getRequirementsForRoute(route: OnboardingRouteNames) {
    return this._allSteps
      .filter((step) => step.routeName === route)
      .flatMap((step) => step.requirements);
  }

  getRequirementsFromStatus(status: _ApplicationState) {
    const requiredFields = uniq(
      this.requirementArrayNames.flatMap((name) => status[name]),
    );

    return requiredFields;
  }

  getNextRouteFromStatus(
    status: _ApplicationState,
  ): OnboardingRouteNames | 'end' {
    const routeList = this.buildRouteList(status);
    const routeMap = routeList.reduce<Record<string, StepNode>>(
      (acc, route) => {
        route.requirements.forEach((req) => (acc[req] = route));

        return acc;
      },
      {},
    );

    const requirements = this.getRequirementsFromStatus(status);
    const routes = requirements.map((req) => routeMap[req]).filter(Boolean);
    const orderedRoutes = routes.sort((a, b) => a.order - b.order);

    if (!orderedRoutes.length) {
      return this.endRoute || 'end';
    }

    return orderedRoutes[0].routeName;
  }

  getNextRouteFromCurrentRoute(
    route: OnboardingRouteNames,
    userIsAdmin: boolean,
    status: _ApplicationState,
  ): OnboardingRouteNames | 'oops' {
    const routelist = this.buildRouteList(status);
    const routeIdx = routelist.findIndex((r) => r.routeName === route);

    // the given route doesn't exist
    if (routeIdx < 0) {
      return 'oops';
    }

    // get all next routes, then filter for adminOnly
    const nextRoutes = routelist
      .slice(routeIdx + 1)
      .filter((r) => userIsAdmin || !r.adminOnly);

    // return next route if exists
    return nextRoutes[0] ? nextRoutes[0].routeName : route;
  }

  getPreviousRoute(
    route: OnboardingRouteNames,
    userIsAdmin: boolean,
    status: _ApplicationState,
  ): OnboardingRouteNames | 'oops' {
    const routelist = this.buildRouteList(status);
    const routeIdx = routelist.findIndex((r) => r.routeName === route);

    // the given route doesn't exist
    if (routeIdx < 0) {
      return 'oops';
    }

    // get all previous routes, then filter for adminOnly
    const previousRoutes = routelist
      .slice(0, routeIdx)
      .filter((r) => userIsAdmin || !r.adminOnly);

    // pop to get the prev route
    const prevRoute = previousRoutes.pop();

    // return previous route if exists
    return prevRoute ? prevRoute.routeName : route;
  }

  getProgressForRoute(
    route: OnboardingRouteNames,
    status: _ApplicationState,
  ): number {
    const routeList = this.buildRouteList(status);
    const idx = routeList.findIndex((r) => r.routeName === route);

    if (idx < 0) {
      return 0;
    }

    return (idx + 1) / routeList.length;
  }
}

/**
 * Build a configuration to handle requirements driven by the backend.
 *
 * @example
 * // Instantiate builder for the CREDIT product.
 * // Read requirements from the requiredCredit property of the onboarding object.
 * const builder = new ApplicationConfigBuilder(['CREDIT'])
 *   .forRequirementArray('requiredCredit');
 *
 * // Navigate to the 'verify-phone' route if 'user.phone' is a requirement.
 * builder.addStep('verify-phone')
 *   .withRequirement('user.phone');
 *
 * // The 'job-title' branching route can be followed by either 'control-person' or 'business-officers'.
 * // To determine which path to take, add a condition that takes the onboarding object and returns a path name.
 * // Each path has its own nested builder object to setup the steps for that branch.
 * builder
 *   .addBranchingStep('job-title')
 *   .withRequirement('user.jobTitle')
 *   .withBranchCondition((state) =>
 *     state.someField ? 'path_foo' : 'path_bar',
 *   )
 *   .withPath('path_foo', (b) => {
 *     b.addStep('control-person')
 *       .withRequirement('company.controlPerson');
 *   })
 *   .withPath('path_bar', (b) => {
 *     b.addStep('business-officers')
 *       .withRequirement('company.officers');
 *   });
 *
 * // Build the final configuration object.
 * const config = builder.build();
 */
export class ApplicationConfigBuilder {
  private _products: OptedProduct[] = [];
  private _requirementArrayNames = new Set<RequirementArrayPropertyName>();
  private _builderSteps: StepBuilder[] = [];
  private _endRoute: OnboardingRouteNames | undefined;

  constructor(products: OptedProduct[]) {
    this._products = [...products];
  }

  forRequirementArray(name: RequirementArrayPropertyName) {
    this._requirementArrayNames.add(name);

    return this;
  }

  addEndRoute(route: OnboardingRouteNames) {
    this._endRoute = route;
  }

  addStep(routeName: OnboardingRouteNames, adminOnly = false) {
    const step = new StepBuilder({
      routeName,
      adminOnly,
      order: this._builderSteps.length + 1,
    });

    this._builderSteps.push(step);

    const stepFns = {
      withRequirement: (req: ApplicationRequiredFields) => {
        step.addRequirement(req);

        return stepFns;
      },
    };

    return stepFns;
  }

  addBranchingStep(routeName: OnboardingRouteNames, adminOnly = false) {
    const branchingStep = new StepBuilder({
      routeName,
      adminOnly,
      order: this._builderSteps.length + 1,
    });

    this._builderSteps.push(branchingStep);

    const branchingStepFns = {
      withRequirement: (req: ApplicationRequiredFields) => {
        branchingStep.addRequirement(req);

        return branchingStepFns;
      },

      withBranchCondition: <TBranchName extends string>(
        branchMapper: (s: _ApplicationState) => TBranchName,
      ) => {
        branchingStep.mapToBranch = branchMapper;

        const branchConditionFns = {
          withRequirement: (req: ApplicationRequiredFields) => {
            branchingStep.addRequirement(req);

            return branchConditionFns;
          },
          withPath: (
            pathName: TBranchName,
            builderFn: (b: ApplicationConfigBuilder) => void,
          ) => {
            const branchBuilder = new ApplicationConfigBuilder(this._products);

            builderFn(branchBuilder);

            branchBuilder._builderSteps.forEach((step) => {
              branchingStep.addStepToBranch(pathName, step);
            });

            return branchConditionFns;
          },
        };

        return branchConditionFns;
      },
    };

    return branchingStepFns;
  }

  build(): ApplicationConfiguration {
    this._builderSteps.reduce<Record<string, string>>((acc, step) => {
      step.requirements.forEach((req) => {
        const mappedRoute = acc[req];

        if (mappedRoute) {
          throw new Error(
            `Duplicate requirement in application config for [${this._products.join(
              ', ',
            )}]: ${req} cannot be used for both ${mappedRoute} and ${
              step.routeName
            }`,
          );
        }

        acc[req] = step.routeName;
      });

      return acc;
    }, {});

    const steps = this._builderSteps.map((step) => step.build());
    const tree = new ApplicationConfiguration(
      this._products,
      steps,
      [...this._requirementArrayNames],
      this._endRoute,
    );

    return tree;
  }
}

/**
 * Flattens the tree to get all possible steps.
 */
function flattenAllBranches(steps: StepNode[]): StepNode[] {
  return steps.reduce<StepNode[]>((acc, step) => {
    acc.push(step);

    Object.values(step.branches).forEach((branchSteps) => {
      acc.push(...flattenAllBranches(branchSteps));
    });

    return acc;
  }, []);
}

/**
 * Computes a steplist by traversing the tree according to the given status.
 */
function flattenBranchesFromStatus(
  steps: StepNode[],
  status: _ApplicationState,
): StepNode[] {
  return steps.reduce<StepNode[]>((acc, step) => {
    acc.push(step);

    if (step.mapToBranch) {
      const branchName = step.mapToBranch(status);
      const branchSteps = step.branches[branchName] || [];

      acc.push(...flattenBranchesFromStatus(branchSteps, status));
    }

    return acc;
  }, []);
}
