import { useEffect, useState } from 'react';
import { Box, Group, Text } from '@mantine/core';
import { statementsStyles } from './styles';
import FlexbaseTable from 'components/table/flexbase-table';
import Select from 'components/select/flexbase-select';
import { generateStatementDates } from 'utilities/dates/dates';
import { DateTime } from 'luxon';
import { DownloadIcon } from 'assets/svg';
import { useMediaQuery } from '@mantine/hooks';
import { ApplicationState } from '../../../recoil-state/application/product-onboarding';
import { useRecoilValue } from 'recoil';
import { downloadCSV } from 'utilities/file-io/csv/csv-export';
import { showNotification } from '@mantine/notifications';
import { useFetchCreditTransactionsCsv } from '@queries/use-credit-transactions-csv';
import { useGetMe } from '@queries/use-get-me';
import PdfCell from './pdf-cell';
import { IStatements } from 'types/statements';
import { TableColumn } from 'react-data-table-component';

const EMPTY_STATEMENT: IStatements = {
  after: '',
  before: '',
  date: '',
  target: '',
  type: '',
};
const EMPTY_STATE_DATA = new Array<IStatements>(7).fill(EMPTY_STATEMENT);

const CreditStatements = () => {
  const { cx, classes } = statementsStyles();
  const { company } = useRecoilValue(ApplicationState);
  const useMobileView = useMediaQuery('(max-width: 767px)');
  const [loading, setLoading] = useState(true);
  const [statements, setStatements] = useState<IStatements[]>([]);
  const [filterDate, setFilterDate] = useState('');
  const [totalYears, setTotalYears] = useState<string[]>([]);
  const creditTxCsv = useFetchCreditTransactionsCsv();
  const { data: personInfo } = useGetMe();
  const id = personInfo?.accountId ?? '00000000-0000-0000-0000-000000000000';

  const handleDownloadCsv = async (options: IStatements) => {
    try {
      const data = await creditTxCsv.fetchQuery(company.id, {
        after: options.after,
        before: options.before,
      });

      const [YYYY, MM] = options.target.split('-');
      const fileName = `Flex Credit Card Monthly Statement ${MM}-${YYYY}`;

      downloadCSV({ data, fileName, withDate: false });
    } catch (e) {
      showNotification({
        title: 'Error',
        message: `An error occurred while fetching csv data.`,
        color: 'red',
      });
      console.error(`An error occurred while fetching csv data. ${e}`);
    }
  };

  const columns: TableColumn<IStatements>[] = [
    {
      name: (
        <Text size="13px" fw={500}>
          Month
        </Text>
      ),
      cell: (statement) => {
        return statements.length === 0 ? (
          <div className={classes.rectangleRounded}></div>
        ) : (
          <div>{statement.date}</div>
        );
      },
      selector: (statement) => statement.target,
      sortable: true,
      width: useMobileView ? 'auto' : '80%',
      grow: 1,
    },
    {
      name: (
        <Text className={classes.pdfTableHeader} size="13px" fw={500}>
          PDF
        </Text>
      ),
      cell: (statement) => {
        return (
          <PdfCell
            companyId={company.id}
            accountId={id}
            statement={statement}
            hidePdfButton={statements.length === 0}
          />
        );
      },
      width: useMobileView ? 'auto' : '10%',
      grow: useMobileView ? 0 : 1,
    },
    {
      name: (
        <Text className={classes.pdfTableHeader} size="13px" fw={500}>
          CSV
        </Text>
      ),
      cell: (statement) => {
        return statements.length === 0 ? (
          <div className={classes.contentCenter}>
            <Box className={classes.rectangleRounded}></Box>
          </div>
        ) : (
          <div
            onClick={() => handleDownloadCsv(statement)}
            className={classes.statementRow}
          >
            <DownloadIcon />
          </div>
        );
      },
      width: useMobileView ? 'auto' : '10%',
      grow: useMobileView ? 0 : 1,
    },
  ];

  const filterDates = async (newDate: string, newStatemets: IStatements[]) => {
    if (newDate !== '') {
      const result = (newStatemets || []).filter(
        (item: { date: string; target: string }) => {
          if (newDate === item.target.slice(0, 4)) {
            return item;
          }
        },
      );
      setStatements(result.reverse());
    } else {
      setStatements(newStatemets.reverse());
    }
  };

  const getStatements = async (): Promise<IStatements[]> => {
    try {
      setLoading(true);
      if (company) {
        const generatedDates = generateStatementDates(
          company['createdAt'],
          'statements',
        );
        // Need to put this side effect here to make the code work.
        setTotalYears(generatedDates.totalYears.reverse());

        return generatedDates.statements;
      }

      return [];
    } catch (error) {
      console.error('Unable to get company data', error);
      return [];
    } finally {
      setLoading(false);
    }
  };

  const getAvailabilityMessage = () => {
    const currentDate = DateTime.local();
    const currentDay = currentDate.day;
    let availabilityDate = currentDate.set({ day: 15 });

    if (currentDay >= 1) {
      availabilityDate = currentDate.plus({ months: 1 }).startOf('month');
    }

    return (
      <>
        <Text align="center">Your first statement will be </Text>
        <Text align="center">
          available after the 1st of {availabilityDate.toFormat('MMMM')}
        </Text>
      </>
    );
  };

  useEffect(() => {
    try {
      setLoading(true);
      getStatements().then((result) => {
        const maxYear = result.reduce(
          (max, item) => Math.max(max, DateTime.fromISO(item.target).year),
          -Infinity,
        );

        const selectedFilter = filterDate
          ? filterDate.toString()
          : maxYear.toString();
        setFilterDate(selectedFilter);
        filterDates(selectedFilter, result);
      });
    } finally {
      setLoading(false);
    }
  }, [filterDate]);

  return (
    <Box className={classes.container}>
      <Group position="right" mb="xl">
        <Select
          className={cx(statements.length === 0 && classes.blurryTextStyle)}
          value={filterDate}
          data={totalYears}
          placeholder="Filter by year"
          onChange={(value) => setFilterDate(value!)}
          disabled={statements.length === 0}
        />
      </Group>

      {statements.length === 0 && !loading ? (
        <Box pos="relative" mih={'500px'}>
          <Box className={classes.filterStyle}>
            <FlexbaseTable
              data={EMPTY_STATE_DATA}
              columns={columns}
              pagination={false}
              disabled
            />
          </Box>
          <Box className={classes.containerMessageNoData}>
            <Text className={classes.noDataTitle}>No statements yet</Text>
            <Box color="#5f5f5f">{getAvailabilityMessage()}</Box>
          </Box>
        </Box>
      ) : (
        <Box>
          <FlexbaseTable
            data={statements}
            columns={columns}
            noDataComponent={'There are no statements to display'}
            pagination={statements.length > 8}
            isFetchingData={loading}
          />
        </Box>
      )}
    </Box>
  );
};

export default CreditStatements;
