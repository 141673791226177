import { Divider, Text } from '@mantine/core';
import { CardByUser } from 'services/flexbase/banking.model';
import { formatCurrency } from 'utilities/formatters';
import { capitalizeOnlyFirstLetter } from 'utilities/formatters/format-strings';
import BillingAddress from './billing-address';
import DebitCard from './debit-card';
import { useStyles } from './debit-card-details.styles';
import { getATMSpendLimitByTier } from '../util';
import { ListIcon } from 'assets/svg';
import { closeAllModals } from '@mantine/modals';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { useGetDepositAccounts } from 'queries/use-deposit-accounts';

type Props = {
  limit: number;
  card: CardByUser;
  limitType: string;
  cardStatus: string;
};

const TerminatedDebitCard = ({ card, limitType, cardStatus, limit }: Props) => {
  const { classes, cx } = useStyles();
  const { data } = useGetDepositAccounts();
  const depositAccounts = data?.accounts || [];
  const navigate = useNavigate();
  const cardDepositAccount = depositAccounts.find(
    (account) => account.id === card.depositAccountId,
  );
  const unitcoCardId = card.ucCardId.substring(5);
  const currentDailyWithdrawalLimit =
    card.expensesTypes?.dailyWithdrawal ??
    getATMSpendLimitByTier(depositAccounts, card.depositAccountId);

  const footerData = [
    {
      title: 'Account',
      value: cardDepositAccount?.nickName || cardDepositAccount?.name,
      isVisible: true,
    },
    {
      title: 'Card type',
      value: card.cardSubtype.includes('Virtual') ? 'Virtual' : 'Physical',
      isVisible: true,
    },
    {
      title: 'Daily ATM withdrawal limit',
      value: formatCurrency(currentDailyWithdrawalLimit),
      isVisible: !card.cardSubtype.includes('Virtual'),
    },
    {
      title:
        limitType !== 'unlimited'
          ? `${capitalizeOnlyFirstLetter(limitType)} spending limit`
          : 'Spending limit',
      value: limitType !== 'unlimited' ? formatCurrency(limit) : 'Unlimited',
      isVisible: true,
    },
  ];

  return (
    <div className={classes.cardDetails}>
      <div className={cx(classes.header, classes.rowContent)}>
        <div>
          <Text size={20}>{card.cardName || null}</Text>
          <Text size={14} mt={6}>
            {card.holder}
          </Text>
        </div>
      </div>

      <DebitCard {...{ card, cardStatus }} />

      <div
        className={classes.transactionsAction}
        style={{ justifyContent: 'flex-start', marginLeft: 50 }}
        onClick={() => {
          closeAllModals();
          navigate({
            pathname: `/banking/transactions`,
            search: createSearchParams({
              cardId: `${unitcoCardId}`,
            }).toString(),
          });
        }}
      >
        <ListIcon width={20} />
        <Text>Transactions</Text>
      </div>

      {card.address && (
        <>
          <Divider className={classes.divider} />
          <BillingAddress card={card} />
        </>
      )}

      <Divider className={classes.divider} />
      <div className={classes.padding}>
        {footerData.map(
          ({ title, value, isVisible }) =>
            isVisible && (
              <div
                className={`${classes.rowContent} ${classes.footerItem}`}
                key={title}
              >
                <Text size={14}>{title}</Text>
                <Text size={14}>{value}</Text>
              </div>
            ),
        )}
      </div>
    </div>
  );
};

export default TerminatedDebitCard;
