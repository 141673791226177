import { useSelfServiceStyles } from '../self-service-dashboard.styles';
import { OnboardingOverviewResponse } from '../../../types/onboarding-info';
import { OutstandingOwnersItem } from './outstanding-owners.item';
import { RequestedDocumentsItem } from './requested-documents.item';
import { LinkAccountsItem } from './link-accounts.item';
import { _ApplicationState } from '../../../recoil-state/application/product-onboarding.models';
import { ActionItemNames } from '../calculate-action-items';
import { FrozenCreditItem } from '@common/self-service-dashboard/action-items/frozen-credit.item';
import { Fragment } from 'react';

type Props = {
  status: _ApplicationState;
  overview: OnboardingOverviewResponse;
  actionItems: ActionItemNames[];
};

export const ActionItemList = ({ status, overview, actionItems }: Props) => {
  const { classes } = useSelfServiceStyles();

  const owners = status.company.owners.map((o) => o.id!);
  const officers = status.company.officers.map((o) => o.id!);
  const pendingUsers = [...owners, ...officers];

  return (
    <div className={classes.actionItemContainer}>
      {actionItems.map((item) => {
        switch (item) {
          case 'docs':
            return (
              <RequestedDocumentsItem
                requiredDocuments={status.company.requestedDocuments}
                key={item}
              />
            );
          case 'pendingUsers':
            return (
              <OutstandingOwnersItem
                companyUsers={overview.users.filter(
                  (u) => u.id !== status.user.id && pendingUsers.includes(u.id),
                )}
                key={item}
              />
            );
          case 'plaid':
            return <LinkAccountsItem key={item} />;
          case 'frozen':
            return <FrozenCreditItem key={item} />;
          default:
            return <Fragment key={item}></Fragment>;
        }
      })}
    </div>
  );
};
