import { RedemptionStepper } from '../redemption-stepper';
import { RedemptionAmountContent } from './content/redemption-amount-content';

export const RedemptionAmount = () => {
  return (
    <RedemptionStepper
      nextButtonText="Next"
      title="Select the amount"
      subtitle="Select the points you want to redeem cashback for"
    >
      <RedemptionAmountContent />
    </RedemptionStepper>
  );
};
