import OnboardingStep from '../components/onboarding-step';
import {
  Dropzone,
  IMAGE_MIME_TYPE,
  MIME_TYPES,
  MS_EXCEL_MIME_TYPE,
  MS_WORD_MIME_TYPE,
  PDF_MIME_TYPE,
} from '@mantine/dropzone';
import { Image, Text } from '@mantine/core';
import { useOnboardingStyles } from '../onboarding.styles';
import { useState } from 'react';
import { flexbaseOnboardingClient } from '../../../services/flexbase-client';
import { IoTrashOutline } from 'react-icons/io5';
import {
  AiOutlineFile,
  AiOutlineFileExcel,
  AiOutlineFilePdf,
  AiOutlineFileWord,
} from 'react-icons/ai';
import FlexbaseSelect from '../../../components/select/flexbase-select';
import { useApplicationFlowContext } from '../onboarding-hooks';

export type TreasuryDocTypes = 'tax' | 'csa' | 'id';
type TreasuryDocInfo = {
  apiDescriptionId: string;
  label: string;
  description: string;
  allowedDocs: { value: string; label: string }[];
};
const TREASURY_DOC_TYPES: Record<TreasuryDocTypes, TreasuryDocInfo> = {
  tax: {
    apiDescriptionId: 'Tax',
    label: 'Tax',
    description: "Upload your company's W-9 or W-8BEN",
    allowedDocs: [
      { value: 'W-9', label: 'W-9' },
      { value: 'W-8BEN', label: 'W-8BEN' },
    ],
  },
  csa: {
    apiDescriptionId: 'Corporate Signer Authority',
    label: 'Corporate Signer Authority',
    description: 'Upload a document verifying your signing authority',
    allowedDocs: [
      {
        value: 'Articles of Incorporation',
        label: 'Articles of Incorporation',
      },
      { value: 'Resolution of Authority', label: 'Resolution of Authority' },
      {
        value: 'Certificate of Good Standing',
        label: 'Certificate of Good Standing',
      },
      { value: 'Corporate Resolution', label: 'Corporate Resolution' },
      {
        value: 'State Filing of Incorporation',
        label: 'State Filing of Incorporation',
      },
    ],
  },
  id: {
    apiDescriptionId: 'Government Photo ID',
    label: 'Photo ID',
    description: 'Upload a photo of a government-issued ID',
    allowedDocs: [
      { value: 'Drivers License', label: 'Drivers License' },
      { value: 'Passport', label: 'Passport' },
      { value: 'Government ID', label: 'Government ID' },
    ],
  },
};

type Props = {
  documentType: TreasuryDocTypes;
};

const DocUpload = ({ documentType }: Props) => {
  const { goBack, navigateToNextProductStep } = useApplicationFlowContext();
  const { classes } = useOnboardingStyles();
  const [file, setFile] = useState<File>();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const docInfo = TREASURY_DOC_TYPES[documentType];
  const [selectedDocType, setSelectedDocType] = useState<string | null>(
    docInfo.allowedDocs[0].value,
  );

  const onContinueClick = async () => {
    if (!file) {
      setError(
        `You must upload your ${docInfo.label} in an accepted format to continue`,
      );
    } else {
      setLoading(true);
      try {
        await flexbaseOnboardingClient.uploadDocument({
          file,
          description: selectedDocType!,
        });
        navigateToNextProductStep();
      } catch (e) {
        setError(`Unable to upload your ${selectedDocType}`);
      } finally {
        setLoading(false);
      }
    }
  };

  const addFile = (files: File[]) => {
    setError('');
    setFile(files[0]);
  };

  const imagePreview = (_file: File) => {
    const imageUrl = URL.createObjectURL(_file);
    return (
      <div className={classes.documentPreview}>
        <Image
          key={_file.name}
          src={imageUrl}
          width={50}
          height={50}
          fit="contain"
          radius={8}
          mr={8}
          imageProps={{ onLoad: () => URL.revokeObjectURL(imageUrl) }}
        />
        {_file.name}
      </div>
    );
  };

  const documentPreview = (_file: File) => {
    let icon = <AiOutlineFile className={classes.documentPreviewIcon} />;
    if (PDF_MIME_TYPE.includes(_file.type as any)) {
      icon = <AiOutlineFilePdf className={classes.documentPreviewIcon} />;
    }

    if (MS_WORD_MIME_TYPE.includes(_file.type as any)) {
      icon = <AiOutlineFileWord className={classes.documentPreviewIcon} />;
    }

    if (MS_EXCEL_MIME_TYPE.includes(_file.type as any)) {
      icon = <AiOutlineFileExcel className={classes.documentPreviewIcon} />;
    }

    return (
      <div className={classes.documentPreview}>
        {icon}
        {_file.name}
      </div>
    );
  };

  const filePreview = file
    ? IMAGE_MIME_TYPE.includes(file.type as any)
      ? imagePreview(file)
      : documentPreview(file)
    : false;

  return (
    <OnboardingStep
      title={`${docInfo.label} Upload`}
      subtitle={docInfo.description}
      stepId={`doc-upload-${documentType}`}
      onNextClick={onContinueClick}
      onBackClick={() => goBack()}
      error={error}
      showContinueSpinner={loading}
    >
      <FlexbaseSelect
        label={`${docInfo.label} Document Type`}
        placeholder="Select document type"
        style={{ marginTop: '16px', marginBottom: '16px' }}
        data={docInfo.allowedDocs}
        value={selectedDocType}
        onChange={setSelectedDocType}
        classNames={classes}
        id={`input-document-${documentType}`}
      />
      {file ? (
        <div className={classes.file}>
          {filePreview}
          <button
            className={classes.deleteFile}
            onClick={() => {
              setFile(undefined);
            }}
          >
            <IoTrashOutline size={18} color="#D80027" />
          </button>
        </div>
      ) : (
        <Dropzone
          onDrop={(fileToAdd) => addFile(fileToAdd)}
          maxSize={3 * 1024 ** 2}
          accept={[
            ...IMAGE_MIME_TYPE,
            ...PDF_MIME_TYPE,
            ...MS_WORD_MIME_TYPE,
            ...MS_EXCEL_MIME_TYPE,
            MIME_TYPES.csv,
            'text/plain',
            'application/rtf',
          ]}
          multiple={false}
          classNames={{
            root: classes.dropZone,
          }}
        >
          <div className={classes.dropZonePlaceholderContainer}>
            <Image
              width={32}
              height={32}
              alt="no-invoice-image"
              src="/images/upload-cloud.svg"
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginRight: '15px',
              }}
            />
            <Text className={classes.dropZonePlaceholder}>
              Drag and drop or&nbsp;<span>browse</span>&nbsp;to add document
            </Text>
          </div>
        </Dropzone>
      )}
    </OnboardingStep>
  );
};

export default DocUpload;
