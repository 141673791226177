import { LoadingOverlay, useMantineTheme } from '@mantine/core';
import {
  useGetDomesticWireInstructions,
  useGetInternationalWireInstructions,
} from '@queries/use-wire-instructions';
import { Document } from '@react-pdf/renderer';
import { useParams } from 'react-router-dom';
import WireInstructionDoc from './wire-instruction-doc';
import PDFWireInstructionsViewer from './wire-instructions-pdf';
import { useIntlPaymentsFeatureFlag } from '@utilities/feature-flags';
import { useRecoilValue } from 'recoil';
import { ApplicationState } from 'recoil-state/application/product-onboarding';

const useHasIntlWires = () => {
  const { productStatus } = useRecoilValue(ApplicationState);
  const intlPaymentsFeatureFlagIsOn = useIntlPaymentsFeatureFlag();

  /** internationalPayments.appStatus could be 'approved' but
   * internationalPayments.status could be 'incomplete' if some
   * new requirements need to be provided
   */
  const isApprovedApplicationForIntlWires =
    productStatus.internationalPayments.appStatus === 'approved';
  const hasIntlWiresProduct =
    productStatus.internationalPayments.status === 'approved';
  const hasIntlWires =
    intlPaymentsFeatureFlagIsOn &&
    isApprovedApplicationForIntlWires &&
    hasIntlWiresProduct;

  return hasIntlWires;
};

const getGenericError = (error: boolean) => {
  return error ? 'Unable to load wire instructions for this account.' : '';
};

const WireInstructionsViewer = () => {
  const theme = useMantineTheme();
  const { accountId } = useParams();
  const {
    data: domesticWireData,
    isLoading: isLoadingDomesticWire,
    isError: isDomesticWireError,
  } = useGetDomesticWireInstructions(accountId);

  const hasIntlWires = useHasIntlWires();
  const {
    data: intlWireData,
    isLoading: isLoadingIntlWire,
    isError: isIntlWireError,
  } = useGetInternationalWireInstructions(hasIntlWires, accountId);

  const domesticError = getGenericError(isDomesticWireError);
  const intlError = getGenericError(isIntlWireError);

  const name =
    domesticWireData?.beneficiaryName || intlWireData?.beneficiaryName || '';
  const accountNumber =
    domesticWireData?.beneficiaryAccountNumber ||
    intlWireData?.beneficiaryAccountNumber ||
    '';
  const title = `${name}_Wire-Details-Checking-${accountNumber?.slice(-4)}`;

  const isLoading = isLoadingDomesticWire || isLoadingIntlWire;

  if (isLoading) {
    return <LoadingOverlay visible />;
  }

  return (
    <PDFWireInstructionsViewer
      document={
        <Document title={title}>
          <WireInstructionDoc
            theme={theme}
            error={domesticError}
            wireInstructions={domesticWireData}
          />
          {hasIntlWires && (
            <WireInstructionDoc
              theme={theme}
              error={intlError}
              wireInstructions={intlWireData}
            />
          )}
        </Document>
      }
    />
  );
};

export default WireInstructionsViewer;
