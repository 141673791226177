import { InvoiceAmountFilter } from './invoice-amount-filter';
import { InvoiceCreateAtFilter } from './invoice-created-at-filter';
import { InvoiceVendorFilter } from './invoice-vendor-filter';
import { BillpayInvoice } from 'types/bill-pay';

export const filterStatus = (invoices: BillpayInvoice[]) => [
  {
    key: 'date',
    header: 'Created on',
    component: <InvoiceCreateAtFilter />,
  },
  {
    key: 'amount',
    header: 'Amount',
    component: <InvoiceAmountFilter />,
  },
  {
    key: 'recipient',
    header: 'Recipient',
    component: <InvoiceVendorFilter invoices={invoices} />,
  },
];
