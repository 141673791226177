import { Box, Button, TextInput, useMantineTheme } from '@mantine/core';
import { PlusSignIcon, SearchIcon } from 'assets/svg';
import { useMediaQuery } from '@mantine/hooks';
import { isTruthyString } from '@utilities/validators/validate-string';
import { IoFunnelOutline } from 'react-icons/io5';
import useModal from '@common/modal/modal-hook';
import { useFilterModal } from '@common/filter/filter-modal';
import { useMemo } from 'react';
import {
  useSpendCardFilters,
  useSpendCardModalFilters,
} from './use-spend-cards-filter';
import { SpendCardholderFilter } from './filters/cardholder-filter';
import { SpendCardNameFilter } from './filters/spend-card-name-filter';
import { RepeatFundsFilter } from './filters/repeat-funds-filter';
import { AmountAssignedFilter } from './filters/amount-assiged-filter';
import { SpendPlanCardsResponse } from '@services/flexbase/spend-plans.model';
import IssueCardHeader from 'areas/cards/issue-card';
import {
  useGetAdminSpendPlan,
  useGetSpendPlan,
} from '@queries/use-spend-plans';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { IsAdmin } from 'recoil-state/application/product-onboarding';
import { usePlatformPersonContext } from 'providers/platform-person.context';
import { FilterChips } from '@common/filter/filter-chips';

type Props = {
  data: SpendPlanCardsResponse[];
};

const SpendCardsTableHeader = ({ data }: Props) => {
  const theme = useMantineTheme();
  const useMobileView = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
  const { openFullModal, closeAllModals } = useModal();
  const filterHook = useSpendCardFilters();
  const modalFilterHook = useSpendCardModalFilters();
  const {
    activeFiltersArray,
    removeAllFilters,
    getFilterByKey,
    addFilter,
    removeFilter,
    openResponsiveFilterModal,
  } = useFilterModal({ filterHook, modalFilterHook });

  const { id: spendPlanId = '' } = useParams();
  const { person } = usePlatformPersonContext();
  const accountId = person?.accountId || '';
  const isAdmin = useRecoilValue(IsAdmin);

  const { data: spendPlan } = useGetSpendPlan({
    accountId: accountId,
    spendPlanId: spendPlanId,
    isAdmin,
  });

  const { data: adminSpendPlan } = useGetAdminSpendPlan({
    accountId: accountId,
    spendPlanId: spendPlanId || '',
    isAdmin,
  });

  const plan = useMemo(() => {
    if (isAdmin) {
      return adminSpendPlan;
    } else {
      return spendPlan;
    }
  }, [adminSpendPlan, spendPlan]);

  const onIssueCardClick = () => {
    openFullModal(
      <IssueCardHeader
        closeModal={closeAllModals}
        spendPlan={plan}
        method={'spendPlan'}
      />,
    );
  };

  const filters = [
    {
      key: 'cardholder',
      header: 'Cardholder',
      component: <SpendCardholderFilter cards={data || []} />,
    },
    {
      key: 'repeatFunds',
      header: 'Repeat funds',
      component: <RepeatFundsFilter />,
    },
    {
      key: 'cardName',
      header: 'Card name',
      component: <SpendCardNameFilter cards={data || []} />,
    },
    {
      key: 'remainingPtd',
      header: 'Assigned (remaining)',
      component: <AmountAssignedFilter />,
    },
  ];

  const onSearchChange = (value: string) => {
    if (!value) {
      removeFilter('search');
      return;
    }

    addFilter('search', {
      key: 'search',
      filterValue: value,
      label: `Includes ${value}`,
      showChip: false,
      fn: (card) => {
        const normalizedFilterText = value.toLowerCase().trim();
        return Object.values(card)
          .filter(isTruthyString)
          .map((v) => v.toLowerCase())
          .some((v) => v.includes(normalizedFilterText));
      },
    });
  };

  const handleRemoveFilter = (filterKey: string) => {
    removeFilter(filterKey);
  };

  const handleClearFilters = () => {
    removeAllFilters();
  };

  const openModal = () => {
    openResponsiveFilterModal(filters);
  };

  return (
    <>
      <Box
        sx={() => ({
          display: 'flex',
          gap: theme.spacing.md,
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        })}
        mb="md"
      >
        <Box
          sx={() => ({
            display: 'flex',
            gap: theme.spacing.md,
          })}
        >
          <TextInput
            w={useMobileView ? '100%' : 368}
            placeholder="Search cards"
            icon={<SearchIcon width={20} height={20} />}
            value={getFilterByKey('search')?.filterValue || ''}
            onChange={(e) => onSearchChange(e.target.value)}
          />
          <Button
            variant="outline"
            rightIcon={<IoFunnelOutline />}
            onClick={() => openModal()}
            disabled={data.length === 0}
          >
            Filters
          </Button>
          {isAdmin && !!plan?.isPlanActive && (
            <Button
              variant="outline"
              leftIcon={<PlusSignIcon />}
              onClick={onIssueCardClick}
            >
              New credit card
            </Button>
          )}
        </Box>
      </Box>

      <FilterChips
        filterArray={activeFiltersArray}
        onRemoveFilter={handleRemoveFilter}
        onClearFilters={handleClearFilters}
      />
    </>
  );
};

export default SpendCardsTableHeader;
