import { ReactNode } from 'react';
import {
  Box,
  createStyles,
  Divider as MantineDivider,
  Flex,
  MantineNumberSize,
  Progress,
  rem,
  SpacingValue,
  SystemProp,
  Text,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import useModal from './modal-hook';
import { ArrowBackIconWhite } from '../../assets/svg';
import { IAddress } from 'types/user-info';
import { MantineStyleSystemProps } from '@mantine/styles/lib/theme/types/MantineStyleSystem';
import { FiX } from 'react-icons/fi';

type ChildrenProps = { children: ReactNode };

type HeaderProps = {
  title: ReactNode;
  rightTitle?: ReactNode;
  subtitle?: ReactNode;
  rightSubtitle?: ReactNode;
  titleSize?: string | number;
};

function Header({
  title,
  rightTitle,
  subtitle,
  rightSubtitle,
  titleSize,
}: HeaderProps) {
  const { classes } = useStyles();
  const isMobileView = useMediaQuery('(max-width: 767px)');
  const modal = useModal();
  return (
    <>
      {isMobileView && (
        <Box className={classes.backButtonBar}>
          <Box
            className={classes.iconContainer}
            onClick={() => modal.closeAllModals()}
          >
            <ArrowBackIconWhite height="16px" width="16px" />
          </Box>
        </Box>
      )}
      <Box
        className={classes.headerBar}
        sx={() => ({ height: subtitle || rightSubtitle ? 152 : 120 })}
      >
        <Box className={classes.spaceBetween}>
          <Text fz={titleSize ?? 20} lineClamp={1}>
            {title}
          </Text>
          <Text fz={20}>{rightTitle}</Text>
        </Box>
        <Box className={classes.spaceBetween}>
          <Text fz={14}>{subtitle}</Text>
          <Text fz={14}>{rightSubtitle}</Text>
        </Box>
      </Box>
    </>
  );
}

function SingleTitleHeader({
  title,
  subtitle,
}: {
  title: ReactNode;
  subtitle?: ReactNode;
}) {
  const modal = useModal();
  const { classes } = useStyles();

  return (
    <Flex className={classes.headerBar} h={133} align="center">
      <Flex align="center" justify="space-between" w="100%">
        <Box>
          <Text fz={40} lineClamp={1} lts="-0.88px" lh="1">
            {title}
          </Text>
          {subtitle && (
            <Text fz={14} lh="1" mt={4}>
              {subtitle}
            </Text>
          )}
        </Box>
        <FiX
          onClick={() => modal.closeAllModals()}
          className={classes.headerCloseButton}
          fontSize="2rem"
        />
      </Flex>
    </Flex>
  );
}

function Body({ children }: ChildrenProps) {
  const { classes } = useStyles();
  return <Box className={classes.contentContainer}>{children}</Box>;
}

function Card({ children }: ChildrenProps) {
  const { classes, cx } = useStyles();
  return (
    <Box
      mt="md"
      w="100%"
      className={cx(classes.spaceBetween, classes.flexCenterMobile)}
    >
      {children}
    </Box>
  );
}

type DividerProps = {
  mx?: SystemProp<SpacingValue>;
  my?: SystemProp<SpacingValue>;
};

function Divider({ mx, my }: DividerProps) {
  return <MantineDivider my={my ?? 'xl'} mx={mx ?? -32} />;
}

type AddressSectionProps = { address: IAddress; title: string };

function AddressSection({ address, title }: AddressSectionProps) {
  return (
    <Box fz={20}>
      <Text fz={14} mb="xs">
        {title}
      </Text>
      <Text>{address.line1}</Text>
      <Text>{address.line2}</Text>
      <Text>
        {address.city}, {address.state}{' '}
        {address.postalCode?.length > 5
          ? address.postalCode.substring(0, 5)
          : address.postalCode}
      </Text>
    </Box>
  );
}

type TitleValueLineProps = {
  title: ReactNode;
  value: ReactNode;
} & Partial<MantineStyleSystemProps>;

function TitleValueLine({ title, value, ...styleProps }: TitleValueLineProps) {
  return (
    <Flex justify="space-between" align="center" fz={14} {...styleProps}>
      <Text>{title}</Text>
      <Text>{value}</Text>
    </Flex>
  );
}

type ProgressBarProps = {
  progressSize?: MantineNumberSize;
  progressValue: number;
  animate: boolean;
  leftText: ReactNode;
  rightText: ReactNode;
  hideProgressBar?: boolean;
} & Partial<MantineStyleSystemProps>;

function ProgressBar({
  progressValue,
  progressSize,
  animate,
  leftText,
  rightText,
  hideProgressBar,
  ...styleProps
}: ProgressBarProps) {
  return (
    <>
      {!hideProgressBar && (
        <Progress
          value={progressValue}
          animate={animate}
          size={progressSize || 'sm'}
          color="primarySecondarySuccess.8"
          sx={(theme) => ({
            backgroundColor: theme.fn.themeColor('neutral', 0),
          })}
          my="md"
          h={6}
          {...styleProps}
        />
      )}
      <TitleValueLine fw={500} title={leftText} value={rightText} />
    </>
  );
}

export function RightContentModal({ children }: ChildrenProps) {
  const { classes } = useStyles();
  return <Box className={classes.container}>{children}</Box>;
}

RightContentModal.Header = Header;
RightContentModal.SingleTitleHeader = SingleTitleHeader;
RightContentModal.Body = Body;
RightContentModal.Card = Card;
RightContentModal.Divider = Divider;
RightContentModal.AddressSection = AddressSection;
RightContentModal.TitleValueLine = TitleValueLine;
RightContentModal.ProgressBar = ProgressBar;

const useStyles = createStyles((theme) => ({
  container: {
    backgroundColor: theme.fn.themeColor('neutral', 2),
    minHeight: '100vh',
    color: theme.fn.themeColor('primarySecondarySuccess', 8),
  },
  headerBar: {
    backgroundColor: theme.fn.themeColor('primarySecondarySuccess', 8),
    color: theme.fn.themeColor('neutral', 0),
    padding: '2rem',
    [theme.fn.smallerThan('sm')]: {
      paddingTop: theme.spacing.md,
    },
  },
  headerCloseButton: {
    fontSize: '2rem',
    cursor: 'pointer',
    ...theme.fn.hover({
      opacity: 0.8,
    }),
  },
  backButtonBar: {
    width: '100%',
    backgroundColor: theme.fn.themeColor('primarySecondarySuccess', 8),
    paddingTop: 8,
    paddingLeft: 16,
  },
  iconContainer: {
    width: 32,
    height: 32,
    display: 'flex',
    alignItems: 'center',
  },
  spaceBetween: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.spacing.md,
  },
  flexCenterMobile: {
    [theme.fn.smallerThan('sm')]: {
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  contentContainer: {
    padding: `${theme.spacing.xl} ${rem(32)}`,
    [theme.fn.smallerThan('sm')]: {
      overflowX: 'hidden',
      padding: theme.spacing.sm,
    },
  },
}));
