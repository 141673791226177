import { ContainerWithFooter } from '@common/footer/footer-container';
import {
  RedemptionFormState,
  RedemptionProvider,
} from './redemption-multi-step-form.context';
import { RouteStep } from '../../../providers/multi-step-form-provider';
import { useStyles } from './styles';
import { RedemptionFormContainer } from './redemption-form-container';
import { Navigate } from 'react-router-dom';
import { useEliteFeatureFlag } from '@utilities/feature-flags';

export const Redemption = () => {
  const { classes } = useStyles();

  const isRedemptionEnabled = useEliteFeatureFlag();

  if (!isRedemptionEnabled) {
    return <Navigate to="/" />;
  }

  const formSteps: RouteStep<RedemptionFormState>[] = [
    {
      route: 'amount',
      metadata: {
        title: 'Select the amount',
        id: 'amount',
        description: 'Select the amount you would like to redeem.',
      },
      // condition: (state) => !state.phoneVerified,
    },
    {
      route: 'account',
      metadata: {
        title: 'Select the account',
        id: 'account',
        description: 'Select the account you would like to redeem to.',
      },
      // condition: (state) => !state.phoneVerified,
    },
    {
      route: 'review',
      metadata: {
        title: 'Review',
        id: 'review',
        description: 'Review your redeem request.',
      },
      // condition: (state) => !state.phoneVerified,
    },
    {
      route: 'success',
      metadata: {
        title: 'Success',
        id: 'success',
        description: 'Success!',
      },
      // condition: (state) => !state.phoneVerified,
    },
  ];

  const initialRedemptionFormState: RedemptionFormState = {
    fullPointsRedemption: true,
    points: 0,
    cashBack: 0,
    redemptionAccountId: '',
    error: null,
    loading: false,
  };

  return (
    <ContainerWithFooter
      disclaimer2={true}
      className={classes.footerContainer}
      classNames={{
        container: classes.footerContainer,
        footerDiscloure: classes.footerDisclosure,
        scrollContainer: classes.scrollContainer,
      }}
    >
      <RedemptionProvider
        initialState={initialRedemptionFormState}
        steps={formSteps}
      >
        <RedemptionFormContainer />
      </RedemptionProvider>
    </ContainerWithFooter>
  );
};
