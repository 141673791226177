import { selector } from 'recoil';
import { IsAccountant, IsAdmin } from '../application/product-onboarding';

export const IsIntegrationsAuthorized = selector<boolean>({
  key: 'is_integrations_authorized_selector',
  get: ({ get }) => {
    const isAuthorized = get(IsAdmin) || get(IsAccountant);

    return isAuthorized;
  },
});
