import { useEffect, useState } from 'react';
import { MultiSelect, SelectItem } from '@mantine/core';

import { Group } from 'constants/card-info';
import { oldCategories, newCategories } from 'constants/mcc-restrictions';
import { detailsCardStyles } from './styles';

type ItemProps = {
  form: any;
  disabled?: boolean;
  filter?: (items: SelectItem[]) => SelectItem[];
};

const Multiselect = ({ form, disabled, filter }: ItemProps) => {
  const { classes } = detailsCardStyles();
  const [isUnrestricted, setIsUnrestricted] = useState(
    form.values.groups.length === 0,
  );

  const itemsSelected = `${
    form.values?.groups.includes('UNRESTRICTED')
      ? '0'
      : form.values.groups.length
  } categories selected`;

  useEffect(() => {
    if (isUnrestricted) {
      form.setFieldValue('groups', []);
    }
  }, [isUnrestricted]);

  const hasOldCategory = form.values.groups.some((group: string) => {
    return oldCategories.some((category) => category.value === group);
  });

  let data = hasOldCategory
    ? oldCategories.concat(newCategories)
    : newCategories;

  if (filter) {
    data = filter(data);
  }

  return (
    <div style={{ position: 'relative' }}>
      <MultiSelect
        label="Spend restrictions"
        placeholder={itemsSelected}
        data={data}
        value={form.values?.groups}
        onChange={(value) => {
          setIsUnrestricted(false);
          form.setFieldValue('groups', value as Group[]);
        }}
        className={classes.multiselect}
        styles={{ input: { padding: 3, paddingLeft: 12 } }}
        searchable
        nothingFound="Nothing found..."
        disabled={disabled}
      />
    </div>
  );
};

export default Multiselect;
