import { Box, Flex, Skeleton, Text } from '@mantine/core';
import {
  useGetAdminSpendPlan,
  useGetSpendPlan,
} from '../../../../queries/use-spend-plans';
import { usePlatformPersonContext } from '../../../../providers/platform-person.context';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { IsAdmin } from '../../../../recoil-state/application/product-onboarding';

type Props = {
  spendPlanId: string;
};

const DetailsHeader = ({ spendPlanId }: Props) => {
  const { person } = usePlatformPersonContext();
  const isAdmin = useRecoilValue(IsAdmin);
  const { data: spendPlan, isLoading: spendPlanLoading } = useGetSpendPlan({
    accountId: person.accountId,
    spendPlanId,
    isAdmin,
  });

  const { data: adminSpendPlan, isLoading: spendPlanAdminLoading } =
    useGetAdminSpendPlan({
      accountId: person.accountId,
      spendPlanId,
      isAdmin,
    });

  const plan = useMemo(() => {
    if (isAdmin) {
      return adminSpendPlan;
    } else {
      return spendPlan;
    }
  }, [adminSpendPlan, spendPlan]);

  const loading = spendPlanLoading || spendPlanAdminLoading;

  return (
    <Flex align="center" justify="space-between">
      <Box>
        {loading ? (
          <>
            <Skeleton height={5} radius="xl" />
            <Skeleton height={5} mt={6} radius="xl" />
          </>
        ) : (
          <>
            <Text ff="Redaction" fz="28px" c="primarySecondarySuccess.8">
              {plan?.name}
            </Text>
            {plan?.description && plan?.description !== 'string' && (
              <Text fz="14px" c="neutral.7">
                {plan?.description}
              </Text>
            )}
          </>
        )}
      </Box>
    </Flex>
  );
};

export default DetailsHeader;
