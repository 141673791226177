import { useReducer } from 'react';
import { Transactions } from '@flexbase-eng/types/dist/accounting';

enum ActionTypes {
  REMOVE,
  REMOVE_AND_RESET_ALL_STATUS,
  UPDATE_STATE,
  UPDATE_ACCOUNT,
  UPDATE_STATUS,
  RESET_STATUS_ALL,
}

type Implements<T, U extends T> = U;

// Transactions is kind of a confusing name, renaming and exposing the props for easier reading
export type SyncTransactionPayload = Implements<
  Transactions,
  {
    /**
     * The ID of the transaction to be synced.
     */
    transactionId: string;

    /**
     * The expense category ID to sync to the transaction.
     * A 'category' is also called 'account' depending on the expense integration.
     */
    accountId: string;
  }
>;

export type SyncToCategoryChange = {
  /**
   * Payload to be sent in a sync request.
   */
  data: SyncTransactionPayload;

  /**
   * Whether an individual tx is in syncing a change.
   */
  status?: 'loading';
};

type Action =
  | {
      type: ActionTypes.REMOVE;
      payload: { transactionId: string };
    }
  | {
      type: ActionTypes.REMOVE_AND_RESET_ALL_STATUS;
      payload: { transactionIds: string[] };
    }
  | {
      type: ActionTypes.UPDATE_STATE;
      payload: {
        transactionId: string;
        data: SyncTransactionPayload;
        status?: 'loading';
      };
    }
  | {
      type: ActionTypes.UPDATE_ACCOUNT;
      payload: { transactionId: string; accountId: string };
    }
  | {
      type: ActionTypes.UPDATE_STATUS;
      payload: { transactionIds: string[]; status: 'loading' | undefined };
    }
  | {
      type: ActionTypes.RESET_STATUS_ALL;
    };

type State = Partial<Record<string, SyncToCategoryChange>>;

const reducer = (state: State, action: Action) => {
  const next = { ...state };

  switch (action.type) {
    case ActionTypes.REMOVE:
      delete next[action.payload.transactionId];

      return next;
    case ActionTypes.REMOVE_AND_RESET_ALL_STATUS:
      action.payload.transactionIds.forEach((txId) => {
        delete next[txId];
      });

      Object.values(next).forEach((item) => {
        if (item?.status) {
          item.status = undefined;
        }
      });

      return next;
    case ActionTypes.UPDATE_STATE: {
      const existing = next[action.payload.transactionId];

      next[action.payload.transactionId] = {
        data: action.payload.data || existing?.data,
        status: action.payload.status || existing?.status,
      };

      return next;
    }
    case ActionTypes.UPDATE_ACCOUNT: {
      const fromAccountId =
        state[action.payload.transactionId]?.data?.accountId;
      const toAccountId = action.payload.accountId;

      if (fromAccountId === toAccountId) {
        return state;
      }

      next[action.payload.transactionId] = {
        data: {
          transactionId: action.payload.transactionId,
          accountId: action.payload.accountId,
        },
      };

      return next;
    }
    case ActionTypes.UPDATE_STATUS:
      action.payload.transactionIds.forEach((txId) => {
        const item = next[txId];

        if (item) {
          item.status = action.payload.status;
        }
      });

      return next;

    case ActionTypes.RESET_STATUS_ALL:
      Object.values(next).forEach((item) => {
        if (item) {
          item.status = undefined;
        }
      });

      return next;
    default:
      return state;
  }
};

/**
 * Hook to keep track of local changes when users update transaction expense categories.
 */
export const useExpenseCategorySyncState = () => {
  const [state, dispatch] = useReducer(reducer, {});

  return [
    state,
    {
      removeSyncState: (transactionId: string) => {
        dispatch({
          type: ActionTypes.REMOVE,
          payload: { transactionId },
        });
      },
      removeAndResetAllSyncStatus: (transactionIds: string[]) => {
        dispatch({
          type: ActionTypes.REMOVE_AND_RESET_ALL_STATUS,
          payload: { transactionIds },
        });
      },
      resetSyncStatusAll: () => {
        dispatch({
          type: ActionTypes.RESET_STATUS_ALL,
        });
      },
      updateSyncState: (
        transactionId: string,
        update: {
          data: SyncTransactionPayload;
          status?: 'loading' | undefined;
        },
      ) => {
        dispatch({
          type: ActionTypes.UPDATE_STATE,
          payload: {
            transactionId,
            data: update.data,
            status: update.status,
          },
        });
      },
      updateSyncCategory: (transactionId: string, categoryId: string) => {
        dispatch({
          type: ActionTypes.UPDATE_ACCOUNT,
          payload: {
            transactionId,
            accountId: categoryId,
          },
        });
      },
      updateSyncStatus: (
        transactionIds: string[],
        status: 'loading' | undefined,
      ) => {
        dispatch({
          type: ActionTypes.UPDATE_STATUS,
          payload: {
            transactionIds,
            status,
          },
        });
      },
    },
  ] as const;
};
