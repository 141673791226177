import { RedemptionStepper } from '../redemption-stepper';
import { RedemptionSuccessContent } from './content/redemption-success-content';

export const RedemptionSuccess = () => {
  return (
    <RedemptionStepper nextButtonText="Back To Membership">
      <RedemptionSuccessContent />
    </RedemptionStepper>
  );
};
