import WizardErrorBanner from 'areas/billpay/wizard-error-banner';
import { Text } from '@mantine/core';
import { AccountProps } from 'areas/banking/move-funds/move-funds.model';
import AccountSelection from 'areas/payments/components/account-selection';

export const BankingAccountSource = ({
  sendFrom,
  isActionDisabled,
  paymentAccounts,
  onAccountChange,
  notEnoughFundsError,
}: {
  sendFrom?: AccountProps;
  isActionDisabled: boolean;
  paymentAccounts: AccountProps[];
  onAccountChange: (accountId: string) => void;
  notEnoughFundsError?: string;
}) => {
  return (
    <>
      <Text size="sm">Send from</Text>
      {sendFrom && (
        <AccountSelection
          disabled={isActionDisabled}
          currentAccount={sendFrom}
          accounts={paymentAccounts}
          onAccountChange={(account) => onAccountChange(account.id)}
        />
      )}
      {notEnoughFundsError && (
        <WizardErrorBanner message={notEnoughFundsError} />
      )}
    </>
  );
};
