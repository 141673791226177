import { Select, TextInput, createStyles } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useMatchMedia } from '@utilities/url/window-helpers';
import { validateBirthDateInSpecifiedFormat } from '@utilities/validators/validate-dates';
import State from 'country-state-city/lib/state';
import { forwardRef } from 'react';
import FlexPatternFormat from '../../common/flex-pattern-format';
import { useSubmitByRef } from './hooks/use-submit-by-ref';
import { formatCamelCasedValue, formatLabel, isRestrictedCity } from './util';
import {
  BeneficiaryRequirementsFormRef,
  FieldRecord,
  InternationalRecipient,
} from './util/types';

const createInitialValues = (
  fields: FieldRecord,
  recipient: InternationalRecipient,
  isIndividual: boolean,
) => {
  const initialValues: FieldRecord = isIndividual
    ? { dob: recipient.dob || '' }
    : {};

  for (const field in fields) {
    initialValues[field] =
      recipient[field as keyof InternationalRecipient] || '';
  }

  return initialValues;
};

const getStateOrProvinceOptionsByCountryCode = (countryCode: string) => {
  return State.getStatesOfCountry(countryCode).map((state) => ({
    value: state.isoCode,
    label: state.name,
  }));
};

type Props = {
  fields: FieldRecord;
  newRecipient: InternationalRecipient;
  isIndividualRecipient: boolean;
  recipientCountryCode: string;
};

type FieldProperties = Record<
  string,
  {
    isFullWidth: boolean;
    component: React.ComponentType<any>;
    placeholder?: string;
    data?: any;
    searchable?: boolean;
    label?: string;
  }
>;

const BeneficiaryRequirementsForm = forwardRef<
  BeneficiaryRequirementsFormRef,
  Props
>(
  (
    { fields, newRecipient, isIndividualRecipient, recipientCountryCode },
    ref,
  ) => {
    const { classes, theme } = useStyles();
    const isMobileView = useMatchMedia(`(max-width: ${theme.breakpoints.md})`);
    const form = useForm({
      initialValues: createInitialValues(
        fields,
        newRecipient,
        isIndividualRecipient,
      ),
      validate: (values) => {
        const errors: Record<string, string> = {};
        for (const field in fields) {
          const associatedRegex = fields[field];
          if (!values[field] || !values[field].match(associatedRegex)) {
            errors[field] = `Please provide a valid ${formatCamelCasedValue(
              field,
            )} value`;
          }
          if (field === 'city' && isRestrictedCity(values[field])) {
            errors[field] =
              'This city is not supported for international wires';
          }
        }

        if (
          isIndividualRecipient &&
          !validateBirthDateInSpecifiedFormat('yyyy-MM-dd', values.dob)
        ) {
          errors.dob = 'Please provide a valid date of birth';
        }

        return errors;
      },
    });

    useSubmitByRef(form, ref);

    const fieldKeysSet = new Set(Object.keys(fields));

    const fieldProperties: FieldProperties = {
      companyName: { isFullWidth: true, component: TextInput },
      address: { isFullWidth: true, component: TextInput },
      city: { isFullWidth: true, component: TextInput },
      stateOrProvince: {
        isFullWidth: true,
        component: Select,
        placeholder: `Enter a ${formatCamelCasedValue(
          'stateOrProvince',
        )} value`,
        data: getStateOrProvinceOptionsByCountryCode(recipientCountryCode),
        searchable: true,
      },
      postcode: { isFullWidth: true, component: TextInput },
      firstName: {
        isFullWidth: false,
        component: TextInput,
        label: 'Recipient first name',
      },
      lastName: {
        isFullWidth: false,
        component: TextInput,
        label: 'Recipient last name',
      },
      accountNumber: { isFullWidth: true, component: TextInput },
      bicSwift: { isFullWidth: true, component: TextInput },
      iban: { isFullWidth: true, component: TextInput },
    };

    const filteredFieldProperties: FieldProperties = Object.fromEntries(
      Object.entries(fieldProperties).filter(([key]) => fieldKeysSet.has(key)),
    );

    // Add additional fields that may be required by the beneficiary
    for (const field in fields) {
      if (!fieldProperties[field]) {
        fieldProperties[field] = {
          isFullWidth: true,
          component: TextInput,
          placeholder: `Enter ${formatCamelCasedValue(field)}`,
        };
      }
      filteredFieldProperties[field] = fieldProperties[field];
    }

    return (
      <form>
        {isIndividualRecipient && (
          <FlexPatternFormat
            mt={'md'}
            label={'Recipient date of birth'}
            placeholder="Enter date of birth"
            description="Format: YYYY-MM-DD"
            c={'neutral.8'}
            format="####-##-##"
            mask="_"
            allowEmptyFormatting
            {...form.getInputProps('dob')}
          />
        )}

        <div
          className={classes.containerInputs}
          style={{ marginTop: isIndividualRecipient ? 0 : '-1rem' }}
        >
          {Object.entries(filteredFieldProperties).map(
            ([
              fieldName,
              { isFullWidth, component: Component, label, ...props },
            ]) => {
              const regex = fields[fieldName];

              return (
                <Component
                  key={fieldName}
                  mt={'md'}
                  label={label ? label : formatLabel(fieldName)}
                  placeholder={`Enter ${formatCamelCasedValue(fieldName)}`}
                  c={'neutral.8'}
                  {...form.getInputProps(fieldName)}
                  {...props}
                  pattern={regex}
                  className={classes.component}
                  style={{
                    width: isFullWidth || isMobileView ? '100%' : 'auto',
                  }}
                />
              );
            },
          )}
        </div>
      </form>
    );
  },
);

BeneficiaryRequirementsForm.displayName = 'BeneficiaryRequirementsForm';

export default BeneficiaryRequirementsForm;

const useStyles = createStyles(() => ({
  containerInputs: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
  },

  component: {
    marginLeft: '0.28rem',
    marginRight: '0',
  },
}));
