import { Box, createStyles, Text } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import HelpActionButtons from './help-action-buttons';
import UserActionButtons from './user-action-buttons';
import BankingActionButton from './banking-action-button';
import { ReactNode } from 'react';
import { useRecoilValue } from 'recoil';
import {
  ApplicationState,
  IsAdmin,
} from 'recoil-state/application/product-onboarding';

type Props = {
  title?: string | JSX.Element;
  center?: boolean;
  primaryAction?: ReactNode;
};

const useStyles = createStyles((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '24px 0',
  },
  headerTitle: {
    fontFamily: 'Redaction',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '28px',
    color: theme.fn.themeColor('primarySecondarySuccess', 8),
  },
  headerComponent: {
    flexGrow: 1,
    marginRight: '1rem',
  },
  centeredHeaderTitle: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '28px',
    color: '#5F5F5F',
    marginLeft: 'auto',
  },
  actionButtons: {
    gap: '24px',
    display: 'flex',
    alignItems: 'center',
  },
}));

const FlexbaseDefaultHeader = ({ title, center, primaryAction }: Props) => {
  const { classes } = useStyles();
  const isAdmin = useRecoilValue(IsAdmin);
  const useMobileView = useMediaQuery('(max-width: 767px)');
  const { productStatus } = useRecoilValue(ApplicationState);

  const isApprovedBankingComptroller =
    productStatus.banking.appStatus === 'approved' && isAdmin;

  return (
    <Box className={classes.header}>
      {typeof title === 'string' ? (
        <Text
          className={center ? classes.centeredHeaderTitle : classes.headerTitle}
          data-testid="text-default-header"
        >
          {title}
        </Text>
      ) : (
        <Box className={classes.headerComponent}>{title}</Box>
      )}

      <Box className={classes.actionButtons}>
        {primaryAction ||
          (isApprovedBankingComptroller && <BankingActionButton />) ||
          null}
        {!useMobileView && (
          <>
            <HelpActionButtons />
            <UserActionButtons />
          </>
        )}
      </Box>
    </Box>
  );
};

export default FlexbaseDefaultHeader;
