import { useStyles } from '../summary.styles';
import { useOnboardingStyles } from '../../../onboarding.styles';
import { useRecoilState } from 'recoil';
import { ApplicationState } from '../../../../../recoil-state/application/product-onboarding';
import { useForm } from '@mantine/form';
import { flexbaseOnboardingClient } from '../../../../../services/flexbase-client';
import { SummaryEditBox, SummaryEditBoxClassnames } from '../summary-edit-box';
import { useEffect, useState } from 'react';
import { BUSINESS_MERCHANT_CODES } from '../../../../../constants/constants';
import { validateRequired } from '../../../../../utilities/validators/validate-required';
import { filterAutocompleteData } from '../../../../../utilities/filter/filter-data';
import { Autocomplete } from '@mantine/core';

type Props = { classNames?: SummaryEditBoxClassnames };

const SummaryCategoryForm = ({ classNames }: Props) => {
  const { classes } = useStyles();
  const { classes: onboardingClasses } = useOnboardingStyles();
  const [{ company }, setStatus] = useRecoilState(ApplicationState);
  const form = useForm({
    initialValues: {
      category: company.category,
    },
    validate: {
      category: (val) =>
        validateRequired(val) ? null : 'Category is required',
    },
  });

  const onSave = async () => {
    const validationResult = form.validate();
    if (validationResult.hasErrors) {
      throw 'Please correct all errors before saving';
    }
    try {
      if (form.values.category.includes('-')) {
        form.values.category = form.values.category.split('-')?.[0]?.trim();
      }
      await flexbaseOnboardingClient.updateCompany({
        id: company.id,
        category: form.values.category,
      });
      setStatus((prev) => ({
        ...prev,
        company: {
          ...prev.company,
          category: form.values.category,
        },
      }));
    } catch (e) {
      throw 'An error occurred while trying to update company website';
    }
  };

  const [mcc, setMcc] = useState<{ value: string; label: string }[]>([]);
  const [searchString, setSearchString] = useState('');
  const mccAutocompleteData = filterAutocompleteData(
    searchString,
    mcc.length > 0 ? mcc : BUSINESS_MERCHANT_CODES,
  );

  const categoryLabel = (value: string) => {
    const category = mcc.find((code) => code.value === value);
    return category ? `${category?.value} - ${category?.label}` : value;
  };

  const getCodes = async () => {
    const code = await flexbaseOnboardingClient.getMccCodes();
    setMcc(code.map((c) => ({ value: c.code, label: c.description })));
  };

  useEffect(() => {
    getCodes();
  }, []);

  return (
    <SummaryEditBox
      label="Category"
      value={categoryLabel(company.category)}
      classNames={classNames}
      savePromise={onSave}
      editInputs={
        <div className={classes.inputContainer}>
          <Autocomplete
            classNames={{ label: onboardingClasses.inputLabel }}
            className={classes.input}
            {...form.getInputProps('category')}
            onChange={(e) => {
              setSearchString(e);
              form.setFieldValue('category', e);
            }}
            onItemSubmit={(i) => {
              form.setFieldValue('category', i.value);
            }}
            data={mccAutocompleteData}
            label="Category"
            placeholder="Search for your MCC by code or description"
            id="business-industry"
            nothingFound="No results"
          />
        </div>
      }
    />
  );
};

export default SummaryCategoryForm;
