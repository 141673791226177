import { useMantineTheme } from '@mantine/core';
import { BillPayStatus } from 'types/bill-pay';

export const getBillPayStatusDisplay = (status: BillPayStatus) => {
  const theme = useMantineTheme();
  let backgroundColor: string, textColor: string, displayStatus: string;

  switch (status) {
    case 'drafted':
      displayStatus = 'Draft';
      backgroundColor = theme.fn.themeColor('flexbaseGrey', 0);
      textColor = theme.fn.themeColor('neutral', 7);
      break;

    case 'requested':
      displayStatus = 'Needs approval';
      backgroundColor = theme.fn.themeColor('tertiary', 0);
      textColor = theme.fn.themeColor('tertiary', 5);
      break;

    case 'pending':
      displayStatus = 'Pending';
      backgroundColor = theme.fn.themeColor('tertiary', 0);
      textColor = theme.fn.themeColor('tertiary', 5);
      break;

    case 'scheduled':
      displayStatus = 'Scheduled';
      backgroundColor = theme.fn.themeColor('neutral', 1);
      textColor = theme.fn.themeColor('neutral', 7);
      break;

    case 'succeeded':
      displayStatus = 'Paid';
      backgroundColor = theme.fn.themeColor('positive', 0);
      textColor = theme.fn.themeColor('primarySecondarySuccess', 7);
      break;

    case 'failed':
      displayStatus = 'Failed';
      backgroundColor = theme.fn.themeColor('critical', 0);
      textColor = theme.fn.themeColor('critical', 6);
      break;

    default:
      displayStatus = 'Unknown';
      backgroundColor = theme.fn.themeColor('gray', 0);
      textColor = theme.fn.themeColor('gray', 7);
  }

  return { displayStatus, backgroundColor, textColor };
};
