import { Text } from '@mantine/core';
import type { Invoice } from 'flexbase-client';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import flexbaseClient from 'services/flexbase-client';
import { getCurrentWeek, getMinMaxDates } from 'utilities/dates/dates';
import { formatCurrency } from '@utilities/formatters';
import { useStyles } from './last-transaction.styles';
import { UserInfoState } from 'types/user-info';
import { useRecoilValue } from 'recoil';

const LastTransaction = () => {
  const { id } = useRecoilValue(UserInfoState);
  const [lastTransaction, setLastTransaction] = useState({
    storeName: '',
    purchaseDate: '',
    total: '',
  });
  const { classes } = useStyles();

  const getLastTransaction = async () => {
    try {
      const dates = getCurrentWeek();
      const result = await flexbaseClient.getInvoicesByUser(id, {
        after: dates.sunday,
        before: dates.saturday,
        includeMerchantName: true,
      });
      if (result && result.length > 0) {
        const resultDates = result.map((invoice) => invoice.purchaseDate);
        const maxDate = getMinMaxDates(resultDates, 'MMM dd, yyyy').maxDate;

        const quantityInv = 0;

        result
          .map((item: Invoice) =>
            Number(item?.total) > 0 ? quantityInv + 1 : 0,
          )
          .reduce((prev, curr) => prev + curr, 0);

        const lastInvoice = result.find(
          (invoice) =>
            DateTime.fromSQL(invoice.purchaseDate).toFormat('MMM dd, yyyy') ===
            maxDate,
        );
        if (lastInvoice && resultDates.length > 0) {
          setLastTransaction({
            storeName: lastInvoice.storeName,
            purchaseDate: maxDate,
            total: lastInvoice.total,
          });
        }
      }
    } catch (error) {
      console.error('Unable to get invoices', error);
    }
  };

  useEffect(() => {
    getLastTransaction();
  }, []);

  return (
    <div className={classes.LastTransactionContainer}>
      <div className={classes.totalTransactionLabel}>
        <Text className={classes.TransactionLabel}>Last transaction</Text>
        <div className={classes.totalTransaction}>
          {formatCurrency(
            Math.abs(lastTransaction.total as unknown as number),
          ) || '$---.--'}
        </div>
        <div style={{ marginTop: '4.3rem' }}>
          <Text className={classes.storeText}>
            {lastTransaction.storeName && lastTransaction.purchaseDate ? (
              <>
                {lastTransaction.storeName} on {lastTransaction.purchaseDate}
              </>
            ) : (
              'Your last transaction will show up here!'
            )}
          </Text>
        </div>
      </div>
    </div>
  );
};

export default LastTransaction;
