import { createWizard } from '@common/wizard/create-wizard';
import { WizardStepConfig } from '@common/wizard/wizard.types';

export type WizardState = {
  someStateValue: string;
  counter: number;
};

type WizardStepMeta = {
  someHint: React.ReactNode;
};

export type WizardStep = WizardStepConfig<WizardState, WizardStepMeta>;

export const [CreateWizard, useCreateWizard] = createWizard<
  WizardState,
  WizardStepMeta
>();
