import { DateTime } from 'luxon';
import { useParams } from 'react-router-dom';
import { Center, Loader } from '@mantine/core';
import PDFPaymentReceipt from '@common/pdf-payment-receipt/pdf-payment-receipt';
import { usePaymentReceipt } from '@utilities/custom-hooks/use-payment-receipt';

const ChargePaymentReceipt = () => {
    const { paymentId } = useParams();
    const {
      data: payment,
      paymentAccount,
      isLoading,
      isError,
    } = usePaymentReceipt(paymentId ?? '');

    const documentName = `Flex Charge Payment Receipt ${DateTime.fromSQL(
      payment?.createdAt || '',
    ).toFormat('MM-yy')}`;
  
    if (isLoading) {
      return (
        <Center h="100vh">
          <Loader />
        </Center>
      );
    }
  
    return (
      <PDFPaymentReceipt
        isError={isError}
        documentName={documentName}
        creditText="Flex Charge Card"
        createdAt={payment?.createdAt}
        paymentAccount={paymentAccount}
        payAmount={payment?.payAmount ?? 0}
      />
    );
}

export default ChargePaymentReceipt
