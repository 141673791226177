import { useRecoilValue } from 'recoil';
import {
  IsAdmin,
  IsComptroller,
} from 'recoil-state/application/product-onboarding';

export const useIsLimitedAdmin = () => {
  const isAdmin = useRecoilValue(IsAdmin);
  const isComptroller = useRecoilValue(IsComptroller);
  return isAdmin && !isComptroller;
};
