import { TrashIconNew } from '../../../../assets/svg';
import { useOnboardingStyles } from '../../onboarding.styles';
import { CompanyOwner } from '../../../../types/onboarding-info';
import { Box } from '@mantine/core';

type Props = {
  onDeleteClick: () => void;
  owner: CompanyOwner;
};

const AdditionalOwnerCard = ({ onDeleteClick, owner }: Props) => {
  const { classes, cx } = useOnboardingStyles();
  return (
    <div className={cx(classes.cardBeneficialOwners, classes.bottomSpacing)}>
      <div className={classes.cardTextContent}>
        <Box c="neutral.5" fz={14} fw={500}>
          Name and Ownership %
        </Box>
        <div className={classes.containerListOwner}>
          <div className={cx(classes.nameOwnerText, classes.unsetMargin)}>
            {owner.firstName} {owner.lastName} | {owner.ownershipPct}%
          </div>

          <TrashIconNew
            className={cx(classes.actionButton, classes.autoMarginLeft)}
            onClick={() => onDeleteClick()}
          />
        </div>
      </div>
    </div>
  );
};

export default AdditionalOwnerCard;
