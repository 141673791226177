import {
  Button,
  Divider,
  Flex,
  Stack,
  Text,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import FlexNumberInput from 'areas/payments/components/common/flex-number-input';
import { useEffect, useMemo, useRef, useState } from 'react';
import { IoMdAdd } from 'react-icons/io';
import {
  InvoiceDetails,
  LineItemInput,
  useInvoiceWizard,
} from '../../invoice-wizard';
import { IoCalendarClearOutline, IoTrashOutline } from 'react-icons/io5';
import { useForm } from '@mantine/form';
import { useParams } from 'react-router-dom';
import { DateInput } from '@mantine/dates';
import WizardErrorBanner from 'areas/billpay/wizard-error-banner';
import {
  useCreateBillpayInvoice,
  useUpdateBillpayInvoice,
} from '@queries/use-bill-pay';
import LineItemsTotal from './line-items-total';
import {
  currencyToCents,
  formatCents,
  formatCurrency,
} from '@utilities/formatters/format-currency';
import {
  BillpayLineItemAccountingInfo,
  CreateBillpayInvoiceLineItemRequest,
} from 'types/bill-pay';
import { ApplicationState } from 'recoil-state/application/product-onboarding';
import { useRecoilValue } from 'recoil';
import { useGetPlatformBusinessAccountsPayable } from '@queries/use-platform-business';
import { isEmpty } from 'underscore';
import LineItemAccountingInfo from './line-item-accounting-info';

/**
 * The api deactivates all existing lineItems, so we want to make sure it treats
 * these as new lineItems so we omit the ID
 */
const mapLineItemInputsToApiFormat = (
  lineItems: LineItemInput[],
  removeId?: boolean,
) =>
  lineItems.map(
    (item) =>
      ({
        ...item,
        id: removeId ? undefined : item.id,
        total: currencyToCents(item.total || 0),
      }) as CreateBillpayInvoiceLineItemRequest,
  );

const invoiceCreationError =
  'There was an error saving the invoice, please try again.';

const AddLineItems = () => {
  const theme = useMantineTheme();
  const [showError, setShowError] = useState(false);
  const [isAccountingApplyAllChecked, setIsAccountingApplyAllChecked] =
    useState(false);
  const { state, setState, onEvent, onNext, goToNextStep, onBack } =
    useInvoiceWizard();
  const { businessId } = useRecoilValue(ApplicationState);
  const { data: accountsPayableData } =
    useGetPlatformBusinessAccountsPayable(businessId);
  const { isInvoiceDraft, isActionDisabled } = state;
  const { id: existingInvoiceId } = useParams();
  const { mutate: createBillpayInvoice, error: createBillpayInvoiceError } =
    useCreateBillpayInvoice();
  const { mutate: updateBillpayInvoice, error: updateBillpayInvoiceError } =
    useUpdateBillpayInvoice();
  const hasAccountsPayable = !isEmpty(accountsPayableData);

  const topOfPageRef = useRef<HTMLDivElement>(null);

  const form = useForm<InvoiceDetails>({
    validateInputOnBlur: true,
    initialValues: {
      ...state.invoiceDetails,
      lineItems: state.invoiceDetails?.lineItems?.map((item) => ({
        ...item,
        total: item.total ? formatCents(item.total) : undefined,
      })) as LineItemInput[], // map cents from api to dollar amount for input
    },
    validate: {
      lineItems: {
        description: (value) => (value ? null : 'Description is required'),
        total: (value) => (value ? null : 'Amount is required'),
        quantity: (value) => (value ? null : 'Quantity is required'),
      },
      dueDate: (value) => (value ? null : 'Due date is required'),
    },
  });

  const activeLineItems = useMemo(() => {
    return form.values.lineItems?.filter((item) => item.status !== 'inactive');
  }, [form.values.lineItems]);

  const invoiceTotal = useMemo(() => {
    // sum up line items
    const lineItemsTotal = activeLineItems?.reduce(
      (acc, item) =>
        acc + (Number(item.total) || 0) * (Number(item.quantity) || 0),
      0,
    );
    // add credits and tax
    const total =
      (lineItemsTotal ?? 0) +
      (Number(form.values.credits) || 0) * -1 +
      (Number(form.values.tax) || 0);

    if (
      Math.round(total * 100) >= 5000 &&
      state.recipientAccount?.type === 'wire'
    ) {
      setShowError(false);
    }

    return {
      formatted: formatCurrency(total),
      cents: Math.round(total * 100),
    };
  }, [activeLineItems, form.values.tax, form.values.credits]);

  const createLineItem = () => {
    form.insertListItem('lineItems', {
      description: undefined,
      quantity: undefined,
      total: undefined,
      timestamp: Date.now(),
      status: 'active',
      accountingInfo: isAccountingApplyAllChecked
        ? form.values.lineItems[0].accountingInfo
        : {},
    });
  };

  onEvent('onSaveEdits', async () => {
    form.validate();
    if (!form.isDirty()) {
      return (
        form.isValid() && {
          invoiceDetails: {
            ...form.values,
            lineItems: mapLineItemInputsToApiFormat(activeLineItems),
          },
          invoiceTotal,
        }
      );
    }
    if (form.isValid()) {
      setState({
        invoiceTotal,
        invoiceDetails: {
          ...form.values,
          lineItems: mapLineItemInputsToApiFormat(activeLineItems),
        },
      });
      return {
        invoiceTotal,
        invoiceDetails: {
          ...form.values,
          lineItems: mapLineItemInputsToApiFormat(activeLineItems),
        },
      };
    }
    return false;
  });

  // save the form values on back
  onBack(() => {
    setState({
      invoiceTotal,
      invoiceDetails: {
        ...form.values,
        lineItems: mapLineItemInputsToApiFormat(activeLineItems),
      },
    });
  });

  onNext(async () => {
    form.validate();
    const invoiceTotalError =
      state.recipientAccount?.type === 'wire' &&
      form.values.lineItems.length > 0 &&
      invoiceTotal.cents < 5000;
    if (!form.isDirty()) {
      setState({
        invoiceTotal,
        invoiceDetails: {
          ...form.values,
          lineItems: mapLineItemInputsToApiFormat(activeLineItems),
        },
      });
      if (invoiceTotalError) {
        setShowError(true);
        topOfPageRef.current?.scrollIntoView({
          behavior: 'smooth',
        });
        return;
      }
      goToNextStep();
      return;
    }
    if (form.isValid() && form.values.dueDate && state.existingDocumentId) {
      if (invoiceTotalError) {
        setShowError(true);
        topOfPageRef.current?.scrollIntoView({
          behavior: 'smooth',
        });
        return;
      }

      if (existingInvoiceId) {
        try {
          updateBillpayInvoice(
            {
              id: existingInvoiceId!,
              recipientId: state.recipient?.id,
              dueDate: form.values?.dueDate,
              documentId: state.existingDocumentId,
              total: invoiceTotal?.cents,
              tax: currencyToCents(form.values?.tax || 0),
              credits: currencyToCents(form.values?.credits || 0),
              lineItems: mapLineItemInputsToApiFormat(activeLineItems, true),
            },
            {
              onSuccess: () => {
                setState({
                  invoiceTotal,
                  invoiceDetails: {
                    ...form.values,
                    lineItems: mapLineItemInputsToApiFormat(activeLineItems),
                  },
                });
                goToNextStep();
              },
              onError: () => {
                topOfPageRef.current?.scrollIntoView({
                  behavior: 'smooth',
                });
              },
            },
          );
        } catch (err) {
          topOfPageRef.current?.scrollIntoView({
            behavior: 'smooth',
          });
        }
      } else {
        createBillpayInvoice(
          {
            recipientId: state.recipient?.id,
            dueDate: form.values.dueDate,
            total: invoiceTotal.cents,
            tax: currencyToCents(form.values?.tax || 0),
            credits: currencyToCents(form.values?.credits || 0),
            documentId: state.existingDocumentId,
            lineItems: mapLineItemInputsToApiFormat(activeLineItems, true),
          },
          {
            onError: () => {
              topOfPageRef.current?.scrollIntoView({
                behavior: 'smooth',
              });
            },
            onSuccess: (invoiceResponse) => {
              setState({
                invoiceTotal,
                existingInvoiceId: invoiceResponse.invoice.id,
                invoiceDetails: {
                  ...form.values,
                  lineItems: mapLineItemInputsToApiFormat(activeLineItems),
                },
              });
              goToNextStep();
            },
          },
        );
      }
    }
  });

  useEffect(() => {
    setState({ isNextEnabled: true });
  }, []);

  const isDisabled = !isInvoiceDraft || isActionDisabled;

  const handleDeleteLineItem = (index: number) => {
    form.setFieldValue(`lineItems.${index}.status`, 'inactive');
  };

  const handleApplyAllChange = (isChecked: boolean) => {
    setIsAccountingApplyAllChecked(isChecked);
    if (isChecked) {
      const firstItemValues = form.values.lineItems[0].accountingInfo;
      form.values.lineItems.forEach((item, index) => {
        if (item.status !== 'inactive') {
          form.setFieldValue(
            `lineItems.${index}.accountingInfo`,
            firstItemValues,
          );
        }
      });
    }
  };

  const handleValueChange =
    (index: number) =>
    (field: keyof BillpayLineItemAccountingInfo, value: string | null) => {
      form.setFieldValue(
        `lineItems.${index}.accountingInfo.${field}`,
        value || undefined,
      );
      if (index === 0) {
        // Update other line items to new value if apply all is checked
        if (isAccountingApplyAllChecked) {
          form.values.lineItems.forEach((item, idx) => {
            if (item.status !== 'inactive') {
              form.setFieldValue(
                `lineItems.${idx}.accountingInfo.${field}`,
                value || undefined,
              );
            }
          });
        }
      }
    };

  return (
    <Stack ref={topOfPageRef}>
      {(createBillpayInvoiceError || updateBillpayInvoiceError) && (
        <WizardErrorBanner message={invoiceCreationError} />
      )}
      {form.values.lineItems?.map((item, index) =>
        item?.status === 'inactive' ? null : (
          <Stack key={`${index}-${item.timestamp}-${item.id}`}>
            <Flex direction="row" gap="md">
              <Flex sx={{ flexBasis: '50%' }}>
                <TextInput
                  disabled={isDisabled}
                  label="Description"
                  placeholder="Description"
                  w="100%"
                  {...form.getInputProps(`lineItems.${index}.description`)}
                />
              </Flex>
              <Flex sx={{ flexBasis: '15%' }}>
                <FlexNumberInput
                  disabled={isDisabled}
                  icon=" "
                  iconWidth={10}
                  label="Qty"
                  thousandSeparator
                  decimalScale={0}
                  allowNegative={false}
                  placeholder="0"
                  variant="unstyled"
                  {...form.getInputProps(`lineItems.${index}.quantity`)}
                  onChange={() => null}
                  onValueChange={(value) => {
                    form.setFieldValue(
                      `lineItems.${index}.quantity`,
                      value?.floatValue,
                    );
                  }}
                />
              </Flex>
              <Flex sx={{ flexBasis: '35%' }}>
                <FlexNumberInput
                  disabled={isDisabled}
                  label="Amount"
                  placeholder="0.00"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  allowNegative={false}
                  icon="$"
                  iconWidth={24}
                  variant="unstyled"
                  {...form.getInputProps(`lineItems.${index}.total`)}
                  onChange={() => null}
                  onValueChange={(value) => {
                    form.setFieldValue(
                      `lineItems.${index}.total`,
                      value?.floatValue,
                    );
                  }}
                />
              </Flex>
            </Flex>
            {hasAccountsPayable && (
              <LineItemAccountingInfo
                index={index}
                value={item.accountingInfo}
                onValueChange={handleValueChange(index)}
                onApplyAllChange={handleApplyAllChange}
                isApplyAllChecked={isAccountingApplyAllChecked}
              />
            )}
            {activeLineItems.length > 1 && (
              <Flex justify="flex-end">
                <Flex
                  onClick={() => handleDeleteLineItem(index)}
                  sx={{
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: theme.fn.themeColor('red.1', 0),
                    },
                  }}
                  gap="xxs"
                  align="center"
                  py="xxs"
                  px="xs"
                >
                  <Text size={12} c="red.4">
                    Remove
                  </Text>
                  <IoTrashOutline
                    size={14}
                    color={theme.fn.themeColor('red', 4)}
                  />
                </Flex>
              </Flex>
            )}
            <Divider />
          </Stack>
        ),
      )}
      <Flex>
        <Button
          disabled={isDisabled}
          variant="subtle"
          leftIcon={
            <IoMdAdd
              size={20}
              color={theme.fn.themeColor('primarySecondarySuccess', 4)}
            />
          }
          onClick={createLineItem}
        >
          <Text c="primarySecondarySuccess.4" size={14}>
            Add Another Line Item
          </Text>
        </Button>
      </Flex>

      <Flex direction="row" gap="md">
        <FlexNumberInput
          disabled={isDisabled}
          label="Credits"
          thousandSeparator
          decimalScale={2}
          fixedDecimalScale
          allowNegative={false}
          icon="$"
          iconWidth={24}
          placeholder="0.00"
          variant="unstyled"
          w="100%"
          {...form.getInputProps('credits')}
          onChange={() => null}
          onValueChange={(value) => {
            form.setFieldValue('credits', value?.floatValue);
          }}
        />
        <FlexNumberInput
          disabled={isDisabled}
          label="Tax"
          thousandSeparator
          decimalScale={2}
          fixedDecimalScale
          allowNegative={false}
          icon="$"
          iconWidth={24}
          placeholder="0.00"
          variant="unstyled"
          w="100%"
          {...form.getInputProps('tax')}
          onChange={() => null}
          onValueChange={(value) => {
            form.setFieldValue('tax', value?.floatValue);
          }}
        />
      </Flex>
      <Flex direction="row" gap="md" mb={20}>
        <DateInput
          disabled={isDisabled}
          label="Due Date"
          w="100%"
          icon={<IoCalendarClearOutline size={18} />}
          placeholder="Select due date"
          {...form.getInputProps('dueDate')}
          value={form.values.dueDate ? new Date(form.values.dueDate) : null}
          onChange={(date) =>
            form.setFieldValue('dueDate', date?.toISOString())
          }
        />
      </Flex>
      <LineItemsTotal
        title="Invoice total"
        description="To send a wire to this recipient, this payment must meet the
              minimum wire amount of $50.00."
        invoiceTotal={invoiceTotal.formatted}
        isError={showError}
      />
    </Stack>
  );
};

export default AddLineItems;
