import { Flex, Text } from '@mantine/core';
import { EmptyBillTableIcon } from 'assets/svg';

const TableEmptyState = ({ message }: { message: string }) => (
  <Flex p="xl" h="60vh" bg="neutral.0" align="center" direction="column">
    <EmptyBillTableIcon />
    <Text c="primarySecondarySuccess.8" fw={500} mt="xl">
      {message}
    </Text>
  </Flex>
);

export default TableEmptyState;
