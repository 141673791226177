import { useNet60CreditCards } from '@queries/use-credit-cards';
import { useCreditCardsFilters } from './credit-cards-header/use-credit-cards-filters';
import { CreditCard } from '@services/flexbase/card.model';
import { CreditCardsTableHeader } from './credit-cards-header/credit-cards-table-header';
import CardDetails from './card-details/card-details';
import { CardsTable } from './cards-table';

export const Net60CreditCardsTable = () => {
  const filterHook = useCreditCardsFilters();
  const searchTerm = filterHook.getFilterByKey('search')?.filterValue;

  const dataQuery = useNet60CreditCards({
    full: true,
    searchTerm,
  });

  const getTableHeader = (cards: CreditCard[]) => {
    return <CreditCardsTableHeader cardsData={cards} />;
  };

  const getDetailsModal = (card: CreditCard) => {
    return <CardDetails passedCard={card} />;
  };

  return (
    <CardsTable
      dataQuery={dataQuery}
      filterHook={filterHook}
      getTableHeader={getTableHeader}
      getDetailsModal={getDetailsModal}
    />
  );
};
