import { useNewInternationalRecipientForm } from 'areas/payments/components/send-payment/international-payments/hooks/use-international-recipient-form';
import {
  InternationalPayMethod,
  InternationalRecipient,
} from 'areas/payments/components/send-payment/international-payments/util/types';
import {
  PAYMENT_STEP_VALUES,
  isInitialBeneficiaryRequirementsFormValues,
} from '../../add-recipient';
import { useAddRecipientWizard } from '../../add-recipient-wizard';
import IntlPaymentForm from './forms/intl/intl-payment-form';
import DomesticAchForm from './forms/domestic/domestic-ach-form';
import DomesticWireForm from './forms/domestic/domestic-wire-form';

export const PaymentDetailsStep = () => {
  const { state } = useAddRecipientWizard();

  const {
    paymentMethod,
    recipientDetailsFormValues,
    beneficiaryRequirementsFormValues,
  } = state;
  const intialIntlFormValues = isInitialBeneficiaryRequirementsFormValues(
    beneficiaryRequirementsFormValues,
  )
    ? {
        name: recipientDetailsFormValues.name,
        method: paymentMethod as InternationalPayMethod,
        type: recipientDetailsFormValues.type,
      }
    : (beneficiaryRequirementsFormValues as InternationalRecipient);

  const intlForm = useNewInternationalRecipientForm(intialIntlFormValues);
  const { domesticACH, domesticWire, internationalWireUSD, internationalWire } =
    PAYMENT_STEP_VALUES;

  const paymentDetailsFormMapping = {
    [domesticACH]: <DomesticAchForm />,
    [domesticWire]: <DomesticWireForm />,
    [internationalWireUSD]: <IntlPaymentForm form={intlForm} />,
    [internationalWire]: <IntlPaymentForm form={intlForm} />,
  };

  return paymentDetailsFormMapping[paymentMethod];
};

PaymentDetailsStep.stepId = 'payment-details-step';
