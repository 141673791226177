import SummaryDetailLayout from '@common/layouts/detail-page/summary-detail-layout';
import { Flex, Text, UnstyledButton } from '@mantine/core';
import { formatCurrency } from '@utilities/formatters';
import { EyeClosedIcon, EyeOpenIcon } from 'assets/svg';
import { useState } from 'react';
import getPaddedAccountMask from 'utilities/formatters/get-padded-account-mask';

type AccountDetailsProps = {
  accountNumber: string;
  routingNumber: string;
  currentBalance: number;
};

const AccountDetails = ({
  accountNumber,
  routingNumber,
  currentBalance,
}: AccountDetailsProps) => {
  const [displayAccountNumber, setDisplayAccountNumber] = useState(false);

  return (
    <SummaryDetailLayout
      headline={formatCurrency(currentBalance / 100)}
      subtitle="Available balance"
    >
      <Flex justify="space-between">
        <Text size="sm" c="neutral.7">
          Account number
        </Text>
        <Flex gap="xs">
          <UnstyledButton
            onClick={() => setDisplayAccountNumber((prev) => !prev)}
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              color: theme.fn.themeColor('neutral', 6),
            })}
          >
            {displayAccountNumber ? (
              <EyeClosedIcon width={15} />
            ) : (
              <EyeOpenIcon width={16} />
            )}
          </UnstyledButton>
          <Text size="sm" c="neutral.7" ta="right">
            {displayAccountNumber
              ? accountNumber
              : getPaddedAccountMask(accountNumber, 5)}
          </Text>
        </Flex>
      </Flex>
      <Flex justify="space-between">
        <Text size="sm" c="neutral.7">
          Routing number
        </Text>
        <Text size="sm" c="neutral.7" ta="right">
          {routingNumber}
        </Text>
      </Flex>
    </SummaryDetailLayout>
  );
};

export default AccountDetails;
