import { useForm } from '@mantine/form';
import { useCreateSpendPlanWizard } from '../../create-spend-plan.wizard';
import {
  deriveCurrentAssignedError,
  deriveFutureAssignedError,
} from '../../../utils/derive-member-limits';

export type SpendPlanMemberFields = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  isManager: boolean;
  fullFundsAccess: boolean;
  currentAssigned: number;
  repeatFundsAccess: boolean;
  assigned: number;
};

export type TeamMembersStepFormValues = {
  /**
   * Not used for input, just validation
   */
  managers: false;
  members: SpendPlanMemberFields[];
};

export const TeamMembersFormInitialValues: TeamMembersStepFormValues = {
  managers: false,
  members: [],
};

const TeamMembersFormKeys = Object.keys(
  TeamMembersFormInitialValues,
) as (keyof TeamMembersStepFormValues)[];

type UseTeamMembersFormProps = {
  initialValues: TeamMembersStepFormValues;
};

export const useTeamMembersForm = ({
  initialValues,
}: UseTeamMembersFormProps) => {
  const { state } = useCreateSpendPlanWizard();

  // ensure the form doesn't contain unrelated values
  initialValues = TeamMembersFormKeys.reduce<TeamMembersStepFormValues>(
    (acc, k) => {
      return {
        ...acc,
        [k]: initialValues[k],
      };
    },
    { ...TeamMembersFormInitialValues },
  );

  return useForm<TeamMembersStepFormValues>({
    initialValues,
    validate: {
      managers: (_, formValues) =>
        formValues.members.filter((m) => m.isManager).length
          ? null
          : 'At least one manager is required',
      members: (value) => {
        return value.every((member) => {
          return (
            !deriveCurrentAssignedError({
              member,
              planLimit: state.currentLimit || 0,
            }) &&
            !deriveFutureAssignedError({
              member,
              planLimit: state.currentLimit || 0,
              planFutureLimit:
                (state.repeatCurrentLimit
                  ? state.currentLimit
                  : state.futureLimit) || 0,
            })
          );
        })
          ? null
          : 'At least one member has an invalid amount assigned';
      },
    },
  });
};
