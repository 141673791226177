import { UseFormReturnType } from '@mantine/form';
import { BeneficiaryRequirements } from '../../../../../../services/flexbase/banking.model';
import { PayMethod } from '../../payment.states';

export type AvailableCurrencies =
  | 'CAD'
  | 'CNY'
  | 'EUR'
  | 'INR'
  | 'MXN'
  | 'SAR'
  | 'AED'
  | 'GBP'
  | 'USD'; // 'USD' option is only for 1:1 USD transfers

export type CurrencySelectProps = {
  value: AvailableCurrencies;
  label: string;
  code: string;
  symbol: string;
};

export type PaymentPurposeFormValues = {
  purpose: string;
  source: string;
  code?: string;
};

export type CountriesRequiringPotentialPaymentPurposeCodes = 'CN' | 'AE' | 'BH';

export type InternationalWireUSD = 'internationalWireUSD';
export type InternationalWire = 'internationalWire';

export type InternationalPayMethod = InternationalWireUSD | InternationalWire;

export type IndividualOrCompany = 'individual' | 'company';

export type InternationalRecipient = {
  recipientId?: string; // TODO: remove this upon moving to new 'add recipient' flow, this should not be part of form values
  name: string;
  nickName?: string;
  accountHolder: string;
  dob?: string;
  type: InternationalPayMethod;
  recipientType: IndividualOrCompany;
  currency: AvailableCurrencies;
  countryOfRecipientsBank: string;
  countryOfRecipient: string;
} & BeneficiaryRequirements;

export type InternationalPaymentRecipientFormRef = {
  submitForm: () => InternationalRecipient | null;
};

export type BeneficiaryRequirementsFormRef = {
  submitForm: () => BeneficiaryRequirements | null;
};

export type InternationalRecipientForm = UseFormReturnType<
  InternationalRecipient,
  (values: InternationalRecipient) => InternationalRecipient
>;

export type DomesticAchRecipient = {
  recipientId?: string;
  nickName?: string;
  routingNumber: string;
  accountNumber: string;
  recipientType: IndividualOrCompany;
  dob?: string;
  type: PayMethod;
  accountType: string;
};

export type DomesticWireRecipient = {
  recipientId?: string;
  nickName?: string;
  routingNumber: string;
  accountNumber: string;
  recipientType: IndividualOrCompany;
  dob?: string;
  type: PayMethod;
  state?: string;
  address?: string;
  city?: string;
  postalCode?: string;
  addressLine2?: string;
  country?: string;
};

export type FieldRecord = Record<string, string>;

export type BeneficiaryResponse = {
  id: string;
  version: number;
  asOf: string;
  createdAt: string;
  byUser: string;
  createdBy: string;
  accountId: string;
  name: string;
  accountHolder: string;
  country: string;
  currency: AvailableCurrencies;
  entityType: string;
  ccResponse: CcResponse;
  ccBeneficiaryId: string;
  checks: Check[];
  status: string;
  reason: string | null;
  lastUsedAt: string | null;
  frecencyScore: number;
  recipientId: string;
  nickName: string;
};

export const isBeneficiaryResponse = (obj: any): obj is BeneficiaryResponse => {
  return !!obj.checks;
};

export type CcResponse = {
  id: string;
  iban: null;
  name: string;
  email: null;
  bankName: string;
  bicSwift: string;
  currency: string;
  createdAt: Date;
  updatedAt: Date;
  bankAddress: string[];
  bankCountry: string;
  paymentTypes: string[];
  accountNumber: string;
  businessNature: null;
  companyWebsite: null;
  bankAccountType: null;
  beneficiaryCity: string;
  creatorContactId: string;
  routingCodeType1: null;
  routingCodeType2: null;
  routingCodeValue1: null;
  routingCodeValue2: null;
  beneficiaryAddress: string[];
  beneficiaryCountry: string;
  defaultBeneficiary: string;
  beneficiaryLastName: string;
  beneficiaryPostcode: string;
  beneficiaryFirstName: string;
  bankAccountHolderName: string;
  beneficiaryEntityType: string;
  beneficiaryCompanyName: null;
  beneficiaryDateOfBirth: Date;
  beneficiaryStateOrProvince: string;
  beneficiaryExternalReference: null;
  beneficiaryIdentificationType: null;
  beneficiaryIdentificationValue: null;
};

type Check = {
  id: string;
  type: string;
  attributes: Attributes;
  relationships: Relationships;
};

type Attributes = {
  tags: any[];
  term: string;
  status: string;
  hasMatch: boolean;
  nameLast: string;
  birthdate: Date;
  createdAt: Date;
  nameFirst: string;
  ignoreList: any[];
  nameMiddle: null;
  redactedAt: null;
  runHistory: RunHistory[];
  completedAt: Date;
  countryCode: null;
  isContinuous: boolean;
  matchedLists: any[];
  reportTemplateVersionName: string;
};

type RunHistory = {
  type: string;
  completedAt: Date;
  scheduledAt: Date;
  reportTemplateVersionId: number;
};

type Relationships = {
  account: Account;
  inquiry: Account;
  reportTemplate: Account;
};

type Account = {
  data: IDAndType | null;
};

type IDAndType = {
  id: string;
  type: string;
};
