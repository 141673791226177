import { Badge, Text, useMantineTheme } from '@mantine/core';
import { MoneyMovementStatus } from '@services/flexbase/banking.model';
import { formatApiStrings } from '@utilities/formatters/format-api-strings';

const BadgeStatus = ({ status }: { status: MoneyMovementStatus }) => {
  const theme = useMantineTheme();
  let statusColor, labelColor;

  switch (status) {
    case 'Sent':
      statusColor = theme.fn.themeColor('primarySecondarySuccess', 0);
      labelColor = theme.fn.themeColor('primarySecondarySuccess', 6);
      break;
    case 'Pending':
    case 'PendingReview':
    case 'AwaitingApproval':
    case 'AwaitingConfirmation':
      statusColor = theme.fn.themeColor('tertiary', 0);
      labelColor = theme.fn.themeColor('tertiary', 7);
      break;
    case 'Canceled':
    case 'Rejected':
      statusColor = theme.fn.themeColor('critical', 0);
      labelColor = theme.fn.themeColor('critical', 6);
      break;
    default:
      statusColor = theme.fn.themeColor('neutral', 1);
      labelColor = theme.fn.themeColor('neutral', 7);
  }
  return (
    <Badge bg={statusColor}>
      <Text c={labelColor}>{formatApiStrings(status)}</Text>
    </Badge>
  );
};

export default BadgeStatus;
