import { useEffect, useState } from 'react';

export const getEnvironment = (
  host: string = window.location.host,
): 'local' | 'development' | 'production' | string => {
  if (host.includes('localhost')) {
    return 'local';
  } else if (
    host.includes('home.flexbase.dev') ||
    host.includes('home.flex.dev') ||
    host.includes('dev.flex.one')
  ) {
    return 'development';
  } else if (
    host.includes('home.flexbase.app') ||
    host.includes('home.flex.one')
  ) {
    //|| host.includes('demo.flex.one') if we want demo env to hold the same navtab rules
    return 'production';
  } else if (host.includes('netlify.app')) {
    return host.split('.')[0];
  }
  return '';
};

export const useMatchMedia = (mediaQuery: string) => {
  const [matches, setMatches] = useState(
    window.matchMedia(mediaQuery)?.matches ?? false,
  );

  useEffect(() => {
    const matchQuery = window.matchMedia(mediaQuery);
    const handler = (event: MediaQueryListEvent) => {
      setMatches(event.matches);
    };
    matchQuery.addEventListener('change', handler);

    return () => {
      matchQuery.removeEventListener('change', handler);
    };
  }, []);

  return matches;
};
