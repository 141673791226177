import {
  Button,
  ButtonProps,
  createStyles,
  Flex,
  Group,
  rem,
  useMantineTheme,
  Box,
} from '@mantine/core';
import { memo } from 'react';
import { IoClose } from 'react-icons/io5';

type FlexWizardDialogProps = {
  title: string;
  message: string;
  options: {
    label: string;
    onClick: () => void;
    variant: ButtonProps['variant'];
    disabled?: boolean;
    loading?: boolean;
  }[];
  onClose: () => void;
};

const InvoiceWizardDialog = ({
  title,
  message,
  options,
  onClose,
}: FlexWizardDialogProps) => {
  const theme = useMantineTheme();
  const { classes } = useStyles();

  return (
    <Box className={classes.wizardDialogOverlay} onClick={onClose}>
      <Flex
        className={classes.wizardDialog}
        onClick={(e) => e.stopPropagation()}
        direction="column"
        gap="xs"
      >
        <Group position="apart" align="flex-start">
          <Box className={classes.wizardDialogTitle}>{title}</Box>
          <Flex className={classes.wizardDialogClose} onClick={onClose}>
            <IoClose color={theme.fn.themeColor('black', 9)} size={20} />
          </Flex>
        </Group>
        <Box className={classes.wizardDialogMessage}>{message}</Box>
        <Box className={classes.wizardDialogOptions}>
          {options.map((option) => (
            <Button
              key={option.label}
              onClick={option.onClick}
              variant={option.variant || 'subtle'}
              c={
                option.variant !== 'light'
                  ? 'primarySecondarySuccess'
                  : undefined
              }
              disabled={option.disabled}
              loading={option.loading}
            >
              {option.label}
            </Button>
          ))}
        </Box>
      </Flex>
    </Box>
  );
};

export default memo(InvoiceWizardDialog);

const useStyles = createStyles((theme) => ({
  wizardDialogOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: theme.fn.rgba(theme.fn.themeColor('neutral', 9), 0.5),
    backdropFilter: 'blur(4px)',
    zIndex: 1000,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  wizardDialog: {
    backgroundColor: theme.colors.contentBackground[0],
    padding: theme.spacing.xs,
    width: rem(480),
    boxShadow: theme.shadows.md,
    borderRadius: theme.radius.xs,
  },
  wizardDialogTitle: {
    fontSize: theme.fontSizes.xl,
    paddingLeft: theme.spacing.sm,
    paddingTop: theme.spacing.xxs,
    color: theme.colors.neutral[9],
  },
  wizardDialogMessage: {
    padding: theme.spacing.sm,
    color: theme.colors.neutral[7],
  },
  wizardDialogOptions: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing.xs,
    padding: theme.spacing.sm,
  },
  wizardDialogClose: {
    padding: theme.spacing.xs,
    borderRadius: theme.radius.xs,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.colors.neutral[1],
    },
  },
}));
