import { formatCurrency } from '@utilities/formatters';
import { Avatar, Group, Stack, Text } from '@mantine/core';
import { formatDatetime } from '@utilities/formatters/format-datetime';
import { PropsWithChildren } from 'react';
import { VscChromeClose } from 'react-icons/vsc';
import { useDetailsStyles } from '../details-styles';
import { CreditTransactionsTableRow } from '../../credit-transactions-table-helpers';
import { useMatchMedia } from '@utilities/url/window-helpers';

type DetailsProps = {
  tableRow: CreditTransactionsTableRow;
  onCloseClick: () => void;
};

type HeaderProps = DetailsProps & {
  onCloseClick: () => void;
};

export const DetailsBody = ({ children }: PropsWithChildren) => {
  return (
    <Stack p="xxxl" spacing={40}>
      {children}
    </Stack>
  );
};

export const DetailsFieldsSection = ({ children }: PropsWithChildren) => {
  const isMobile = useMatchMedia('(max-width: 767px)');

  return <Stack spacing={isMobile ? 30 : 48}>{children}</Stack>;
};

export const DetailsFieldsRow = ({ children }: PropsWithChildren) => {
  return <Group grow>{children}</Group>;
};

export const DetailsHeader = ({ tableRow, onCloseClick }: HeaderProps) => {
  const { classes } = useDetailsStyles();
  const {
    logoUrl,
    toFrom: storeName,
    amount: total,
    date: createdAt,
  } = tableRow;

  return (
    <Group className={classes.headerRow1} spacing="xs">
      <Group spacing="md">
        {logoUrl ? (
          <img src={logoUrl} className={classes.storeLogo} alt={storeName} />
        ) : (
          <Avatar radius="md" className={classes.avatar}>
            {storeName?.charAt(0) || '$'}
          </Avatar>
        )}
        <Stack spacing="xs">
          <Text className={classes.amount}>
            {formatCurrency(Math.abs(total as unknown as number)) || 'N/A'}
          </Text>
          <Text className={classes.dateTime}>{formatDatetime(createdAt)}</Text>
        </Stack>
      </Group>
      <button className={classes.closeButton} onClick={onCloseClick}>
        <VscChromeClose size={34} color="#FFFF" />
      </button>
    </Group>
  );
};
