import { useRecoilValue } from 'recoil';
import { ApplicationState } from '../../../recoil-state/application/product-onboarding';
import { useOnboardingOverview } from '@queries/use-onboarding-overview';
import { SelfServiceDashboard } from '@common/self-service-dashboard/self-service-dashboard';
import CreditSalesPage from '../credit-sales-page/credit-sales.page';
import { ReactNode, useMemo, useState } from 'react';
import { LoadingOverlay } from '@mantine/core';
import { useFicoScore } from '@queries/use-fico-score';
import { difference } from 'underscore';
import { useCompanyDocuments } from '@queries/use-company-documents';
import {
  ActionItemNames,
  calculateCreditActionItems,
} from '@common/self-service-dashboard/calculate-action-items';
import { CreditContinuePage } from '../credit-sales-page/credit-continue.page';
import { CreditDeniedPage } from '../credit-sales-page/credit-denied.page';
import { CreditPendingPage } from '../credit-sales-page/credit-pending.page';

type ContentSection =
  | 'sales'
  | 'service'
  | 'continue'
  | 'denied'
  | 'pending'
  | 'loading';

export const NoCreditPage = () => {
  const status = useRecoilValue(ApplicationState);
  const { data: overview, isError: overviewError } = useOnboardingOverview();
  const { data: fico, isError: ficoError } = useFicoScore(status.user.id);
  const { data: uploadedDocs, isError: uploadedDocsError } =
    useCompanyDocuments();
  const [actionItems, setActionItems] = useState<ActionItemNames[]>([]);

  const section: ContentSection = useMemo(() => {
    const likelyApplicant =
      status.userIsApplicant ||
      status.user.id === status.company.controlPerson ||
      status.user.roles.includes('OWNER') ||
      status.user.roles.includes('OFFICER');

    const isError = overviewError || ficoError || uploadedDocsError;

    if (isError) {
      return 'sales';
    } else if (!overview || !fico || !uploadedDocs) {
      return 'loading';
    }

    const creditStatus = status.productStatus.credit.status;
    const hasDeniedLimit =
      !!status.productStatus.credit.creditLimit &&
      parseFloat(status.productStatus.credit.creditLimit) === 0;
    const hasDenialStatus =
      creditStatus === 'unqualified' || creditStatus === 'denied';
    const hasDenial = hasDeniedLimit || hasDenialStatus;

    const hasNonSelfServiceableReqs =
      creditStatus === 'incomplete' &&
      difference(status.requiredCredit, [
        'company.financialInstitutions',
        'user.plaidConnection',
      ]).length > 0;

    const currentActionItems = calculateCreditActionItems(
      status,
      overview,
      fico,
      uploadedDocs,
    );

    // This is so stupid but I can't think of another way to fix.
    // What this does is prevent the self-service dashboard from disappearing if your only item was Plaid
    // and you add an account but don't have allPlaidAccountsLinked === false
    if (
      actionItems.includes('plaid') &&
      !currentActionItems.includes('plaid') &&
      status.user.allPlaidAccountsLinked !== true
    ) {
      currentActionItems.push('plaid');
    }

    setActionItems(currentActionItems);

    // The Plaid action item is added by default for all companies, we don't want to show the self-service dashboard
    // if the application is otherwise complete/they have no other action items
    const hasActionItems = currentActionItems.length > 0;

    if (creditStatus === 'unused' || creditStatus === 'restricted') {
      return 'sales';
    } else if (hasDenial && likelyApplicant) {
      return 'denied';
    } else if (hasDenial) {
      return 'sales';
    } else if (
      likelyApplicant &&
      hasActionItems &&
      !hasDeniedLimit &&
      !hasNonSelfServiceableReqs
    ) {
      return 'service';
    } else if (likelyApplicant && hasNonSelfServiceableReqs) {
      return 'continue';
    } else if (
      creditStatus === 'pending' ||
      (creditStatus === 'incomplete' && status.requiredCredit.length === 0) // Requirements are filtered by the UI to hide company.* and Plaid reqs from non-applicant users, so if status is incomplete and they have 0 requirements, this user has completed their onboarding or application
    ) {
      return 'pending';
    } else {
      return 'sales';
    }
  }, [status, overview, ficoError, overviewError, fico, uploadedDocs]);

  const sectionMap: Record<ContentSection, ReactNode> = {
    service: (
      <SelfServiceDashboard
        status={status}
        overview={overview!}
        actionItems={actionItems}
      />
    ),
    sales: <CreditSalesPage />,
    continue: <CreditContinuePage />,
    denied: <CreditDeniedPage />,
    pending: <CreditPendingPage />,
    loading: <LoadingOverlay visible={true} />,
  };

  return sectionMap[section];
};
