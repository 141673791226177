import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  banner: {
    width: '1304px',
    height: '141px',
    borderRadius: '2px',
    background: `linear-gradient(232deg, ${theme.fn.themeColor(
      'tertiary',
      1,
    )} -160.15%, ${theme.fn.themeColor('neutral', 1)} 155.25%)`,
    transition: 'height 0.5s',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'start',
    position: 'relative',
  },
  mobileBanner: {
    paddingTop: theme.spacing.md,
    width: '100%',
    borderRadius: '2px',
    background: 'linear-gradient(232deg, #EFD5AB -160.15%, #D2D2D2 155.25%)',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    justifyContent: 'start',
    alignItems: 'center',
  },
  firstBanner: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'start',
    transition: 'opacity 0.5s',
  },
  firstBannerOpaque: {
    opacity: 0,
  },
  hiddenFirstBanner: {
    pointerEvents: 'none',
  },
  secondBanner: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'grid',
    gridTemplateColumns: '206px auto',
    alignItems: 'start',
    overflow: 'hidden',
    transition: 'opacity 0.5s',
    opacity: 0,
    pointerEvents: 'none',
  },
  revealedSecondBanner: {
    opacity: 100,
    pointerEvents: 'auto',
  },
  howItWorks: {
    height: '100%',
    borderRight: '1px solid rgba(0, 0, 0, 0.14)',
  },
  smallText: {
    color: theme.fn.themeColor('neutral', 7),
    fontFamily: 'PP Neue Montreal',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
  },
  smallishText: {
    color: theme.fn.themeColor('neutral', 7),
    fontFamily: 'PP Neue Montreal',
    fontSize: theme.spacing.md,
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
  },
  mediumText: {
    color: theme.fn.themeColor('primarySecondarySuccess', 8),
    fontFamily: 'PP Neue Montreal',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
  },
  largeText: {
    color: theme.fn.themeColor('primarySecondarySuccess', 8),
    fontFamily: 'PP Neue Montreal',
    fontSize: '40px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    letterSpacing: '-0.88px',
  },
  leftBorder: {
    borderLeft: '1px solid rgba(0, 0, 0, 0.14)',
  },
  sliderDiv: {
    height: '100%',
    gridColumn: '2 / span 2',
    display: 'grid',
    gridTemplateColumns: '277px 277px 371px 60px',
    gridTemplateRows: '155px auto',
    columnGap: theme.spacing.xxxl,
  },
  sliderTextDiv: { margin: '35px 0 0 47px' },
  slider: {
    borderTop: '1px solid rgba(0, 0, 0, 0.14)',
    gridRow: '2 / 3',
    gridColumn: '1 / 6',
  },
  collapsed: {
    height: '413px',
  },
  cardImage: {
    opacity: '100',
    flexBasis: '358px',
    borderRight: '1px solid rgba(0, 0, 0, 0.14)',
    height: '100%',
    overflow: 'hidden',
  },
  bigCard: {
    margin: '93.5px 32px 93.5px 32px',
    transition: 'margin 0.5s',
  },
  littleCard: {
    margin: '32px 32px 0 32px',
    transition: 'margin 0.5s',
  },
  name: {
    color: 'white',
    lineHeight: theme.spacing.md,
    position: 'relative',
  },
  cardName: {
    top: '-150px',
    left: '30px',
  },
  expandedCardName: {
    top: '-212.5px',
    left: '30px',
  },
  bigRow: {
    opacity: '100',
    flexGrow: 1,
    minWidth: 0,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'start',
    overflow: 'hidden',
  },
  topRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: '141px',
    flexShrink: 0,
    width: '100%',
  },
  topRowWide: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'start',
    columnGap: '4.5rem',
  },
  redeemPoints: {
    marginLeft: '142px',
    height: '100%',
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: '142px',
    justifyContent: 'flex-end',
    alignSelf: 'end',
    paddingLeft: theme.spacing.md,
    borderLeft: '1px solid rgba(0, 0, 0, 0.14)',
  },
  mobileRedeemPoints: {
    cursor: 'pointer',
    height: '55px',
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr 4fr 4fr',
    gridColumn: '1 / 6',
    justifyContent: 'start',
    alignItems: 'center',
    borderTop: '1px solid rgba(0, 0, 0, 0.14)',
  },
  mobileRedeemPointsArrow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
    padding: theme.spacing.sm,
  },
  mobileDollarSignIcon: {
    padding: theme.spacing.sm,
    marginBottom: `-${theme.spacing.xs}`,
  },
  redeemPointsContent: { cursor: 'pointer', marginLeft: '55px' },
  waysToRedeem: {
    color: theme.fn.themeColor('neutral', 8),
    fontFamily: 'PP Neue Montreal',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
  },
  redeemPointsLink: {
    marginTop: '24px',
  },
  redeemPointsArrow: {
    alignSelf: 'end',
    margin: '0 0 12px 60px',
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  redemptionSquareBottomRow: {
    display: 'grid',
    gridTemplateColumns: '70% 30%',
    width: '100%',
  },
  redemptionBottomArrow: {
    margin: theme.spacing.sm,
    alignSelf: 'end',
  },
  smallIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '36px',
    width: '36px',
    marginTop: theme.spacing.md,
    borderRadius: theme.spacing.xl,
    background: theme.fn.themeColor('neutral', 5),
    backgroundColor: theme.fn.themeColor('tertiary', 0),
  },
  redemptionIcons: {
    marginLeft: theme.spacing.md,
  },
  redemptionText: {
    margin: '34px 0 16px 16px',
    color: theme.fn.themeColor('neutral', 8),
    fontFamily: 'PP Neue Montreal',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
  },
  bottomRow: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'start',
    height: '0',
    width: '100%',
    transition: 'height 0.5s',
    borderTop: '1px solid rgba(0, 0, 0, 0.14)',
    boxSizing: 'border-box',
  },
  expandedBottomRow: {
    height: '272px',
  },
  bottomTopRow: {
    margin: '39px 0 14px 39px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'start',
  },
  bottomBottomRow: {
    width: '100%',
    marginLeft: '39px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'start',
    columnGap: theme.spacing.md,
  },
  redemptionSquare: {
    width: '157px',
    height: '142px',
    borderRadius: theme.spacing.xs,
    background: '#FFF',
    boxShadow: '0px 8px 50px 0px rgba(0, 0, 0, 0.20)',
    cursor: 'pointer',
  },
  topTextRow: {
    color: theme.fn.themeColor('neutral', 8),
    fontFamily: 'PP Neue Montreal',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
  },
  middleTextRow: {
    color: theme.fn.themeColor('primarySecondarySuccess', 8),
    fontFamily: 'PP Neue Montreal',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
  },
  moneyText: {
    color: theme.fn.themeColor('neutral', 7),
    fontFamily: 'PP Neue Montreal',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
  },
  textBlock: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'start',
    rowGap: '6px',
  },
  mobileTextBlock: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    rowGap: '6px',
    paddingBottom: theme.spacing.md,
  },
  brownTextLink: {
    marginTop: '-5px',
    color: theme.fn.themeColor('tertiary', 6),
    textAlign: 'center',
    fontFamily: 'PP Neue Montreal',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: theme.spacing.xl /* 171.429% */,
    textDecorationLine: 'underline',
    textTransform: 'capitalize',
  },
  tab: {
    display: 'block',
    width: '75px',
    height: '91px',
    margin: 'auto 8px auto 8px',
    padding: '16px 0 16px 0',
    textAlign: 'center',
  },
  tabIcon: {
    margin: 'auto auto 14px auto !important',
  },
  tabLabel: {
    color: theme.fn.themeColor('neutral', 6),
    fontFamily: 'PP Neue Montreal',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '-0.084px',
  },
  tabRoot: {
    width: '1086',
    margin: '2rem auto 2rem auto',
  },
  tabList: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    margin: '0 auto 0 auto',
  },
  searchRoot: {
    width: '756px',
    height: '48px',
    margin: '2.5rem auto 0 auto',
  },
  mobileSearchRoot: {
    height: '48px',
    margin: '2.5rem auto 0 auto',
  },
  searchWrapper: {
    width: '100%',
    height: '100%',
  },
  searchRightSection: {
    backgroundColor: theme.colors.primarySecondarySuccess[8],
    width: '30px',
    height: '30px',
    margin: 'auto 8px auto auto',
    color: 'white',
    borderRadius: '2px',
  },
  nonFeaturedBenefits: {
    marginTop: '2rem',
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr);',
    columnGap: theme.spacing.xxxl,
    justifyItems: 'center',
  },
  card: {
    padding: '22px 24px 22px 24px',
    width: '341px',
    height: '378px',
    marginBottom: '2rem',
    borderRadius: '2px',
    border: `1px solid ${theme.fn.themeColor('neutral', 3)}`,
    background: '#FFF',
  },
  featuredBenefits: {
    marginTop: '2rem',
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr);',
    columnGap: theme.spacing.xxxl,
    alignItem: 'center',
    justifyContent: 'space-between',
  },
  mobileBenefits: {
    marginTop: '2rem',
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '100%',
    justifyItems: 'center',
    justifyContent: 'center',
  },
  featuredCard: {
    padding: '22px 24px 22px 24px',
    height: '374px',
    marginBottom: '2rem',
    borderRadius: '2px',
    border: `1px solid ${theme.fn.themeColor('neutral', 3)}`,
    background: '#FFF',
  },
  mobileFeaturedCard: {
    padding: '22px 24px 22px 24px',
    marginBottom: '2rem',
    borderRadius: '2px',
    border: '1px solid #C5C2BE',
    background: '#FFF',
  },
  featuredTitle: {
    display: 'flex',
    height: '27px',
    width: '100%',
    margin: '0px',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  cardTitle: {
    color: theme.fn.themeColor('neutral', 6),
    fontFamily: 'PP Neue Montreal',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: theme.spacing.md,
    textTransform: 'capitalize',
  },
  featuredCardSubtitleSpan: {
    borderRadius: '4px',
    background: theme.fn.themeColor('neutral', 2),
    padding: '1px 6px 1px 6px',
    boxSizing: 'border-box',
  },
  featuredCardSubtitle: {
    display: 'inline',
    marginLeft: '4px',
    color: theme.fn.themeColor('neutral', 5),
    fontFamily: 'PP Neue Montreal',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: theme.spacing.md,
  },
  featuredCardBigText: {
    fontFamily: 'PP Neue Montreal',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: theme.spacing.md,
    marginTop: '1rem',
  },
  featuredCardDescription: {
    fontFamily: 'PP Neue Montreal',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: theme.spacing.md,
    color: theme.fn.themeColor('neutral', 7),
    marginTop: '1rem',
  },
  featuredCardButtonDiv: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '1rem',
  },
  ctaButton: {
    background: theme.fn.themeColor('neutral', 9),
  },
  featuredCardButtonArrow: {
    marginLeft: theme.spacing.xs,
  },
  learnMore: {
    fontFamily: 'PP Neue Montreal',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: theme.spacing.md,
    color: theme.fn.themeColor('neutral', 7),
    marginRight: '1rem',
    alignSelf: 'center',
    cursor: 'pointer',
  },
  detailModalButtonRoot: {
    padding: '0 16px 0 16px',
  },
  detailModalDiv: {
    display: 'flex',
    padding: '40px 48px',
    flexDirection: 'column',
    alignItems: 'start',
    gap: '8px',
  },
  detailModalCloseIconDiv: {
    alignSelf: 'end',
    background: 'black',
    color: 'white',
    width: '21px',
    height: '21px',
    borderRadius: '40px',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  detailModalTitle: {
    color: 'black',
    fontFamily: 'PP Neue Montreal',
    fontSize: '32px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    marginBottom: '24px',
    marginTop: '12px',
  },
  detailModalContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',

    alignItems: 'center',
    minHeight: '283px',
    minWidth: '100%',
    width: '285px',
    height: 'fit-content',
    background: theme.fn.themeColor('neutral', 2),
    borderRadius: '2px',
    padding: '2rem',
    marginBottom: '32px',
  },
  mobileDetailModalContent: {
    display: 'block',
    overflow: 'auto',
    height: 'fit-content',
    background: theme.fn.themeColor('neutral', 2),
    borderRadius: '2px',
    padding: '2rem',
    marginBottom: '32px',
  },
  detailModalDescription: {
    fontFamily: 'PP Neue Montreal',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '150%',
  },
  detailModalButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
    alignItems: 'center',
    width: '100%',
  },
  detailModalFinePrint: {
    fontFamily: 'PP Neue Montreal',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    color: theme.fn.themeColor('neutral', 6),
    alignSelf: 'center',
    textAlign: 'center',
    marginTop: '32px',
  },
}));
