import { ChargeCardAccount } from '@services/flexbase/banking.model';
import CreditLimitWidget from '@common/credit-limit-widget/credit-limit-widget';

const ChargeLimitOverview = ({
  chargeBalance,
}: {
  chargeBalance: ChargeCardAccount[];
}) => {
  const getCompanyChargeBalance = (chargeAccounts: ChargeCardAccount[]) => {
    // the current maximum amount the customer can be further loaned
    const chargeAccountsAvailable =
      chargeAccounts.reduce((acc, curr) => acc + Number(curr.available), 0) /
      100;
    // the amount of authorizations pending
    const chargeAccountsHold =
      chargeAccounts.reduce((acc, curr) => acc + Number(curr.hold), 0) / 100;
    // the amount the customer currently owes
    const chargeAccountsBalance =
      chargeAccounts.reduce((acc, curr) => acc + Number(curr.balance), 0) / 100;
    const totalLineOfCredit =
      chargeAccountsBalance + chargeAccountsHold + chargeAccountsAvailable;

    const utilization =
      totalLineOfCredit > 0
        ? Math.ceil((1 - chargeAccountsAvailable / totalLineOfCredit) * 100)
        : 0;

    return {
      utilization,
      available: chargeAccountsAvailable,
      total: totalLineOfCredit,
      spent: chargeAccountsBalance,
      hold: chargeAccountsHold,
    };
  };

  const companyChargeBalance = getCompanyChargeBalance(chargeBalance);

  return <CreditLimitWidget creditBalance={companyChargeBalance} />;
};

export default ChargeLimitOverview;
