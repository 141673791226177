import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  iconColumn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    svg: {
      margin: `0 !important`,
    },
  },
  icon: {
    width: theme.spacing.xl,
    height: theme.spacing.xl,
    cursor: 'pointer',
  },
  iconText: {
    color: theme.colors.blackish[1],
  },
  noBorder: {
    border: 'none',
    width: 'min-intrinsic',
  },
  select: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  greenButton: {
    width: '165px',
    color: theme.colors.primarySecondarySuccess[8],
    borderColor: theme.colors.primarySecondarySuccess[8],
  },
  darkGreenButton: {
    width: '165px',
    backgroundColor: theme.colors.primarySecondarySuccess[8],
    borderColor: theme.colors.primarySecondarySuccess[8],
  },
}));
