import { useQuery } from '@tanstack/react-query';
import { flexbaseOnboardingClient } from '../services/flexbase-client';

const QUERY_KEY = 'creditBalance';

export const useCreditBalance = (id: string) => {
  return useQuery({
    queryKey: [QUERY_KEY, id],
    queryFn: async () => {
      return await flexbaseOnboardingClient.getCreditBalance(id);
    },
    meta: {
      errorMessage: 'Unable to get credit balance',
    },
  });
};
