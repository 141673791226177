import type { TermsOfServiceAcceptance } from 'services/flexbase/flexbase-onboarding-client';
import { useMemo, useState } from 'react';
import { formatDate } from 'utilities/formatters/format-datetime';
import { Box, Text, createStyles } from '@mantine/core';
import { usePersonalGuaranty } from '@queries/use-personal-guaranty';
import { useTermsOfServiceHistory } from '@queries/use-terms-of-service';

// an external document that the user has agreed to
// as part of accepting some terms-of-service
type Agreement = {
  label: string;
  acceptedAt: string | null;
  renderAgreement: React.ReactNode;
};

const externalLinks: Record<string, { url: string; label: string }> = {
  'CurrencyCloud Terms and Conditions': {
    url: 'https://www.currencycloud.com/wp-content/uploads/2021/07/Terms-of-Use-CC-Inc-CFSB-19-July-21.pdf',
    label: 'CurrencyCloud Terms and Conditions',
  },
  'CurrencyCloud Privacy Policy': {
    url: 'https://www.currencycloud.com/legal/privacy/',
    label: 'CurrencyCloud Privacy Policy',
  },
};

// extract the external User Agreement links from the anchor tags
// in the HTML contents returned from the API
type ExtractAgreements = (acceptance: TermsOfServiceAcceptance) => Agreement[];
const extractAgreements: ExtractAgreements = ({ acceptedAt, contents }) => {
  const parser = new DOMParser();

  const links = parser
    .parseFromString(contents, 'text/html')
    .querySelectorAll('a');

  const isExternalLink = (link: HTMLAnchorElement) => {
    return !!link.textContent && !!externalLinks[link.textContent];
  };

  return Array.from(links).map<Agreement>((link) => {
    return isExternalLink(link)
      ? {
          label: externalLinks[link.textContent!].label,
          acceptedAt,
          renderAgreement: (
            <a
              target="_blank"
              href={externalLinks[link.textContent!].url}
              rel="noreferrer"
            >
              {externalLinks[link.textContent!].label}
            </a>
          ),
        }
      : {
          label: link.textContent!,
          acceptedAt,
          renderAgreement: (
            <embed
              src={link.href}
              type="application/pdf"
              width="100%"
              height="700px"
            />
          ),
        };
  });
};

function removeDuplicates<T>(arr: T[], getKey: (item: T) => any): T[] {
  const uniqueKeys = new Set();
  const result: T[] = [];

  for (const obj of arr) {
    const key = getKey(obj);
    if (!uniqueKeys.has(key)) {
      uniqueKeys.add(key);
      result.push(obj);
    }
  }

  return result;
}
const selectUnique = (t: TermsOfServiceAcceptance[]) => {
  const extractedList = t.flatMap(extractAgreements);
  const asUniqueList = removeDuplicates(extractedList, (item) => item.label);

  return asUniqueList;
};

type UserAgreementsTableProps = {
  agreements: Agreement[];
};

// Render in a simple table the User Agreements that have been
// accepted as parts of terms-of-service
const UserAgreementsTable = ({ agreements }: UserAgreementsTableProps) => {
  const [selectedTerm, setSelectedTerm] = useState<Agreement>(agreements[0]);
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.tabsContainer}>
        {agreements.map((item) => {
          return (
            <Box
              mb={9}
              onClick={() => {
                setSelectedTerm(item);
              }}
              key={item.label}
              className={classes.containerTerm}
              sx={(t) => ({
                backgroundColor:
                  selectedTerm?.label === item.label
                    ? t.colors.neutral[2]
                    : '#FFFF',
              })}
            >
              <Text> {item.label} </Text>
            </Box>
          );
        })}
      </div>

      <div className={classes.contentContainer}>
        {!!selectedTerm && (
          <>
            {!!selectedTerm.acceptedAt && (
              <Text mb={25} fs={'italic'}>
                Accepted at: {formatDate(selectedTerm.acceptedAt)}
              </Text>
            )}

            {selectedTerm.renderAgreement}
          </>
        )}
      </div>
    </div>
  );
};

const UserAgreements = () => {
  const { data: termsHistoryData } = useTermsOfServiceHistory({
    select: selectUnique,
  });
  const { data: pgData } = usePersonalGuaranty();

  const agreements = useMemo(() => {
    if (!termsHistoryData) {
      return undefined;
    }

    const list = [...termsHistoryData];

    if (pgData) {
      list.push({
        label: 'Personal Guaranty',
        acceptedAt: pgData.personalGuaranty.acceptedAt,
        renderAgreement: (
          <div
            dangerouslySetInnerHTML={{
              __html: pgData.personalGuaranty.contents,
            }}
          />
        ),
      });
    }

    return list;
  }, [termsHistoryData, pgData]);

  if (!agreements) {
    return;
  }

  return <UserAgreementsTable agreements={agreements} />;
};

const useStyles = createStyles((theme) => ({
  container: { display: 'flex', flexDirection: 'row' },
  contentContainer: {
    backgroundColor: '#FFFF',
    padding: '20px',
    borderRadius: theme.defaultRadius,
    width: '100%',
    marginLeft: 21,
  },
  containerTerm: {
    padding: 8,
    cursor: 'pointer',
  },
  tabsContainer: {
    backgroundColor: '#FFFF',
    width: '32rem',
    padding: '20px',
    borderRadius: theme.defaultRadius,
  },
}));
export default UserAgreements;
