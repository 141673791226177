import { Navigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { ApplicationState } from 'recoil-state/application/product-onboarding';
import { ApplicationStatus } from 'recoil-state/application/product-onboarding.models';

/**
 * Hack to allow users with approved credit but limit = 0 to redirect correctly.
 */
export function checkCreditDenied(productStatus: ApplicationStatus) {
  let isDenied = productStatus.credit.status === 'denied';

  if (productStatus.credit.status === 'denied') {
    const appApproved = productStatus.credit.appStatus === 'approved';
    const creditLimit = parseFloat(productStatus.credit.creditLimit ?? '-1');
    const validLimit = !isNaN(creditLimit) && creditLimit >= 0;

    if (appApproved && validLimit) {
      isDenied = false;
    }
  }

  return isDenied;
}

const ProductNavigator = () => {
  const { productStatus } = useRecoilValue(ApplicationState);

  const isCreditDenied = checkCreditDenied(productStatus);

  if (
    productStatus.credit.status === 'unused' ||
    isCreditDenied ||
    productStatus.credit.status === 'unqualified' ||
    productStatus.credit.status === 'restricted'
  ) {
    return <Navigate to="/banking" />;
  } else {
    return <Navigate to="/cards" />;
  }
};

export default ProductNavigator;
