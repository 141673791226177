import { useForm, UseFormReturnType } from '@mantine/form';
import { Link } from 'react-router-dom';
import {
  Anchor,
  Checkbox,
  Group,
  PasswordInput,
  Stack,
  TextInput,
} from '@mantine/core';
import { MultiStepFormStepWrapper } from '@common/multi-step-form/multi-step-form-step-wrapper';
import { EmailValidator } from '../../utilities/validators/validate-email';
import { RequiredFieldValidator } from '../../utilities/validators/validate-required';
import { useLoginContext } from './use-login';
import { useLoginStyles } from './login.styles';
import {
  KEY_USER_EMAIL_STORAGE,
  KEY_REMEMBER_USER,
} from 'providers/auth.provider';

type FormValues = {
  email: string;
  password: string;
  rememberMe: boolean;
};

type LoginProps = {
  errorMsg: string;
  onSubmit: () => void;
  loginForm: UseFormReturnType<FormValues>;
};

export const PasswordLoginForm = (_props: Partial<LoginProps>) => {
  const { classes } = useLoginStyles();
  const { loginWithPassword } = useLoginContext();

  const loginForm = useForm({
    initialValues: {
      email:
        localStorage.getItem(KEY_REMEMBER_USER) === 'true'
          ? localStorage.getItem(KEY_USER_EMAIL_STORAGE) || ''
          : '',
      password: '',
      rememberMe: localStorage.getItem(KEY_REMEMBER_USER) === 'true',
    },
    validate: {
      email: EmailValidator(),
      password: RequiredFieldValidator(),
    },
  });

  const handleFormSubmit = async () => {
    const validationResult = loginForm.validate();

    if (!validationResult.hasErrors) {
      const { email, password, rememberMe } = loginForm.values;
      await loginWithPassword(email.trim(), password.trim(), rememberMe);
    }
  };

  return (
    <MultiStepFormStepWrapper
      id="password-login"
      onFormSubmit={handleFormSubmit}
    >
      <Stack spacing="xl">
        <TextInput
          label="Email"
          inputMode="email"
          id="username-email"
          classNames={{ label: classes.label, error: classes.error }}
          placeholder="Enter your email"
          data-testid="input-username-email"
          {...loginForm.getInputProps('email')}
        />
        <PasswordInput
          id="password"
          label="Password"
          classNames={{ label: classes.label, error: classes.error }}
          data-testid="input-password"
          placeholder="Enter your password"
          {...loginForm.getInputProps('password')}
        />

        <Group grow mt="xl">
          <Checkbox
            color="primarySecondarySuccess.2"
            id="remember-user"
            label="Remember email"
            classNames={{ label: classes.label }}
            data-testid="checkbox-remember-username"
            {...loginForm.getInputProps('rememberMe', { type: 'checkbox' })}
          />
          <Anchor
            underline
            size="sm"
            align="right"
            component={Link}
            to="/forgot-password"
            c="primarySecondarySuccess.2"
            data-testid="button-forgot-password"
          >
            Forgot Password
          </Anchor>
        </Group>
      </Stack>
    </MultiStepFormStepWrapper>
  );
};
