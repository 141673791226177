import { atom } from 'recoil';
import { PromoCode } from './onboarding-info';

export type IAddress = {
  line1: string;
  line2?: string | null;
  city: string;
  state: string;
  postalCode: string;
  country?: string;
};

export type UserRole =
  | 'ADMIN'
  | 'EMPLOYEE'
  | 'ACCOUNTANT'
  | 'NO-LOGINS'
  | 'PLAID-STALE'
  | 'SERVICER'
  | 'OWNER'
  | 'COMPTROLLER'
  | 'OFFICER';

export type UserInfo = {
  id: string;
  firstName: string;
  lastName: string;
  initials: string;
  phone: string;
  picUrl: string | null;
  jobTitle: string;
  address: IAddress;
  email: string;
  cellPhone: string;
  roles: UserRole[];
  promoCode?: PromoCode;
};

export const UserInfoState = atom<UserInfo>({
  key: 'user-info',
  default: {
    id: '',
    firstName: '',
    lastName: '',
    initials: '',
    phone: '',
    picUrl: null,
    address: {
      state: '',
      city: '',
      country: '',
      line2: '',
      postalCode: '',
      line1: '',
    },
    promoCode: {
      id: '',
      userId: '',
      code: '',
      level: '',
      validFrom: '',
      validUntil: '',
      title: '',
      description: '',
      uwReject: false,
    },
    jobTitle: '',
    email: '',
    cellPhone: '',
    roles: [],
  },
});

export type NotificationChannels = 'sms' | 'email' | 'push';

export type UserPreferences = {
  notifications: Record<
    string,
    {
      default: NotificationChannels[];
      [key: string]: NotificationChannels[];
    }
  >;
};
