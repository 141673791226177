import { flexbaseOnboardingClient } from '../services/flexbase-client';
import {
  TermsOfServiceAcceptance,
  TermsOfServiceType,
} from '../services/flexbase/flexbase-onboarding-client';
import { DefaultError, useQuery } from '@tanstack/react-query';

const QUERY_KEY = 'terms_of_service';

type TermsOfServiceDocsReturnType = Awaited<
  ReturnType<typeof flexbaseOnboardingClient.getTermsOfServiceDocs>
>;

type Props<T> = {
  termsType?: TermsOfServiceType;
  useAuth?: boolean;
  select?: (data: TermsOfServiceDocsReturnType) => T;
};

export const useGetTermsOfServiceDocs = <T = TermsOfServiceDocsReturnType>({
  termsType,
  useAuth,
  select,
}: Props<T> = {}) => {
  return useQuery({
    queryKey: [QUERY_KEY, 'docs', termsType, useAuth],
    queryFn: () =>
      flexbaseOnboardingClient.getTermsOfServiceDocs(termsType, useAuth),
    select,
    meta: {
      errorMessage: `We're unable to get the terms of service documents at this time.`,
    },
  });
};

type UseTermsOfServiceProps<T> = {
  select?: (terms: TermsOfServiceAcceptance[]) => T[];
};

export const useTermsOfServiceHistory = function <T>({
  select,
}: UseTermsOfServiceProps<T> = {}) {
  return useQuery<TermsOfServiceAcceptance[], DefaultError, T[]>({
    queryKey: [QUERY_KEY, 'history'],
    queryFn: async () => {
      return await flexbaseOnboardingClient.getTosHistory();
    },
    select,
    staleTime: Infinity,
  });
};
