import { Box, Loader } from '@mantine/core';
import { useParams } from 'react-router-dom';
import { IntegrationLink } from '..';
import {
  useAdsLinks,
  useCommerceLinks,
  useExpenseLinks,
} from '@queries/use-integrations';
import { useRecoilValue } from 'recoil';
import { IsIntegrationsAuthorized } from 'recoil-state/integrations/integrations';
import { UseQueryResult } from '@tanstack/react-query';
import { IntegrationCard } from '../components/integration-card';
import { getIntegrationCards, IntegrationCardItem } from '../list-integrations';
import { IntuitBankFeedSettings } from '../components/intuit-bank-feed-settings';
import { IntegrationSettings } from '../components/integration-settings';
import { isQuickbooksBankFeeds } from '@utilities/integrations';

const INTEGRATIONS = getIntegrationCards();

function stubIntegration(title: string): IntegrationCardItem {
  return {
    title: title,
    abbreviation: '',
    category: undefined,
    tags: [],
    visible: false,
    content: '',
    iconComponent: <Box />,
    platform: undefined,
  };
}

type IntegrationDetailsProps<T extends IntegrationLink> = {
  queryResult: UseQueryResult<T[]>;
  children?: (props: {
    integration: IntegrationCardItem;
    link: T;
  }) => JSX.Element;
};

const IntegrationDetails = function <T extends IntegrationLink>({
  children,
  queryResult,
}: IntegrationDetailsProps<T>) {
  const { connectionId } = useParams();
  const { data: links, isPending, isError } = queryResult;

  const link = links?.find((l) => l.connectionId === connectionId);
  const integration = INTEGRATIONS.find((i) => i.platform === link?.platform);

  if (isError) {
    return (
      <IntegrationCard
        integration={stubIntegration('Error')}
        withNavigation
        backTo={'..'}
      >
        Unable to load integration data.
      </IntegrationCard>
    );
  }

  if (isPending) {
    return (
      <IntegrationCard
        integration={stubIntegration('Loading...')}
        withNavigation
        backTo={'..'}
      >
        <Loader />
      </IntegrationCard>
    );
  }

  if (!link || !integration) {
    return (
      <IntegrationCard
        integration={stubIntegration('Not Found')}
        withNavigation
        backTo={'..'}
      >
        Unable to find your integration.
      </IntegrationCard>
    );
  }

  return (
    <IntegrationCard
      integration={integration}
      link={link}
      withNavigation
      backTo={'..'}
    >
      {children ? children({ integration, link }) : null}
    </IntegrationCard>
  );
};

export const AccountingIntegrationDetails = () => {
  const isAuthorized = useRecoilValue(IsIntegrationsAuthorized);
  const queryResult = useExpenseLinks(isAuthorized);

  return (
    <IntegrationDetails queryResult={queryResult}>
      {({ integration, link }) => (
        <Box mt="2rem">
          {isQuickbooksBankFeeds(link) ? (
            <IntuitBankFeedSettings integration={integration} link={link} />
          ) : (
            <IntegrationSettings integration={integration} expenseLink={link} />
          )}
        </Box>
      )}
    </IntegrationDetails>
  );
};

export const AdsIntegrationDetails = () => {
  const isAuthorized = useRecoilValue(IsIntegrationsAuthorized);
  const queryResult = useAdsLinks(isAuthorized);

  return <IntegrationDetails queryResult={queryResult} />;
};

export const CommerceIntegrationDetails = () => {
  const isAuthorized = useRecoilValue(IsIntegrationsAuthorized);
  const queryResult = useCommerceLinks(isAuthorized);

  return <IntegrationDetails queryResult={queryResult} />;
};
