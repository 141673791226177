import { DateTime } from 'luxon';
import { Box, Button, Center, Flex, Loader, Text } from '@mantine/core';
import { LuPencilLine } from 'react-icons/lu';
import AccountSelection from 'areas/payments/components/account-selection';
import {
  PlusSignIcon,
  TransparentCenteredLoader,
} from '@flexbase-eng/web-components';
import { PlaidAccount } from 'areas/banking/move-funds/move-funds.model';
import { DepositAccount } from '@services/flexbase/banking.model';
import { CompanyBalance } from 'flexbase-client/dist/clients/FlexbaseClient.Company';
import { AutoPay as AutoPayProps } from 'types/onboarding-info';
import { FlexAccountInfo } from 'areas/cash-management/treasury-management.state';
import { useStyles } from './auto-pay.styles';
import { formatCurrency } from '@utilities/formatters';

export type Props = {
  isAdmin: boolean;
  loading: boolean;
  accountLoading: boolean;
  accountsList: (PlaidAccount | DepositAccount)[];
  currentAccount: PlaidAccount | DepositAccount | FlexAccountInfo;
  companyBalance: CompanyBalance;
  autopay: Partial<AutoPayProps>;
  onEditSettings: () => void;
  onAccountChange: (accountId: string) => void;
  onLinkBankAccount: () => void;
  isAccountsLoading: boolean;
};

const AutoPay = ({
  isAdmin,
  loading,
  accountLoading,
  accountsList,
  currentAccount,
  companyBalance,
  autopay,
  onEditSettings,
  onAccountChange,
  onLinkBankAccount,
  isAccountsLoading,
}: Props) => {
  const autoPayPct = autopay?.payBalancePct;
  const now = DateTime.now().toString();
  const { classes, theme } = useStyles();

  return (
    <Box className={classes.baseContainer}>
      <Box className={classes.autoPayContainer}>
        <TransparentCenteredLoader visible={loading} />
        <Box className={classes.autoPaySettings}>
          <Box className={classes.headerCard}>
            <Text className={classes.title}> Auto pay settings </Text>
            {isAdmin && (
              <Flex direction="row" align="center">
                <Text className={classes.grayTitle}>
                  {autopay?.when
                    ? autoPayPct && autoPayPct !== 100
                      ? `Auto pay ${autoPayPct}%`
                      : `Auto pay full balance`
                    : 'Edit auto pay'}
                </Text>
                <LuPencilLine
                  style={{
                    marginLeft: theme.spacing.xs,
                    color: theme.colors.primarySecondarySuccess[2],
                    cursor: 'pointer',
                  }}
                  onClick={onEditSettings}
                />
              </Flex>
            )}
          </Box>
          {isAccountsLoading ? (
            <Center h="80%">
              <Loader size="md" />
            </Center>
          ) : (
            <>
              <Text className={classes.dateAmountDue}>
                {companyBalance.billDate
                  ? DateTime.fromISO(companyBalance.billDate).toFormat('LLL dd')
                  : DateTime.fromISO(now).toFormat('LLL dd')}{' '}
                for{' '}
                {formatCurrency(
                  companyBalance?.minimumDue && companyBalance.minimumDue > 0
                    ? companyBalance.minimumDue
                    : 0,
                )}
              </Text>
              <Box>
                <Flex justify="space-between">
                  <Text className={classes.title}>
                    {autopay?.when
                      ? 'Default payment account'
                      : 'Enable auto pay to set default payment account'}
                  </Text>
                </Flex>
                <Box mt={8}>
                  {currentAccount && (
                    <AccountSelection
                      accounts={accountsList}
                      currentAccount={currentAccount}
                      onAccountChange={(account) => onAccountChange(account.id)}
                      showAccountFilters
                      disabled={
                        accountLoading || isAccountsLoading || !autopay?.when
                      }
                    />
                  )}
                  <Button
                    mt="md"
                    variant="outline"
                    w="fit-content"
                    disabled={accountLoading}
                    leftIcon={<PlusSignIcon />}
                    onClick={onLinkBankAccount}
                  >
                    Link New Account
                  </Button>
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default AutoPay;
