import LimitTile from 'areas/spend-plans/components/spend-plan-tiles/limit-tile/limit-tile';
import {
  SpendPlanAdminView,
  SpendPlanView,
} from '@flexbase-eng/types/dist/accounting';
import { Box, Grid, useMantineTheme } from '@mantine/core';
import { useMatchMedia } from '@utilities/url/window-helpers';
import { useNavigate } from 'react-router-dom';
import { usePlatformPersonContext } from 'providers/platform-person.context';
import { useRecoilValue } from 'recoil';
import { IsAdmin } from 'recoil-state/application/product-onboarding';

type SpendPlansTabLayoutProps = {
  spendPlans: (SpendPlanView | SpendPlanAdminView)[];
};

export const SpendPlansTabLayout = ({
  spendPlans,
}: SpendPlansTabLayoutProps) => {
  const theme = useMantineTheme();
  const useMobileView = useMatchMedia(`(max-width: ${theme.breakpoints.md})`);
  const navigate = useNavigate();
  const isAdmin = useRecoilValue(IsAdmin);
  const { person } = usePlatformPersonContext();

  const handlePlanClick = (id: string) => {
    navigate(`/spend-plans/${id}/details`);
  };

  const sortedPlans = spendPlans.sort((a, b) => {
    return b.createdAt > a.createdAt ? 1 : -1;
  });

  return (
    <Grid mt="16px" align="stretch">
      {sortedPlans.map((plan) => {
        const isManager =
          'managers' in plan
            ? plan.managers.some((m) => m.personId === person.id)
            : false;

        return (
          <Grid.Col key={plan.id} span={useMobileView ? 12 : 4}>
            <Box
              sx={{ cursor: 'pointer' }}
              onClick={() => handlePlanClick(plan.id)}
              h="100%"
            >
              <LimitTile plan={plan} isManaged={isAdmin || isManager} />
            </Box>
          </Grid.Col>
        );
      })}
    </Grid>
  );
};
