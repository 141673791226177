import { Box, Button, createStyles, Flex, Text } from '@mantine/core';
import { MdErrorOutline } from 'react-icons/md';

const useStyles = createStyles((theme) => ({
  infoText: {
    fontWeight: 400,
    fontSize: 28,
    lineHeight: '40px', // In this case using a number does not set it to px..
    textAlign: 'center',
  },
  errorIcon: {
    color: theme.fn.primaryColor(),
    fontSize: 40,
  },
}));

type Props = {
  onClose: () => void;
  onTryAgain: () => void;
};

const PaymentError = ({ onClose, onTryAgain }: Props) => {
  const { classes } = useStyles();

  return (
    <Box>
      <Flex
        align="center"
        direction="column"
        justify="center"
        bg="neutral.0"
        p="md"
      >
        <MdErrorOutline className={classes.errorIcon} />
        <Text className={classes.infoText}>Something went wrong.</Text>
      </Flex>
      <Flex mt="xxxl" justify="flex-end" gap="md">
        <Button variant="outline" onClick={() => onClose()}>
          Back to credit
        </Button>
        <Button onClick={() => onTryAgain()}>Try again</Button>
      </Flex>
    </Box>
  );
};

export default PaymentError;
