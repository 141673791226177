import { Avatar, Box, Button, Center, Flex, rem, Text } from '@mantine/core';
import { useMatchMedia } from '@utilities/url/window-helpers';
import {
  Clock,
  PhoneIcon,
  MailIcon,
  MessageIcon,
  ArrowUpRight,
} from 'assets/svg';

const ConciergeBanner = () => {
  const infoItems = [
    {
      info: 'Hours of availability:',
      add: 'Monday - Sunday 8am-10pm',
      icon: <Clock />,
    },
    {
      info: 'Message on Whatsapp',
      icon: <MessageIcon />,
    },
    {
      info: '305-987-4321',
      icon: <PhoneIcon />,
    },
    {
      info: 'concierge@flex.one',
      icon: <MailIcon />,
    },
  ];
  const isMobileScreenSize = useMatchMedia('(max-width: 767px)');
  return (
    <Center
      sx={() => ({
        position: isMobileScreenSize ? 'unset' : 'absolute',
        top: 250,
        left: 0,
        width: '100%',
        height: '100%',
      })}
    >
      <Box
        sx={(theme) => ({
          backgroundColor: '#F2F2F3',
          borderRadius: theme.radius.md,
        })}
        w={isMobileScreenSize ? '100%' : rem(1304)}
        p={24}
      >
        <Flex
          direction={isMobileScreenSize ? 'column' : 'row'}
          justify={'space-between'}
          align={isMobileScreenSize ? '' : 'center'}
        >
          <div>
            <Text fz={rem(24)} fw={500}>
              {' '}
              How to access your Flex Concierge{' '}
            </Text>
            <Text fz={rem(16)}>
              {' '}
              Contact your personal concierge from Flex Elite to book dinner
              reservations, travel, and more.
            </Text>
          </div>
          <Button
            w={rem(198)}
            mt={isMobileScreenSize ? 20 : 0}
            variant="light"
            rightIcon={<ArrowUpRight />}
          >
            Chat with concierge
          </Button>
        </Flex>
        <Flex
          direction={isMobileScreenSize ? 'column' : 'row'}
          align={isMobileScreenSize ? '' : 'center'}
          justify="space-between"
          mt={31}
        >
          {infoItems.map((item) => {
            return (
              <Flex
                key={item.info}
                direction="row"
                align="center"
                mb={isMobileScreenSize ? 20 : 0}
              >
                <Avatar color="#FFFF" mr={10}>
                  {item.icon}
                </Avatar>
                <div>
                  <Text fz={rem(16)} fw={500}>
                    {' '}
                    {item.info}{' '}
                  </Text>
                  {item.add && (
                    <Text fz={rem(16)} fw={500}>
                      {item.add}
                    </Text>
                  )}
                </div>
              </Flex>
            );
          })}
        </Flex>
      </Box>
    </Center>
  );
};

export default ConciergeBanner;
