import {
  Accordion,
  Badge,
  Box,
  Button,
  Divider,
  Image,
  LoadingOverlay,
  Tooltip,
} from '@mantine/core';
import { useMemo, useState } from 'react';
import { useClipboard, useMediaQuery } from '@mantine/hooks';

import { useStyles } from './add-funds';
import { CopyIcon } from 'assets/svg';
import PlaidLogo from 'assets/images/plaid-logo.png';
import { PaymentForm } from 'types/payments';
import ConnectBankAccount from './connect-bank-account';
import TransferFundsSection from './transfer-funds-section';
import { FinancialInstitution } from 'types/onboarding-info';
import AccountSelection from 'areas/payments/components/account-selection';
import { DepositAccount, PlaidAccount } from '../move-funds/move-funds.model';
import GetPaddedAccountMask from 'utilities/formatters/get-padded-account-mask';
import { useRecoilValue } from 'recoil';
import { UserInfoState } from 'types/user-info';
import { downloadWireInstructions } from '../accounts/utilities';
import { useGetDepositAccounts } from '@queries/use-deposit-accounts';

type Props = {
  transferLoading: boolean;
  plaidAccounts: PlaidAccount[];
  depositAccounts: DepositAccount[];
  onLinkAccount: (x: boolean) => void;
  onMakeTransfer: (values: PaymentForm) => void;
  onReLinkAccount: (accounts: FinancialInstitution[]) => void;
};

const AddFundsOptions = ({
  onLinkAccount,
  plaidAccounts,
  onMakeTransfer,
  depositAccounts,
  transferLoading,
  onReLinkAccount,
}: Props) => {
  const clipboard = useClipboard();
  const { classes, theme } = useStyles({ amount: 0 });
  const [displayAccountNumber, setDisplayAccountNumber] = useState(false);
  const [accountSelected, setAccountSelected] = useState(depositAccounts[0]);
  const useMobileView = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
  const plaidLinkedAccounts = plaidAccounts.filter(
    (acc) => acc.active && !acc.unlinked,
  );
  const user = useRecoilValue(UserInfoState);
  const { data, isLoading } = useGetDepositAccounts();

  const allowACHFunding = useMemo(() => {
    if (!data) {
      return false;
    }

    const totalAvailable = data.accounts.reduce(
      (sum, deposit) => sum + deposit.available,
      0,
    );

    const hasMadeInitialDeposit = totalAvailable >= 0.01;

    return hasMadeInitialDeposit;
  }, [data, user]);

  if (isLoading) {
    return <LoadingOverlay visible={isLoading} />;
  }

  return (
    <Box w={useMobileView ? '100%' : 420}>
      <div className={classes.contentContainer}>
        <div className={classes.title}>Add funds</div>

        <Divider my={15} />

        <Accordion
          radius={0}
          defaultValue="wire-transfer"
          classNames={{
            control: classes.accordionRoot,
            chevron: classes.accordionChevron,
          }}
        >
          <Accordion.Item value="wire-transfer">
            <Accordion.Control>
              <div style={{ padding: '0px 10px' }}>
                <div className={classes.rowContent}>
                  <span className={classes.infoContent}>Wire transfer</span>
                  <Badge
                    classNames={{
                      inner: classes.badgeInner,
                      root: classes.badgeRoot,
                    }}
                  >
                    Recommended
                  </Badge>
                </div>

                <div className={classes.infoContent}>
                  <p>Arrives in 1-2 business days</p>
                </div>
              </div>
            </Accordion.Control>
            <Accordion.Panel>
              <div style={{ padding: '0px 10px' }}>
                <AccountSelection
                  label="Your receiving account info"
                  accounts={depositAccounts}
                  currentAccount={accountSelected}
                  onAccountChange={(acc) =>
                    setAccountSelected(acc as DepositAccount)
                  }
                />

                <Box className={classes.rowContent} mt={12}>
                  <div style={{ width: '100%' }}>
                    <span className={classes.label}>Routing number</span>
                    <Tooltip
                      withArrow
                      label={clipboard.copied ? 'Copied' : 'Click to copy'}
                      onClick={() =>
                        clipboard.copy(accountSelected?.routingNumber)
                      }
                    >
                      <div className={classes.input}>
                        <p>{accountSelected?.routingNumber}</p>
                        <CopyIcon color={theme.fn.themeColor('neutral', 1)} />
                      </div>
                    </Tooltip>
                  </div>
                  <div style={{ width: '100%' }}>
                    <span className={classes.label}>Account number</span>
                    <Tooltip
                      withArrow
                      label={clipboard.copied ? 'Copied' : 'Click to copy'}
                      onClick={() =>
                        clipboard.copy(accountSelected?.accountNumber)
                      }
                    >
                      <div
                        className={classes.input}
                        onMouseOver={() => setDisplayAccountNumber(true)}
                        onMouseLeave={() => setDisplayAccountNumber(false)}
                      >
                        <p>
                          {displayAccountNumber
                            ? accountSelected?.accountNumber
                            : GetPaddedAccountMask(
                                accountSelected?.accountNumber,
                                4,
                              )}
                        </p>
                        <CopyIcon color={theme.fn.themeColor('neutral', 1)} />
                      </div>
                    </Tooltip>
                  </div>
                </Box>
                <Button
                  mt="1rem"
                  fullWidth
                  onClick={() => downloadWireInstructions(accountSelected.id)}
                >
                  Wire details
                </Button>
              </div>
            </Accordion.Panel>
          </Accordion.Item>
          {allowACHFunding ? (
            <Accordion.Item value="bank-transfer">
              <Accordion.Control>
                <div style={{ padding: '10px', paddingBottom: 0 }}>
                  <div className={classes.flexContent}>
                    <span className={classes.infoContent}>Bank transfer</span>
                    <Image src={PlaidLogo} width={46.5} height={18} />
                  </div>
                  <div className={classes.infoContent}>
                    <p>Arrives in 3 business days</p>
                  </div>
                </div>
              </Accordion.Control>
              <Accordion.Panel>
                <div style={{ padding: '0px 10px' }}>
                  {!plaidAccounts.length || !plaidLinkedAccounts.length ? (
                    <ConnectBankAccount
                      {...{ onLinkAccount, plaidAccounts, onReLinkAccount }}
                    />
                  ) : (
                    <TransferFundsSection
                      onMakeTransfer={onMakeTransfer}
                      plaidAccounts={plaidLinkedAccounts}
                      {...{ depositAccounts, transferLoading }}
                    />
                  )}
                  <div className={classes.textInfo}>
                    Flex is powered by Flexbase Technologies, Inc.
                  </div>
                </div>
              </Accordion.Panel>
            </Accordion.Item>
          ) : null}
        </Accordion>
      </div>
    </Box>
  );
};

export default AddFundsOptions;
