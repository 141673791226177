import { RedemptionStepper } from '../redemption-stepper';
import { RedemptionAccountContent } from './content/redemption-account-content';

export const RedemptionAccount = () => {
  return (
    <RedemptionStepper
      nextButtonText="Next"
      title="Select account"
      subtitle="Select the account where you want to receive the cashback of $800 USD."
    >
      <RedemptionAccountContent />
    </RedemptionStepper>
  );
};
