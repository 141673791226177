import { RedemptionStepper } from '../redemption-stepper';
import { Stack, Text, useMantineTheme } from '@mantine/core';
import { RedemptionReviewContent } from './content/redemption-review-content';

export const RedemptionReview = () => {
  const theme = useMantineTheme();

  return (
    <RedemptionStepper
      nextButtonText="Confirm Transfer"
      title="Review"
      // TODO change this based on the state of the stepper
      subtitle={
        <Stack>
          <Text>We will apply this amount to your credit line.</Text>
          <Text mt={`-${theme.spacing.md}`}>
            Please review the information below, you won&#39;t be able to un-do
            this action.
          </Text>
        </Stack>
      }
    >
      <RedemptionReviewContent />
    </RedemptionStepper>
  );
};
