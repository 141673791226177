import { useRecoilValue } from 'recoil';
import { useForm } from '@mantine/form';
import { useEffect, useState } from 'react';
import { Box, Button, Group, Loader, Text } from '@mantine/core';
import OfficerForm from './officer-form';
import OfficerCard from './officer-card';
import FlexbaseSelect from '../../../../components/select/flexbase-select';
import OnboardingStep from '../../components/onboarding-step';
import { CompanyOwner } from '../../../../types/onboarding-info';
import { ApplicationState } from '../../../../recoil-state/application/product-onboarding';
import { validateRequired } from '../../../../utilities/validators/validate-required';
import { useOnboardingStyles } from '../../onboarding.styles';
import { useApplicationFlowContext } from '../../onboarding-hooks';

const Officers = () => {
  const [error, setError] = useState('');
  const { classes, cx } = useOnboardingStyles();
  const [loading, setLoading] = useState(false);
  const { company, user } = useRecoilValue(ApplicationState);
  const [officersLoading, setOfficersLoading] = useState(false);
  const [showOfficerForm, setShowOfficerForm] = useState(false);
  const [officers, setOfficers] = useState<Partial<CompanyOwner>[]>([]);
  const {
    goBack,
    createOrUpdateCompany,
    navigateToNextProductStep,
    refreshProductOnboardingStatus,
  } = useApplicationFlowContext();

  const form = useForm({
    initialValues: {
      userIsOfficer: company.officers.some((o) => o.id === user.id)
        ? 'yes'
        : '',
    },
    validate: {
      userIsOfficer: (value) =>
        validateRequired(value) ? null : 'Select an officer option',
    },
  });

  const getOfficerData = async () => {
    setOfficersLoading(true);
    try {
      const newStatus = await refreshProductOnboardingStatus(true);
      setOfficers(newStatus.company.officers);
    } catch (e) {
      setError('An error occurred while refreshing officer data.');
    } finally {
      setOfficersLoading(false);
    }
  };

  const onOfficerAdded = async () => {
    await getOfficerData();
    setShowOfficerForm(false);
  };

  const onDeleteOfficer = async (officerId: string) => {
    try {
      const officersMinusOfficer = company.owners
        .filter((o) => o.id !== officerId)
        .map((o) => ({
          id: o.id,
          jobTitle: o.jobTitle,
        }));

      await createOrUpdateCompany({
        officers: officersMinusOfficer,
      });

      await getOfficerData();
    } catch (e) {
      console.error('error deleting an officer', e);
      setError('An error occurred while deleting the officer.');
    }
  };

  const onSubmit = async () => {
    const validationResult = form.validate();
    if (!validationResult.hasErrors) {
      try {
        setLoading(true);
        let newOfficers = officers;
        if (form.values.userIsOfficer === 'yes') {
          newOfficers = [...officers, { id: user.id, jobTitle: user.jobTitle }];
        }
        if (!newOfficers.length) {
          return setError('You must have at least one officer to continue');
        }
        await createOrUpdateCompany({
          officers: newOfficers.map((officer) => ({
            id: officer.id,
            jobTitle: officer.jobTitle,
          })),
        });
        navigateToNextProductStep();
      } catch (e) {
        console.error('error adding a officer', e);
        setError(`An error occurred, could not add the officers.`);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getOfficerData();
  }, []);

  return (
    <OnboardingStep
      title="Officers"
      subtitle="Before we can process your application, we need to verify the identity of the officers in the company."
      stepId="business-officers"
      error={error}
      onBackClick={goBack}
      onNextClick={onSubmit}
      showContinueSpinner={loading}
    >
      <div>
        {officersLoading ? (
          <Box my="lg" className={classes.centerFlex}>
            <Loader color="primarySecondarySuccess.3" size="sm" />
          </Box>
        ) : (
          <>
            <Group grow>
              <FlexbaseSelect
                classNames={{
                  label: classes.inputLabel,
                  root: classes.btnSpacingAddressPage,
                }}
                label="Are you an officer?"
                placeholder="Select one"
                data={[
                  { value: 'yes', label: 'Yes' },
                  { value: 'no', label: 'No' },
                ]}
                {...form.getInputProps('userIsOfficer')}
                id="input-select-user-is-officer"
                onChange={(value) => {
                  if (value === 'no') {
                    setShowOfficerForm(true);
                  }
                  form.setFieldValue('userIsOfficer', value!);
                }}
              />
            </Group>
            {officers.filter((o) => o.id !== user.id).length > 0 && (
              <div className={classes.containerCardOwners}>
                <Text mb="md" ff="Redaction" size={20}>
                  Officers
                </Text>
                {officers.map((o) => (
                  <OfficerCard
                    key={o.id}
                    officer={o}
                    onDeleteClick={() => onDeleteOfficer(o.id!)}
                  />
                ))}
              </div>
            )}

            {showOfficerForm ? (
              <>
                <OfficerForm
                  onSuccess={onOfficerAdded}
                  onCancel={() => setShowOfficerForm(false)}
                />
                <div className={cx(classes.flavorText, classes.topSpacing)}>
                  By providing the information above, I hereby certify, to the
                  best of my knowledge, that the information provided above is
                  complete and correct.
                </div>
              </>
            ) : (
              <Button
                mt="xl"
                fullWidth
                variant="outline"
                id="button-add-officer"
                classNames={{ root: classes.addNewOwnerBtn }}
                onClick={() => {
                  setError('');
                  setShowOfficerForm(true);
                }}
              >
                + Add an officer
              </Button>
            )}
          </>
        )}
      </div>
    </OnboardingStep>
  );
};

export default Officers;
