import { useQuery } from '@tanstack/react-query';
import { flexbaseBankingClient } from '../services/flexbase-client';

const DOMESTIC_WIRE_QUERY_KEY = 'domesticWireInstructions';

export const useGetDomesticWireInstructions = (accountId?: string) => {
  return useQuery({
    queryKey: [DOMESTIC_WIRE_QUERY_KEY, accountId],
    queryFn: async () => {
      return await flexbaseBankingClient.getDomesticWireInstructions(
        accountId || '',
      );
    },
    enabled: !!accountId,
  });
};

const INTERNATIONAL_WIRE_QUERY_KEY = 'internationalWireInstructions';

export const useGetInternationalWireInstructions = (
  enabled: boolean,
  accountId?: string,
) => {
  return useQuery({
    queryKey: [INTERNATIONAL_WIRE_QUERY_KEY, accountId],
    queryFn: async () => {
      return await flexbaseBankingClient.getInternationalWireInstructions(
        accountId || '',
      );
    },
    enabled,
  });
};
