import { createStyles, rem } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  container: {
    padding: '80px',
    minHeight: '100vh',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(/images/page-top-frame.png)`,
    backgroundColor: theme.colors.primarySecondarySuccess[8],
    '@media (max-width: 768px)': {
      padding: '20px',
    },
  },
  formContainer: {
    backgroundColor: theme.fn.themeColor('contentBackground', 2),
    border: `1px solid ${theme.fn.themeColor('neutral', 3)}`,
    borderRadius: theme.radius.sm,
  },
  error: {
    color: theme.fn.themeColor('critical', 2),
  },
  alert: {
    fontWeight: 400,
    fontSize: rem(14),
    lineHeight: rem(19),
    padding: theme.spacing.sm,
    color: theme.fn.themeColor('neutral', 9),
    borderRadius: theme.defaultRadius,
    background: theme.fn.themeColor('critical', 0),
    border: `1px solid ${theme.fn.themeColor('critical', 2)}`,
  },
  reSendCode: {
    fontSize: rem(14),
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
    textAlign: 'left',
  },
}));
