import { Box, Button, Group, TextInput, useMantineTheme } from '@mantine/core';
import {
  DollarCircleIcon,
  PlusSignIcon,
  SearchIcon,
  StarIcon,
  TeamsIcon,
} from '../../../assets/svg';
import NewAccountModal from './new-account.modal';
import { useMediaQuery } from '@mantine/hooks';
import {
  useBankingAccountsFilters,
  useBankingAccountsFiltersModal,
} from './use-banking-filters';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { BankingAccountsStatusFilter } from './filters/banking-accounts-status-filter';
import { BankingAccountsBalanceFilter } from './filters/banking-accounts-balance-filter';
import { BankingAccountsNameFilter } from './filters/banking-accounts-nickname-filter';
import { useGetUser } from '../../../queries/use-users';
import { UserIdState } from '../../onboarding/onboarding-form.state';
import { isTruthyString } from '../../../utilities/validators/validate-string';
import { useFilterModal } from '@common/filter/filter-modal';
import { IoFunnelOutline } from 'react-icons/io5';
import { FilterChips } from '@common/filter/filter-chips';

const BankingAccountsHeader = () => {
  const theme = useMantineTheme();
  const useMobileView = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
  const [addModal, setAddModal] = useState(false);
  const [roles, setRoles] = useState<string[]>([]);
  const userId = useRecoilValue(UserIdState);
  const { data, isSuccess } = useGetUser(userId);

  const filterHook = useBankingAccountsFilters();
  const modalFilterHook = useBankingAccountsFiltersModal();

  const {
    activeFiltersArray,
    removeAllFilters,
    getFilterByKey,
    addFilter,
    removeFilter,
    openResponsiveFilterModal,
  } = useFilterModal({ filterHook, modalFilterHook });

  const onSearchChange = (value: string) => {
    if (!value) {
      removeFilter('search');
      return;
    }

    addFilter('search', {
      key: 'search',
      filterValue: value,
      label: `Includes ${value}`,
      showChip: false,
      fn: (transaction) => {
        const normalizedFilterText = value.toLowerCase().trim();
        return Object.values(transaction)
          .filter(isTruthyString)
          .map((v) => v.toLowerCase())
          .some((v) => v.includes(normalizedFilterText));
      },
    });
  };

  const filters = [
    {
      key: 'status',
      header: 'Status',
      icon: <StarIcon />,
      component: <BankingAccountsStatusFilter />,
    },
    {
      key: 'balance',
      header: 'Balance',
      icon: <DollarCircleIcon />,
      component: <BankingAccountsBalanceFilter />,
    },
    {
      key: 'nickName',
      header: 'Account',
      icon: <TeamsIcon />,
      component: <BankingAccountsNameFilter />,
    },
  ];

  const handleRemoveFilter = (filterKey: string) => {
    removeFilter(filterKey);
  };

  const handleClearFilters = () => {
    removeAllFilters();
  };

  const openModal = () => {
    openResponsiveFilterModal(filters);
  };

  useEffect(() => {
    if (isSuccess && data?.roles) {
      setRoles(data.roles || []);
    }
  }, [data, isSuccess]);

  return (
    <>
      <Group position="apart" mb="md">
        <Box
          sx={() => ({
            display: 'flex',
            gap: theme.spacing.md,
            justifyContent: 'space-between',
            flexWrap: 'wrap',
          })}
          mb="md"
        >
          <Box
            sx={() => ({
              display: 'flex',
              gap: theme.spacing.md,
              [theme.fn.smallerThan('sm')]: {
                flexDirection: 'column',
                width: '100%',
              },
            })}
          >
            <TextInput
              w={useMobileView ? '100%' : 368}
              placeholder="Search accounts"
              onChange={(e) => onSearchChange(e.target.value)}
              icon={<SearchIcon width={20} height={20} />}
              value={getFilterByKey('search')?.filterValue || ''}
            />
            <Button
              variant="outline"
              rightIcon={<IoFunnelOutline />}
              onClick={() => openModal()}
            >
              Filters
            </Button>
          </Box>
        </Box>
        <Group>
          <Button
            leftIcon={<PlusSignIcon width={20} />}
            variant="light"
            onClick={() => {
              setAddModal(true);
            }}
            mt={useMobileView ? '1rem' : 0}
            mb={useMobileView ? '1rem' : 0}
            fullWidth={useMobileView}
            disabled={!roles.includes('ADMIN')}
          >
            Open Flex account
          </Button>
          <NewAccountModal addModal={addModal} setAddModal={setAddModal} />
        </Group>
      </Group>

      <FilterChips
        filterArray={activeFiltersArray}
        onRemoveFilter={handleRemoveFilter}
        onClearFilters={handleClearFilters}
      />
    </>
  );
};

export default BankingAccountsHeader;
