import {
  Alert,
  Text,
  Textarea,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { Dropzone, IMAGE_MIME_TYPE, PDF_MIME_TYPE } from '@mantine/dropzone';
import { useForm } from '@mantine/form';
import { AttachmentIcon, InfoIcon, TrashIcon } from 'assets/svg';
import AccountBox from 'components/account-box/account-box';
import { DateTime } from 'luxon';
import { ReactNode, useRef, useState } from 'react';
import { DepositAccount } from 'services/flexbase/banking.model';
import { getCurrentIsoDate, isHoliday, isWeekend } from 'utilities/dates/dates';
import { formatCurrency } from 'utilities/formatters';
import getPaddedAccountMask from 'utilities/formatters/get-padded-account-mask';
import AccountIcon from '../acount-icon';
import PaymentStep, { getPrettyMethod } from '../payment-step';
import CurrencyConversionInputs from './components/currency-conversion-inputs';
import {
  isInternationalPayMethod,
  isInternationalWire,
} from './international-payments/util';
import { AvailableCurrencies } from './international-payments/util/types';
import { useStyles } from './payment-amount.styles';
import { MoneyMovementDetails, PayMethod } from './payment.states';
import { getDailyAchCreditLimitByTier } from './util';
import { ACH_COPY, WIRE_COPY } from 'constants/constants';

type Props = {
  onNextClick: () => void;
  onBackClick: () => void;
  moneyMovementDetails: MoneyMovementDetails;
  setMoneyMovementDetails: (details: MoneyMovementDetails) => void;
  method: PayMethod;
  accounts: DepositAccount[];
  selectedIntlCurrency?: AvailableCurrencies;
  footer?: ReactNode;
};

const PaymentAmount = ({
  onBackClick,
  onNextClick,
  moneyMovementDetails,
  setMoneyMovementDetails,
  method,
  accounts,
  selectedIntlCurrency,
  footer,
}: Props) => {
  const theme = useMantineTheme();
  const { classes } = useStyles();
  const openAccounts = accounts
    .filter((a) => a.status === 'Open')
    .sort((a, b) => b.available - a.available);

  const [showAccountList, setShowAccountList] = useState(false);
  const [greaterThanMillion, setGreaterThanMillion] = useState(false);

  const [selectedAccount, setSelectedAccount] = useState<DepositAccount>(
    moneyMovementDetails.sourceAccount.accountNumber
      ? moneyMovementDetails.sourceAccount
      : openAccounts[0],
  );

  // upload files
  const [files, setFiles] = useState<File[]>(moneyMovementDetails.files || []);
  const addFile = async (droppedFiles: File[]) => {
    setFiles([...files, ...droppedFiles]);
  };

  const MAX_DATE = DateTime.now().plus({ years: 2 }).toJSDate();
  const dailyAchCreditLimit = getDailyAchCreditLimitByTier(
    selectedAccount.tier,
  );

  const form = useForm({
    initialValues: {
      paymentAmount: moneyMovementDetails.amount ?? 0,
      memoText: moneyMovementDetails.memo ?? '',
      internalNote: moneyMovementDetails.internalNotes ?? '',
      files,
      scheduledFor: DateTime.fromISO(
        moneyMovementDetails.scheduledFor ?? getCurrentIsoDate(),
      ).toJSDate(),
    },
    validate: {
      paymentAmount: (value) => {
        if (value === 0) {
          return 'Please enter a payment amount';
        }
        if (value > selectedAccount.available) {
          return 'The account does not have enough money to make the payment.';
        }
        if (method === 'wire' && value < 50.0) {
          return 'Domestic wire amount must be greater than $50.00';
        }
        if (method === 'ach' && value > dailyAchCreditLimit) {
          return `The maximum ACH amount available for this account is ${formatCurrency(
            dailyAchCreditLimit,
          )}.`;
        }
        return null;
      },
      memoText: (value) => {
        if (!value) {
          return 'Memo required for payment';
        }
        return null;
      },
    },
  });

  const inputRef = useRef<HTMLInputElement>(null);

  const onInputChange = (input: string) => {
    let currentValue = input.replace(/[^\d]/g, '');

    if (currentValue.length === 0) {
      form.setFieldValue('paymentAmount', 0);
      return;
    }

    if (currentValue.length < 2) {
      currentValue = `0.0${currentValue}`;
    } else if (currentValue.length === 2) {
      currentValue = `0.${currentValue}`;
    } else if (currentValue.length > 9) {
      return;
    } else {
      currentValue =
        currentValue.slice(0, currentValue.length - 2) +
        '.' +
        currentValue.slice(-2);
    }
    const numVal = Number.parseFloat(currentValue);

    numVal > 1000000
      ? setGreaterThanMillion(true)
      : setGreaterThanMillion(false);

    form.setFieldValue('paymentAmount', numVal);
  };

  const formattedDate = DateTime.fromJSDate(
    form.values.scheduledFor,
  ).toISODate() as string;

  const onNext = () => {
    const validationResult = form.validate();
    if (!validationResult.hasErrors) {
      setMoneyMovementDetails({
        amount: form.values.paymentAmount,
        sourceAccount: selectedAccount,
        memo: form.values.memoText,
        internalNotes: form.values.internalNote,
        files,
        scheduledFor: formattedDate,
      });
      onNextClick();
    }
  };

  const onBack = () => {
    setMoneyMovementDetails({
      amount: form.values.paymentAmount,
      sourceAccount: selectedAccount,
      memo: form.values.memoText,
      internalNotes: form.values.internalNote,
      files,
      scheduledFor: formattedDate,
    });
    onBackClick();
  };

  const handleIntlInputChange = (val: number) => {
    form.setFieldValue('paymentAmount', val);
  };

  const characterMaxLength =
    getPrettyMethod(method) === 'ACH' || isInternationalPayMethod(method)
      ? 10
      : 50;

  return (
    <PaymentStep
      titleText="Payment amount"
      onNextClick={onNext}
      onBackClick={onBack}
      footer={footer}
    >
      {isInternationalWire(method) && selectedIntlCurrency ? (
        <CurrencyConversionInputs
          currency={selectedIntlCurrency}
          onChange={handleIntlInputChange}
          error={form.errors.paymentAmount}
          value={form.values.paymentAmount}
        />
      ) : (
        <>
          <TextInput
            variant="unstyled"
            value={formatCurrency(form.values.paymentAmount)}
            onChange={(e) => onInputChange(e.target.value)}
            classNames={{ root: classes.inputRoot, input: classes.input }}
            ref={inputRef}
            onFocus={(e) => {
              e.currentTarget.setSelectionRange(
                e.currentTarget.value.length,
                e.currentTarget.value.length,
              );
            }}
            w="100%"
            error={form.errors.paymentAmount}
            data-testid={'amount'}
          />
          {greaterThanMillion && method === 'ach' && (
            <Alert
              variant="light"
              mt="1rem"
              color="info"
              icon={<InfoIcon />}
              withCloseButton={false}
            >
              <Text size={'sm'} data-testid="amount-alert">
                This amount exceeds same-day ACH limit and will process in 1-3
                days.
              </Text>
            </Alert>
          )}
        </>
      )}
      <div className={classes.sectionHeader}>Pay from</div>
      <AccountBox
        onClick={() => setShowAccountList((prev) => !prev)}
        showArrow={openAccounts.length > 1}
        rotateArrow={showAccountList}
        isListItem={false}
        icon={<AccountIcon />}
        headerText={
          selectedAccount.nickName || selectedAccount.name || 'Account'
        }
        subheaderText={`${formatCurrency(selectedAccount.available / 100)} / ${
          selectedAccount.accountType[0].toUpperCase() +
          selectedAccount.accountType.slice(1)
        } ${getPaddedAccountMask(selectedAccount.accountNumber, 2)}`}
        data-testid={'deposit-accounts-list'}
        showBorder
      />
      {showAccountList && openAccounts.length > 1 && (
        <div className={classes.selectList}>
          {openAccounts
            .filter((a) => a.id !== selectedAccount.id)
            .sort((a, b) => b.available - a.available)
            .map((c, index) => (
              <AccountBox
                headerText={c.nickName || c.name || 'Account'}
                subheaderText={`${formatCurrency(c.available / 100)} / ${
                  c.accountType[0].toUpperCase() + c.accountType.slice(1)
                } ${getPaddedAccountMask(c.accountNumber, 2)}`}
                onClick={() => {
                  setSelectedAccount(c);
                  setShowAccountList(false);
                }}
                showArrow={false}
                rotateArrow={false}
                isListItem={true}
                icon={<AccountIcon />}
                key={c.id}
                data-testid={`deposit-accounts-list-${index}`}
              />
            ))}
        </div>
      )}

      <DatePickerInput
        mt="xl"
        label="Send on"
        valueFormat="MMM DD, YYYY"
        // limit the max date to 2 years
        maxDate={MAX_DATE}
        excludeDate={(date) => isWeekend(date) || isHoliday(date)}
        minDate={DateTime.now().toJSDate()}
        {...form.getInputProps('scheduledFor')}
        data-testid="send-on"
      />
      {method === 'ach' ? (
        <Text color={theme.fn.themeColor('neutral', 7)} size="xs" mt={4}>
          {ACH_COPY}
        </Text>
      ) : null}
      {method === 'wire' && (
        <Text color={theme.fn.themeColor('neutral', 7)} size="xs" mt={4}>
          {WIRE_COPY}
        </Text>
      )}
      <div className={classes.sectionHeader}>
        External memo{' '}
        <span>(required, max {characterMaxLength} characters)</span>
      </div>
      <TextInput
        placeholder="Description for counterparty statement"
        maxLength={characterMaxLength}
        data-testid={'memo'}
        {...form.getInputProps('memoText')}
      />
      <div className={classes.sectionHeader}>
        Internal notes <span>(optional)</span>
      </div>
      <Textarea
        placeholder="Enter your internal notes here"
        styles={{ input: { height: '90px !important' } }}
        data-testid={'internal-note'}
        {...form.getInputProps('internalNote')}
      ></Textarea>

      <div className={classes.sectionHeader}>
        Internal attachment <span>(optional)</span>
      </div>
      <Dropzone
        onDrop={(file) => addFile(file)}
        maxSize={3 * 1024 ** 2}
        accept={[...IMAGE_MIME_TYPE, ...PDF_MIME_TYPE]}
        classNames={{
          root: classes.dropZone,
        }}
      >
        <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
          <AttachmentIcon />
          Upload
        </div>
      </Dropzone>
      {files.length > 0 && (
        <div className={classes.filesContainer}>
          {files.map((file, i) => (
            <div key={file.name + i} className={classes.file}>
              {file.name}
              <button
                className={classes.deleteFile}
                onClick={() => {
                  setFiles(files.filter((f) => f !== file));
                }}
              >
                <TrashIcon width={16} color={theme.fn.primaryColor()} />
              </button>
            </div>
          ))}
        </div>
      )}
    </PaymentStep>
  );
};

export default PaymentAmount;
