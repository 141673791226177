import { PlatformPerson } from '../services/platform/models/identity.model';
import { createContext, ReactNode, useContext } from 'react';
import { useGetMe } from '../queries/use-get-me';
import { LoadingOverlay } from '@mantine/core';

type UsePlatformPersonContextReturnType = {
  person: PlatformPerson;
};

const PlatformPersonContext =
  createContext<UsePlatformPersonContextReturnType | null>(null);

export function PlatformPersonProvider({ children }: { children: ReactNode }) {
  const { data, isLoading } = useGetMe();

  if (!data) {
    return isLoading ? <LoadingOverlay visible={true} /> : false;
  }

  return (
    <PlatformPersonContext.Provider value={{ person: data }}>
      {children}
    </PlatformPersonContext.Provider>
  );
}

export function usePlatformPersonContext() {
  const context = useContext(PlatformPersonContext);

  if (context === null) {
    throw new Error(
      'usePlatformPersonContext must be used within PlatformPersonProvider',
    );
  }

  return context;
}
