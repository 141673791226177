import { StatusBox } from './status-box';
import { Button } from '@mantine/core';
import { ApplicationStatus } from '../../../recoil-state/application/product-onboarding.models';
import { BankingApplicationStatus } from '@services/flexbase/banking.model';
import { useRouteSectionContext } from 'providers/route-context';
import { useLogout } from '@services/logout/logout-hook';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mantine/hooks';

type Props = {
  appStatus: ApplicationStatus;
  bankingStatus: BankingApplicationStatus;
};

export const BankingStatusBox = ({ appStatus, bankingStatus }: Props) => {
  const { setShowRoutesFor } = useRouteSectionContext();
  const logout = useLogout();
  const navigate = useNavigate();
  let description: string;

  const isMobile = useMediaQuery('(max-width: 767px)');
  const redirectApp = () => {
    setShowRoutesFor('main');
    navigate('/banking');
  };
  const redirectMobileApp = () => {
    window.location.replace('flexmobile://welcome');
  };

  switch (bankingStatus) {
    case 'Approved':
      description =
        'Congratulations! Your bank account is approved. We have shipped your debit card to your home address. It should arrive in 3 business days. Login and transfer funds to maximize APY';
      break;
    case 'Denied':
      description = `We are unable to approve you for a Flex bank account at this time. Please contact us.`;
      break;
    case 'AwaitingDocuments':
    case 'Pending':
    default:
      if (appStatus.banking.invalidOwners?.length) {
        description =
          'All beneficial owners must accept their invites and provide identification data. Once this is done your application will be processed.';
      } else {
        description =
          'We are processing your application and will let you know our decision within 5 business days.';
      }
  }

  return (
    <StatusBox title="Flex Banking" description={description}>
      {bankingStatus === 'Approved' ? (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            onClick={() => {
              redirectApp();
            }}
            data-testid="button-primary-approved"
          >
            Access Web App
          </Button>
          {isMobile && (
            <Button
              onClick={() => {
                redirectMobileApp();
              }}
              data-testid="button-primary-mobile-app"
            >
              Access Mobile App
            </Button>
          )}
        </div>
      ) : (
        <Button
          variant="outline"
          bg="neutral.0"
          sx={(theme) => ({
            '&:not([data-disabled])': theme.fn.hover({
              backgroundColor: theme.fn.themeColor('neutral', 0),
            }),
          })}
          onClick={() => logout()}
          data-testid="button-primary-close-app"
        >
          Close Application
        </Button>
      )}
    </StatusBox>
  );
};
