import { useForm } from '@mantine/form';
import {
  ConditionalFieldValidator,
  RequiredFieldValidator,
} from '../../../../../utilities/validators/validate-required';

export type RestrictionsStepFormValues = {
  restrictAccountingCategories: boolean;
  accountingCategories: Set<string>;
  restrictMerchantCategories: boolean;
  merchantCategoryRestrictionType: 'whitelist' | 'blacklist' | null;
  merchantCategoryWhitelist: Set<string>;
  merchantCategoryBlacklist: Set<string>;
  receiptPolicy: 'none' | 'all' | 'threshold' | null;
  receiptThreshold: number | '';
};

export const RestrictionsStepFormInitialValues: RestrictionsStepFormValues = {
  restrictAccountingCategories: false,
  accountingCategories: new Set(),
  restrictMerchantCategories: false,
  merchantCategoryRestrictionType: null,
  merchantCategoryWhitelist: new Set(),
  merchantCategoryBlacklist: new Set(),
  receiptPolicy: 'none',
  receiptThreshold: '',
};

const RestrictionsStepFormKeys = Object.keys(
  RestrictionsStepFormInitialValues,
) as (keyof RestrictionsStepFormValues)[];

type UseRestrictionsFormProps = {
  initialValues: RestrictionsStepFormValues;
};

export const useRestrictionsForm = ({
  initialValues,
}: UseRestrictionsFormProps) => {
  // ensure the form doesn't contain unrelated values
  initialValues = RestrictionsStepFormKeys.reduce<RestrictionsStepFormValues>(
    (acc, k) => {
      return {
        ...acc,
        [k]: initialValues[k],
      };
    },
    { ...RestrictionsStepFormInitialValues },
  );

  return useForm<RestrictionsStepFormValues>({
    initialValues,
    validate: {
      accountingCategories: (value, formValues) => {
        const isRestricted = formValues.restrictAccountingCategories;

        return isRestricted && !value.size
          ? 'At least 1 category required'
          : null;
      },
      merchantCategoryRestrictionType: (value, formValues) => {
        return formValues.restrictMerchantCategories && !value
          ? 'Must choose to block or allow merchants'
          : null;
      },
      merchantCategoryBlacklist: (value, formValues) => {
        const isRestricted = formValues.restrictMerchantCategories;
        const isBlacklist =
          formValues.merchantCategoryRestrictionType === 'blacklist';

        if (!isRestricted) {
          return null;
        }

        return isBlacklist && !value.size
          ? 'At least 1 category required'
          : null;
      },
      merchantCategoryWhitelist: (value, formValues) => {
        const isRestricted = formValues.restrictMerchantCategories;
        const isWhitelist =
          formValues.merchantCategoryRestrictionType === 'whitelist';

        if (!isRestricted) {
          return null;
        }

        return isWhitelist && !value.size
          ? 'At least 1 category required'
          : null;
      },
      receiptPolicy: RequiredFieldValidator('Receipt policy is required'),
      receiptThreshold: (value, formValues) => {
        return ConditionalFieldValidator(
          formValues.receiptPolicy === 'threshold',
        )(value);
      },
    },
  });
};
