import { Stack, TextInput, Text, Button, Group } from '@mantine/core';
import { useState, ChangeEventHandler } from 'react';
import { useLoginContext } from 'areas/login/use-login';
import { useStyles } from './integration-modal.styles';

const AuthVerifyCode = () => {
  const [code, setCode] = useState('');
  const [error, setError] = useState<string | null>(null);
  const { verifyCodeOauth, goToPreviousStep, resendSmsCode } =
    useLoginContext();
  const loading = false;
  const { classes } = useStyles();

  const handleCodeInputChange: ChangeEventHandler<HTMLInputElement> = (
    event,
  ) => {
    const value = event.target.value;
    if (value.length <= 6) {
      setCode(value);
    }
  };

  const handleFormSubmit = async () => {
    if (code.length < 6) {
      setError('Please enter a valid 6-digit code.');
    } else {
      await verifyCodeOauth(code);
    }
  };
  const handleResendCodeClick = () => {
    resendSmsCode();
  };

  return (
    <Stack spacing="md">
      <Text mt="xl">Enter the 6-digit code we sent to your cellphone.</Text>
      <TextInput
        label="Code"
        placeholder="000000"
        value={code}
        onChange={handleCodeInputChange}
        error={error !== null}
      />
      {error && <Text color="red">{error}</Text>}
      <Text
        className={classes.reSendCode}
        mt="sm"
        onClick={handleResendCodeClick}
      >
        Re-send code
      </Text>

      <Group mt="xl" sx={{ justifyContent: 'space-between' }}>
        <Button
          variant="outline"
          onClick={() => goToPreviousStep({ error: null, loading: false })}
          bg="neutral.0"
          disabled={loading}
        >
          Back
        </Button>
        <Button onClick={handleFormSubmit} loading={loading}>
          Submit
        </Button>
      </Group>
    </Stack>
  );
};

export default AuthVerifyCode;
