import ValidatorFn from './validator-fn';

type PasswordStrengthCheck = {
  testFn: (val: string) => boolean;
  label: string;
};

export const passwordStrengthChecks: PasswordStrengthCheck[] = [
  { testFn: (val) => val.length >= 8, label: 'Includes at least 8 characters' },
  { testFn: (val) => /[0-9]/.test(val), label: 'Includes number' },
  { testFn: (val) => /[a-z]/.test(val), label: 'Includes lowercase letter' },
  { testFn: (val) => /[A-Z]/.test(val), label: 'Includes uppercase letter' },
];

export const isPasswordStrong = (value: string): boolean => {
  return passwordStrengthChecks.every((check) => check.testFn(value));
};

export function NewPasswordValidator(
  errorMessage?: string,
): ValidatorFn<string> {
  return (val) =>
    val && isPasswordStrong(val)
      ? null
      : errorMessage ||
        'Password must be at least 8 characters, contain 1 lowercase letter, 1 uppercase letter, a number and a symbol';
}
