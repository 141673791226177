import {
  Box,
  createStyles,
  Group,
  LoadingOverlay,
  UnstyledButton,
} from '@mantine/core';
import { useRecoilState } from 'recoil';
import { ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CloseIconSquareEnds } from 'assets/svg';
import {
  AUTHORIZATIONS,
  DepositAccount,
  PlaidAccount,
} from '../move-funds/move-funds.model';
import {
  ApplicationState,
  getProductOnboardingStatus,
} from 'recoil-state/application/product-onboarding';
import AddFundsOptions from './add-funds-options';
import { Analytics } from 'services/analytics/analytics';
import { showNotification } from '@mantine/notifications';
import ModalError from '../components/modal-error/modal-error';
import { flexbaseBankingClient } from 'services/flexbase-client';
import ModalSuccess from '../components/modal-success/modal-success';
import { formatCurrency } from 'utilities/formatters/format-currency';
import { FinancialInstitution } from 'types/onboarding-info';
import { PaymentForm, paymentFormInitial } from 'types/payments';
import { usePlaidBankingComponent } from '@utilities/custom-hooks/plaid-banking-component';
import { useMakePayment } from '@queries/use-payments';
import { useMediaQuery } from '@mantine/hooks';
import FlexIconLink from '@common/icons/flex-icon-link';
import { useKeyPress } from 'utilities/custom-hooks';

export enum AddFundsSteps {
  ADD_FUNDS,
  SUCCESS,
  LOADING,
  ERROR,
}

type Props = {
  content: ReactNode;
  closeButton: ReactNode;
  onClose: () => void;
};

const ConditionalWrapper = ({ content, closeButton, onClose }: Props) => {
  const { classes, theme } = useStyles({ amount: 0 });
  const useMobileView = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);

  useKeyPress('Escape', onClose);

  if (useMobileView) {
    return (
      <Box w="100%">
        <Group className={classes.containerMobile}>
          <FlexIconLink width={90} />
          {closeButton}
        </Group>
        <Box className={classes.boxContainer}>{content}</Box>
      </Box>
    );
  } else {
    return (
      <Box w="100%">
        <Group style={{ justifyContent: 'space-between' }}>
          <FlexIconLink width={90} />
          <Box>{closeButton}</Box>
        </Group>
        <Box className={classes.boxContainer}>{content}</Box>
      </Box>
    );
  }
};

const AddFunds = () => {
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [transferForm, setTransferForm] =
    useState<PaymentForm>(paymentFormInitial);
  const { classes } = useStyles({ amount: 0 });
  const [content, setContent] = useState(AddFundsSteps.LOADING);
  const [transferLoading, setTransferLoading] = useState(false);
  const [needsLinkAccount, setNeedsLinkAccount] = useState(false);
  const depositAccount = transferForm.toAccount as DepositAccount;
  const [plaidAccounts, setPlaidAccounts] = useState<PlaidAccount[]>([]);
  const [linkingAccountLoading, setLinkingAccountLoading] = useState(false);
  const [{ company }, setCompany] = useRecoilState(ApplicationState);
  const [depositAccounts, setDepositAccounts] = useState<DepositAccount[]>([]);
  const paymentRequest = useMakePayment();

  const getAccounts = async (compPlaidAccounts: FinancialInstitution[]) => {
    try {
      // set linked Plaid accounts
      const plaidLinkedAccounts = compPlaidAccounts.map((acc) => {
        return { ...acc, plaidOrDeposit: 'plaid' } as PlaidAccount;
      });
      setPlaidAccounts(plaidLinkedAccounts);

      // set deposit accounts
      const result = await flexbaseBankingClient.getDepositList();
      if (result.success) {
        const activeAccounts = result.accounts.filter(
          (acc) => acc.status === 'Open',
        );
        const accountsData: DepositAccount[] = activeAccounts.map((account) => {
          return { ...account, plaidOrDeposit: 'deposit' } as DepositAccount;
        });
        setDepositAccounts(accountsData);
        setContent(AddFundsSteps.ADD_FUNDS);
      } else {
        setError('Unable to get your deposit accounts. Try again later.');
        setContent(AddFundsSteps.ERROR);
      }
    } catch (err) {
      console.error('error getting deposit accounts', err);
      setError('Unable to get your deposit accounts. Try again later.');
      setContent(AddFundsSteps.ERROR);
    }
  };

  const makeTransfer = async (values: PaymentForm) => {
    try {
      setTransferLoading(true);

      const transferFormToAccount = values.toAccount as DepositAccount;
      // fund banking account with plaid linked account
      const payment = await paymentRequest.mutateAsync({
        type: 'ach',
        direction: 'Debit',
        description: 'Funding',
        accountId: transferFormToAccount.id,
        plaidTokenId: values.fromAccount!.id,
        amount: formatCurrency(values.amount),
        authorizations: [AUTHORIZATIONS.achDebit],
      });

      setTransferForm({
        ...values,
        status: payment.status,
        reason: payment.reason,
        id: payment.id,
      });
      setContent(AddFundsSteps.SUCCESS);
      Analytics.track('Banking Payment Submitted', { payment });
    } catch (err) {
      console.error('unable to make payment', err);
      const { payment = {}, error: errorMsg = '' } = JSON.parse(err.message);

      if (payment.status === 'Rejected' && payment.reason) {
        setTransferForm({
          ...transferForm,
          status: payment.status,
          reason: payment.reason,
        });
        setContent(AddFundsSteps.SUCCESS);
      } else if (errorMsg.includes('expired')) {
        setError(
          'Your Plaid account connection has expired, please relink plaid by logging out and logging back in again.',
        );
      } else {
        setError('Unable to make the payment. Please, try it again');
      }
      setContent(AddFundsSteps.ERROR);
    } finally {
      setTransferLoading(false);
    }
  };

  // plaid logic when there are no linked accounts yet
  const { open, ready } = usePlaidBankingComponent({
    onSuccess: () => onLinkSuccess(),
    onError: () => {
      closeModal();
      showNotification({
        color: 'red',
        title: 'Error',
        message: 'Unable to link bank account',
      });
    },
    setLoading: (loading) => setLinkingAccountLoading(loading),
  });

  const onLinkSuccess = async () => {
    setLinkingAccountLoading(true);
    // update company recoil value with the new linked accounts
    const { company: co } = await getProductOnboardingStatus();
    setCompany((prev) => ({ ...prev, company: co }));
    setNeedsLinkAccount(false);
    await getAccounts(co.financialInstitutions);
    setLinkingAccountLoading(false);
  };

  useEffect(() => {
    if (needsLinkAccount && ready) {
      open();
    }
  }, [needsLinkAccount, ready]);

  // generate the contents of this flow based on content state
  const getContents = () => {
    switch (content) {
      case AddFundsSteps.ADD_FUNDS:
        return (
          <AddFundsOptions
            onReLinkAccount={getAccounts}
            onLinkAccount={setNeedsLinkAccount}
            onMakeTransfer={(values: PaymentForm) => makeTransfer(values)}
            {...{ depositAccounts, transferLoading, plaidAccounts }}
          />
        );

      case AddFundsSteps.SUCCESS:
        return (
          <ModalSuccess
            backTo="dashboard"
            closeModal={() => closeModal()}
            onClick={() => setContent(AddFundsSteps.ADD_FUNDS)}
            title={`You’ve transferred ${formatCurrency(
              transferForm.amount,
            )} to ${depositAccount.nickName} ${depositAccount.accountNumber}`}
            textToStart="Make another transfer"
            status={transferForm.status}
            reason={transferForm.reason}
          />
        );

      case AddFundsSteps.LOADING:
        return <LoadingOverlay visible={true} />;

      case AddFundsSteps.ERROR:
        return (
          <ModalError errorMessage={error} onGoBack={() => closeModal()} />
        );
    }
  };

  const closeModal = () => {
    navigate(-2);
  };

  useEffect(() => {
    getAccounts(company.financialInstitutions);
  }, []);

  return (
    <Group className={classes.container} position="apart">
      {linkingAccountLoading && <LoadingOverlay visible={true} />}

      <ConditionalWrapper
        onClose={closeModal}
        content={getContents()}
        closeButton={
          <UnstyledButton onClick={closeModal} className={classes.closeButton}>
            <CloseIconSquareEnds />
          </UnstyledButton>
        }
      />
    </Group>
  );
};

export default AddFunds;

type StylesProps = {
  amount: number;
};

export const useStyles = createStyles((theme, { amount }: StylesProps) => ({
  container: {
    padding: '50px',
    minHeight: '100vh',
    alignItems: 'start',
    backgroundColor: theme.fn.themeColor('neutral', 2),
    width: '100%',
    '@media(max-width: 767px)': {
      padding: 0,
    },
  },
  avatarPlaceholder: {
    color: '#fff',
    fontSize: '20px',
  },
  contentContainer: {
    marginTop: '20px',
    maxWidth: '420px',
    backgroundColor: '#fff',
    borderRadius: theme.defaultRadius,
    border: `1px solid ${theme.fn.themeColor('neutral', 1)}`,
  },
  closeButton: {
    display: 'flex',
    color: theme.fn.themeColor('neutral', 5),
    alignItems: 'center',
    flexDirection: 'column',
    svg: {
      height: '1.125rem',
      width: '1.125rem',
    },
  },
  title: {
    color: '#000',
    fontWeight: 400,
    fontSize: '32px',
    padding: '30px 30px 0px 30px',
  },
  rowContent: {
    gap: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  chevronIcon: {
    transform: 'rotate(-90deg)',
    fill: theme.colors.blackish[2],
  },
  icon: {
    padding: '8px',
    display: 'flex',
    borderRadius: '8px',
    alignItems: 'center',
    backgroundColor: theme.colors['flexbase-rose'],
  },
  infoContent: {
    color: '#000',
    fontWeight: 400,
    fontSize: '24px',
    p: {
      marginTop: 5,
      fontWeight: 400,
      fontSize: '14px',
      color: theme.fn.themeColor('neutral', 6),
    },
  },
  cursor: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  badgeInner: {
    fontWeight: 500,
    fontSize: '14px',
    color: theme.fn.themeColor('neutral', 7),
  },
  badgeRoot: {
    height: 28,
    padding: '6px 14px',
    borderRadius: theme.defaultRadius,
    backgroundColor: theme.fn.themeColor('tertiary', 1),
  },
  label: {
    fontWeight: 500,
    fontSize: '14px',
    marginBottom: '12px',
    color: theme.colors.blackish[3],
  },
  input: {
    height: '42px',
    display: 'flex',
    padding: '12px 16px',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: theme.colors.blackish[3],
    borderRadius: theme.defaultRadius,
    border: `1px solid ${theme.fn.themeColor('neutral', 1)}`,
    p: {
      margin: '0px',
      fontSize: '14px',
    },
    '&:hover': {
      cursor: 'pointer',
      p: {
        textDecoration: 'underline',
      },
    },
  },
  target: {
    borderRadius: theme.defaultRadius,
    border: `1px solid ${theme.fn.themeColor('neutral', 1)}`,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  amountInput: {
    border: 'none',
    marginTop: 20,
    marginBottom: 34,
    fontSize: '48px',
    borderRadius: '0px',
    textAlign: 'center',
    height: 'fit-content',
    fontFamily: 'PP Neue Montreal',
    color: amount === 0 ? '#C5C2BE' : '#000',
    borderBottom: `3px solid ${theme.fn.themeColor(
      'primarySecondarySuccess',
      2,
    )}`,
    '&:focus': {
      borderBottom: `3px solid ${theme.fn.themeColor(
        'primarySecondarySuccess',
        2,
      )}`,
    },
  },
  accountsSection: {
    fontSize: '14px',
    fontWeight: 500,
    margin: '20px 0px',
    color: theme.colors.blackish[3],
  },
  disclaimer: {
    width: 200,
    fontWeight: 400,
    fontSize: '12px',
    color: theme.colors.blackish[3],
  },
  flexContent: {
    gap: 20,
    display: 'flex',
    alignItems: 'center',
  },
  IconSection: {
    padding: '8px',
    display: 'flex',
    borderRadius: '8px',
    alignItems: 'center',
    backgroundColor: theme.fn.themeColor('primarySecondarySuccess', 0),
  },
  accordionRoot: {
    alignItems: 'flex-start',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  accordionChevron: {
    marginTop: 20,
  },
  textInfo: {
    fontSize: 12,
    marginTop: 24,
    fontWeight: 400,
    marginBottom: 10,
    textAlign: 'center',
    color: theme.fn.themeColor('neutral', 7),
  },
  containerMobile: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    padding: '0rem 1.25rem',
    justifyContent: 'space-between',
  },
  boxContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
}));
