import { Avatar, Badge, Box, Flex, Text, Tooltip } from '@mantine/core';
import { useBillPayStyles } from './bills.styles';
import { FlexbaseTable } from '@common/table';
import { useMemo } from 'react';
import { BillpayInvoiceWithPendingPayments } from 'types/bill-pay';
import { useBillpayInvoicesFilters } from './use-billpay-bills-filters';
import { TableColumn } from 'react-data-table-component';
import { getInitialsOfNames } from '@utilities/extensions/object';
import { useMediaQuery } from '@mantine/hooks';
import { getMonthDayYear, sortDateFromSQL } from '@utilities/dates/dates';
import { formatCurrency } from '@utilities/formatters';
import { DateTime } from 'luxon';
import EmptyBillTable from './empty-bill-table';
import { useBillpayInvoicesWithRecipientsAndPayments } from '../use-bill-pay';
import { SkeletonLoading } from '@flexbase-eng/web-components';
import Header from '../header/header';
import { useNavigate } from 'react-router-dom';
import { filterStatus } from './filters/filters';
import { getBillPayStatusDisplay } from './util/status';
import ReviewPayStep from '../create-invoice/steps/review-pay-step/review-pay-step';
import { MdCheck } from 'react-icons/md';
import { isEmpty } from 'underscore';
import { useGetPlatformBusinessAccountsPayable } from '@queries/use-platform-business';
import { useRecoilValue } from 'recoil';
import { ApplicationState } from 'recoil-state/application/product-onboarding';

export const sortInvoiceAmount = (a: string, b: string) => {
  const amountA = parseFloat(a) / 100;
  const amountB = parseFloat(b) / 100;
  return amountB - amountA;
};

const Bills = () => {
  const { classes, theme } = useBillPayStyles();
  const { businessId } = useRecoilValue(ApplicationState);
  const { data, isLoading, isSuccess } =
    useBillpayInvoicesWithRecipientsAndPayments();
  const { data: accountsPayableData } =
    useGetPlatformBusinessAccountsPayable(businessId);
  const { activeFiltersArray } = useBillpayInvoicesFilters();
  const useMobileView = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
  const navigate = useNavigate();

  const getDueInDays = (dueDate: string) => {
    const due = DateTime.fromISO(dueDate);
    const now = DateTime.now();
    const diffDays = due.diff(now, 'days').days;

    if (diffDays > 0) {
      return `${Math.ceil(diffDays)} days`;
    } else if (diffDays === 0) {
      return 'Due today';
    } else {
      return `${Math.abs(Math.floor(diffDays))} days ago`;
    }
  };

  const accountingSyncTooltip = useMemo(() => {
    if (!accountsPayableData) return '';

    const linkedAccount = accountsPayableData?.link?.platform;
    switch (linkedAccount) {
      case 'QUICKBOOKS':
        return 'Synced with QuickBooks';
      case 'XERO':
        return 'Synced with Xero';
      case 'NETSUITE':
        return 'Synced with NetSuite';
      default:
        return '';
    }
  }, [accountsPayableData]);

  const invoices: BillpayInvoiceWithPendingPayments[] = useMemo(() => {
    if (!isSuccess) return [];

    return (data.invoices ?? []).filter((cc) =>
      activeFiltersArray.every((filter) => filter.fn(cc)),
    );
  }, [data, activeFiltersArray]);

  const columns: TableColumn<BillpayInvoiceWithPendingPayments>[] = [
    {
      id: 'accountingSync',
      name: 'Sync',
      cell: (row) =>
        row?.lineItems?.some((li) => !isEmpty(li.accountingInfo)) ? (
          <Tooltip withArrow label={accountingSyncTooltip}>
            <Box>
              <MdCheck size={20} />
            </Box>
          </Tooltip>
        ) : null,
      selector: (invoice) =>
        invoice.lineItems.some((li) => !isEmpty(li.accountingInfo)),
      sortable: true,
      grow: 0,
    },
    {
      id: 'dueDate',
      name: 'Due date',
      selector: (invoice) => invoice.dueDate,
      format: (invoice) => getMonthDayYear(invoice.dueDate),
      sortFunction: (a, b) => sortDateFromSQL(a.dueDate, b.dueDate),
      sortable: true,
    },
    {
      id: 'recipient',
      name: 'Recipient',
      cell: (row) => (
        <Flex align="center" data-tag="allowRowEvents">
          <Avatar data-tag="allowRowEvents" radius="xl" mr={15} w={45} h={45}>
            <Text data-tag="allowRowEvents">
              {getInitialsOfNames(row.recipientName ?? '')}
            </Text>
          </Avatar>
          <Text data-tag="allowRowEvents" sx={{ flex: 1 }}>
            {row.recipientName}
          </Text>
        </Flex>
      ),
      sortable: true,
      selector: (row) => row.recipientName ?? '',
      grow: useMobileView ? 2 : 1,
    },
    {
      id: 'invoiceNumber',
      name: 'Invoice number',
      selector: (invoice) => invoice.invoiceNumber ?? '',
      sortable: true,
    },
    {
      id: 'invoiceAmount',
      name: 'Invoice amount',
      selector: (invoice) => invoice.total,
      format: (invoice) => formatCurrency(invoice.total / 100),
      sortable: true,
      sortFunction: (a, b) =>
        sortInvoiceAmount(a.total.toString(), b.total.toString()),
    },
    {
      id: 'createdAt',
      name: 'Created on',
      selector: (invoice) => invoice.createdAt,
      format: (invoice) => getMonthDayYear(invoice.createdAt),
      sortFunction: (a, b) => sortDateFromSQL(a.createdAt, b.createdAt),
      sortable: true,
    },
    {
      id: 'dueIn',
      name: 'Due in',
      selector: (invoice) => getDueInDays(invoice.dueDate),
      format: (invoice) => getDueInDays(invoice.dueDate),
      sortFunction: (a, b) => sortDateFromSQL(a.dueDate, b.dueDate),
      sortable: true,
    },
    {
      id: 'status',
      name: 'Invoice status',
      cell: (payment) => {
        const { displayStatus, backgroundColor, textColor } =
          getBillPayStatusDisplay(payment.status);
        return (
          <Badge
            styles={{
              root: {
                backgroundColor,
              },
            }}
          >
            <Text color={textColor}>{displayStatus} </Text>
          </Badge>
        );
      },
      selector: (invoice) => invoice.status,
      sortable: true,
    },
  ];

  const handleRowClick = (invoice: BillpayInvoiceWithPendingPayments) => {
    if (invoice.status === 'requested') {
      navigate(`/bill-pay/${invoice.id}/approve`);
      return;
    }
    if (invoice.status === 'drafted') {
      navigate(`/bill-pay/${invoice.id}/edit`);
      return;
    }
    navigate(`/bill-pay/${invoice.id}/edit?step=${ReviewPayStep.stepId}`);
  };

  if (isLoading) {
    return <SkeletonLoading />;
  }

  if (data.invoices && data.invoices.length === 0) {
    return <EmptyBillTable headingText="There are no bills to display" />;
  }

  return (
    <Box className={classes.card} mb="lg">
      <Header
        placeholder="Search bills"
        useFilters={useBillpayInvoicesFilters}
        data={data.invoices}
        filtersData={filterStatus(data.invoices || [])}
        bills={true}
      />
      <FlexbaseTable
        defaultSortFieldId="createdAt"
        columns={columns}
        data={invoices}
        isFetchingData={isLoading}
        onRowClicked={handleRowClick}
      />
    </Box>
  );
};

export default Bills;
