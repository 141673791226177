import { useMemo } from 'react';
import { uniq } from 'underscore';
import { MultiSelect } from '@mantine/core';
import {
  useChargeCardsModalFilters,
  useCreditCardsModalFilters,
} from '../use-credit-cards-filters';
import { CreditCard } from '@services/flexbase/card.model';
import { ActiveFiltersReturnType } from '@common/filter/filters';
import { CardTableRow } from '../../card-table-row.model';

type Props = {
  cards: CreditCard[];
};

type CardholderFilterProps = Props & {
  filterHook: ActiveFiltersReturnType<CardTableRow>;
};

const CardholderFilter = ({ cards, filterHook }: CardholderFilterProps) => {
  const { addFilter, removeFilter, getFilterByKey } = filterHook;

  const cardHolders = useMemo(() => {
    return uniq(cards.map((d) => d.holder));
  }, [cards]);

  return (
    <MultiSelect
      data={cardHolders}
      placeholder="Cardholder name"
      searchable
      nothingFound="Nothing found"
      value={getFilterByKey('cardholder')?.filterValue || []}
      onChange={(val) => {
        if (val.length > 0) {
          addFilter('cardholder', {
            key: 'cardholder',
            filterValue: val,
            fn: (row) => val.includes(row.holder),
            label: `Cardholder: ${val.join(', ')}`,
            showChip: true,
          });
        } else {
          removeFilter('cardholder');
        }
      }}
    />
  );
};

export const CreditCardsCardholderFilter = ({ cards }: Props) => {
  const filterHook = useCreditCardsModalFilters();

  return <CardholderFilter cards={cards} filterHook={filterHook} />;
};

export const ChargeCardsCardholderFilter = ({ cards }: Props) => {
  const filterHook = useChargeCardsModalFilters();

  return <CardholderFilter cards={cards} filterHook={filterHook} />;
};
