import { Avatar, Button } from '@mantine/core';
import { useStyles } from './table.styles';
import { formatInitials } from 'utilities/formatters/format-strings';
import { extractToFrom } from 'utilities/formatters/banking-transactions-utilities';
import { formatCurrency } from 'utilities/formatters';
import { FlexbaseTable } from 'components/table';
import { formatApiStrings } from 'utilities/formatters/format-api-strings';

type Props = {
  openSendPaymentModal: () => void;
};

const PaymentsRequestTable = ({ openSendPaymentModal }: Props) => {
  const { classes } = useStyles();
  const filteredTableData = [
    {
      initials: formatInitials(extractToFrom('Charles Bonito')),
      createdOn: 'Feb 14, 2022',
      contact: 'Charles Bonito',
      amount: 10000,
      status: 'Active',
      account: 'Choice Checking',
    },
    {
      initials: formatInitials(extractToFrom('Charles Bonito')),
      createdOn: 'Feb 14, 2022',
      contact: 'Charles Bonito',
      amount: 10000,
      status: 'Active',
      account: 'Choice Checking',
    },
    {
      initials: formatInitials(extractToFrom('Charles Bonito')),
      createdOn: 'Feb 14, 2022',
      contact: 'Charles Bonito',
      amount: 10000,
      status: 'Active',
      account: 'Choice Checking',
    },
  ];

  const columns = [
    {
      name: 'Created on',
      selector: (row: { createdOn: any }) => row.createdOn,
      sortable: true,
    },
    {
      name: 'Contact',
      cell: (row: { initials: any; contact: any }) => {
        return (
          <div className={classes.contactName}>
            <Avatar radius="md" className={classes.contactIcon}>
              {row.initials || '$'}
            </Avatar>
            {row.contact || ''}
          </div>
        );
      },
      sortable: true,
    },
    {
      name: 'Amount',
      selector: (row: { amount: any }) => formatCurrency(row.amount),
      sortable: true,
    },
    {
      name: 'Status',
      selector: (row: { status: any }) => row.status,
      sortable: true,
    },
    {
      name: 'Account',
      selector: (row: { account: any }) => formatApiStrings(row.account),
      sortable: true,
    },
  ];

  return (
    <div className={classes.container}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingTop: 15,
          paddingBottom: 15,
        }}
      >
        Payment Requests
        <Button
          variant="filled"
          onClick={() => openSendPaymentModal()}
          style={{ width: '15%' }}
        >
          Request
        </Button>
      </div>
      <FlexbaseTable
        columns={columns}
        data={filteredTableData}
        isFetchingData={false}
      />
    </div>
  );
};

export default PaymentsRequestTable;
