import { ActiveFiltersReturnType } from '@common/filter/filters';
import { DateRangeFilter } from '@common/filter/date-range.filter';
import { CreditTransactionsTableRow } from '../../credit-transactions-table-helpers';
import {
  useChargeCardTransactionModalFilters,
  useCreditTransactionModalFilters,
} from '../use-credit-transaction-filters';

type CreditDateRangeFilterProps = {
  filterHook: ActiveFiltersReturnType<CreditTransactionsTableRow>;
};

const CreditDateRangeFilter = ({ filterHook }: CreditDateRangeFilterProps) => {
  return <DateRangeFilter filterHook={filterHook} filterFn={() => true} />;
};

export const CreditTransactionsDateRangeFilter = () => {
  const filterHook = useCreditTransactionModalFilters();

  return <CreditDateRangeFilter filterHook={filterHook} />;
};

export const ChargeCardTransactionsDateRangeFilter = () => {
  const filterHook = useChargeCardTransactionModalFilters();

  return <CreditDateRangeFilter filterHook={filterHook} />;
};
