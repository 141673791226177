import {
  Box,
  Button,
  Flex,
  Group,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import { DownloadIcon, PlusSignIcon, SearchIcon } from 'assets/svg';
import { useMediaQuery } from '@mantine/hooks';
import { isTruthyString } from '@utilities/validators/validate-string';
import { IoFunnelOutline } from 'react-icons/io5';
import { FilterConfig, useFilterModal } from '@common/filter/filter-modal';
import { ActiveFiltersReturnType } from '@common/filter/filters';
import {
  useExampleItemsFilters,
  useExampleItemsModalFilters,
} from '../filters/use-example-filters';
import { FilterChips } from '@common/filter/filter-chips';

type Props<T> = {
  placeholder: string;
  data?: T[];
  filtersData?: FilterConfig[];
  showFilters?: boolean;
  useFilters: () => ActiveFiltersReturnType<T>;
  selectedData?: T[];
  onDownloadCsvClick: () => void;
};

const TableHeader = <T,>({
  placeholder,
  data,
  filtersData = [],
  selectedData,
  onDownloadCsvClick,
}: Props<T>) => {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);

  // hook that returns the current filter state on the table
  const filterHook = useExampleItemsFilters();

  // hook that returns the filter state being selected in the modal
  const modalFilterHook = useExampleItemsModalFilters();

  // utility hook to handle opening, saving, and canceling the filter modal, as well as the active filters
  const {
    activeFiltersArray,
    removeFilter,
    getFilterByKey,
    addFilter,
    removeAllFilters,
    openResponsiveFilterModal,
  } = useFilterModal({ filterHook, modalFilterHook });

  // handle action button click
  const handleActionButton = () => {
    alert('action taken!');
  };

  // handle search input change
  const handleSearchChange = (value: string) => {
    if (!value) {
      removeFilter('search');
      return;
    }
    addFilter('search', {
      key: 'search',
      filterValue: value,
      label: `Includes ${value}`,
      showChip: false,
      fn: (item) => {
        const normalizedFilterText = value.toLowerCase();
        const strValues = Object.values(item).filter(isTruthyString);
        return strValues
          .map((v) => v.toLowerCase())
          .some((v) => v.includes(normalizedFilterText));
      },
    });
  };

  // open the filter modal
  const openModal = () => {
    openResponsiveFilterModal(filtersData);
  };

  return (
    <>
      <Group position="apart" mb="md">
        <Group spacing="md" sx={{ justifyContent: 'space-between' }}>
          <Box
            sx={() => ({
              display: 'flex',
              gap: theme.spacing.md,
              [theme.fn.smallerThan('sm')]: {
                flexDirection: 'column',
                width: '100%',
              },
            })}
          >
            <TextInput
              aria-label={placeholder}
              w={isMobile ? '100%' : 368}
              placeholder={placeholder}
              icon={<SearchIcon width={20} height={20} />}
              value={getFilterByKey('search')?.filterValue || ''}
              onChange={(e) => handleSearchChange(e.target.value)}
            />
            <Flex>
              <Button
                ml={10}
                variant="outline"
                rightIcon={<IoFunnelOutline />}
                onClick={() => openModal()}
                disabled={!data?.length}
              >
                Filters
              </Button>
            </Flex>
          </Box>
        </Group>

        {/* action buttons section */}
        <Group>
          <Button
            type="button"
            variant="outline"
            leftIcon={<DownloadIcon width={12} height={12} />}
            onClick={onDownloadCsvClick}
            disabled={!selectedData?.length}
          >
            CSV
          </Button>
          <Button
            type="button"
            bg={'primarySecondarySuccess.6'}
            leftIcon={<PlusSignIcon width={12} height={12} />}
            onClick={handleActionButton}
          >
            Take Action
          </Button>
        </Group>
      </Group>

      <FilterChips
        filterArray={activeFiltersArray}
        onRemoveFilter={removeFilter}
        onClearFilters={removeAllFilters}
      />
    </>
  );
};

export default TableHeader;
