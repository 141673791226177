import { StatementsType } from 'types/bill-pay';
import { platformClient } from '../services/platform/platform-client';
import { useQuery } from '@tanstack/react-query';

const QUERY_KEY = 'statement';

export const useGetCreditOrBankingStatements = (
  accountId: string,
  type: 'credit' | 'banking',
  depositAccountId?: string,
) => {
  const errorMessage =
    type === 'credit'
      ? 'Unable to retrieve your charge cards statements at this time.'
      : 'Unable to retrieve banking statements for accounts at this time.';

  return useQuery({
    queryKey: [QUERY_KEY, 'list', type, accountId, depositAccountId],
    queryFn: async () => {
      return await platformClient.getListOfStatements(accountId, {
        type,
        ...(depositAccountId && { accountId: depositAccountId }),
      });
    },
    enabled: !!accountId,
    meta: {
      errorMessage,
    },
  });
};

export const useGetStatementsByType = (
  accountId: string,
  type: StatementsType,
) => {
  return useQuery({
    queryKey: [QUERY_KEY, 'statements', accountId, type],
    queryFn: async () => {
      return await platformClient.getStatements(accountId, type);
    },
    meta: {
      errorMessage: 'Could not retrieve statements.',
    },
    enabled: !!accountId && !!type,
  });
};
