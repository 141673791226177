import { atom, DefaultValue, selector } from 'recoil';
import { flexbaseOnboardingClient } from '../../services/flexbase-client';
import { union } from 'underscore';
import {
  formatPhoneForApi,
  formatUSPhoneNumber,
} from '../../utilities/formatters/format-phone-number';
import { _ApplicationState } from './product-onboarding.models';
import { retrieveTokenFromLocalStorage } from '../../utilities/auth/store-token';
import jwt_decode from 'jwt-decode';
import {
  IsSuccessResponse,
  PlatformToken,
} from '../../services/platform/models/authorize.models';
import { platformAuthClient } from '../../services/platform/platform-auth-client';
import { DEFAULT_APP_STATE_VALUES } from 'application/onboarding-defaults';
import { OnboardingCompany, OwnerType } from 'types/onboarding-info';
import { UserRole } from 'types/user-info';

export const RegistrationProductsState = atom<OptedProduct[]>({
  key: 'registration_opted_products',
  default: [],
});

type PromoCode = { code: string; externalId?: string };

export const PromoCodeState = atom<PromoCode>({
  key: 'promo_code_atom',
  default: {
    code: '',
    externalId: '',
  },
});

export const getProductOnboardingStatus = async (
  withFullOwnerData?: boolean,
): Promise<_ApplicationState> => {
  const {
    company,
    user,
    completedOnboarding,
    requiredCredit,
    requiredBanking,
    requiredInternationalPayments,
    requiredTreasury,
    required,
    productStatus,
  } =
    await flexbaseOnboardingClient.getProductOnboardingStatus(
      withFullOwnerData,
    );

  const reqs = (
    company.optedProducts || [
      'CREDIT',
      'BANKING',
      'TREASURY',
      'INTERNATIONAL_PAYMENTS',
    ]
  )
    .filter((p) => !company.excludedProducts.includes(p))
    .reduce<string[]>((allReqs, optedProduct) => {
      switch (optedProduct) {
        case 'TREASURY': {
          return union(allReqs, requiredTreasury);
        }
        case 'CREDIT': {
          return union(
            allReqs,
            productStatus.credit.status === 'unqualified' ? [] : requiredCredit,
          );
        }
        case 'BANKING': {
          return union(allReqs, requiredBanking);
        }
        case 'INTERNATIONAL_PAYMENTS': {
          return union(allReqs, requiredInternationalPayments);
        }
      }
    }, []);

  const additionalRequirements = [];

  // We need this to be able to easily set Platform accountId
  const authenticationToken = retrieveTokenFromLocalStorage();
  const decodedToken = authenticationToken // If this is null then the call getOnboarding call has failed. But we handle the null anyway
    ? jwt_decode<PlatformToken>(authenticationToken?.access_token)
    : null;

  const factorsResponse = await platformAuthClient.getFactors();
  const factors =
    IsSuccessResponse(factorsResponse) && factorsResponse.body
      ? [
          ...factorsResponse.body.knowledge,
          ...factorsResponse.body.possession,
          ...factorsResponse.body.biometric,
        ]
      : null;

  const hasPassword = factors?.some((f) => f.method === 'password') ?? true; // This ?? true may not be true, but I don't want to mistakenly send people to the wrong page

  if (!hasPassword) {
    additionalRequirements.push('user.changePassword');
  }

  const hasNoControlPerson = !company.controlPerson;

  if (hasNoControlPerson) {
    additionalRequirements.push('company.controlPerson');
  }

  const userIsApplicant = user.id === company.createdBy;

  return {
    personId: decodedToken?.personId ?? '',
    businessId: decodedToken?.businessId ?? company.id,
    accountId: decodedToken?.accountId ?? '',
    factors: factors ?? [],
    optedProducts: company.optedProducts,
    excludedProducts: company.excludedProducts,
    company: { ...company },
    user: { ...user },
    requiredCredit: requiredCredit
      .concat(additionalRequirements)
      .filter(
        (r) =>
          userIsApplicant ||
          (!r.startsWith('company') && r !== 'user.plaidConnection'),
      ),
    requiredTreasury: requiredTreasury
      .concat(additionalRequirements)
      .filter(
        (r) =>
          userIsApplicant ||
          (!r.startsWith('company') && r !== 'user.plaidConnection'),
      ),
    requiredBanking: requiredBanking
      .concat(additionalRequirements)
      .filter(
        (r) =>
          userIsApplicant ||
          (!r.startsWith('company') && r !== 'user.plaidConnection'),
      ),
    requiredInternationalPayments: requiredInternationalPayments
      .concat(additionalRequirements)
      .filter(
        (r) =>
          userIsApplicant ||
          (!r.startsWith('company') && r !== 'user.plaidConnection'),
      ),
    requiredProperties: reqs
      .concat(additionalRequirements)
      .filter(
        (r) =>
          userIsApplicant ||
          (!r.startsWith('company') && r !== 'user.plaidConnection'),
      ),
    completedOnboarding: completedOnboarding || '',
    requiredStripeCredit: required.filter(
      (r) =>
        userIsApplicant ||
        (!r.startsWith('company') && r !== 'user.plaidConnection'),
    ),
    productStatus: { ...productStatus },
    userType: user.roles.includes('ADMIN') ? 'admin' : 'other',
    userIsApplicant,
  };
};

export type OptedProduct =
  | 'BANKING'
  | 'CREDIT'
  | 'TREASURY'
  | 'INTERNATIONAL_PAYMENTS';

export const ApplicationState = atom<_ApplicationState>({
  key: 'product_onboarding_state',
  default: {
    company: { ...DEFAULT_APP_STATE_VALUES.company },
    user: { ...DEFAULT_APP_STATE_VALUES.user },
    requiredCredit: [],
    productStatus: { ...DEFAULT_APP_STATE_VALUES.productStatus },
    completedOnboarding: '',
    requiredBanking: [],
    requiredInternationalPayments: [],
    requiredProperties: [],
    requiredStripeCredit: [],
    optedProducts: [],
    excludedProducts: [],
    requiredTreasury: [],
    userType: 'other',
    userIsApplicant: false,
    factors: [],
    personId: '',
    businessId: '',
    accountId: '',
  },
});

// user and company helper/selector reference
export const CompanySelector = selector<OnboardingCompany>({
  key: 'application_company_selector',
  get: ({ get }) => {
    const { company } = get(ApplicationState);
    return company;
  },
});

// if owner list, then find largest %
// if equal %s, default to user that started application
export const MainOwnerSelector = selector<OwnerType>({
  key: 'application_owner_selector',
  get: ({ get }) => {
    const { company } = get(ApplicationState);
    return company.owners.reduce((prev, current) => {
      return prev.ownershipPct! > current.ownershipPct! ? prev : current;
    });
  },
});

export const RolesSelector = selector<UserRole[]>({
  key: 'user_roles',
  get: ({ get }) => {
    const { user } = get(ApplicationState);
    return user.roles;
  },
});

// roles helpers
export const IsAdmin = selector<boolean>({
  key: 'is_admin_selector',
  get: ({ get }) => {
    const { user } = get(ApplicationState);
    return user.roles.includes('ADMIN');
  },
});
export const IsFullAdmin = selector<boolean>({
  key: 'is_admin_full_selector',
  get: ({ get }) => {
    const { user } = get(ApplicationState);
    return user.roles.includes('ADMIN') && user.roles.includes('COMPTROLLER');
  },
});

export const IsComptroller = selector<boolean>({
  key: 'is_comptroller_selector',
  get: ({ get }) => {
    const { user } = get(ApplicationState);
    return user.roles.includes('COMPTROLLER');
  },
});

export const IsEmployee = selector<boolean>({
  key: 'is_employee_selector',
  get: ({ get }) => {
    const { user } = get(ApplicationState);
    return user.roles.includes('EMPLOYEE');
  },
});

export const IsAccountant = selector<boolean>({
  key: 'is_accountant_selector',
  get: ({ get }) => {
    const { user } = get(ApplicationState);
    return user.roles.includes('ACCOUNTANT');
  },
});

export const IsPlaidStale = selector<boolean>({
  key: 'is_plaid_stale_selector',
  get: ({ get }) => {
    const { user, requiredProperties, company, optedProducts } =
      get(ApplicationState);
    return (
      user.roles.includes('PLAID-STALE') ||
      (user.roles.includes('ADMIN') &&
        optedProducts.includes('CREDIT') &&
        (requiredProperties.some(
          (r) =>
            r === 'user.plaidConnection' ||
            r === 'company.financialInstitutions',
        ) ||
          company.financialInstitutions.some((f) => f.unlinked)))
    );
  },
});

export const OnboardingUserPhone = selector<string>({
  key: 'onboarding_user_phone_selector',
  get: ({ get }) => {
    try {
      const unformatted = get(ApplicationState)?.user?.cellPhone || '';

      return formatUSPhoneNumber(unformatted);
    } catch (e) {
      return '';
    }
  },
  set: ({ set }, newValue) => {
    if (newValue instanceof DefaultValue) {
      return newValue;
    }
    set(ApplicationState, (prev) => {
      const formatted = formatPhoneForApi(newValue);
      return {
        ...prev,
        user: { ...prev.user, phone: formatted, cellPhone: formatted },
      };
    });
  },
});

/**
 * This is intended to be used in places where we need to save a user value without saving it to the API
 * or otherwise altering the product onboarding state. Do not rely on this to have data.
 */
export const InProgressUser = atom<{ phone: string }>({
  key: 'in_progress_user',
  default: {
    phone: '',
  },
});

export const ProductOnboardingBtnLoaderState = atom<boolean>({
  key: 'product_onboarding_btn_loader_state',
  default: false,
});

export const ProductNavStack = atom<string[]>({
  key: 'product_nav_stack',
  default: [],
});

export const ProductCurrentStep = atom<string>({
  key: 'product_curr_step',
  default: '',
});
