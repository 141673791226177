import { useQuery } from '@tanstack/react-query';
import { flexbaseOnboardingClient } from '../services/flexbase-client';

const QUERY_KEY = 'companyInfo';

export const UseGetTenant = () => {
  return useQuery({
    queryKey: [QUERY_KEY],
    queryFn: async () => {
      return await flexbaseOnboardingClient.getUserCompany();
    },
    meta: {
      errorMessage: 'Unable to get the company information.',
    },
  });
};
