import { Text, TextInput } from '@mantine/core';
import { useStyles } from './styles';
import { useRecoilState, useRecoilValue } from 'recoil';
import { VerifyIdentityFormState } from '../../onboarding-form.state';
import { validatePersonalTaxId } from '../../../../utilities/validators/validate-tax-id';
import { validateUSBirthDate } from '../../../../utilities/validators/validate-dates';
import { useForm } from '@mantine/form';
import { omit } from 'underscore';
import FlexbaseInput from 'components/input/flexbase-input';
import { useOnboardingStyles } from '../../onboarding.styles';
import { formatPersonalTaxId } from '../../../../utilities/formatters/format-tax-id';
import OnboardingStep from '../../components/onboarding-step';
import { flexbaseOnboardingClient } from '../../../../services/flexbase-client';
import { useState } from 'react';
import {
  formatDateForApi,
  formatDateInput,
} from '../../../../utilities/formatters/format-date-input';
import { ApplicationState } from '../../../../recoil-state/application/product-onboarding';
import TagManager from 'react-gtm-module';
import YellowBanner from '../../components/yellow-banner';
import { useTermsOfService } from '@utilities/custom-hooks/use-has-terms';
import { useApplicationFlowContext } from '../../onboarding-hooks';
import { getVerifyIdentityAccordionListItems } from './get-verify-identity-accordion-list-items';
import { useUpdatePlatformPersonMutation } from '../../../../queries/use-get-me';
import { RFCDate } from '@flexbase-eng/sdk-typescript/types';

export type VerifyIdentityFormValues = {
  birthDate: string;
  taxId: string;
};
const VerifyIdentity = () => {
  const { classes } = useStyles();
  const { classes: onboardingClasses } = useOnboardingStyles();
  const [formState] = useRecoilState(VerifyIdentityFormState); // TODO: Figure out why I removed calls to set this
  const { navigateToNextProductStep, goBack, applyingForProducts } =
    useApplicationFlowContext();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { userIsApplicant, accountId, personId } =
    useRecoilValue(ApplicationState);
  const { mutate: updatePlatformPerson } = useUpdatePlatformPersonMutation();
  const { termsContent: ficoTerms } = useTermsOfService('ficoPull');
  const { termsContent: patriotTerms } = useTermsOfService('patriotAct');
  const accordionList = getVerifyIdentityAccordionListItems(
    userIsApplicant,
    applyingForProducts,
  );

  const verifyIdentityForm = useForm<VerifyIdentityFormValues>({
    initialValues: { ...formState },
    validate: {
      taxId: (val) => {
        // This is a "hack" to ignore the masked value that the API returns
        if (val.includes('*')) {
          return null;
        }
        return validatePersonalTaxId(val) ? null : 'Invalid SSN or ITIN';
      },
      birthDate: (val) =>
        validateUSBirthDate(val)
          ? null
          : 'Must be at least 18 in format MM/DD/YYYY.',
    },
  });

  const setPersonalTaxId = (value: string) =>
    verifyIdentityForm.setFieldValue('taxId', formatPersonalTaxId(value));

  const setDob = (value: string) => {
    verifyIdentityForm.setFieldValue('birthDate', formatDateInput(value));
  };

  const onContinueClick = async () => {
    const formValues = verifyIdentityForm.values;
    const validationResult = verifyIdentityForm.validate();
    if (!validationResult.hasErrors) {
      setLoading(true);
      try {
        const request = {
          birthDate: formatDateForApi(formValues.birthDate),
          taxId: formValues.taxId,
          ficoPullSigned: true,
          patriotActSigned: true,
        };
        await flexbaseOnboardingClient.updateUser({
          ...(formValues.taxId.includes('*')
            ? omit(request, 'taxId')
            : request),
        });
        updatePlatformPerson({
          personId,
          accountId,
          update: {
            taxId: formValues.taxId.includes('*')
              ? undefined
              : formValues.taxId,
            dateOfBirth: new RFCDate(request.birthDate),
          },
        });
        TagManager.dataLayer({
          dataLayer: {
            event: 'verifiedIdentity',
          },
        });
        await navigateToNextProductStep();
      } catch (e) {
        if (
          e?.text &&
          typeof e.text === 'string' &&
          e.text.toLowerCase().includes('already in use')
        ) {
          verifyIdentityForm.setFieldError(
            'taxId',
            'You already have an account with us. Please contact us at support@flex.one',
          );
        } else {
          setErrorMessage(
            'Unable to update identity data. Please check for correctness.',
          );
        }
      } finally {
        setLoading(false);
      }
    }
  };

  const onBackClick = () => {
    goBack();
  };

  const disclosureArray = [...patriotTerms, ...ficoTerms];
  const disclosures = disclosureArray.map((e, i) => (
    <p key={`terms-content-${i}`} style={{ ...(i === 0 && { marginTop: 0 }) }}>
      {e}
    </p>
  ));

  return (
    <div>
      <OnboardingStep
        title="Verify identity"
        subtitle={<>We are required by law to collect this information.</>}
        onBackClick={onBackClick}
        onNextClick={onContinueClick}
        stepId="verify-identity"
        error={errorMessage}
        showContinueSpinner={loading}
        bottomDisclosureText={disclosures}
      >
        <div className={classes.accordionContainer}>
          {accordionList.map((item) => {
            return (
              <div className={classes.container} key={item.label}>
                <Text ff="redaction">{item.description}</Text>
              </div>
            );
          })}
        </div>
        <div className={classes.inputContainer}>
          {!formState.taxId.includes('*') && (
            <FlexbaseInput
              placeholder="Social Security # or ITIN"
              data-sardine-id="input-ssn"
              label="SSN or ITIN"
              classNames={{
                label: onboardingClasses.inputLabel,
                root: onboardingClasses.btnSpacingAddressPage,
              }}
              {...verifyIdentityForm.getInputProps('taxId')}
              onChange={(e) => setPersonalTaxId(e.target.value)}
              id="input-ssn"
            />
          )}
          <TextInput
            classNames={{
              label: onboardingClasses.inputLabel,
              root: onboardingClasses.btnSpacingAddressPage,
            }}
            data-sardine-id="input-birthdate"
            {...verifyIdentityForm.getInputProps('birthDate')}
            label="Birthday"
            placeholder="MM / DD / YYYY"
            onChange={(e) => setDob(e.target.value)}
            id="input-birthdate"
          />
        </div>
        {userIsApplicant && applyingForProducts.includes('CREDIT') && (
          <YellowBanner text="If you currently have a freeze on your credit, you must remove the Equifax freeze before submitting your application. We will not be able to process your application until the Equifax freeze is removed." />
        )}
      </OnboardingStep>
    </div>
  );
};

export default VerifyIdentity;
