import { useStyles } from '../../styles';
import { Text } from '@mantine/core';
import { RedemptionAccountContent } from './redemption-account-content';

export const RedemptionReviewContent = () => {
  const { classes } = useStyles();

  return (
    <div style={{ marginTop: '20px' }}>
      <div className={classes.reviewYellowBox}>
        <Text className={classes.reviewLine1}>Statement Credit</Text>
        <Text className={classes.reviewLine2}>$800</Text>
        <Text className={classes.reviewLine3}>Redeeming 80,000 points</Text>
      </div>
      <Text className={classes.reviewDepositLine}>Deposited into:</Text>
      <RedemptionAccountContent />
    </div>
  );
};
