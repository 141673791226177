import { SkeletonLoading } from '@flexbase-eng/web-components';
import { Avatar, Flex, useMantineTheme, Text } from '@mantine/core';
import { useOutletContext } from 'react-router-dom';
import type { ParsedAccount } from './types';
import { FlexbaseTable } from '@common/table';
import { FlexIconShort, SearchIcon } from 'assets/svg';
import { TableColumn } from 'react-data-table-component';
import { useMediaQuery } from '@mantine/hooks';
import { useState } from 'react';
import { Recipient } from 'types/recipient';
import TabTableLayout from '@common/layouts/tab-table-layout';
import FlexbaseInput from '@common/input/flexbase-input';
import { isTruthyString } from '@utilities/validators/validate-string';
import { AccountDetailCell } from 'areas/payments/components/account-information/account-detail-cell';
import { RoutingNumberCell } from 'areas/payments/components/account-information/routing-number-cell';
import { formatLabel } from 'areas/payments/components/send-payment/international-payments/util';

type AccountInfoContext = {
  domesticAccounts: ParsedAccount[];
  recipient: Recipient;
};

const RecipientAccountInformation = () => {
  const { domesticAccounts } = useOutletContext<AccountInfoContext>();
  const theme = useMantineTheme();
  const useMobileView = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
  const [searchTerm, setSearchTerm] = useState('');
  const handleSearchChange = (value: string) => {
    setSearchTerm(value.toLowerCase().trim());
  };

  const filteredAccounts: ParsedAccount[] = domesticAccounts.filter((account) =>
    Object.values(account)
      .filter(isTruthyString)
      .some((v) => v.toLowerCase().includes(searchTerm)),
  );

  const columns: TableColumn<ParsedAccount>[] = [
    {
      name: 'Account name',
      cell: (row) => (
        <Flex display="flex" align="center">
          <Avatar radius="xl" mr={15} w={45} h={45}>
            <FlexIconShort />
          </Avatar>
          {row.nickName} ••{row.accountNumber?.slice(-4)}
        </Flex>
      ),
      sortable: true,
      selector: (row) => row.nickName ?? '',
    },
    {
      name: 'Method type',
      selector: (row) => row.type,
      format: (row) => formatLabel(row.type),
      sortable: true,
      grow: 0.7,
    },
    {
      name: 'Account type',
      cell: (row) => (row.type === 'ach' ? row.accountType : ''),
      selector: (row) => row.accountType ?? '',
      sortable: true,
      grow: 0.7,
    },
    {
      name: 'Account number',
      cell: (row) => <AccountDetailCell value={row.accountNumber} />,
      grow: 0.7,
    },
    {
      name: 'Routing number',
      cell: (row) => <RoutingNumberCell value={row.routingNumber ?? ''} />,
      selector: (row) => row.routingNumber ?? '',
      sortable: true,
      grow: 0.7,
    },
  ];

  if (!domesticAccounts) {
    return <SkeletonLoading />;
  }

  return (
    <TabTableLayout
      leftHeaderContent={
        <FlexbaseInput
          w={useMobileView ? '100%' : '320px'}
          placeholder="Search accounts"
          onChange={(e) => handleSearchChange(e.target.value)}
          icon={<SearchIcon width={20} height={20} />}
        />
      }
      borderBottomSolid={false}
      rightHeaderContent={undefined}
      table={
        <FlexbaseTable
          columns={columns}
          data={filteredAccounts}
          noDataComponent={<Text mt="md">No accounts have been added.</Text>}
        />
      }
    />
  );
};

export default RecipientAccountInformation;
