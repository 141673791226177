import { Box, Flex, Group, Radio, Text, TextInput } from '@mantine/core';
import { IndividualOrCompany } from './international-payments/util/types';
import { useForm } from '@mantine/form';
import { forwardRef, useImperativeHandle } from 'react';
import { validateRequired } from '@utilities/validators';
import { Recipient } from 'types/recipient';
import PaymentStep from '../payment-step';
import { ParsedAccount } from 'areas/payments/pages/recipient-details/types';

export type RecipientFormRef = {
  submitForm: () => RecipientTypeRequirement | null;
};

export type RecipientTypeRequirement = {
  recipientType: IndividualOrCompany;
  firstName: string;
  lastName: string;
};

type Props = {
  onNextClick: (recipientTypeData: RecipientTypeRequirement) => void;
  onBackClick: () => void;
  setFilter: (value: string) => void;
  recipientName: string;
  selectedRecipient?: Recipient;
  isNewRecipient: boolean;
  setIsNewRecipient: (value: boolean) => void;
  filterAccounts: () => ParsedAccount[];
  isPending: boolean;
};

const RecipientForm = forwardRef<RecipientFormRef, Props>(
  (
    {
      onNextClick,
      onBackClick,
      setFilter,
      recipientName,
      selectedRecipient,
      isNewRecipient,
      setIsNewRecipient,
      filterAccounts,
      isPending,
    },
    ref,
  ) => {
    const filteredAccounts = filterAccounts();
    const hasAtLeastOneFilteredRecipient = filteredAccounts.length > 0;

    const formRecipient = useForm<RecipientTypeRequirement>({
      initialValues: {
        recipientType:
          (selectedRecipient?.type as IndividualOrCompany) ?? 'individual',
        firstName: selectedRecipient?.firstName ?? '',
        lastName: selectedRecipient?.lastName ?? '',
      },
      validate: {
        recipientType: (value: IndividualOrCompany) =>
          validateRequired(value) ? null : 'Recipient type required',
        firstName: (value, formValues) =>
          formValues.recipientType === 'individual' && !validateRequired(value)
            ? 'First name required'
            : null,
        lastName: (value, formValues) =>
          formValues.recipientType === 'individual' && !validateRequired(value)
            ? 'Last name required'
            : null,
      },
    });

    const submit = (): RecipientTypeRequirement | null => {
      const validationResult = formRecipient.validate();
      if (validationResult.hasErrors) {
        return null;
      } else {
        return formRecipient.values;
      }
    };

    useImperativeHandle(ref, () => ({
      submitForm: () => {
        return submit();
      },
    }));

    const handleRecipientTypeOnchange = (value: IndividualOrCompany) => {
      formRecipient.setFieldValue('recipientType', value);
      if (value === 'company') {
        formRecipient.setFieldValue('firstName', '');
        formRecipient.setFieldValue('lastName', '');
      }
    };

    const onNext = async () => {
      const validationResult = formRecipient.validate();
      if (validationResult.hasErrors) {
        return null;
      } else {
        onNextClick(formRecipient.values);
      }
    };

    const onBack = () => {
      if (isNewRecipient && !hasAtLeastOneFilteredRecipient) {
        setIsNewRecipient(false);
        setFilter('active_recipient_method');
      }
      onBackClick();
    };

    return (
      <PaymentStep
        titleText={`Recipient details for ${recipientName}`}
        onNextClick={onNext}
        onBackClick={onBack}
        loading={isPending}
      >
        <Box>
          <Text mt="md" size="sm">
            We need more information about {recipientName}. Make a selection
            below to continue.
          </Text>
          <Radio.Group
            mt="md"
            {...formRecipient.getInputProps('recipientType')}
            onChange={handleRecipientTypeOnchange}
            label="Recipient type"
          >
            <Group mt={12}>
              <Radio
                disabled={!!selectedRecipient?.type}
                value="individual"
                label="Person"
              />
              <Radio
                disabled={!!selectedRecipient?.type}
                value="company"
                label="Business"
              />
            </Group>
          </Radio.Group>

          {formRecipient.values.recipientType === 'individual' && (
            <Flex justify="space-between">
              <TextInput
                mt={10}
                label="First name"
                placeholder="First name"
                {...formRecipient.getInputProps('firstName')}
                disabled={!!selectedRecipient?.firstName}
              />

              <TextInput
                mt={10}
                label="Last name"
                placeholder="Last name"
                {...formRecipient.getInputProps('lastName')}
                disabled={!!selectedRecipient?.lastName}
              />
            </Flex>
          )}
        </Box>
      </PaymentStep>
    );
  },
);

RecipientForm.displayName = 'RecipientForm';

export default RecipientForm;
