export const formatApiStrings = (string: string) => {
  // In some cases the API returns nothing for certain fields..
  if (!string) {
    return 'N/A';
  }

  let capitalized = '';

  if (string === 'ach') {
    return 'ACH';
  }

  if (string === 'inDispute') {
    capitalized = 'In dispute';
    return capitalized;
  }
  if (string === 'auto') {
    return 'Auto Pay';
  }
  if (string === 'manual') {
    return 'One Time';
  }
  capitalized = string.charAt(0).toUpperCase() + string.slice(1);
  return capitalized;
};
