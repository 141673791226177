import { MultiSelect } from '@mantine/core';
import { useMemo } from 'react';
import { uniq } from 'underscore';
import { useBankingAccountsFiltersModal } from '../use-banking-filters';
import { useGetDepositAccounts } from '../../../../queries/use-deposit-accounts';

export const BankingAccountsNameFilter = () => {
  const { data } = useGetDepositAccounts();

  const account = useMemo(() => {
    if (data) {
      return uniq(data.accounts.map((t) => t.nickName).filter((t) => !!t));
    }
    return [];
  }, [data]);

  const { addFilter, removeFilter, getFilterByKey } =
    useBankingAccountsFiltersModal();

  return (
    <MultiSelect
      data={account}
      placeholder="Account"
      searchable
      nothingFound="Nothing found"
      value={getFilterByKey('nickName')?.filterValue || []}
      onChange={(val) => {
        if (val.length > 0) {
          addFilter('nickName', {
            key: 'nickName',
            filterValue: val,
            fn: (row) => val.includes(row.nickName),
            label: `Account: ${val.join(', ')}`,
            showChip: true,
          });
        } else {
          removeFilter('nickName');
        }
      }}
    />
  );
};
