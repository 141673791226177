import LimitTile from 'areas/spend-plans/components/spend-plan-tiles/limit-tile/limit-tile';
import { Box, Grid, useMantineTheme } from '@mantine/core';
import RecentActivity from '../components/spend-plan-tiles/recent-activity/recent-activity';
import { useMatchMedia } from '../../../utilities/url/window-helpers';
import UsageTile from '../components/spend-plan-tiles/usage-tile';
import { useSpendPlanDetailsContext } from '../details/spend-plan-details.context';
import SkeletonLoading from '../../../components/loading/skeleton-loading';

type Props = {
  spendPlanId: string;
};

const SpendPlanTiles = ({ spendPlanId }: Props) => {
  const { isAdmin, isManager, plan } = useSpendPlanDetailsContext();
  const theme = useMantineTheme();
  const isMobileView = useMatchMedia(`(max-width: ${theme.breakpoints.md})`);
  const canManagePlan = isAdmin || isManager;

  let formattedFrequency = '';

  switch (plan?.frequency) {
    case 'onetime':
      formattedFrequency = 'One time';
      break;
    case 'daily':
      formattedFrequency = 'Today';
      break;
    case 'monthly':
      formattedFrequency = 'This month';
      break;
      break;
    case 'annually':
      formattedFrequency = 'This year';
      break;
    default:
      formattedFrequency = 'This month';
      break;
  }

  return (
    <Box>
      {plan ? (
        <Grid mb={20} align="stretch">
          <Grid.Col span={isMobileView ? 12 : canManagePlan ? 4 : 6}>
            <LimitTile
              plan={plan}
              spendPlanId={spendPlanId}
              frequency={formattedFrequency}
              isManaged={canManagePlan}
            />
          </Grid.Col>
          <Grid.Col span={isMobileView ? 12 : canManagePlan ? 4 : 6}>
            <UsageTile
              frequency={formattedFrequency}
              limit={plan?.limit || 0}
              spendPlanId={spendPlanId}
            />
          </Grid.Col>
          {canManagePlan && (
            <Grid.Col span={isMobileView ? 12 : 4}>
              <RecentActivity spendPlan={plan} />
            </Grid.Col>
          )}
        </Grid>
      ) : (
        <SkeletonLoading />
      )}
    </Box>
  );
};

export default SpendPlanTiles;
