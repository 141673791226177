import { useMagicLinkContext } from './use-magic-link';
import { LoadingOverlay } from '@mantine/core';
import { useEffect } from 'react';
import { platformAuthClient } from '../../services/platform/platform-auth-client';
import { IsSuccessResponse } from '../../services/platform/models/authorize.models';
import { useNavigate } from 'react-router-dom';
import { useHandleLoginSuccess } from './use-handle-login-success';

export const LinkLogin = () => {
  const { token } = useMagicLinkContext();
  const navigate = useNavigate();
  const { handleLoginSuccess } = useHandleLoginSuccess();

  const loginWithLink = async () => {
    const result = await platformAuthClient.requestTokenByMagicLink(token);
    if (!IsSuccessResponse(result) || !result.body) {
      navigate('/link/error?type=invite');
    } else {
      await handleLoginSuccess(result.body, {
        onError: () => navigate('/link/error?type=invite'),
      });
    }
  };

  useEffect(() => {
    loginWithLink();
  }, []);

  return <LoadingOverlay visible={true} />;
};
