import { DateTime, Interval } from 'luxon';

import { DateRangeFilter } from '@common/filter/date-range.filter';
import { useActionRequiredModalFilters } from '../use-action-required-filters';

export const PaymentCreateAtFilter = () => {
  const paymentCreateOnFilters = useActionRequiredModalFilters();

  return (
    <DateRangeFilter
      label="Created on"
      filterHook={paymentCreateOnFilters}
      filterFn={(startDate, endDate, item) => {
        const dateRange = Interval.fromDateTimes(startDate, endDate);
        return dateRange.contains(DateTime.fromSQL(item.createdAt));
      }}
    />
  );
};
