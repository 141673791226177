import {
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { AvailableCurrencies } from '../areas/payments/components/send-payment/international-payments/util/types';
import { flexbaseBankingClient } from '../services/flexbase-client';
import { IntlPayment } from '../services/flexbase/banking.model';
import { ApplicationState } from 'recoil-state/application/product-onboarding';
import { useRecoilValue } from 'recoil';

const QUERY_KEY = 'internationalPaymentsApplication';
const CURRENCY_CONVERSION_RATE_QUERY_KEY = 'currencyConversionRate';
const INTL_PAYMENTS = 'internationalPayments';

const mutationFn = async () => {
  return await flexbaseBankingClient.createInternationalPaymentsApplication();
};

export const useCreateInternationalPaymentsApplication = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
    },
  });
};

export type CurrencyConversionRateParams = {
  toCurrency: AvailableCurrencies;
  amount?: number;
};

export const fetchConversionRate = async ({
  toCurrency,
  amount,
}: CurrencyConversionRateParams) => {
  // handle the base case without an API call
  if (toCurrency === 'USD') {
    return {
      rate: 1,
      amount,
    };
  }
  return await flexbaseBankingClient.getConversionRate(toCurrency, amount);
};

export const useGetCurrencyConversionRate = ({
  toCurrency,
  amount,
}: CurrencyConversionRateParams) => {
  return useQuery({
    enabled: !!toCurrency || !!amount,
    queryKey: [CURRENCY_CONVERSION_RATE_QUERY_KEY, toCurrency, amount],
    queryFn: async () =>
      fetchConversionRate({
        toCurrency,
        amount,
      }),
    meta: {
      errorMessage: 'Unable to get currency conversion information',
    },
  });
};

export const useInternationalPaymentRates = (
  intlPaymentsMapped: (IntlPayment & { currency: AvailableCurrencies })[],
) => {
  const queryResults = useQueries({
    queries: intlPaymentsMapped.map((payment) => ({
      queryKey: ['currency', payment.amount, payment.currency],
      queryFn: async () =>
        fetchConversionRate({
          toCurrency: payment.currency,
          amount: Number(payment.amount),
        }),
      staleTime: 1000 * 60 * 5,
    })),
  });

  const intlPayments = intlPaymentsMapped.map((payment, index) => {
    const cachedPayment = queryResults[index].data;
    return {
      ...payment,
      currencyRateAmount: {
        rate: cachedPayment?.rate || 1,
        amount: cachedPayment?.amount || payment.amount,
      },
    };
  });

  return intlPayments;
};

export const useGetInternationalPayments = () => {
  const { optedProducts } = useRecoilValue(ApplicationState);

  return useQuery({
    queryKey: [INTL_PAYMENTS],
    queryFn: async () => {
      return await flexbaseBankingClient.getInternationalPaymens();
    },
    meta: {
      errorMessage: 'Unable to get international payments information',
    },
    enabled: !!optedProducts.includes('INTERNATIONAL_PAYMENTS'),
  });
};
