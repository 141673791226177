import OnboardingStep from '../../components/onboarding-step';
import { useCallback, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { ApplicationState } from '../../../../recoil-state/application/product-onboarding';
import FlexbaseSelect from '../../../../components/select/flexbase-select';
import { useOnboardingStyles } from '../../onboarding.styles';
import { useForm } from '@mantine/form';
import { validateRequired } from '../../../../utilities/validators/validate-required';
import {
  Box,
  Group,
  Loader,
  Text,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import BeneficialOwnerForm from './beneficial-owner-form';
import {
  CompanyOwner,
  IsFullCompanyOwner,
} from '../../../../types/onboarding-info';
import { flexbaseOnboardingClient } from '../../../../services/flexbase-client';
import AdditionalOwnerCard from './additional-owner-card';
import TagManager from 'react-gtm-module';
import { useMediaQuery } from '@mantine/hooks';
import { ga4Helpers } from '../../../../services/ga4/helpers';
import HiddenInput from './hidden-input';
import YellowBanner from '../../components/yellow-banner';
import { useApplicationFlowContext } from '../../onboarding-hooks';
import { useGetMe } from '../../../../queries/use-get-me';
import {
  useCreatePlatformBusinessOwnerMutation,
  useDeactivePlatformBusinessOwnerMutation,
} from '../../../../queries/use-platform-business';

const BeneficialOwnersNew = () => {
  const theme = useMantineTheme();
  const { classes, cx } = useOnboardingStyles();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [ownersLoading, setOwnersLoading] = useState(false);
  const useMobileView = useMediaQuery('(max-width: 767px)');
  const [isGtagReady, setIsGtagReady] = useState(false); // Ensure gtag is ready before requesting session id.
  const { goBack, refreshProductOnboardingStatus, navigateToNextProductStep } =
    useApplicationFlowContext();
  const status = useRecoilValue(ApplicationState);
  const { data: person } = useGetMe();
  const { mutate: createPlatformBusinessOwner } =
    useCreatePlatformBusinessOwnerMutation();
  const { mutate: deletePlatformBusinessOwner } =
    useDeactivePlatformBusinessOwnerMutation();
  const [additionalOwners, setAdditionalOwners] = useState<CompanyOwner[]>([]);
  const [totalOwnershipPercent, setTotalOwnershipPercent] = useState(
    status.company.owners?.reduce<number>(
      (previousValue, currentValue) =>
        previousValue + (currentValue.ownershipPct ?? 0),
      0,
    ) ?? 0,
  );
  const isSoleProp = status.company.legalStructure === 'S-Prop';
  const isAdmin = status.user.roles.includes('ADMIN');

  const getExistingOwnershipPct = useCallback(() => {
    const userOwnerData = status.company.owners.find(
      (o) => o.id === status.user.id,
    );
    if (userOwnerData?.ownershipPct) {
      return userOwnerData.ownershipPct + '';
    }
    if (isSoleProp) {
      return '100';
    }
    return '';
  }, [status]);

  const form = useForm<{
    ownershipPercent: string;
    userIsOwner: string;
    session_id: string;
    client_id: string;
  }>({
    initialValues: {
      ownershipPercent: getExistingOwnershipPct(),
      userIsOwner:
        status.company.owners.some((o) => o.id === status.user.id) || isSoleProp
          ? 'yes'
          : '',
      session_id: '',
      client_id: '',
    },
    validate: {
      ownershipPercent: (value, formValues) => {
        if (formValues.userIsOwner === 'yes') {
          const asNum = Number(value);
          return !asNum || asNum < 25 ? 'Must be 25% or greater' : null;
        }
        return null;
      },

      userIsOwner: (value) => {
        if (isSoleProp || !isAdmin) {
          return null;
        }
        validateRequired(value) ? null : 'Select an ownership option';
      },
    },
  });

  const getOwnerData = async () => {
    setOwnersLoading(true);
    try {
      const fullStatus = await refreshProductOnboardingStatus(true);
      setAdditionalOwners([]); // Reset the owner array since we can't map, see below.
      // Can't just map it because typescript. Would help if the gd model didn't change depending on query params
      fullStatus.company.owners
        .filter((o) => o.id !== fullStatus.user.id)
        .forEach((o) => {
          if (IsFullCompanyOwner(o)) {
            setAdditionalOwners((prev) => [...prev, o]);
          }
        });
    } catch (e) {
      setError('An error occurred while refreshing owner data.');
    } finally {
      setOwnersLoading(false);
    }
  };

  useEffect(() => {
    // Refresh the status to grab full owner detail, but the only portion of the UI this affects is addt owner cards.
    getOwnerData();
  }, []);

  useEffect(() => {
    let totalOwnership = additionalOwners.reduce<number>((prev, curr) => {
      return prev + curr.ownershipPct;
    }, 0);

    totalOwnership += Number(form.values.ownershipPercent);

    setTotalOwnershipPercent(totalOwnership);
  }, [additionalOwners, form.values.ownershipPercent]);

  // Set gtag ready flag.
  useEffect(() => {
    const checkGtag = async () => {
      try {
        await ga4Helpers.getTagReady(); // Wait for gtag to be ready
        setIsGtagReady(true); // Set gtag ready flag
      } catch (err) {
        console.error('Error getting gtag ready', err);
      }
    };

    checkGtag();
  }, []);

  const deleteOwnerCall = async (ownerId: string) => {
    const ownersMinusOwner = status.company.owners
      .filter((o) => o.id !== ownerId)
      .map((o) => ({
        ownershipPct: o.ownershipPct,
        id: o.id,
      }));

    // The API normalization functions in createOrUpdateCompany are removing the empty array if there are no owners.
    // So using direct client access.
    await flexbaseOnboardingClient.updateCompany({
      id: status.company.id,
      owners: ownersMinusOwner,
    });

    if (person?.accountId) {
      deletePlatformBusinessOwner({
        accountId: person.accountId,
        businessId: status.company.id,
        personId: ownerId,
      });
    }
  };

  const onContinueClick = async () => {
    const validationResult = form.validate();
    if (!validationResult.hasErrors) {
      // Check to make sure total ownership isn't above 100%
      if (totalOwnershipPercent > 100) {
        form.setFieldError(
          'ownershipPercent',
          `Total ownership % including additional owners cannot exceed 100%. (Currently ${totalOwnershipPercent}%)`,
        );
      } else {
        // I am always very wary of parallel requests to the API operating on the same data.
        setLoading(true);
        setError('');

        try {
          await flexbaseOnboardingClient.updateUser({
            tracking: {
              timestamp_micros: Math.floor(Date.now() / 1000),
              client_id: form.values.client_id,
              session_id: form.values.session_id,
            },
          });
          if (form.values.userIsOwner === 'yes') {
            const ownershipPctNum = Number(form.values.ownershipPercent);
            // Currently addOwner is bugged for existing users, so it needs to be directly added to the company
            await flexbaseOnboardingClient.updateCompany({
              id: status.company.id,
              owners: [
                ...status.company.owners,
                {
                  id: status.user.id,
                  ownershipPct: ownershipPctNum,
                },
              ],
            });
            if (person?.accountId) {
              createPlatformBusinessOwner({
                accountId: person.accountId,
                businessId: status.company.id,
                personId: status.user.id,
                percentage: ownershipPctNum,
              });
            }
          } else {
            const userIsSavedAsOwner = status.company.owners.some(
              (o) => o.id === status.user.id,
            );
            if (userIsSavedAsOwner && !additionalOwners.length) {
              setError(
                'You must have at least one beneficial owner to continue',
              );
              return;
            } else if (userIsSavedAsOwner) {
              await flexbaseOnboardingClient.updateCompany({
                id: status.company.id,
                owners: additionalOwners.map((o) => ({
                  id: o.id,
                  ownershipPct: o.ownershipPct,
                })),
              });
            }
          }
          TagManager.dataLayer({
            dataLayer: {
              event: 'businessOwnersSubmitted',
            },
          });
          navigateToNextProductStep();
        } catch (e) {
          setError('Unable to add personal ownership data');
        } finally {
          setLoading(false);
        }
      }
    }
  };

  const onBackClick = async () => {
    goBack();
  };

  const setOwnershipPercent = (value: string) => {
    if (!value || Number(value)) {
      form.setFieldValue('ownershipPercent', value);
    }
  };

  const onModalSuccess = () => {
    getOwnerData();
  };

  const deleteOwner = async (owner: CompanyOwner) => {
    setOwnersLoading(true);
    try {
      await deleteOwnerCall(owner.id);
      await getOwnerData();
    } catch (e) {
      setError(`Could not delete owner ${owner.firstName} ${owner.lastName}`);
    } finally {
      setOwnersLoading(false);
    }
  };

  return (
    <OnboardingStep
      title={useMobileView ? 'Beneficial owners' : 'Additional company owners'}
      subtitle="Before we can process your application, we need to verify the identity of all owners who own 25% or more in the company."
      stepId="personal-ownership"
      error={error}
      onNextClick={onContinueClick}
      onBackClick={onBackClick}
      showContinueSpinner={loading}
    >
      <YellowBanner
        text={
          <span>
            After submitting the application, additional owners will be invited
            to verify their identity. All owners must complete this process
            before the application is processed. Once all owners are complete,
            the application will be processed in 1-3 business days.
          </span>
        }
      />
      <Group grow>
        <FlexbaseSelect
          classNames={{
            label: classes.inputLabel,
            root: classes.btnSpacingAddressPage,
          }}
          disabled={isSoleProp || !isAdmin}
          label={`Do you own more than 25%?`}
          placeholder="Select one"
          data={[
            { value: 'yes', label: 'Yes' },
            { value: 'no', label: 'No' },
          ]}
          {...form.getInputProps('userIsOwner')}
          onChange={(value: string) => {
            if (value === 'no') {
              form.setFieldValue('ownershipPercent', '');
            }
            form.setFieldValue('userIsOwner', value);
          }}
          data-sardine-id="input-select-user-is-owner"
          id="input-select-user-is-owner"
        />
      </Group>

      {form.values.userIsOwner === 'yes' && (
        <TextInput
          mt="md"
          classNames={{ label: classes.inputLabel }}
          disabled={isSoleProp || !isAdmin}
          label="Ownership percentage"
          placeholder="What % of the company you own (eg 29)"
          {...form.getInputProps('ownershipPercent')}
          onChange={(e) => setOwnershipPercent(e.target.value)}
          data-sardine-id="input-user-ownership-percent"
          id="input-user-ownership-percent"
          rightSection={
            <Text sx={(t) => ({ color: t.colors.blackish[0] })}>%</Text>
          }
        />
      )}
      <div className={classes.dividerLine} />

      {isAdmin &&
        !isSoleProp &&
        (ownersLoading ? (
          <Box
            mt="lg"
            className={cx(classes.bottomSpacing, classes.centerFlex)}
          >
            <Loader color={theme.fn.primaryColor()} size="sm" />
          </Box>
        ) : (
          <>
            {additionalOwners.length > 0 && (
              <div className={classes.containerCardOwners}>
                <Text mb="md" ff="Redaction" size={20}>
                  Beneficial Owners
                </Text>
                {additionalOwners.map((o) => (
                  <AdditionalOwnerCard
                    key={o.id}
                    onDeleteClick={() => deleteOwner(o)}
                    owner={o}
                  />
                ))}
              </div>
            )}
            {totalOwnershipPercent < 100 && person?.accountId && (
              <BeneficialOwnerForm
                onSuccess={onModalSuccess}
                totalOwnershipPercent={totalOwnershipPercent}
                platformAccountId={person.accountId}
              />
            )}
          </>
        ))}
      {isGtagReady && (
        <HiddenInput
          {...form.getInputProps('sessionId')}
          data-id="input-session-id"
          data-sardine-id="input-session-id"
          name="session_id"
          value={ga4Helpers.gtagGet('session_id')}
          onDataResolved={(name, resolvedValue) => {
            form.setFieldValue(name, resolvedValue);
          }}
        />
      )}
      {isGtagReady && (
        <HiddenInput
          {...form.getInputProps('clientId')}
          data-id="input-client-id"
          data-sardine-id="input-client-id"
          name="client_id"
          value={ga4Helpers.gtagGet('client_id')}
          onDataResolved={(name, resolvedValue) => {
            form.setFieldValue(name, resolvedValue);
          }}
        />
      )}
      <div className={cx(classes.flavorText, classes.topSpacing)}>
        By providing the information above, I hereby certify, to the best of my
        knowledge, that the information provided above is complete and correct.
      </div>
    </OnboardingStep>
  );
};

export default BeneficialOwnersNew;
