import { DateTime } from 'luxon';
import { User } from '@queries/use-users';
import { formatStatus } from '../payments-table/utils';
import {
  MoneyMovement,
  DepositAccount,
  IntlPayment,
  MoneyMovementStatus,
} from '@services/flexbase/banking.model';
import {
  formatDepositAccountName,
  formatDepositAccountDetails,
} from 'utilities/formatters/format-strings';
import { AvailableCurrencies } from '../send-payment/international-payments/util/types';
import { useRecoilValue } from 'recoil';
import { UserIdState } from 'areas/onboarding/onboarding-form.state';
import { parseTransactionDisplayValueByType } from '@utilities/formatters/banking-transactions-utilities';
import { sortDate } from '@utilities/dates/dates';

export type TablePayment = {
  id: string;
  type: string;
  notes: string;
  amount: string;
  status: string;
  userId: string;
  recipient: string;
  createdAt: string;
  direction: string;
  requestedBy?: string;
  description: string;
  accountName: string;
  approvedBy?: string;
  scheduledFor?: string;
  dateSubmitted?: string;
  fxAmount?: string | null;
  currency?: AvailableCurrencies;
  exactStatus: MoneyMovementStatus;
  estimatedAmount?: string | number;
  invoiceNumber?: string;
  vendor?: string;
  byUser?: string;
  expectedCompletionDate?: string;
};

const parsePaymentStatus = (status: string, isOwnPayment: boolean) => {
  if (status === 'Canceled') {
    return isOwnPayment ? status : 'Denied';
  }
  return status;
};

export type IntlPaymentInfo = {
  currency: AvailableCurrencies;
  currencyRateAmount: {
    rate: number;
    amount: number | string;
  };
  estimatedAmount?: number | string;
} & IntlPayment;

type RowsProps = {
  users: User[];
  payments: MoneyMovement[];
  intlPayments: IntlPaymentInfo[];
  depositAccounts: DepositAccount[];
  accountId?: string;
};

type RowsBillpayProps = {
  users: User[];
  payments: (MoneyMovement & { invoiceNumber?: string })[];
  depositAccounts: DepositAccount[];
};

const getPayAmount = (payAmount?: string, intlPaymentAmount?: string) => {
  if (payAmount) {
    return payAmount.includes('$') ? payAmount : `$${payAmount}`;
  }
  if (!payAmount && !intlPaymentAmount) {
    return 'pending';
  }
  return `$${intlPaymentAmount}`;
};

export const generateRows = ({
  users,
  payments,
  intlPayments,
  depositAccounts,
  accountId,
}: RowsProps) => {
  const userId = useRecoilValue(UserIdState);

  return payments
    .sort((a, b) => sortDate(a, b, false))
    .map((payment) => {
      const recipient =
        payment.payDirection === 'Debit'
          ? formatDepositAccountName(depositAccounts, payment.depositId)
          : payment.payCtrParty;

      const accountName =
        payment.payDirection === 'Debit'
          ? payment.payCtrParty
          : formatDepositAccountDetails(depositAccounts, payment.depositId);

      const requestedBy = users.find((user) => user.id === payment.userId);

      const intlPayment = intlPayments.find(
        (intlP) => intlP.id === payment.internationalPaymentId,
      );

      const intlPaymentStatus = intlPayment?.status;
      const isIntlPaymentRejected = ['failed', 'rejected'].includes(
        intlPaymentStatus || '',
      );

      const paymentStatus =
        payment.internationalPaymentId && payment.status === 'Sent'
          ? (intlPaymentStatus ?? 'queued')
          : payment.status;
      const formattedStatus = isIntlPaymentRejected
        ? 'Failed'
        : formatStatus(paymentStatus);
      const parsedStatus = parsePaymentStatus(
        formattedStatus,
        payment.byUser === userId,
      );

      const payAmount = getPayAmount(payment.payAmount, intlPayment?.amount);
      const rate = intlPayment?.currencyRateAmount?.rate || null;

      let estimatedAmount: number | string = '';

      const isValidAmount =
        intlPayment?.amount && !isNaN(parseFloat(intlPayment.amount));
      const isValidRate = rate && !isNaN(rate);
      if (isValidAmount && isValidRate) {
        estimatedAmount = parseFloat(intlPayment?.amount) / rate;
      }

      if (accountId && payment.depositId !== accountId) {
        return null;
      }

      const parsedPaymentType = parseTransactionDisplayValueByType(
        payment.internationalPaymentId
          ? 'outgoingACHInternationalWire'
          : payment.type,
        payment.payDirection,
      );

      return {
        dateSubmitted: DateTime.fromSQL(payment.createdAt).toFormat(
          'LLL d, yyyy',
        ),
        amount: payAmount,
        fxAmount: intlPayment?.amount || null,
        status: parsedStatus,
        datePosted: payment.asOf || '',
        createdAt: payment.createdAt || '',
        origin: payment.type || '',
        id: payment.id || '',
        description: payment.payDescription || '',
        direction: payment.payDirection || '',
        notes: payment.notes || '',
        recipient,
        userId: payment.userId,
        approvedBy: payment.approvedBy,
        exactStatus: payment.status,
        intlPaymentStatus: intlPaymentStatus || null,
        type: parsedPaymentType,
        accountName,
        requestedBy: `${requestedBy?.firstName} ${requestedBy?.lastName}` || '',
        scheduledFor: payment.scheduledFor,
        internationalPaymentId: payment.internationalPaymentId,
        currency: intlPayment?.currency,
        rate,
        estimatedAmount: estimatedAmount
          ? `$${parseFloat(estimatedAmount.toString()).toFixed(2)}`
          : '',
      };
    })
    .filter((payment) => payment !== null) as TablePayment[];
};

export const mapPaymentToTablePayment = (
  payment: MoneyMovement & {
    invoiceNumber?: string;
  },
  depositAccount: DepositAccount,
  requestedBy: User,
) => {
  const recipient =
    payment.payDirection === 'Debit'
      ? formatDepositAccountName(depositAccount, payment.depositId)
      : payment.payCtrParty;

  const accountName =
    payment.payDirection === 'Debit'
      ? payment.payCtrParty
      : formatDepositAccountDetails(depositAccount, payment.depositId);

  const payAmount = getPayAmount(payment.payAmount);

  const parsedPaymentType = parseTransactionDisplayValueByType(
    payment.type,
    payment.payDirection,
  );

  return {
    dateSubmitted: DateTime.fromSQL(payment.createdAt).toFormat('LLL d, yyyy'),
    amount: payAmount,
    status: formatStatus(payment.status),
    datePosted: payment.asOf || '',
    createdAt: payment.createdAt || '',
    origin: payment.type || '',
    id: payment.id || '',
    description: payment.payDescription || '',
    direction: payment.payDirection || '',
    notes: payment.notes || '',
    recipient,
    userId: payment.userId,
    approvedBy: payment.approvedBy,
    exactStatus: payment.status,
    type: parsedPaymentType,
    accountName,
    requestedBy: `${requestedBy?.firstName} ${requestedBy?.lastName}` || '',
    scheduledFor: payment.scheduledFor,
    internationalPaymentId: payment.internationalPaymentId,
    invoiceNumber: payment.invoiceNumber,
    expectedCompletionDate: payment.expectedCompletionDate,
    vendor: payment.payCtrParty,
  };
};

export const generateBillPaymentRows = ({
  users,
  payments,
  depositAccounts,
}: RowsBillpayProps) => {
  return payments
    .sort((a, b) => sortDate(a, b, false))
    .map((payment) => {
      const requestedBy = users.find(
        (user) => user.id === payment.userId,
      ) as User;
      const depositAccount = depositAccounts.find(
        (acc) => acc.id === payment.depositId,
      ) as DepositAccount;
      return mapPaymentToTablePayment(payment, depositAccount, requestedBy);
    })
    .filter((payment) => payment !== null) as TablePayment[];
};
