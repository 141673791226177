import { useQuery } from '@tanstack/react-query';
import { flexbaseOnboardingClient } from '../services/flexbase-client';

const QUERY_KEY = 'creditPayments';

export const useCreditPayments = (
  paymentScope: 'billpay' | 'banking' | 'credit',
) => {
  return useQuery({
    queryKey: [QUERY_KEY, 'list', paymentScope],
    queryFn: async () => {
      return await flexbaseOnboardingClient.getCreditPayments(paymentScope);
    },
    meta: {
      errorMessage: `Could not retrieve ${paymentScope} payments at this time.`,
    },
  });
};

export const useGetCreditPayment = (id: string) => {
  return useQuery({
    queryKey: [QUERY_KEY, id],
    queryFn: async () => {
      return await flexbaseOnboardingClient.getCreditPayment(id);
    },
    meta: {
      errorMessage: `Could not retrieve the payment info at this time.`,
    },
    enabled: !!id,
  });
};

export const useGetCardPayment = (id: string) => {
  return useQuery({
    queryKey: [QUERY_KEY, 'card-payment', id],
    queryFn: async () => {
      return await flexbaseOnboardingClient.getPaymentById(id);
    },
    meta: {
      errorMessage: `Could not retrieve the credit card payment info at this time.`,
    },
    enabled: !!id,
  });
};
