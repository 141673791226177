import { AuthenticationTokenModel } from '../../services/platform/models/authorize.models';

import {
  KEY_FULL_TOKEN_STORAGE,
  KEY_TOKEN_STORAGE,
} from 'providers/auth.provider';

export function storeToken(fullToken: AuthenticationTokenModel) {
  try {
    localStorage.setItem(KEY_FULL_TOKEN_STORAGE, JSON.stringify(fullToken));
    localStorage.setItem(KEY_TOKEN_STORAGE, fullToken.access_token);
  } catch {
    console.error('An error occurred while decoding auth token');
  }
}

export function retrieveTokenFromLocalStorage(): AuthenticationTokenModel | null {
  try {
    const token = localStorage.getItem(KEY_FULL_TOKEN_STORAGE);
    return token ? JSON.parse(token) : null;
  } catch {
    console.error('Unable to retrive token from local storage');
    return null;
  }
}
