import {
  Alert,
  Button,
  Container,
  Text,
  Tooltip,
  createStyles,
} from '@mantine/core';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { formatCurrency } from 'utilities/formatters';
import { BiCopy } from 'react-icons/bi';
import { FaFileDownload } from 'react-icons/fa';
import { useClipboard } from '@mantine/hooks';

type Props = {
  createAnotherPaymentLink: () => void;
};

const CopyLink = ({ createAnotherPaymentLink }: Props) => {
  const { classes } = useStyles();
  const clipboard = useClipboard();
  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <BsFillCheckCircleFill size={70} />
      </div>

      <Text
        mt="1rem"
        size="xl"
        style={{ textAlign: 'center', fontWeight: '400' }}
      >
        You’ve emailed a Payment Request to Charles Bonito
      </Text>
      <Text size="sm" color="gray" style={{ textAlign: 'center' }}>
        charles@flexbase.com
      </Text>
      <Container
        mt="1rem"
        style={{ display: 'flex', justifyContent: 'space-between' }}
        p="0"
      >
        <Text size="sm" color="gray">
          Deposit to
        </Text>
        <Text size="sm" color="gray">
          Amount
        </Text>
      </Container>
      <Alert className={classes.alert}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Text style={{ display: 'flex', alignItems: 'center' }}>
            Choice Checking{' '}
            <Alert pr="1" pl="1" pb="0" pt="0" ml="1vh">
              Checking ••1734
            </Alert>
          </Text>
          <Text>{formatCurrency(10000)}</Text>
        </div>
      </Alert>
      <Text size="sm" color="gray" mt="1rem">
        Copy Payment Request URL
      </Text>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Tooltip
          withArrow
          label={clipboard.copied ? 'Copied' : 'Click to copy'}
        >
          <Alert className={classes.alert}>
            <Text
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <BiCopy />
              <Text
                onClick={() =>
                  clipboard.copy(
                    'https://demo.mercury.com/pay/h2nmlk7m47p5l743',
                  )
                }
                ml="1vh"
                className={classes.link}
              >
                https://demo.mercury.com/pay/h2nmlk7m47p5l743
              </Text>
            </Text>
          </Alert>
        </Tooltip>
        <FaFileDownload
          onClick={() => window.open('/payment-request-pdf', '_blank')}
          style={{ marginLeft: '1vh', cursor: 'pointer' }}
          size={20}
        />
      </div>
      <Text size="xs" color="gray">
        Expires on February 24, 2024
      </Text>
      <Button variant="filled" mt="1rem">
        View all Payment Requests
      </Button>
      <Button
        variant="outline"
        onClick={() => createAnotherPaymentLink()}
        mt="1rem"
        style={{ borderRadius: 8 }}
      >
        Create another payment link
      </Button>
    </div>
  );
};

const useStyles = createStyles({
  link: {
    width: '360px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    cursor: 'pointer',
    ':hover': {
      textDecoration: 'underline',
    },
  },
  alert: {
    background: 'white',
    border: '1px solid #e4e5fc',
    borderRadius: 8,
  },
});

export default CopyLink;
