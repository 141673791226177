import { useRecoilValue } from 'recoil';
import { Box, LoadingOverlay } from '@mantine/core';
import ChargeLimit from './charge-limit';
import ChargePaymentCard from './charge-payment';
import { ApplicationState } from 'recoil-state/application/product-onboarding';
import { useGetChargeCardAccounts } from '@queries/use-charge-card-accounts';
import { CreditTransactions } from 'areas/credit/components/credit-transactions/credit-transactions';
import CreditTransactionsSm from 'areas/credit/components/credit-transactions/credit-transactions-sm/credit-transactions-sm';
import {
  useChargeCardTransactionFilters,
  useChargeCardTransactionModalFilters,
} from 'areas/credit/components/credit-transactions/credit-transactions-header/use-credit-transaction-filters';
import {
  useEarlyPayProjectedDueFlag,
  useSpendPlansFeatureFlag,
} from '@utilities/feature-flags';
import { useChargeCardTransactions } from '@queries/use-credit-transactions';
import { useMatchMedia } from '@utilities/url/window-helpers';
import { EarlyPayProjectedDueCard } from 'areas/credit/components/early-pay-card/early-pay-projected-due-card';
import { useStyles } from './styles';
import { useCreditLines } from '@queries/use-credit-lines';

const ChargeCardsOverview = () => {
  const { accountId, user } = useRecoilValue(ApplicationState);
  const isMobile = useMatchMedia('(max-width: 767px)');
  const filterHook = useChargeCardTransactionFilters();
  const modalFilterHook = useChargeCardTransactionModalFilters();
  const dateFilterValue = filterHook.getFilterByKey('date')?.filterValue;
  const transactionsStartDate = dateFilterValue?.startDate.toISODate();
  const transactionsEndDate = dateFilterValue?.endDate.toISODate();
  const spendPlansEnabled = useSpendPlansFeatureFlag();
  const { classes, cx } = useStyles();
  const earlyPayFeatureFlag = useEarlyPayProjectedDueFlag();

  const dataQuery = useChargeCardTransactions(accountId, {
    spendPlansEnabled,
    params: {
      ...(transactionsStartDate && { after: transactionsStartDate }),
      ...(transactionsEndDate && { before: transactionsEndDate }),
    },
  });

  const {
    data: chargeAccountsData,
    isError,
    isLoading,
    refetch: refetchChargeAccounts,
  } = useGetChargeCardAccounts();

  const { data: linesOfCredit } = useCreditLines();

  const activeChargeAccounts =
    chargeAccountsData?.accounts.filter((acc) => acc.status !== 'Closed') ?? [];

  const allowedRoles = new Set(['ADMIN', 'ACCOUNTANT', 'OWNER']);
  const userHasAllowedRole = user.roles.some((role) => allowedRoles.has(role));

  if (isLoading) {
    return <LoadingOverlay visible />;
  }

  const filteredCreditLines = linesOfCredit?.lines.find(
    (item) =>
      item.repaymentConfigurations &&
      'earlyPay' in item.repaymentConfigurations &&
      item.issuer === 'unit',
  );

  return (
    <Box>
      {userHasAllowedRole && (
        <div className={classes.widgetContainer}>
          <div className={classes.widget}>
            <ChargeLimit chargeBalance={activeChargeAccounts} />
          </div>
          <div className={cx(classes.earlyPayContainer, classes.widget)}>
            {filteredCreditLines && earlyPayFeatureFlag && (
              <EarlyPayProjectedDueCard
                creditLine={filteredCreditLines}
                issuer="unit"
              />
            )}
          </div>
          <div className={classes.widget}>
            <ChargePaymentCard
              isError={isError}
              chargeBalance={activeChargeAccounts[0]}
              refetchChargeAccounts={refetchChargeAccounts}
            />
          </div>
        </div>
      )}
      {isMobile ? (
        <CreditTransactionsSm lineOfCredit="unit" dataQuery={dataQuery} />
      ) : (
        <CreditTransactions
          lineOfCredit="unit"
          dataQuery={dataQuery}
          filterHook={filterHook}
          modalFilterHook={modalFilterHook}
        />
      )}
    </Box>
  );
};

export default ChargeCardsOverview;
