import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { platformAuthClient } from '../services/platform/platform-auth-client';
import {
  IsSuccessResponse,
  RegisterAuthenticationFactorModel,
} from '../services/platform/models/authorize.models';

const QUERY_KEY = 'AUTHENTICATION_FACTORS';

export const useGetAuthenticationFactors = () => {
  return useQuery({
    queryKey: [QUERY_KEY],
    queryFn: async () => {
      // Please do not do this kind of mapping in your query.
      // This is only here because the getFactors function does not throw an error
      // So we need to make sure that this query can correctly do errors.
      const response = await platformAuthClient.getFactors();
      if (IsSuccessResponse(response) && response.body) {
        return response.body;
      } else if (response.statusCode > 399) {
        throw response.wwwAuthenticate ?? response.error ?? 'Unknown error';
      }
    },
  });
};

export const useRegisterAuthFactorMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      factor,
    }: {
      factor: RegisterAuthenticationFactorModel;
    }) => {
      return await platformAuthClient.registerFactor(factor);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
    },
  });
};

export const useDeleteAuthFactorMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (methodId: string) => {
      return await platformAuthClient.deleteFactor(methodId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
    },
  });
};
