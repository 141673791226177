import {
  useChargeCardsModalFilters,
  useCreditCardsModalFilters,
} from '../use-credit-cards-filters';
import { filterDirectionToLabel } from '@common/filter/amount-filter';
import { QuantityFilter } from '@common/filter/quantity-filter';
import { formatCurrency } from '@utilities/formatters';
import { CardTableRow } from '../../card-table-row.model';
import { ActiveFiltersReturnType } from '@common/filter/filters';

function compareMtdSpendAmount(
  compareAmount: number,
  compareDirection: string,
  mtdSpends?: number,
) {
  if (!mtdSpends && mtdSpends !== 0) {
    return false;
  }

  const amountNum = Math.abs(mtdSpends);
  const filterNum = compareAmount;
  switch (compareDirection) {
    case 'greaterThan':
      return amountNum > filterNum;
    case 'lessThan':
      return amountNum < filterNum;
    case 'equal':
    default:
      return amountNum === filterNum;
  }
}

type AmountSpentFilterProps = {
  filterHook: ActiveFiltersReturnType<CardTableRow>;
};

const AmountSpentFilter = ({ filterHook }: AmountSpentFilterProps) => {
  const { addFilter, getFilterByKey } = filterHook;

  return (
    <QuantityFilter
      value={
        getFilterByKey('amount')?.filterValue || {
          amount: '',
          direction: 'equal',
        }
      }
      onChange={(val) =>
        addFilter('amount', {
          label: `Monthly usage - ${filterDirectionToLabel(
            val.direction,
          )} ${formatCurrency(val.amount)}`,
          key: 'amount',
          fn: (row) =>
            compareMtdSpendAmount(
              +val.amount,
              val.direction,
              row.monthToDateSpends?.mtdSpend,
            ),
          filterValue: val,
          showChip: true,
        })
      }
    />
  );
};

export const CreditCardsAmountSpentFilter = () => {
  const filterHook = useCreditCardsModalFilters();

  return <AmountSpentFilter filterHook={filterHook} />;
};

export const ChargeCardsAmountSpentFilter = () => {
  const filterHook = useChargeCardsModalFilters();

  return <AmountSpentFilter filterHook={filterHook} />;
};
