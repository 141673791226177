import {
  BenefitsActive,
  BenefitsConcierge,
  BenefitsDining,
  BenefitsEvents,
  BenefitsFeatured,
  BenefitsHealth,
  BenefitsLounges,
  BenefitsNew,
  BenefitsProtection,
  BenefitsTravel,
} from '../../assets/svg';
import BenefitsConciergeLarge from '../../assets/images/benefits-and-rewards/benefits-concierge-large.jpeg';

type Benefit = {
  type: string;
  category: string;
  categoryIcon: JSX.Element;
  imageSrc: string;
  title: string;
  description: string;
  ctaButtonText: string;
  featured: boolean;
  path?: string;
};

const BenefitTabs = [
  { value: 'all', label: 'All', icon: <BenefitsNew width={30} height={30} /> },
  {
    value: 'active',
    label: 'Active',
    icon: <BenefitsActive width={30} height={30} />,
  },
  {
    value: 'featured',
    label: 'Featured',
    icon: <BenefitsFeatured width={30} height={30} />,
  },
  {
    value: 'travel',
    label: 'Travel',
    icon: <BenefitsTravel width={30} height={30} />,
  },
  {
    value: 'lounges',
    label: 'Lounges',
    icon: <BenefitsLounges width={30} height={30} />,
  },
  {
    value: 'dining',
    label: 'Dining',
    icon: <BenefitsDining width={30} height={30} />,
  },
  {
    value: 'events',
    label: 'Events',
    icon: <BenefitsEvents width={30} height={30} />,
  },
  {
    value: 'concierge',
    label: 'Concierge',
    icon: <BenefitsConcierge width={30} height={30} />,
  },
  {
    value: 'health',
    label: 'Health',
    icon: <BenefitsHealth width={30} height={30} />,
  },
  {
    value: 'protection',
    label: 'Protection',
    icon: <BenefitsProtection width={30} height={30} />,
  },
];

const getBenefits = (iconSize: number, iconColor: string): Benefit[] => {
  return [
    {
      type: 'Flex Elite',
      category: 'Travel',
      categoryIcon: (
        <BenefitsFeatured
          width={iconSize}
          height={iconSize}
          color={iconColor}
        />
      ),
      imageSrc: BenefitsConciergeLarge,
      title: 'Travel',
      description:
        'Contact your personal concierge from Flex Elite to book travel for you and your circle. Blah blah',
      ctaButtonText: 'Access Travel',
      featured: true,
    },
    {
      type: 'Flex Elite',
      category: 'Travel',
      categoryIcon: (
        <BenefitsFeatured
          width={iconSize}
          height={iconSize}
          color={iconColor}
        />
      ),
      imageSrc: BenefitsConciergeLarge,
      title: 'Travel2',
      description:
        'Contact your personal concierge from Flex Elite to book travel for you and your circle. Turtles.',
      ctaButtonText: 'Access Travel',
      featured: true,
    },
    {
      type: 'Flex Elite',
      category: 'Lounges',
      categoryIcon: (
        <BenefitsFeatured
          width={iconSize}
          height={iconSize}
          color={iconColor}
        />
      ),
      imageSrc: BenefitsConciergeLarge,
      title: 'Lounges',
      description:
        'Contact your personal concierge from Flex Elite to get access to exclusive lounges worldwide',
      ctaButtonText: 'Access Concierge',
      featured: false,
    },
    {
      type: 'Flex Elite',
      category: 'Dining',
      categoryIcon: (
        <BenefitsFeatured
          width={iconSize}
          height={iconSize}
          color={iconColor}
        />
      ),
      imageSrc: BenefitsConciergeLarge,
      title: 'Dining',
      description:
        'Contact your personal concierge from Flex Elite to book dinner reservations at exclusive restaurants',
      ctaButtonText: 'Access Concierge',
      featured: false,
    },
    {
      type: 'Flex Elite',
      category: 'Concierge',
      categoryIcon: (
        <BenefitsFeatured
          width={iconSize}
          height={iconSize}
          color={iconColor}
        />
      ),
      imageSrc: BenefitsConciergeLarge,
      title: 'Concierge',
      description:
        'Contact your personal concierge from Flex Elite to book dinner reservations, travel, and more.',
      ctaButtonText: 'Access Concierge',
      featured: false,
      path: '/concierge',
    },
  ];
};

export { BenefitTabs, getBenefits };
export type { Benefit };
