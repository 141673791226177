import { TablePayment } from 'areas/payments/components/common/payments-rows';
import { PaymentAmountFilter } from './payment-amount-filter';
import { PaymentCreateAtFilter } from './payment-created-at-filter';
import { PaymentVendorFilter } from './payment-vendor-filter';
import { PaymentRequestedByFilter } from './payment-requested-by-filter';

export const filterStatus = (payments: TablePayment[]) => [
  {
    key: 'date',
    header: 'Created on',
    component: <PaymentCreateAtFilter />,
  },
  {
    key: 'amount',
    header: 'Amount',
    component: <PaymentAmountFilter />,
  },
  {
    key: 'recipient',
    header: 'Recipient',
    component: <PaymentVendorFilter payments={payments} />,
  },
  {
    key: 'requestedBy',
    header: 'Requested by',
    component: <PaymentRequestedByFilter payments={payments} />,
  },
];
