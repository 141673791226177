import { DateTime, Interval } from 'luxon';

import { DateRangeFilter } from '@common/filter/date-range.filter';
import { useBillpayPaymentsModalFilters } from 'areas/billpay/payments/use-billpay-payments-filters';

export const PaymentCreateOnFilter = () => {
  const paymentCreateOnFilters = useBillpayPaymentsModalFilters();

  return (
    <DateRangeFilter
      label="Created on"
      filterHook={paymentCreateOnFilters}
      filterFn={(startDate, endDate, item) => {
        const dateRange = Interval.fromDateTimes(startDate, endDate);
        return dateRange.contains(DateTime.fromSQL(item.createdAt));
      }}
    />
  );
};
