import { ApplicationStatus } from 'recoil-state/application/product-onboarding.models';
import { OnboardingCompany, OnboardingUser } from 'types/onboarding-info';
import { IAddress } from 'types/user-info';

const defaultAddress: IAddress = {
  line1: '',
  city: '',
  state: '',
  postalCode: '',
  country: 'US',
};

const defaultCompany: OnboardingCompany = {
  id: '',
  companyName: '',
  address: { ...defaultAddress },
  doingBusinessAs: '',
  taxId: '',
  formationDate: '',
  legalStructure: '',
  website: '',
  monthlyExpenditure: '',
  phone: '',
  owners: [],
  financialInstitutions: [],
  category: '',
  annualRevenue: '',
  averageMonthlyPayroll: '',
  insuranceTypes: [],
  hasInsurance: false,
  optedProducts: [],
  autopay: {},
  formationState: '',
  businessPurpose: '',
  cashOnHand: '',
  naicsCode: '',
  reinvestTreasuryInterest: false,
  primaryPlaidAccount: '',
  businessVertical: '',
  requestedCreditLimit: '',
  stateOfOperation: '',
  onboardedBy: '',
  createdBy: '',
  businessStage: '',
  createdAt: '',
  requestedDocuments: [],
  officers: [],
  excludedProducts: [],
};

const defaultOnboardingUser: OnboardingUser = {
  id: '',
  firstName: '',
  lastName: '',
  address: { ...defaultAddress },
  taxId: '',
  birthDate: '',
  jobTitle: '',
  email: '',
  phone: '',
  cellPhone: '',
  authorizedSignatory: false,
  roles: [],
  status: '',
  plaidLinked: false,
  termsOfServiceSigned: false,
  bankingTermsOfServiceSigned: false,
  internationalPaymentsTermsOfServiceSigned: false,
  treasuryTermsOfServiceSigned: false,
  creditTermsOfServiceSigned: false,
  hasBusinessCard: false,
  businessCardTypes: [],
  employeeCount: '',
  personalGuarantySigned: false,
  patriotActSigned: false,
  ficoPullSigned: false,
};

const defaultProductStatus: ApplicationStatus = {
  credit: {
    status: 'unused',
  },
  banking: {
    status: 'unused',
  },
  treasury: {
    status: 'unused',
  },
  internationalPayments: {
    status: 'unused',
  },
};

type _Defaults = {
  company: OnboardingCompany;
  user: OnboardingUser;
  address: IAddress;
  productStatus: ApplicationStatus;
};

export const DEFAULT_APP_STATE_VALUES: _Defaults = {
  company: defaultCompany,
  user: defaultOnboardingUser,
  address: defaultAddress,
  productStatus: defaultProductStatus,
};
