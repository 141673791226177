import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
  flexbaseBankingClient,
  flexbaseOnboardingClient,
} from '../services/flexbase-client';

const QUERY_KEY = 'applicationStatus';

export const useGetApplicationStatus = () => {
  return useQuery({
    queryKey: [QUERY_KEY],
    queryFn: async () => {
      return await flexbaseBankingClient.getApplicationStatus();
    },
    meta: {
      errorMessage: 'Unable to retrieve application status.',
    },
  });
};

export const useSardineCustomer = () => {
  const queryClient = useQueryClient();

  const fetchQuery = (sessionId: string, userId: string) => {
    return queryClient.fetchQuery({
      queryKey: [sessionId, userId],
      queryFn: () =>
        flexbaseOnboardingClient.sardineCustomer(sessionId, userId),
    });
  };

  return {
    fetchQuery,
  };
};
