import { StyleSheet, Font } from '@react-pdf/renderer';
import RedactionRegular from 'assets/fonts/Redaction-Regular.otf';
import PPNeueMontrealBold from 'assets/fonts/PPNeueMontreal-Bold.otf';
import PPNeueMontrealMedium from 'assets/fonts/PPNeueMontreal-Medium.otf';
import PPNeueMontrealRegular from 'assets/fonts/PPNeueMontreal-Regular.otf';

type Colors = {
  neutral?: string;
  textColor?: string;
  primaryDark?: string;
};

export const usePDFStyles = ({ neutral, textColor, primaryDark }: Colors) => {
  Font.register({
    family: 'Redaction',
    fonts: [
      { src: RedactionRegular }, // font-style: normal, font-weight: normal
    ],
  });

  Font.register({
    family: 'PP Neue Montreal',
    fonts: [
      { src: PPNeueMontrealMedium }, // font-style: normal, font-weight: normal
      { src: PPNeueMontrealBold, fontStyle: 'bold' },
      { src: PPNeueMontrealRegular, fontStyle: 'thin' },
    ],
  });

  return StyleSheet.create({
    headerRow: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },

    textHeader: {
      fontSize: 10,
      color: primaryDark,
      alignSelf: 'flex-end',
      fontFamily: 'PP Neue Montreal',
    },

    text: {
      fontSize: 12,
      fontStyle: 'thin',
      color: primaryDark,
      fontFamily: 'PP Neue Montreal',
    },

    amountText: {
      fontSize: 36,
      marginTop: 10,
      fontStyle: 'thin',
      fontFamily: 'PP Neue Montreal',
    },

    smallText: {
      fontSize: 9,
      marginTop: 10,
      color: textColor,
      fontStyle: 'thin',
      fontFamily: 'PP Neue Montreal',
    },

    cardReceiptContainer: {
      height: 263,
      marginTop: 20,
      borderRadius: 8,
      alignItems: 'center',
      padding: '10px 20px',
      flexDirection: 'column',
      justifyContent: 'center',
      border: `1px solid ${neutral}`,
    },

    cardSentFromContainer: {
      padding: 20,
      marginTop: 20,
      borderRadius: 8,
      justifyContent: 'center',
      border: `1px solid ${neutral}`,
    },

    footerContainer: {
      bottom: 0,
      fontSize: 10,
      height: '7vh',
      width: '100%',
      color: textColor,
      padding: '15px 20px',
      flexDirection: 'row',
      position: 'absolute',
      fontFamily: 'Helvetica',
      backgroundColor: neutral,
      justifyContent: 'space-between',
    },
  });
};
