import { Box, rem, Text } from '@mantine/core';

/**
 * This function uses HTML5 measureText function to calculate the width (in px) of a given text string with a given font style
 * @param text The text that you want to calculate the width for.
 * @param font Font string used in calculations. Ex: 2rem | 400 2rem | 400 2rem redaction
 */
function getTextWidth(text: string, font: string) {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  if (context) {
    context.font = font;
    const metrics = context.measureText(text);
    return metrics.width;
  }

  return -1;
}

type Props = { firstName: string; lastName: string };
/**
 * This component shows a credit card with the user's first name and last name on it.
 * Text automatically resizes to fit without breaking the frame.
 *
 * @param firstName
 * @param lastName
 * @constructor
 */
export const CreditCardGraphic = ({ firstName, lastName }: Props) => {
  let cardNameTextSize = 32;

  while (
    getTextWidth(firstName, `400 ${rem(cardNameTextSize)} redaction`) > 314 ||
    getTextWidth(lastName, `400 ${rem(cardNameTextSize)} redaction`) > 314
  ) {
    cardNameTextSize--;
  }

  return (
    <Box
      w={346.399}
      h={218.874}
      sx={{
        backgroundImage: 'url(/svg/credit-card-blank.svg)',
      }}
      px="md"
      py="xxl"
    >
      <Text
        ff="Redaction"
        fz={cardNameTextSize}
        lts={-0.328}
        lh="90%"
        c="#ECECEC"
      >
        {firstName}
        <div>{lastName}</div>
      </Text>
    </Box>
  );
};
