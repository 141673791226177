import { atom } from 'recoil';
import {
  ActiveFiltersReturnType,
  createUseFiltersHook,
  FilterFnMap,
} from '@common/filter/filters';
import { TablePayment } from 'areas/payments/components/common/payments-rows';

const ActiveRequiredFilterState = atom<FilterFnMap<TablePayment>>({
  key: 'active_required_filter_state',
  default: {},
});

const ActiveRequiredFilterStateModal = atom<FilterFnMap<TablePayment>>({
  key: 'active_required_filter_state_modal',
  default: {},
});

export function useActionRequiredModalFilters(): ActiveFiltersReturnType<TablePayment> {
  return createUseFiltersHook<TablePayment>(ActiveRequiredFilterStateModal);
}

export function useActionRequiredFilters(): ActiveFiltersReturnType<TablePayment> {
  return createUseFiltersHook<TablePayment>(ActiveRequiredFilterState);
}
