import FlexbaseTable from 'components/table/flexbase-table';

import { useStyles } from '../../styles';

import { Center, Image, Text, useMantineTheme } from '@mantine/core';
import getPaddedAccountMask from 'utilities/formatters/get-padded-account-mask';
import { useNavigate } from 'react-router-dom';
import { liteTableStyles } from 'components/styles';
import { FlexAccountInfo } from '../../treasury-management.state';
import { formatCurrency } from '@utilities/formatters';

type Props = {
  accounts: FlexAccountInfo[];
  loading: boolean;
};

const FlexAccountsTable = ({ accounts, loading }: Props) => {
  const theme = useMantineTheme();
  const { classes, cx } = useStyles();
  const navigate = useNavigate();

  const columns = [
    {
      name: 'Account',
      sortable: true,
      selector: (row: FlexAccountInfo) => row.name,
      minWidth: '400px',
      grow: 2,
      cell: (row: FlexAccountInfo) => (
        <>
          <Center className={classes.avatar}>
            <Image src="/svg/fb.svg" alt="fb" width="15px" />
          </Center>
          <div>
            <Text className={classes.flexAccountName}>
              {row.name} {getPaddedAccountMask(row.accountNumber.toString(), 2)}
            </Text>
            <Text className={classes.flexAccountSubName}>{row.name}</Text>
          </div>
        </>
      ),
    },
    {
      name: 'Available Balance',
      sortable: true,
      format: (row: FlexAccountInfo) => formatCurrency(row.availableBalance),
      selector: (row: FlexAccountInfo) => row.availableBalance,
    },
    {
      name: 'Uninsured Capital',
      sortable: true,
      selector: (row: FlexAccountInfo) => row.uninsuredCapital,
      cell: (row: FlexAccountInfo) => (
        <Text
          className={cx(
            row.uninsuredCapital > 0 && classes.risky,
            row.uninsuredCapital === 0 && classes.good,
          )}
        >
          {row.uninsuredCapital}%
        </Text>
      ),
    },
    {
      name: 'Account Diversification #',
      sortable: true,
      selector: (row: FlexAccountInfo) => row.accountDiversificationNumber,
    },
  ];

  return (
    <FlexbaseTable
      customStyles={liteTableStyles(theme.fn.primaryColor())}
      striped={true}
      columns={columns}
      data={accounts}
      isFetchingData={loading}
      onRowClicked={() => navigate('/accounts/treasury')}
      noDataComponent={
        <Text
          className={cx(classes.pp, classes.emptyTableComp)}
          weight={500}
          size={14}
          color="#000000"
        >
          Your Flex Treasury is being created and will appear here!
        </Text>
      }
    />
  );
};

export default FlexAccountsTable;
