import { Button, Text } from '@mantine/core';
import { formatCurrency } from 'utilities/formatters';
import { useEffect, useMemo, useState } from 'react';
import { useStyles } from './banking-transactions.styles';
import BankingTransactionsHeader from './banking-transactions-header/banking-transactions-header';
import FlexbaseTable from 'components/table/flexbase-table';
import { formatInitials } from 'utilities/formatters/format-strings';
import { downloadCSV } from 'utilities/file-io/csv/csv-export';
import {
  extractToFrom,
  parseTransactionDisplayValueByType,
} from 'utilities/formatters/banking-transactions-utilities';
import { DateTime } from 'luxon';
import { useParams, useSearchParams } from 'react-router-dom';
import { Transaction } from 'services/flexbase/banking.model';
import { OverflowTooltip } from '@flexbase-eng/web-components';
import { useMediaQuery } from '@mantine/hooks';
import useModal from 'components/modal/modal-hook';
import TransactionDetails from './transaction-details/transaction-details';
import getPaddedAccountMask from 'utilities/formatters/get-padded-account-mask';
import { useGetBankingTransactions } from 'queries/use-banking-transactions';
import { useGetDepositAccounts } from 'queries/use-deposit-accounts';
import { sortDate } from 'utilities/dates/dates';
import { useBankingTransactionsFilters } from './use-banking-transactions-filters';
import { TableColumn } from 'react-data-table-component';
import { TagDelta } from 'areas/banking/delta';
import CheckDepositDetails from '../check-deposits/components/check-deposit-details-modal/check-deposit-details';

const paymentDirection = (direction: string, displaySign?: boolean) => {
  if (displaySign) {
    return direction.toLocaleLowerCase() === 'debit' ? '-' : '+';
  }
  return direction.toLocaleLowerCase() === 'debit' ? '-' : '';
};

export type TableTransactionsData = {
  initials: string;
  cardId: string | null;
  id: string;
  date: string;
  createdAt: string;
  name: string;
  toFrom: string;
  amount: number;
  account: string;
  type: string;
  direction: string;
  transactionDetail: Transaction;
};

const checkDirection = (direction: string) => {
  if (direction === 'Credit') {
    return 'increase';
  }
  return 'decrease';
};

const getTransactionDirection = (direction: string, type: string) => {
  switch (type) {
    case 'Payment canceled':
      return '';
    default:
      return paymentDirection(direction, true);
  }
};

const selectAmountColumn = (row: TableTransactionsData) => {
  const isNegativeTransaction =
    getTransactionDirection(row.direction, row.type) === '-';
  const parsedAmount = isNegativeTransaction ? `-${row.amount}` : row.amount;
  return Number(parsedAmount);
};

const BankingTransactions = () => {
  const modal = useModal();
  const { classes } = useStyles();
  const { id: accountId, recipientId } = useParams();
  const [searchParams] = useSearchParams();
  const cardId = searchParams.get('cardId');
  const bankingAccounts = useGetDepositAccounts();
  const [filterText, setFilterText] = useState('');
  const useMobileView = useMediaQuery('(max-width: 767px)');
  const [selectedRows, setSelectedRows] = useState<TableTransactionsData[]>([]);

  const { activeFiltersArray, getFilterByKey, removeFilter } =
    useBankingTransactionsFilters();
  const currentDateFilter = getFilterByKey('date')?.filterValue;
  const toDate = currentDateFilter?.endDate?.toISODate();
  const fromDate = currentDateFilter?.startDate?.toISODate();

  const params = {
    ...(accountId && { depositAccountId: accountId }),
    ...(recipientId && { recipientId }),
    ...(fromDate && toDate && { fromDate, toDate }),
  };

  const { data, isSuccess, isLoading, isError, refetch } =
    useGetBankingTransactions(params);

  /**
   * Define columns to be passed into the FlexbaseTable component
   * put this back in when we have this ironed out: {
   *   name: 'To/From',
   *   selector: (row) => row.name,
   *   sortable: true,
   *   compact: true,
   * },
   */
  const columns: TableColumn<TableTransactionsData>[] = [
    {
      name: 'Created Date',
      cell: (row) => {
        return <Text className={classes.date}>{row.date}</Text>;
      },
      selector: (row) => row.createdAt,
      sortable: true,
      sortFunction: sortDate,
      id: 'date',
      grow: 1,
    },
    {
      name: 'Type',
      selector: (row) => row.type,
      cell: (row) => <div className={classes.storeName}>{row.type}</div>,
      sortable: true,
      compact: true,
      grow: 1.5,
    },
    {
      name: 'Summary',
      cell: (row) => (
        <div className={classes.storeName}>
          {row.toFrom || 'Payment received'}
        </div>
      ),
      selector: (row) => row.toFrom,
      sortable: true,
      compact: true,
      grow: 2,
    },
    {
      name: 'Amount',
      cell: (row) => (
        <TagDelta
          tone={
            checkDirection(row.direction) === 'increase'
              ? 'positive'
              : 'critical'
          }
          change={checkDirection(row.direction)}
        >{`${formatCurrency(row.amount)}`}</TagDelta>
      ),
      selector: selectAmountColumn,
      sortable: true,
      compact: true,
    },
    {
      name: 'Account',
      cell: (row) => <OverflowTooltip text={row.account} />,
      selector: (row) => row.account,
      sortable: true,
      grow: 1.5,
    },
  ];

  const columnsSm: TableColumn<TableTransactionsData>[] = [
    {
      sortable: true,
      name: 'Summary',
      cell: (row) => (
        <div>
          <div style={{ fontWeight: 500, fontSize: 14 }}>{row.name}</div>
          <div style={{ fontWeight: 500, fontSize: 12 }}>{row.type}</div>
          <div style={{ fontSize: 12 }}>{row.date}</div>
        </div>
      ),
      selector: (row) => row.date,
    },
    {
      name: 'Amount',
      cell: (row) => (
        <Text className={classes.amount}>
          {`${getTransactionDirection(row.direction, row.type)}${formatCurrency(
            row.amount,
          )}`}
        </Text>
      ),
      selector: selectAmountColumn,
      sortable: true,
      compact: true,
      right: true,
      style: {
        padding: '0px 16px',
      },
    },
  ];

  const filteredTransactions = useMemo(() => {
    if (!data) {
      return [];
    }

    const accounts = bankingAccounts.data?.accounts || [];

    const mappedTransactions = data.transactions
      .map((transaction) => {
        const formattedDate = transaction.createdAt
          ? DateTime.fromSQL(transaction.createdAt).toFormat('LLL dd, yyyy')
          : '';

        const bankingAccount = accounts.find(
          (account) => account.id === transaction.depositAccountId,
        );

        const accountName =
          bankingAccount?.nickName || bankingAccount?.name || 'Flex';
        const maskedAccountNumber = getPaddedAccountMask(
          bankingAccount?.accountNumber || '',
          3,
        );

        const toFromName = transaction.whoFirstName
          ? [transaction.whoFirstName, transaction.whoLastName]
              .filter(Boolean)
              .join(' ')
          : transaction.accountName;

        if (cardId && transaction.cardId !== cardId) {
          return null;
        }

        const parsedPaymentType = parseTransactionDisplayValueByType(
          transaction?.paymentType === 'internationalWire'
            ? 'outgoingACHInternationalWire'
            : transaction.type,
          transaction.direction,
        );

        return {
          initials: formatInitials(extractToFrom(transaction.summary)),
          cardId: transaction.cardId,
          id: transaction.id,
          date: formattedDate,
          createdAt: transaction.createdAt,
          name: toFromName,
          toFrom: transaction.summary,
          amount: parseFloat(`${transaction.amount || 0}`) / 100,
          account: bankingAccount
            ? `${accountName} ${maskedAccountNumber}`
            : '',
          type: parsedPaymentType,
          direction: transaction.direction,
          transactionDetail: transaction,
        } as TableTransactionsData;
      })
      .filter(
        (transaction): transaction is TableTransactionsData =>
          transaction !== null,
      );

    const filtered = mappedTransactions.filter((t) =>
      activeFiltersArray.every((f) => f.fn(t)),
    );

    const sortedTransactions = filtered.sort((a, b) => sortDate(a, b, false));

    return sortedTransactions;
  }, [isSuccess, data, activeFiltersArray, bankingAccounts]);

  /**
   * Prep/format data to be exported
   */

  const selectedData =
    selectedRows.length > 0 ? selectedRows : filteredTransactions;

  const csvData = selectedData.map((item) => {
    return {
      Date: item.date,
      Name: item.name,
      Amount: `${paymentDirection(item.direction, true)}${formatCurrency(
        item.amount,
      )}`,
      Account: item.account,
      Type: item.type,
    };
  });

  const onRowClicked = (row: TableTransactionsData) => {
    const checkDepositId = row.transactionDetail.checkDepositId;
    const isCheckTransaction = [
      'Check deposit',
      'Check payment',
      'Returned check deposit',
    ].includes(row.type);
    if (isCheckTransaction && checkDepositId) {
      modal.openRightModal(
        <CheckDepositDetails id={checkDepositId} account={row.account} />,
      );
    } else {
      modal.openRightModal(<TransactionDetails transaction={row} />);
    }
  };

  // remove all the filters when we're on 'Account details' page
  useEffect(() => {
    if (accountId) {
      activeFiltersArray.forEach((f) => removeFilter(f.key));
    }
  }, [accountId]);

  return (
    <div className={classes.baseContainer}>
      <div className={classes.widgetContainer}>
        <BankingTransactionsHeader
          setFilterText={setFilterText}
          filterText={filterText}
          downloadCSV={() =>
            downloadCSV({ data: csvData, fileName: 'Banking Transactions' })
          }
          transactions={data?.transactions || []}
          accountId={accountId}
        />
        {isError ? (
          <div className={classes.errorMessage}>
            <p>An error occurred getting transaction data</p>
            <Button onClick={() => refetch()}>Retry</Button>
          </div>
        ) : (
          <FlexbaseTable
            columns={useMobileView ? columnsSm : columns}
            data={filteredTransactions}
            onSelectedRowsChange={({ selectedRows: selected }) =>
              setSelectedRows(selected)
            }
            isFetchingData={isLoading}
            defaultSortFieldId={'createdAt'}
            onRowClicked={onRowClicked}
            selectableRows={true}
            noDataComponent={
              <Text size={24} fw={500} mt="lg">
                No transactions yet
              </Text>
            }
          />
        )}
      </div>
    </div>
  );
};
export default BankingTransactions;
