import { Image } from '@mantine/core';
import { useMemo } from 'react';

type Props = {
  document: File;
  altText: string;
};

const DocumentViewer = ({ document, altText }: Props) => {
  const imageUrl = URL.createObjectURL(document);

  const blobUrl = useMemo(() => {
    if (!document) return null;
    return URL.createObjectURL(document);
  }, [document]);

  if (document.type === 'application/pdf') {
    return (
      <object
        data={`${blobUrl}#toolbar=0`}
        type="application/pdf"
        width="100%"
        height="100%"
      />
    );
  }
  return <Image src={imageUrl} alt={altText} />;
};

export default DocumentViewer;
