import { MoneyMovement } from '@services/flexbase/banking.model';
import { useInvoiceWizard } from '../../invoice-wizard';
import PaymentConfirmation from './payment.confirmation';
import TwoFactorConfirmation from './two-factor-confirmation';

const PaymentSubmittedStep = () => {
  const { state, setState, goToPreviousStep } = useInvoiceWizard();
  const { paymentSubmissionDetails: details } = state;
  const isError = details instanceof Error;
  const needsTwoFactor = !isError && details?.status === 'AwaitingConfirmation';

  const onTwoFactorSuccess = (updatedDetails: MoneyMovement) => {
    setState({ paymentSubmissionDetails: updatedDetails });
  };

  if (needsTwoFactor) {
    return (
      <TwoFactorConfirmation
        paymentId={details?.id}
        onConfirmSuccess={onTwoFactorSuccess}
      />
    );
  }

  return <PaymentConfirmation onGoBack={goToPreviousStep} details={details} />;
};

PaymentSubmittedStep.stepId = 'payment-submitted';

export default PaymentSubmittedStep;
