import { Center, createStyles, Flex, Grid, Loader, Text } from '@mantine/core';
import { IoAlertCircleOutline } from 'react-icons/io5';
import TableSummaryCard from './table-summary-card';
import { ReactNode } from 'react';

type SummaryCard = {
  title: string;
  balance: number;
  footer?: string | ReactNode;
};

type Props = {
  summaryData: SummaryCard[];
  isLoading: boolean;
  isError: boolean;
};

const TableSummary = ({ summaryData, isLoading, isError }: Props) => {
  const { classes, theme } = useStyles();

  if (isLoading) {
    return (
      <Center mb="1rem">
        <Loader />
      </Center>
    );
  }

  return (
    <>
      <Grid
        grow
        gutter={0}
        mb={isError ? 0 : '1rem'}
        className={isError ? classes.gridError : classes.grid}
      >
        {summaryData.map((item, idx) => (
          <Grid.Col span={4} key={item.title}>
            <TableSummaryCard
              {...item}
              error={isError}
              isLast={idx === summaryData.length - 1}
            />
          </Grid.Col>
        ))}
      </Grid>
      {isError && (
        <Flex px="1rem" mb="1rem" className={classes.containerError}>
          <Flex align="center">
            <IoAlertCircleOutline color={theme.colors.red[6]} size={22} />{' '}
            <Text mt="0.1rem" ml="0.2rem" weight={500}>
              {' '}
              Error loading data
            </Text>
          </Flex>
        </Flex>
      )}
    </>
  );
};

export default TableSummary;

const useStyles = createStyles((theme) => ({
  containerError: {
    backgroundColor: theme.colors.red[0],
    border: `1px solid ${theme.colors.red[5]}`,
    borderBottomRightRadius: 8,
    borderBottomLeftRadius: 8,
    height: '2rem',
  },
  grid: {
    borderRadius: 8,
    overflow: 'hidden',
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    backgroundColor: theme.colors.beige[0],
  },
  gridError: {
    borderRadius: 8,
    overflow: 'hidden',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    backgroundColor: theme.colors.beige[0],
  },
}));
