import { platformClient } from '../services/platform/platform-client';
import {
  queryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import {
  CreatePlatformPersonPhoneRequest,
  PlatformPerson,
  UpdatePlatformAddressRequest,
} from '../services/platform/models/identity.model';
import { PersonsApiListPersonsPhonesRequest } from '@flexbase-eng/types/dist/identity/types/ObjectParamAPI';
import { platformSdk } from '../services/platform-sdk';
import { PersonYamlInput } from '@flexbase-eng/sdk-typescript/models/components';

const QUERY_KEY = 'platform_person';

const useGetMeQueryOptions = queryOptions({
  queryKey: [QUERY_KEY, 'me'],
  queryFn: async () => {
    return await platformClient.getMe();
  },
});

export const useInvalidateMeQuery = () => {
  const queryClient = useQueryClient();

  return {
    invalidate: () => queryClient.invalidateQueries(useGetMeQueryOptions),
  };
};

export const useGetMe = () => {
  return useQuery(useGetMeQueryOptions);
};

// TODO: Add queries to get a platform person by id and list platform persons, as well as phones and all that.
// We will want to do a query key like this ['accounts', accountId, 'persons', personId] sticking close to the actual resources

type PlatformPersonResourceBaseArgs = {
  accountId: string;
  personId: string;
};

type UseMutatePlatformPersonArgs = PlatformPersonResourceBaseArgs & {
  update: PersonYamlInput;
};

export const useUpdatePlatformPersonMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      accountId,
      personId,
      update,
    }: UseMutatePlatformPersonArgs) => {
      return await platformSdk.persons.patchPerson({
        accountId,
        personId,
        personYaml: update,
      });
    },
    onSuccess: (_data, variables) => {
      const found = queryClient.getQueryData<PlatformPerson>(
        useGetMeQueryOptions.queryKey,
      );
      if (found?.id === variables.personId) {
        queryClient.invalidateQueries({
          queryKey: useGetMeQueryOptions.queryKey,
        });
      }
    },
  });
};

type UseCreatePlatformPersonAddressArgs = PlatformPersonResourceBaseArgs & {
  body: UpdatePlatformAddressRequest;
};

export const useCreatePlatformAddressMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      accountId,
      personId,
      body,
    }: UseCreatePlatformPersonAddressArgs) => {
      return await platformClient.createPersonAddress(
        accountId,
        personId,
        body,
      );
    },
    onSuccess: (_data, variables) => {
      const found = queryClient.getQueryData<PlatformPerson>(
        useGetMeQueryOptions.queryKey,
      );
      if (found?.id === variables.personId) {
        queryClient.invalidateQueries({
          queryKey: useGetMeQueryOptions.queryKey,
        });
      }
    },
  });
};

type UpdatePlatformPersonAddressArgs = PlatformPersonResourceBaseArgs & {
  addressId: string;
  body: Partial<UpdatePlatformAddressRequest>;
};

export const useUpdatePlatformPersonAddressMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      accountId,
      personId,
      addressId,
      body,
    }: UpdatePlatformPersonAddressArgs) => {
      return await platformClient.updatePersonAddress(
        accountId,
        personId,
        addressId,
        body,
      );
    },
    onSuccess: (_data, variables) => {
      const found = queryClient.getQueryData<PlatformPerson>(
        useGetMeQueryOptions.queryKey,
      );
      if (found?.id === variables.personId) {
        queryClient.invalidateQueries({
          queryKey: useGetMeQueryOptions.queryKey,
        });
      }
    },
  });
};

type UseUpdatePlatformPersonPhoneArgs = PlatformPersonResourceBaseArgs & {
  phoneId: string;
  update: CreatePlatformPersonPhoneRequest;
};

export const useGetPlatformPersonPhones = (
  accountId?: string,
  personId?: string,
  listQuery?: PersonsApiListPersonsPhonesRequest,
) => {
  return useQuery({
    queryKey: ['accounts', accountId, 'persons', personId, 'phones'],
    queryFn: async () => {
      if (accountId && personId) {
        return await platformClient.listPersonPhones(
          accountId,
          personId,
          listQuery,
        );
      }
    },
    enabled: !!accountId && !!personId,
  });
};

export const useUpdatePlatformPersonPhoneMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      accountId,
      personId,
      phoneId,
      update,
    }: UseUpdatePlatformPersonPhoneArgs) => {
      return await platformClient.updatePersonPhone(
        accountId,
        personId,
        phoneId,
        update,
      );
    },
    onSuccess: (_data, variables) => {
      const found = queryClient.getQueryData<PlatformPerson>(
        useGetMeQueryOptions.queryKey,
      );
      if (found?.id === variables.personId) {
        queryClient.invalidateQueries({
          queryKey: useGetMeQueryOptions.queryKey,
        });
      }
    },
  });
};

export const useCreatePlatformPersonPhoneMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      accountId,
      personId,
      update,
    }: UseUpdatePlatformPersonPhoneArgs) => {
      return await platformClient.createPersonPhone(
        accountId,
        personId,
        update,
      );
    },
    onSuccess: (_data, variables) => {
      queryClient.invalidateQueries({
        queryKey: [
          'accounts',
          variables.accountId,
          'persons',
          variables.personId,
        ],
      });
      const found = queryClient.getQueryData<PlatformPerson>(
        useGetMeQueryOptions.queryKey,
      );
      if (found?.id === variables.personId) {
        queryClient.invalidateQueries({
          queryKey: useGetMeQueryOptions.queryKey,
        });
      }
    },
  });
};
