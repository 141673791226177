import { useStyles } from './summary.styles';
import { ReactNode, useState } from 'react';
import OnboardingButtonGroup from '../../components/onboarding-button-group';
import { Loader, Tooltip } from '@mantine/core';
import { CheckmarkIconGreen, EditIcon } from '../../../../assets/svg';

export type SummaryEditBoxClassnames = Partial<
  Record<
    | 'box'
    | 'placeholder'
    | 'editIcon'
    | 'actionContainer'
    | 'valueText'
    | 'saveButton'
    | 'cancelButton'
    | 'buttonGroup',
    string
  >
>;

type Props = {
  editInputs?: ReactNode;
  showEdit?: boolean;
  savePromise?: () => Promise<void>;
  label: string | ReactNode;
  value: string | ReactNode;
  onCancelClick?: () => void;
  classNames?: SummaryEditBoxClassnames;
};
export const SummaryEditBox = ({
  editInputs,
  showEdit = true,
  savePromise,
  label,
  value,
  onCancelClick,
  classNames,
}: Props) => {
  const { classes, cx } = useStyles();
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [showConfirm, setShowConfirm] = useState(false);

  const onSaveChanges = async () => {
    if (!savePromise) {
      throw new Error('A function to save data must be provided in edit mode.');
    }
    setError('');
    setLoading(true);
    try {
      await savePromise();
      setShowConfirm(true);
      setEditMode(false);
      setTimeout(() => {
        setShowConfirm(false);
      }, 3000);
    } catch (e) {
      if (typeof e === 'string') {
        setError(e);
      } else {
        setError('Could not save changes. An unexpected error occurred.');
      }
    } finally {
      setLoading(false);
    }
  };

  const onCancel = () => {
    if (onCancelClick) {
      onCancelClick();
    }
    setError('');
    setEditMode(false);
  };

  return (
    <div
      className={cx(
        classes.box,
        editMode && classes.editModeBox,
        error && classes.errorBox,
        classNames?.box,
      )}
    >
      {editMode ? (
        editInputs
      ) : (
        <div
          className={cx(
            classes.text,
            classes.placeholderText,
            classes.placeholderMargin,
            classNames?.placeholder,
          )}
        >
          {label}
        </div>
      )}
      <div
        className={cx(
          classes.summaryActionContainer,
          editMode && classes.editModeActionContainer,
          classNames?.actionContainer,
        )}
      >
        {editMode ? (
          <>
            {error && (
              <div className={cx(classes.text, classes.errorText)}>{error}</div>
            )}
            <OnboardingButtonGroup
              backButtonLabel="Cancel"
              nextButtonLabel={
                loading ? <Loader color="white" size="sm" /> : 'Save changes'
              }
              disableAll={loading}
              classNames={{
                nextButton: cx(
                  classes.summaryBoxSaveButton,
                  classNames?.saveButton,
                ),
                backButton: cx(
                  classes.summaryBoxBackButton,
                  classNames?.cancelButton,
                ),
                buttonGroup: cx(
                  classes.summaryBoxButtonGroup,
                  classNames?.buttonGroup,
                ),
              }}
              onBackClick={() => onCancel()}
              onNextClick={() => onSaveChanges()}
            />
          </>
        ) : (
          <>
            <div
              className={cx(
                classes.text,
                classes.valueText,
                classNames?.valueText,
              )}
            >
              {value}
            </div>
            {showEdit ? (
              showConfirm ? (
                <CheckmarkIconGreen
                  width={20}
                  height={20}
                  className={classes.alignEnd}
                />
              ) : (
                <EditIcon
                  className={cx(classes.editIcon, classNames?.editIcon)}
                  width={20}
                  height={20}
                  onClick={() => setEditMode(true)}
                />
              )
            ) : (
              <Tooltip label="Not right? Contact support to change.">
                <div className={cx(classes.text, classes.questionIcon)}>?</div>
              </Tooltip>
            )}
          </>
        )}
      </div>
    </div>
  );
};
