import {
  MantineSize,
  MantineThemeOverride,
  rem,
  SwitchStylesParams,
} from '@mantine/core';

// TODO: Replace pasted shades with better values, change color indexes
export const FlexbaseTheme: MantineThemeOverride = {
  fontFamily: 'PP Neue Montreal',
  headings: {
    fontFamily: 'Redaction',
    sizes: {
      h2: {
        fontSize: '28px',
        fontWeight: 400,
        lineHeight: '34px',
      },
      h3: {
        fontSize: '26px',
        fontWeight: 500,
        lineHeight: 'normal',
      },
    },
  },
  primaryColor: 'primarySecondarySuccess',
  primaryShade: 8,
  defaultRadius: 2,
  defaultGradient: { from: '#FF5745', to: '#FF8779', deg: 124.59 },
  colors: {
    'flexbase-orange': [
      'rgba(255, 87, 69, 0.1)',
      '#ECC7C3',
      '#EAA69E',
      '#FF8B7E',
      '#FF5745',
      '#E94B3A',
      '#D24334',
      '#B3453A',
      '#96473F',
      '#7F4640',
    ],
    'flexbase-rose': [
      '#FFF2F0',
      '#FFF2F0',
      '#FFF2F0',
      '#FFF2F0',
      '#FFF2F0',
      '#FFF2F0',
      '#FFF2F0',
      '#FFF2F0',
      '#FFF2F0',
      '#FFF2F0',
    ],
    'flexbase-teal': [
      '#27C281',
      '#27C281',
      '#27C281',
      '#27C281',
      '#27C281',
      '#27C281',
      '#27C281',
      '#27C281',
      '#27C281',
      '#27C281',
    ] as any,
    'flexbase-cosmic': [
      '#79E7D4',
      '#79E7D4',
      '#79E7D4',
      '#79E7D4',
      '#79E7D4',
      '#79E7D4',
      '#79E7D4',
      '#79E7D4',
      '#79E7D4',
      '#79E7D4',
    ] as any,
    flexbaseGrey: [
      '#E6E7E9',
      '#D1D5DB',
      '#C4C6C8',
      '#D1D5DB',
      '#E6E7E9',
      '#E6E7E9',
      '#E6E7E9',
      '#E6E7E9',
      '#E6E7E9',
      '#E6E7E9',
    ],
    blackish: ['#5F5F5F', '#4B4B4B', '#646464', '#454648'],
    failure: ['rgba(255, 87, 69, 0.08)', '#D80027'],
    positive: ['#ECF2EF', '#208D5F', '#1C6D4B'],
    url: ['#302CFF'],
    primarySecondarySuccess: [
      '#ECF1EF', // 0 primary-secondary-success-50
      '#A1BEAD', // 1 primary-secondary-success-100-light-bg
      '#437E5B', // 2 Secondary Dark brand color
      '#366549', // 3
      '#2F4D40', // 4 Hovers, #2F5840 same thing mostly
      '#284237', // 5
      '#223F2E', // 6
      '#14211B', // 7
      '#071C14', // 8 Primary dark brand color
      '#010202', // 9 primary-secondary-success-900-aaa-text-darkest
    ],
    neutral: [
      '#FEFEFD', // 0 Brand color
      '#E6E7E9', // 1
      '#F6F3EE', // 2 Primary Brand color
      '#C5C2BE', // 3 Used in outline button border
      '#ACAAA7', // 4
      '#94928F', // 5 --neutral-500-graphics-large-aa-text
      '#7B7A77', // 6
      '#4A4947', // 7
      '#313130', // 8
      '#191818', // 9
    ],
    tertiary: [
      '#FCF6EE',
      '#EFD5AB',
      '#DEAA57', // primary brand color
      '#B28846',
      '#9B773D',
      '#856634',
      '#6F552C',
      '#43331A',
      '#2C2211',
      '#161109',
    ],
    critical: [
      '#F9EEEC', // 0 critical-50
      '#E3AA9E', // 1 critical-100-light-bg
      '#C8553D', // 2 critical-200-main
      '#A04431', // 3 critical-300-border
      '#8C3C2B', // 4 critical-400
      '#783325', // 5 critical-500-graphics-large-aa-text
      '#642B1F', // 6 critical-600-aa-text
      '#3C1A12', // 7 critical-700-aaa-colorful
      '#28110C', // 8 critical-800-aaa-dark
      '#140906', // 9 critical-900-aaa-text-darkest
    ],
    promote: [
      '#F4F6F8', // 0
      '#C6D3DF',
      '#8DA7BE',
      '#718698',
      '#637585',
      '#556472',
      '#47545F',
      '#2A3239',
      '#1C2126',
      '#0E1113',
    ],
    contentBackground: ['#FAFAFA', '#FCFCFC', '#FFFFFF'],
    beige: ['#DDD3C4'],
  },
  breakpoints: {
    xs: rem(576),
    sm: rem(767),
    md: rem(992),
    lg: rem(1200),
    xl: rem(1400),
  },
  spacing: {
    xxs: rem(4),
    xs: rem(8),
    sm: rem(12),
    md: rem(16),
    lg: rem(18),
    xl: rem(24),
    xxl: rem(28),
    xxxl: rem(32),
  },
  components: {
    Checkbox: {
      styles: () => {
        return {
          checkbox: {
            div: {
              width: '18px',
              height: '18px',
            },
            input: {
              width: '18px',
              height: '18px',
              '&:checked': {
                backgroundColor: '#FFFFFF !important',
                borderColor: '#ced4da !important',
              },
              '&:hover': {
                borderColor: '#FF5745 !important',
              },
            },
            svg: {
              color: '#FF5745 !important',
            },
          },
        };
      },
    },
    Switch: {
      styles: (theme, params: SwitchStylesParams, context) => {
        const size: MantineSize =
          typeof context.size === 'string' ? context.size : 'sm';
        const colors = {
          thumb: '#fff',
          checked: params.color
            ? theme.fn.themeColor(params.color)
            : theme.fn.themeColor('primarySecondarySuccess', 6),
          unchecked: theme.fn.themeColor('neutral', 5),
        };

        const minWidthMap: Record<MantineSize, number> = {
          xs: 24,
          sm: 32,
          md: 36,
          lg: 44,
          xl: 56,
        };

        return {
          thumb: {
            borderColor: colors.thumb,
          },
          track: {
            backgroundColor: colors.unchecked,
            borderColor: colors.unchecked,
            minWidth: rem(minWidthMap[size || 'sm']),

            'input:checked:not(:disabled) + &': {
              backgroundColor: colors.checked,
              borderColor: colors.checked,
            },
          },
          trackLabel: {
            color: colors.thumb,
            minWidth: 0,
          },
        };
      },
    },
    Modal: {
      styles: () => {
        return {
          title: {
            fontFamily: 'PP Neue Montreal',
            fontSize: '28px',
            fontWeight: 400,
            lineHeight: '34px',
          },
        };
      },
    },
    Progress: {
      styles: (theme) => ({
        root: {
          backgroundColor: theme.fn.themeColor('neutral', 2),
        },
        bar: {
          backgroundColor: theme.fn.themeColor('primarySecondarySuccess', 2),
        },
      }),
    },
    Avatar: {
      styles: (theme) => {
        return {
          root: {
            borderRadius: '100%',
            border: '1px solid #FFFFFF',
            backgroundColor: theme.fn.themeColor('neutral', 2),
          },
          placeholder: {
            color: theme.fn.themeColor('primarySecondarySuccess', 8),
            background: 'unset',
            fontWeight: 400,
            fontSize: '14px',
          },
        };
      },
    },
    Button: {
      defaultProps: {
        size: 'md',
      },
      styles: (theme, _, context) => {
        // let hoverColor = '';
        // default stylings. override when necessary.
        let color = theme.fn.themeColor('neutral', 0);
        let backgroundColor = theme.fn.themeColor('primarySecondarySuccess', 6);

        switch (context.variant) {
          case 'subtle':
          case 'outline':
            backgroundColor = 'inherit';
            color = theme.fn.themeColor('neutral', 6);
            break;
          case 'light':
            backgroundColor = theme.fn.themeColor('primarySecondarySuccess', 2);
            break;
        }

        return {
          leftIcon: {
            svg: {
              stroke: 'currentColor',
            },
          },
          root: {
            borderRadius: 2,
            fontWeight: 500,
            fontSize: rem(14),
            lineHeight: rem(20),
            boxSizing: 'border-box',
            '&:before, &:after': {
              content: '""',
              position: 'absolute',
              width: 0,
              height: 0,
              boxSizing: 'border-box',
              transition:
                'width 0.18s ease-out, height 0.13s ease-out, border-color 0.1s',
            },
            '&:before': {
              left: context.variant === 'subtle' ? 0 : 3,
              bottom: context.variant === 'subtle' ? 0 : 3,
              borderBottom: '1px solid #00000000',
              borderLeft: '1px solid #00000000',
            },
            '&:after': {
              top: context.variant === 'subtle' ? 0 : 3,
              right: context.variant === 'subtle' ? 0 : 3,
              borderTop: '1px solid #00000000',
              borderRight: '1px solid #00000000',
            },
            color,
            height: context.size === 'md' ? rem(40) : undefined,
            ...(context.variant === 'outline' && {
              backgroundColor: 'inherit',
              border: `1px solid ${theme.fn.themeColor('neutral', 3)}`,
            }),
            ...((context.variant === 'default' ||
              context.variant === 'filled') && {
              border: `1px solid ${theme.fn.themeColor(
                'primarySecondarySuccess',
                6,
              )}`,
            }),
            '&:not([data-disabled])': theme.fn.hover({
              backgroundColor: theme.fn.themeColor(
                'primarySecondarySuccess',
                8,
              ),
              ...((context.variant === 'outline' ||
                context.variant === 'subtle') && {
                backgroundColor: 'inherit',
              }),
              ...(context.variant === 'light' && {
                backgroundColor: theme.fn.themeColor(
                  'primarySecondarySuccess',
                  2,
                ),
              }),
              '&:before , &:after': {
                ...((context.variant === 'default' ||
                  context.variant === 'filled' ||
                  context.variant === 'light') && {
                  borderColor: `${theme.fn.themeColor('neutral', 0)}`,
                }),
                ...((context.variant === 'outline' ||
                  context.variant === 'subtle') && {
                  borderColor: 'currentColor',
                }),
                height:
                  context.variant === 'subtle' ? '100%' : 'calc(100% - 6px)',
                width:
                  context.variant === 'subtle' ? '100%' : 'calc(100% - 6px)',
              },
            }),
            backgroundColor,
            '&[data-disabled]': {
              opacity: 0.5,
              backgroundColor: theme.fn.themeColor(
                'primarySecondarySuccess',
                8,
              ),
              ...((context.variant === 'outline' ||
                context.variant === 'subtle') && {
                backgroundColor: 'inherit',
              }),
              ...(context.variant === 'light' && {
                backgroundColor: theme.fn.themeColor(
                  'primarySecondarySuccess',
                  2,
                ),
              }),
              color,
            },
          },
        };
      },
    },
    /**
     * Note: Input and Input.Wrapper effectively target all inputs in Mantine.
     *
     * Input.Wrapper is used to style the label, error, description, and required asterisk
     * Input is used to style the icon, input, rightSection
     *
     * Both provide the "root" or wrapper styling as well but I'm leaving it out because I'm already confused
     * on why they divided them up into 2
     */
    InputWrapper: {
      styles: (theme) => {
        return {
          label: {
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: 14,
            lineHeight: rem(20),
            '&[data-invalid]': {
              color: theme.fn.themeColor('critical', 2),
            },
            marginBottom: 4,
          },
          error: {
            color: theme.fn.themeColor('critical', 2),
            fontWeight: 400,
            fontSize: rem(12),
            lineHeight: '1rem',
            marginTop: 6,
          },
        };
      },
    },
    Input: {
      styles: (theme) => {
        return {
          input: {
            height: 40,
            fontWeight: 500,
            color: theme.colors.blackish[0],
            fontSize: rem(14),
            lineHeight: rem(20),
            borderRadius: theme.defaultRadius,
            background: theme.fn.themeColor('neutral', 0),
            border: `1px solid ${theme.colors.flexbaseGrey[1]}`,
            '&:focus': { borderColor: theme.fn.primaryColor() },
            '&:focus-within': { borderColor: theme.fn.primaryColor() },
            '&[data-invalid]': {
              color: theme.fn.themeColor('critical', 2),
              borderColor: theme.fn.themeColor('critical', 3),

              '&::placeholder': {
                color: theme.fn.themeColor('critical', 2),
              },
            },
            '&::placeholder': {
              color: theme.fn.themeColor('neutral', 5),
            },
            [theme.fn.smallerThan('sm')]: {
              fontSize: '1rem',
            },
          },
        };
      },
    },
    PasswordInput: {
      styles: (theme) => {
        return {
          rightSection: {
            paddingRight: '1.2rem', // eyeballing it
            position: 'absolute',
          },
          visibilityToggle: {
            color: '#5F5F5F',
          },
          input: {
            padding: 0,
          },
          innerInput: {
            height: '100%',
            fontWeight: 500,
            color: theme.colors.blackish[0],
            fontSize: '14px',
            lineHeight: '16px',
            borderRadius: theme.defaultRadius,
            background: 'white',
            position: 'unset', // Mantine's password input is stupid and does not even follow styling of other Mantine inputs
            paddingLeft: '16px',
            paddingRight: '46px',
            width: '100%',
            '&[data-invalid]': {
              color: theme.fn.themeColor('critical', 2),
              borderColor: theme.fn.themeColor('critical', 2),
              '&::placeholder': {
                color: theme.fn.themeColor('critical', 1),
              },
            },
            [theme.fn.smallerThan('sm')]: {
              fontSize: '1rem',
            },
          },
        };
      },
    },
    Select: {
      styles: (theme) => ({
        rightSection: { pointerEvents: 'none' },
        item: {
          '&:not([data-disabled])': theme.fn.hover({
            backgroundColor: theme.fn.themeColor('primarySecondarySuccess', 0),
          }),
          '&[data-selected]': {
            backgroundColor: theme.fn.themeColor('primarySecondarySuccess', 1),
            color: theme.fn.themeColor('primarySecondarySuccess', 8),
          },
          '&:hover': theme.fn.hover({
            backgroundColor: theme.fn.themeColor('primarySecondarySuccess', 0),
          }),
        },
      }),
    },
    MultiSelect: {
      styles: (theme) => ({
        input: {
          display: 'flex',
          alignItems: 'center',
          height: 'fit-content',
          minHeight: 40,
        },
        searchInput: {
          [theme.fn.smallerThan('sm')]: {
            fontSize: '1rem',
          },
        },
        item: {
          '&:not([data-disabled])': theme.fn.hover({
            backgroundColor: theme.colors['neutral'][2],
          }),
          '&:hover': theme.fn.hover({
            backgroundColor: theme.colors['neutral'][2],
          }),
        },
      }),
    },
    Alert: {
      defaultProps: {
        color: 'red',
        withCloseButton: true,
      },
      styles: (theme, defaultProps) => {
        let iconColor = theme.colors.critical[2];
        let borderColor = theme.colors.critical[1];
        let backgroundColor = theme.colors.critical[0];

        switch (defaultProps.color) {
          case 'red':
            backgroundColor = theme.colors.critical[0];
            borderColor = theme.colors.critical[1];
            iconColor = theme.colors.critical[2];
            break;
          case 'green':
            backgroundColor = theme.colors.primarySecondarySuccess[0];
            borderColor = theme.colors.primarySecondarySuccess[1];
            iconColor = theme.colors.primarySecondarySuccess[3];
            break;
          case 'yellow':
            backgroundColor = theme.colors.tertiary[2];
            borderColor = theme.colors.tertiary[0];
            iconColor = theme.colors.tertiary[1];
            break;
          case 'info':
            backgroundColor = theme.colors.promote[0];
            borderColor = theme.colors.promote[1];
            iconColor = theme.colors.promote[3];
            break;
          case 'warning':
            backgroundColor = theme.colors.tertiary[0];
            borderColor = theme.colors.tertiary[1];
            iconColor = theme.colors.tertiary[2];
            break;
          default:
            backgroundColor = theme.colors.critical[0];
            borderColor = theme.colors.critical[1];
            iconColor = theme.colors.critical[2];
        }

        return {
          root: {
            borderRadius: 2,
            fontWeight: 500,
            backgroundColor,
            fontSize: rem(16),
            lineHeight: rem(24),
            boxSizing: 'border-box',
            color: theme.fn.primaryColor(),
            border: `1px solid ${borderColor}`,
          },
          icon: {
            color: iconColor,
            fill: iconColor,
          },
          closeButton: {
            color: iconColor,
          },
        };
      },
    },
    Badge: {
      styles: (theme) => {
        return {
          root: {
            borderRadius: 4,
            padding: '2px 6px',
            backgroundColor: theme.fn.themeColor('neutral', 1),
          },
          inner: {
            fontSize: 12,
            fontWeight: 400,
            color: theme.fn.themeColor('neutral', 7),
            textTransform: 'none',
          },
        };
      },
    },
    Menu: {
      styles: (theme) => {
        return {
          item: {
            '&:hover': theme.fn.hover({
              backgroundColor: theme.fn.themeColor('neutral', 2),
            }),
          },
        };
      },
    },
    Radio: {
      styles: (theme) => {
        return {
          radio: {
            ':checked': {
              background: theme.fn.themeColor('primarySecondarySuccess', 2),
              borderColor: theme.fn.themeColor('primarySecondarySuccess', 2),
            },
          },
        };
      },
    },
    // I do not know why Mantine has 3 different date pickers nor why they have different styles for each.
    DateInput: {
      styles: (theme) => ({
        day: {
          '&[data-weekend]': { color: theme.fn.themeColor('tertiary', 2) },
        },
      }),
    },
    DatePicker: {
      styles: (theme) => ({
        day: {
          '&[data-weekend]': { color: theme.fn.themeColor('tertiary', 2) },
        },
      }),
    },
    DatePickerInput: {
      styles: (theme) => ({
        day: {
          '&[data-weekend]': { color: theme.fn.themeColor('tertiary', 2) },
        },
      }),
    },
  },
};
