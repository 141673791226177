// These exist since the generated @types are wrong (camelCase instead of snake_case)

import { AuthenticationFactor } from '@flexbase-eng/types/dist/identity';

/**
 * @summary Authentication
 */
export type AuthenticationModel = {
  /**
   * @description Denotes the authorization flow to use
   */
  grant_type:
    | 'client_credentials'
    | 'password'
    | 'refresh_token'
    | 'link'
    | 'otp'
    | 'totp'
    | 'api';
  /**
   * @description Denotes the requested scopes
   */
  scope?: string;
  /**
   * @description The application's client identifier
   */
  client_id?: string;
};

/**
 * @summary Code
 * @description Represents an authentication code request
 */
export type CodeAuthenticationModel = AuthenticationModel & {
  /**
   * @description User's identifier, either an email or phone number in e164 format
   */
  // username: string;
  /**
   * @description A otp or totp value
   */
  code: string;
  /**
   * @description denotes the unique signature for one time login method denoted with the type of login and a guid
   */
  methodId: string;
  codeVerifier: string;
};

/**
 * @summary Authentication Token
 * @description Represents an authentication token
 */
export type AuthenticationTokenModel = {
  /**
   * @description The access token string as issued by the authorization server
   */
  access_token: string;
  /**
   * @description The type of token this is, typically just the string *Bearer*
   */
  token_type: string;
  /**
   * @description The duration of time the access token is granted for
   */
  expires_in: number;
  /**
   * @description A refresh token which applications can use to obtain another access token
   */
  refresh_token?: string;
  /**
   * @description The scopes the token is granted
   */
  scope: string;

  challenge_phone_number_last_four?: string;
};

export type PlatformToken = {
  personId: string;
  accountId: string;
  tenantId: string;
  businessId: string;
  ipAddr: string;
  userAgent: string;
  roles: string[];
  username: string;
  sessionToken: string;
  iat: number;
  exp: number;
  aud: string;
  iss: string;
  sub: string;
  factors: AuthenticationFactor[];
};

/**
 * @summary Authentication Challenge
 * @description Represents an authentication challenge
 */
export type AuthenticationChallengeModel = {
  /**
   * @description The method identifier to use
   */
  methodId: string;
  /**
   * @description The challenge type to issue
   */
  type: 'otp' | 'magic_link' | 'totp';

  /**
   * @description A base64url encoded SHA256 hash of a one time secret used to validate that the request starts and ends on the same device
   */
  codeChallenge?: string;
};

export type RegisterOtpFactorModel = {
  factorType: 'phone';
  codeChallenge: string;
  value: string;
};

export type RegisterTotpFactorModel = {
  factorType: 'totp';
  codeChallenge: string;
};

export type RegisterPasswordFactorModel = {
  factorType: 'password';
  value: string;
};

export type RegisterAuthenticationFactorModel =
  | RegisterOtpFactorModel
  | RegisterTotpFactorModel
  | RegisterPasswordFactorModel;

export type RegisterAuthenticationFactorResponseModel = {
  methodId: string;
  secret?: string;
  qrCode?: string;
  recoveryCodes?: string;
};

//#region Responses

export type postAuthorizeChallengeResponse = { $status: 204 };

/**
 * @description Example response
 */
// export type CreatedAuthenticationFactorResponse = {
//   $body: AuthenticatorFactorSetupModel;
// };

/**
 * @description Represents a list of authentication factors
 */
// export type AuthenticationFactorListResponse = {
//   $body: AuthenticationFactorListModel;
// };

export type WWWAuthenticateBearerError =
  | 'needs_mfa'
  | 'reset_password'
  | 'invalid_credentials'
  | 'invalid_link'
  | 'invalid_factor'
  | 'invalid_token'
  | 'invalid_code'
  | 'invalid_pkce';

export type FlexHttpWwwAuthenticate = {
  error: WWWAuthenticateBearerError;
  codeVerifier?: string;
};

export type PlatformError = {
  message: string;
  errors?: Record<string, unknown>;
};

export type PlatformResponse<T> = {
  statusCode: number;
  correlationId: string;
  requestId: string;
  wwwAuthenticate?: FlexHttpWwwAuthenticate;
  body?: T;
  error?: PlatformError;
  rawResponse: Response;
};

export class FlexHttpError extends Error {
  constructor(
    message: string | undefined,
    private readonly _statusCode: number,
    private readonly _correlationId: string,
    private readonly _requestId: string,
    private readonly _wwwAuthenticate: FlexHttpWwwAuthenticate | undefined,
    private readonly _errors: Record<string, unknown> | undefined,
    private readonly _headers: Headers,
  ) {
    super(message);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (this as any)[Symbol.toStringTag] = 'Object';

    // 👇️ because we are extending a built-in class
    Object.setPrototypeOf(this, FlexHttpError.prototype);
  }

  get statusCode() {
    return this._statusCode;
  }

  get correlationId() {
    return this._correlationId;
  }

  get requestId() {
    return this._requestId;
  }

  get wwwAuthenticate() {
    return this._wwwAuthenticate;
  }

  get errors() {
    return this._errors;
  }

  get headers() {
    return this._headers;
  }
}

export const IsFlexHttpError = (error: unknown): error is FlexHttpError => {
  return error instanceof FlexHttpError;
};

export function IsSuccessResponse<T>(response: PlatformResponse<T>): boolean {
  return response.statusCode >= 200 && response.statusCode <= 299;
}

export type PKCE = { codeVerifier: string; codeChallenge: string };
