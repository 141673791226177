import { useQuery } from '@tanstack/react-query';
import { flexbaseOnboardingClient } from '../services/flexbase-client';
import { Issuer } from '@services/flexbase/credit.model';

const QUERY_KEY = 'rebateHistory';

export const useGetRebateHistory = (companyId: string, issuer?: Issuer) => {
  return useQuery({
    queryKey: [QUERY_KEY, companyId, issuer],
    queryFn: async () => {
      return await flexbaseOnboardingClient.getRebateHistory(companyId, issuer);
    },
    meta: {
      errorMessage:
        'We are unable to retrieve the company payments at the moment.',
    },
  });
};
