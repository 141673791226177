import {
  Box,
  Button,
  Flex,
  Group,
  SegmentedControl,
  Stack,
  Text,
} from '@mantine/core';
import { useStyles } from './add-account.styles';
import { VscChromeClose } from 'react-icons/vsc';
import { PayMethod } from 'areas/payments/components/send-payment/payment.states';
import { useForm } from '@mantine/form';
import FlexbaseInput from '@common/input/flexbase-input';
import { FiUser } from 'react-icons/fi';
import { RiBuilding4Line } from 'react-icons/ri';
import { PlusSignIcon } from 'assets/svg';
import { useCreateCounterparty } from '@queries/use-counterparties';
import { flexbaseBankingClient } from '@services/flexbase-client';
import {
  CounterpartyBase,
  createCounterpartyMutationParams,
} from 'areas/payments/components/send-payment/send-payment';
import { showNotification } from '@mantine/notifications';
import { validateRequired, ValidateRoutingNumber } from '@utilities/validators';
import FlexbaseSelect from '@common/select/flexbase-select';
import GooglePlacesSuggest from '@common/input/google-places-suggest-input';
import { formatZip } from '@utilities/formatters/format-address';
import { Dispatch, SetStateAction } from 'react';
import { ParsedAccount } from '../types';
import { useQueryClient } from '@tanstack/react-query';
import { RECIPIENT } from '@queries/use-recipients';
import { IndividualOrCompany } from 'areas/payments/components/send-payment/international-payments/util/types';
import { ACH_COPY, WIRE_COPY, US_STATES } from 'constants/constants';

type Props = {
  closeModal: () => void;
  recipientId: string;
  recipientName: string;
  setAccountsInfo?: Dispatch<SetStateAction<ParsedAccount[]>>;
};

const AddAccount = ({ closeModal, recipientId, recipientName }: Props) => {
  const { classes, theme, cx } = useStyles();
  const {
    mutate: createCounterparty,
    isPending: isPendingCounterpartyCreation,
  } = useCreateCounterparty();

  const form = useForm({
    initialValues: {
      type: 'ach' as PayMethod,
      nickName: '',
      routingNumber: '',
      accountNumber: '',
      recipientType: 'individual' as IndividualOrCompany,
      accountType: '',
      address: '',
      addressLine2: '',
      city: '',
      state: '',
      postalCode: '',
      country: 'US',
    },
    validate: {
      routingNumber: (value) =>
        ValidateRoutingNumber(value)
          ? null
          : 'Routing number is invalid. Must be 9 digits and match an existing bank routing number.',
      accountNumber: (value) =>
        !!value && value.length <= 17
          ? null
          : 'A valid account number is required.',
      accountType: (value) => {
        // only required for ach
        if (form.values.type === 'ach') {
          return validateRequired(value)
            ? null
            : 'Please select an account type.';
        }
      },
      address: (value) => {
        if (form.values.type === 'wire') {
          return validateRequired(value) ? null : 'Please select an address.';
        }
      },
      city: (value) => {
        if (form.values.type === 'wire') {
          return validateRequired(value)
            ? null
            : 'Please add the city for the address.';
        }
      },
      state: (value) => {
        if (form.values.type === 'wire') {
          return validateRequired(value)
            ? null
            : 'Please select the state for the address.';
        }
      },
      postalCode: (value) => {
        if (form.values.type === 'wire') {
          return validateRequired(value)
            ? null
            : 'Please input a 5 digit postal code.';
        }
      },
      country: (value) => {
        if (form.values.type === 'wire') {
          return validateRequired(value)
            ? null
            : 'Please input a country code.';
        }
      },
    },
    transformValues: (values) => ({
      ...values,
      postalCode: formatZip(values.postalCode),
    }),
  });

  const selectAddress = (item: {
    address: string;
    country: string;
    state: string;
    city: string;
    postalCode: string;
  }) => {
    form.setValues({
      address: item.address.trim(),
      city: item.city,
      state: item.state,
      postalCode: item.postalCode,
      country: item.country,
    });
  };

  const queryClient = useQueryClient();

  const createAccount = async (newRecipient: CounterpartyBase) => {
    const validationResult = form.validate();

    if (validationResult.hasErrors) {
      return null;
    } else {
      try {
        const valRouting = await flexbaseBankingClient.checkRoutingNumberUnit(
          form.values.routingNumber,
        );
        if (!valRouting.success || !valRouting.institution) {
          form.setFieldError(
            'routingNumber',
            'Routing number is not recognized',
          );
          return null;
        }
        switch (form.values.type) {
          case 'ach':
            if (!valRouting.institution.isACHSupported) {
              form.setFieldError(
                'routingNumber',
                'Invalid routing number for ACH payments',
              );
              return null;
            }
            break;
          case 'wire':
            if (!valRouting.institution.isWireSupported) {
              form.setFieldError(
                'routingNumber',
                'Invalid routing number for domestic wire payments',
              );
              return null;
            }
            break;
        }
      } catch (e) {
        form.setFieldError(
          'routingNumber',
          'Routing number could not be validated',
        );
        return null;
      }

      const params = createCounterpartyMutationParams(
        newRecipient,
        form.values.type,
        recipientName,
        recipientId,
      );

      createCounterparty(params, {
        onSuccess: () => {
          showNotification({
            title: 'Success!',
            message: 'Recipient has been successfully created',
            color: 'flexbase-teal',
          });

          queryClient.invalidateQueries({
            queryKey: [RECIPIENT, recipientId],
          });
          closeModal();
        },
        onError: (error) => {
          showNotification({
            color: 'red',
            title: 'Error',
            message: `${error.message}`,
          });
        },
      });
    }
  };

  const handleSubmit = (values: CounterpartyBase) => {
    createAccount(values);
  };

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Group className={classes.headerRow1}>
        <Group spacing="md">
          <Stack spacing="xs">
            <Group align="end" spacing="lg">
              <Text className={classes.title}>Add an account</Text>
            </Group>
          </Stack>
        </Group>
        <Button
          variant="outline"
          className={classes.closeButton}
          onClick={closeModal}
        >
          <VscChromeClose color={theme.colors.contentBackground[2]} />
        </Button>
      </Group>
      <Flex h="85vh" justify="space-between" direction="column">
        <Box p="xl">
          <SegmentedControl
            mt="md"
            fullWidth
            classNames={{
              indicator: classes.select,
              root: classes.selectContainer,
            }}
            data={[
              {
                value: 'ach',
                label: (
                  <>
                    <Text>Domestic ACH</Text>
                    <Text color={theme.colors.neutral[7]}>{ACH_COPY}</Text>
                  </>
                ),
              },
              {
                value: 'wire',
                label: (
                  <>
                    <Text>Domestic Wire</Text>
                    <Text color={theme.colors.neutral[7]}>{WIRE_COPY}</Text>
                  </>
                ),
              },
            ]}
            onChange={(value: string) =>
              form.setFieldValue('type', value as PayMethod)
            }
            value={form.values.type}
          />
          <FlexbaseInput
            mt="xl"
            label={
              <Text>
                Nickname{' '}
                <Text span color={theme.colors.neutral[6]}>
                  (optional)
                </Text>
              </Text>
            }
            {...form.getInputProps('nickName')}
            placeholder="Enter nickname (optional)"
          />
          <Flex justify="space-between" mt="md">
            <FlexbaseInput
              mt="md"
              w="48%"
              label={'Routing number'}
              placeholder="Enter routing number"
              {...form.getInputProps('routingNumber')}
            />
            <FlexbaseInput
              mt="md"
              w="48%"
              label={'Account number'}
              placeholder="Enter account number"
              {...form.getInputProps('accountNumber')}
            />
          </Flex>
          {form.values.type === 'ach' ? (
            <>
              <Flex mt="3rem" align="center">
                <Text size="sm">Recipient type</Text>
                <Button
                  className={cx(
                    form.values.recipientType === 'individual'
                      ? classes.btnSelected
                      : classes.btnOptions,
                  )}
                  ml="1rem"
                  onClick={() =>
                    form.setFieldValue('recipientType', 'individual')
                  }
                  leftIcon={<FiUser />}
                  variant="filled"
                >
                  Person
                </Button>
                <Button
                  className={cx(
                    form.values.recipientType === 'company'
                      ? classes.btnSelected
                      : classes.btnOptions,
                  )}
                  ml="1rem"
                  onClick={() => form.setFieldValue('recipientType', 'company')}
                  leftIcon={<RiBuilding4Line />}
                  variant={'filled'}
                >
                  Business
                </Button>
              </Flex>
              <Flex mt="xl" align="center">
                <Text size="sm">Account type</Text>
                <Button
                  className={cx(
                    form.values.accountType === 'Checking'
                      ? classes.btnSelected
                      : classes.btnOptions,
                  )}
                  onClick={() => form.setFieldValue('accountType', 'Checking')}
                  ml="1rem"
                  leftIcon={<FiUser />}
                  variant="filled"
                >
                  Checking
                </Button>
                <Button
                  className={cx(
                    form.values.accountType === 'Savings'
                      ? classes.btnSelected
                      : classes.btnOptions,
                  )}
                  onClick={() => form.setFieldValue('accountType', 'Savings')}
                  ml="1rem"
                  leftIcon={<RiBuilding4Line />}
                  variant="filled"
                >
                  Savings
                </Button>
              </Flex>
            </>
          ) : (
            <>
              <Text mt="lg" size="xl">
                Recipient’s legal address
              </Text>
              <GooglePlacesSuggest
                label="Recipient legal address"
                {...form.getInputProps('address')}
                onItemSubmit={selectAddress}
                placeholder="Address"
                className={classes.addressInput}
                id="input-search-address"
              />
              <FlexbaseInput
                mt="md"
                label="Apartment, suite, floor (optional)"
                data-testid="address2"
                placeholder="Apt, suite, etc. (optional)"
                {...form.getInputProps('addressLine2')}
              />
              <Flex justify="space-between">
                <FlexbaseInput
                  mt="md"
                  label="City"
                  data-testid="city"
                  placeholder="City"
                  w="60%"
                  {...form.getInputProps('city')}
                />
                <FlexbaseSelect
                  mt="md"
                  label="State"
                  w="40%"
                  ml="md"
                  placeholder="State"
                  data-testid="state"
                  data={US_STATES}
                  searchable
                  maxDropdownHeight={400}
                  nothingFound="No data"
                  filter={(value, item) => {
                    const lowerCaseValue = value.toLowerCase();
                    return (
                      item.label?.toLowerCase().includes(lowerCaseValue) ||
                      item.value.toLowerCase().includes(lowerCaseValue)
                    );
                  }}
                  {...form.getInputProps('state')}
                />
                <FlexbaseInput
                  mt="md"
                  w="24%"
                  ml="md"
                  label="ZIP Code"
                  data-testid="zip"
                  placeholder="Zip Code"
                  {...form.getInputProps('postalCode')}
                />
              </Flex>
              <FlexbaseInput
                mt="md"
                label="Country"
                data-testid="country"
                placeholder="US"
                {...form.getInputProps('country')}
              />
            </>
          )}
        </Box>
        <Box>
          <Box className={classes.containerButton}>
            <hr />
            <Group className={classes.buttonGroup}>
              <Button
                variant="outline"
                onClick={() => {
                  closeModal();
                }}
                bg="neutral.0"
              >
                Cancel
              </Button>
              <Button
                loading={isPendingCounterpartyCreation}
                leftIcon={<PlusSignIcon width="0.5rem" />}
                type="submit"
              >
                Add Account
              </Button>
            </Group>
          </Box>
        </Box>
      </Flex>
    </form>
  );
};

export default AddAccount;
