import { FileWithPath } from '@mantine/dropzone';
import { platformClient } from '../services/platform/platform-client';
import { useMutation } from '@tanstack/react-query';

type CreateInvoiceDocumentArgs = {
  type: string;
  file: FileWithPath;
};

export function useCreateInvoiceDocument() {
  return useMutation({
    mutationFn: (props: CreateInvoiceDocumentArgs) => {
      return platformClient.createInvoiceDocument(props);
    },
  });
}
