import {
  Anchor,
  Box,
  BoxProps,
  createStyles,
  Loader,
  rem,
  Stack,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { Fragment, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { flexbaseOnboardingClient } from 'services/flexbase-client';
import { TermsOfServiceDoc } from 'services/flexbase/flexbase-onboarding-client';
import { CompanySelector } from 'recoil-state/application/product-onboarding';

const creditDisclaimer =
  'The Flex Commercial Credit Card (“Flex Credit Card”) is issued by Patriot Bank, N.A., pursuant to a license from Mastercard® International Incorporated. The Flex Credit Card can be used everywhere Mastercard is accepted. Mastercard is a registered trademark of Mastercard International. Flex provides the credit for the Flex Credit Card. The Flex Credit Card is not available yet in the following states: CA, ND, SD, VT, and NV.';

const bankingDisclaimer =
  'Flexbase Technologies, Inc. is a financial technology company and is not a bank. Banking services provided by Thread Bank, Member FDIC. The Flexbase Technologies Inc. Visa® Debit Card (“Flex Debit Card” / “Flex Banking”) is issued by Thread Bank pursuant to a license from Visa U.S.A. Inc. and may be used anywhere Visa debit cards are accepted.';

const flexDisclaimer =
  '©2023 Flexbase Technologies, Inc., d/b/a Flex, “Flex" and the Flex logo are registered trademarks. Flex products may not be available to all customers. See the Flex Terms of Service for details. Terms are subject to change.';

const useStyles = createStyles((theme) => ({
  footer: {
    borderTop: `${rem(1)} solid ${
      theme.colorScheme === 'dark'
        ? theme.colors.dark[5]
        : theme.colors.neutral[3]
    }`,
    padding: '20px 40px 20px 40px',
    lineHeight: 1,
    '@media(max-width: 767px)': {
      marginTop: theme.spacing.md,
    },
  },

  inner: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: theme.spacing.xs,
    paddingBottom: theme.spacing.xs,
    [theme.fn.smallerThan(1280)]: {
      padding: 0,
    },
    '@media(max-width: 767px)': {
      flexDirection: 'row',
      padding: 0,
      marginBottom: 15,
    },
  },

  disclaimer: {
    fontSize: '10px',
    width: '100%',
  },

  contact: {
    fontSize: '10px',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    marginRight: theme.spacing.md,
    '@media(max-width: 767px)': {
      marginTop: 8,
    },
  },

  disclosure: {
    fontSize: '10px',
    fontWeight: 500,
    marginRight: 66,
  },
  disclosureContainer: {
    maxWidth: 500,
    '@media(max-width: 767px)': {
      maxWidth: 200,
    },
  },
}));

type ClassNames = {
  footer: string;
  footerInner: string;
  footerDisclaimer: string;
  footerDisclaimer2: string;
  footerContact: string;
  footerDiscloure: string;
  footerDisclosureContainer: string;
};

type Props = {
  disclaimer2?: boolean;
  footerMarginTop?: string;
  classNames?: Partial<ClassNames>;
} & Omit<BoxProps, 'classNames'>;

export const FlexbaseFooter = ({
  disclaimer2 = false,
  footerMarginTop,
  classNames,
  ...boxProps
}: Props) => {
  const { classes, cx } = useStyles();
  const [loading, setLoading] = useState(false);
  const [termsDocuments, setTermsDocuments] = useState<TermsOfServiceDoc[]>([]);
  const isMobile = useMediaQuery('(max-width: 767px)');
  const { optedProducts } = useRecoilValue(CompanySelector);

  const getTermsOfServiceDocs = async () => {
    setLoading(true);
    try {
      const flexbaseDocs = await flexbaseOnboardingClient.getTermsOfServiceDocs(
        'flexbase',
        false,
      );

      const footerTerms = flexbaseDocs.filter(
        (item) =>
          item.label === 'Privacy Policy' ||
          item.label === 'Terms and Conditions',
      );

      setTermsDocuments(footerTerms);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTermsOfServiceDocs();
  }, []);

  const supportContact = (
    <Stack
      className={cx(classes.contact, classNames?.footerContact)}
      align="flex-start"
      spacing="sm"
    >
      <div style={{ marginRight: 66 }}>
        <Anchor
          c={disclaimer2 ? undefined : 'neutral.0'}
          href="mailto:support@flex.one"
        >
          support@flex.one
        </Anchor>
      </div>
      <div>(415) 840-8721 ext. 571</div>
    </Stack>
  );

  const disclosures = loading ? (
    <Loader
      size="sm"
      variant="dots"
      c={disclaimer2 ? undefined : 'neutral.0'}
    />
  ) : (
    <Box
      sx={() => ({
        display: 'flex',
        flexDirection: 'row',
      })}
    >
      {termsDocuments.map((doc, i) => {
        return (
          <div key={doc.tosId + i}>
            <Anchor
              href={doc.url}
              target="_blank"
              rel="noreferrer"
              c={disclaimer2 ? undefined : 'neutral.0'}
              className={cx(classes.disclosure, classNames?.footerDiscloure)}
            >
              {doc.label}
            </Anchor>
          </div>
        );
      })}
    </Box>
  );

  return (
    <Box
      mt={footerMarginTop}
      className={cx(classes.footer, classNames?.footer)}
      {...boxProps}
    >
      <div>
        {!isMobile ? (
          <Box
            className={cx(classes.inner, classNames?.footerInner)}
            mb="1.5rem"
          >
            {disclosures}
            {supportContact}
          </Box>
        ) : (
          <Box
            className={cx(classes.inner, classNames?.footerInner)}
            style={{ paddingTop: 0 }}
          >
            <div>
              {disclosures}
              {supportContact}
            </div>
          </Box>
        )}

        <Box className={cx(classes.inner, classNames?.footerInner)}>
          <Stack spacing="xs">
            {optedProducts?.map((p, i) => {
              if (p === 'CREDIT') {
                return (
                  <div key={p + i} className={classes.disclaimer}>
                    {creditDisclaimer}
                  </div>
                );
              }
              if (p === 'BANKING') {
                return (
                  <Fragment key={p + i}>
                    <div className={classes.disclaimer}>
                      {bankingDisclaimer}
                    </div>
                    <div className={classes.disclaimer}>{flexDisclaimer}</div>
                  </Fragment>
                );
              }
            })}
          </Stack>
          {!optedProducts?.length && (
            <Stack spacing="xs">
              <div className={classes.disclaimer}>{bankingDisclaimer}</div>
              <div className={classes.disclaimer}>{creditDisclaimer}</div>

              {!disclaimer2 && (
                <>
                  <div className={classes.disclaimer}>
                    *0% interest applies if the full balance is paid within the
                    60-day interest-free grace period. Interest will begin to
                    accrue if the full balance is not paid by the end of the
                    current bi-monthly billing period following the 60-day
                    interest-free grace period. See your Flex Commercial Credit
                    Cardholder Agreement for details.
                  </div>
                  <div className={classes.disclaimer}>
                    **See your Flex Cashback Terms and Conditions for details.
                  </div>
                  <div className={classes.disclaimer}>
                    ***See your Flex Deposit Account Terms and Conditions for
                    details. Annual Percentage Yield (APY) of up to 3.17% for
                    Tier 3 accounts and up to 1.74% APY for Tier 2 accounts is
                    accurate as of 11/08/2024. These are variable rates based on
                    the effective range of the Federal Funds Rate and may change
                    after the account is opened. An average daily Account
                    balance that is greater than $1,000,000 for at least thirty
                    (30) days is required to be eligible to earn 3.17% APY and
                    an average daily Account balance that is greater than
                    $100,000 for at least thirty (30) days is required to be
                    eligible to earn 1.74% APY.
                  </div>
                  <div className={classes.disclaimer}>
                    ****Your deposits qualify for up to $3,000,000 in FDIC
                    insurance coverage when placed at program banks in the
                    Thread Bank deposit sweep program. Your deposits at each
                    program bank become eligible for FDIC insurance up to
                    $250,000, inclusive of any other deposits you may already
                    hold at the bank in the same ownership capacity. You can
                    access the terms and conditions of the sweep program at{' '}
                    <Anchor
                      color="neutral.0"
                      target="_blank"
                      rel="noreferrer"
                      href="https://go.thread.bank/sweepdisclosure"
                    >
                      https://go.thread.bank/sweepdisclosure
                    </Anchor>{' '}
                    and a list of program banks at{' '}
                    <Anchor
                      color="neutral.0"
                      target="_blank"
                      rel="noreferrer"
                      href="https://go.thread.bank/programbanks"
                    >
                      https://go.thread.bank/programbanks
                    </Anchor>
                    . Please contact{' '}
                    <Anchor
                      color="neutral.0"
                      href="mailto:customerservice@thread.bank"
                    >
                      customerservice@thread.bank
                    </Anchor>{' '}
                    with questions regarding the sweep program. Pass-through
                    insurance coverage is subject to conditions.
                  </div>
                </>
              )}

              <div className={classes.disclaimer}>{flexDisclaimer}</div>
            </Stack>
          )}
        </Box>
      </div>
    </Box>
  );
};
