import { Flex, useMantineTheme } from '@mantine/core';
import { CiCircleAlert } from 'react-icons/ci';

const ErrorIcon = () => {
  const theme = useMantineTheme();
  return (
    <Flex
      sx={{
        backgroundColor: theme.colors.critical[0],
        height: 78,
        width: 78,
        borderRadius: '50%',
        border: `2px solid ${theme.colors.critical[3]}`,
      }}
      align="center"
      justify="center"
    >
      <CiCircleAlert
        size={40}
        color={theme.colors.critical[6]}
        strokeWidth={0.75}
      />
    </Flex>
  );
};

export default ErrorIcon;
