import { createStyles, rem } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  frozen: {
    position: 'absolute',
    zIndex: 1,
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
    borderRadius: theme.defaultRadius,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(48, 44, 255, 0.03)',
    '@media(max-width: 767px)': {
      width: '100%',
    },
  },
  unfreezeButton: {
    width: rem(242),
    lineHeight: '28px',
    textAlign: 'center',
    alignItems: 'center',
    borderRadius: '100px',
    fontSize: theme.spacing.sm,
    backgroundColor: theme.fn.themeColor('promote', 1),
  },
  payNow: {
    cursor: 'pointer',
    textDecoration: 'underline',
    background: 'transparent',
    border: 0,
  },
  unfreezePlaidBtn: {
    textAlign: 'center',
    color: 'white',
    width: '93px',
    padding: theme.spacing.xs,
    lineHeight: '28px',
    borderRadius: theme.defaultRadius,
    backgroundColor: theme.fn.primaryColor(),
    fontSize: 14,
    fontFamily: theme.fontFamily,
  },
}));
