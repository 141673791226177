import { useRecoilValue } from 'recoil';
import { Button, Menu } from '@mantine/core';
import { useStyles } from '../transaction-details.styles';
import { IsAccountant } from '../../../../../../recoil-state/application/product-onboarding';

type Props = {
  disputeOpen?: boolean;
  actionButtonMenuOpen: boolean;
  setActionButtonMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setDisputeOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const DisputeTransaction = ({
  disputeOpen,
  actionButtonMenuOpen,
  setActionButtonMenuOpen,
  setDisputeOpen,
}: Props) => {
  const { classes, theme } = useStyles({
    flagMenuHeight: disputeOpen ? '235px' : '115px',
  });
  const isAccountant = useRecoilValue(IsAccountant);

  return (
    <Menu
      classNames={{
        dropdown: classes.flagMenu,
        itemLabel: classes.flagMenuText,
        item: classes.flagMenuItem,
      }}
      closeOnItemClick={false}
      opened={actionButtonMenuOpen}
      onChange={(open) => setActionButtonMenuOpen(open)}
    >
      <Menu.Target>
        <Button disabled={isAccountant}>Dispute Transaction</Button>
      </Menu.Target>
      <Menu.Dropdown>
        {disputeOpen ? (
          <div className={classes.disputeMenu}>
            <div className={classes.disputeTitle}>Dispute Transaction?</div>
            <div className={classes.disputeText}>
              Please email us the date, transaction amount, disputed amount,
              merchant name and reason for your dispute. Include any applicable
              evidence to support the dispute. Note that disputes may take up to
              120 days to resolve with the MasterCard&trade; and Visa&trade;
              networks.
            </div>
            <div className={classes.disputeButtons}>
              <Button
                variant="outline"
                color="gray"
                onClick={() => setActionButtonMenuOpen(false)}
              >
                Nevermind
              </Button>
              <Button
                color={theme.fn.primaryColor()}
                onClick={() => {
                  window.location.href = 'mailto:disputes@flex.one';
                  setActionButtonMenuOpen(false);
                }}
              >
                Email us to dispute
              </Button>
            </div>
          </div>
        ) : (
          <>
            <Menu.Item
              onClick={() => {
                setDisputeOpen(true);
              }}
            >
              Dispute Transaction
            </Menu.Item>
            <Menu.Item disabled>Flag transaction</Menu.Item>
          </>
        )}
      </Menu.Dropdown>
    </Menu>
  );
};
