import { useEffect, useMemo } from 'react';
import { Box, Grid, Text } from '@mantine/core';
import MakePaymentWidget from 'areas/cash-management/components/make-payment.widget';
import SkeletonLoading from '../../../components/loading/skeleton-loading';
import { useGetDepositAccounts } from '../../../queries/use-deposit-accounts';
import { useRecoilValue } from 'recoil';
import { formatCurrency } from '../../../utilities/formatters';
import ProgressPercent from '../components/progress-percent/progress-percent';
import { useStyles } from '../styles';
import GenericError from '../utils/generic-error';
import RecentTransactionsWidget from './recent-transactions-widget';
import { useBankingAccountsFilters } from './use-banking-filters';
import BankingAccountsHeader from './banking-accounts-header';
import ControlPersonMenu from 'areas/credit/tabs/overview/control-person-menu';
import { modals } from '@mantine/modals';
import { useGetUsers } from '../../../queries/use-users';
import { ApplicationState } from '../../../recoil-state/application/product-onboarding';
import IntlWiresCTA from './international-wires-cta';
import { useShowInternationalPaymentsCTA } from 'areas/payments/components/send-payment/international-payments/hooks/use-show-cta';
import { useGetReserveAccountsList } from '../../../queries/user-reserve-account';
import BankingAccountsTable from './banking-accounts-table';
import { useGetChargeCardAccounts } from '../../../queries/use-charge-card-accounts';
import { DepositAccount } from '../move-funds/move-funds.model';
import { ReserveAccount } from '../../../services/flexbase/banking.model';

export type AccountTableData = {
  id: string;
  nickName: string;
  balance: number;
  status: string;
  isReserveAccount: boolean;
  // reserve accounts won't have these values
  earlyPay?: boolean;
  instantPay?: boolean;
  accountNumber?: string;
  routingNumber?: string;
};

export type RepaymentConfig = {
  earlyPay: string;
  instantPay: string;
}[];

const sortAccounts = (data: AccountTableData[]) => {
  return data.slice().sort((a, b) => {
    if (a?.status === 'Open' && b?.status === 'Open') {
      return b.balance - a.balance;
    } else if (a?.status === 'Open') {
      return -1;
    } else if (b?.status === 'Open') {
      return 1;
    }
    return 0;
  });
};

const BankingAccount = () => {
  const { classes } = useStyles();
  const { data, isSuccess, isLoading, isError } = useGetDepositAccounts();

  const { activeFiltersArray } = useBankingAccountsFilters();
  const { data: employees, isLoading: loadingEmployees } = useGetUsers();
  const { user, company } = useRecoilValue(ApplicationState);
  const showInternationalPaymentsCTA = useShowInternationalPaymentsCTA();

  const {
    data: reserveAccountsData,
    isError: isReserveAccountsError,
    isLoading: isLoadingReserveAccounts,
  } = useGetReserveAccountsList();

  const reserveAccounts = reserveAccountsData?.reserveAccounts ?? [];
  const reserveAccountBalance =
    reserveAccounts.reduce((acc, curr) => acc + Number(curr.balance), 0) / 100;

  const { data: chargeAccountsData, isLoading: isLoadingChargeAccounts } =
    useGetChargeCardAccounts();
  const chargeAccounts =
    chargeAccountsData?.accounts.filter((acc) => acc.status === 'Open') ?? [];
  const depositIdFromchargeAccounts = chargeAccounts.map((acc) => ({
    earlyPay: acc.repaymentConfigurations?.earlyPay?.depositAccountId,
    instantPay: acc.repaymentConfigurations?.instantPay?.depositAccountId,
  }));

  const getDepositAccounts = (
    depositAccounts: DepositAccount[],
    _depositIdFromchargeAccounts: RepaymentConfig,
  ) => {
    return depositAccounts.map((acc) => {
      const hasEarlyPay = _depositIdFromchargeAccounts.find(
        (id) => id.earlyPay === acc.id,
      );
      const hasInstantPay = _depositIdFromchargeAccounts.find(
        (id) => id.instantPay === acc.id,
      );

      return {
        id: acc.id,
        balance: acc.balance / 100,
        nickName: acc.nickName,
        status: acc.status,
        accountNumber: acc.accountNumber,
        routingNumber: acc.routingNumber,
        isReserveAccount: false,
        earlyPay: !!hasEarlyPay,
        instantPay: !!hasInstantPay,
      };
    });
  };

  const getReserveAccounts = (_reserveAccounts: ReserveAccount[]) => {
    return _reserveAccounts.map((acc) => ({
      id: acc.id,
      balance: acc.balance / 100,
      nickName: 'Reserve Account',
      status: acc.status,
      isReserveAccount: true,
    }));
  };

  const filteredAccounts = useMemo(() => {
    if (!isSuccess) return [];

    const depositAccounts = sortAccounts(
      getDepositAccounts(data.accounts, depositIdFromchargeAccounts),
    );
    const _reserveAccounts = sortAccounts(getReserveAccounts(reserveAccounts));

    const accountsList = [..._reserveAccounts, ...depositAccounts];
    return accountsList.filter((t) => activeFiltersArray.every((f) => f.fn(t)));
  }, [
    data,
    activeFiltersArray,
    isSuccess,
    reserveAccountsData,
    depositIdFromchargeAccounts,
  ]);

  const AccountSummaryCard = () => {
    return (
      <ProgressPercent
        currentAmount={bankInfo.balance / 100}
        title="Active banking balance"
        totalAvailableBalance={(bankInfo.balance / 100) * 2}
        classNames={{ card: classes.cardOverride }}
      >
        {reserveAccountBalance > 0 && (
          <>
            <Text size={16} color="#5F5F5F" weight={500} mt={10}>
              Reserve balance
            </Text>
            <Text size={26} color="#000000" weight={500} className={classes.pp}>
              {formatCurrency(reserveAccountBalance)}
            </Text>
          </>
        )}
      </ProgressPercent>
    );
  };

  // temp cals for tiers
  const bankAccountsInfo = () => {
    let balance = 0;

    data?.accounts.forEach((account) => {
      balance += account.balance;
    });
    return {
      balance,
    };
  };
  const bankInfo = bankAccountsInfo();

  const openModal = () =>
    modals.open({
      title: 'Please select a control person',
      children: (
        <ControlPersonMenu employees={employees} isLoading={loadingEmployees} />
      ),
      size: 'lg',
      closeOnClickOutside: false,
      closeOnEscape: false,
      withCloseButton: false,
    });

  useEffect(() => {
    const filterUser = employees?.filter((item) => item.promoCodeId);
    if (
      filterUser?.length === 0 &&
      user.roles?.includes('ADMIN') &&
      !company.controlPerson
    ) {
      openModal();
    }
  }, [employees]);

  if (isError ?? isReserveAccountsError) {
    return (
      <GenericError>
        <p>We are unable to retrieve the account information at the moment.</p>
      </GenericError>
    );
  }

  if (isLoading ?? isLoadingReserveAccounts ?? isLoadingChargeAccounts) {
    return <SkeletonLoading />;
  }

  return (
    <>
      <Grid>
        <Grid.Col xs={12} sm={8} md={6} lg={4}>
          <div className={classes.containerWidgets}>
            <AccountSummaryCard />
          </div>
        </Grid.Col>
        <Grid.Col xs={12} sm={8} md={6} lg={4}>
          <div className={classes.containerWidgets}>
            <RecentTransactionsWidget />
          </div>
        </Grid.Col>
        <Grid.Col xs={12} sm={8} md={6} lg={4}>
          <div className={classes.containerWidgets}>
            {showInternationalPaymentsCTA ? (
              <IntlWiresCTA />
            ) : (
              <MakePaymentWidget />
            )}
          </div>
        </Grid.Col>
      </Grid>
      <Box mt="lg" className={classes.card} data-testid="account-info">
        <BankingAccountsHeader />
        <BankingAccountsTable accounts={filteredAccounts} />
      </Box>
    </>
  );
};

export default BankingAccount;
