import { ReactNode } from 'react';
import { useRecoilValue } from 'recoil';
import { ApplicationState } from '../../../recoil-state/application/product-onboarding';
import { SalesPageContent } from 'areas/credit/sales/sales-page.content';
import { CreditCardGraphic } from './credit-card-graphic';
import { usePageBackground } from '../../../providers/background-provider';

const PendingTitle = () => {
  const { user } = useRecoilValue(ApplicationState);
  return (
    <>
      {user.firstName}, thank you for applying. Our team is reviewing your
      application.
    </>
  );
};

const COPY: Record<
  'applicant' | 'user',
  { title: ReactNode; subtitle: string }
> = {
  user: {
    title: 'Flex Credit Card',
    subtitle:
      "Your company's credit application is still pending. You will be able to view the Flex Credit dashboard when the application is approved.",
  },
  applicant: {
    title: <PendingTitle />,
    subtitle: 'We will let you know our decision within 5 business days.',
  },
};

export const CreditPendingPage = () => {
  const { userIsApplicant, user, company } = useRecoilValue(ApplicationState);

  usePageBackground(
    (t) =>
      `url("/images/page-top-frame.png") top/contain no-repeat ${t.fn.themeColor(
        'primarySecondarySuccess',
        7,
      )}`,
  );

  const likelyApplicant =
    userIsApplicant ||
    user.id === company.controlPerson ||
    user.roles.includes('OWNER') ||
    user.roles.includes('OFFICER');

  const showVerbiageFor = likelyApplicant ? 'applicant' : 'user';

  return (
    <SalesPageContent
      topGraphic={
        <CreditCardGraphic
          firstName={user.firstName}
          lastName={user.lastName}
        />
      }
      title={COPY[showVerbiageFor].title}
      subtitle={COPY[showVerbiageFor].subtitle}
      salesCards={[]}
    />
  );
};
