import { formatCurrency } from '@utilities/formatters';
import { Analytics } from '../../../services/analytics/analytics';
import { StatementInfo } from '../../../services/flexbase/statements.model';
import { platformClient } from '../../../services/platform/platform-client';
import { DateTime } from 'luxon';
import type { Transaction } from '../../../services/flexbase/banking.model';
import { downloadCSV } from '../../../utilities/file-io/csv/csv-export';

export const getStatement = async (
  statementId: string,
  statementName: string,
  accountId: string,
) => {
  try {
    const statement = await platformClient.getMonthlyBankingStatementPdf(
      accountId,
      statementId,
    );
    Analytics.track('Banking Statement Requested', {
      success: true,
    });
    const arr = new Uint8Array(statement);
    const blobURL = URL.createObjectURL(
      new Blob([arr], { type: 'application/pdf' }),
    );
    const link = document.createElement('a');
    link.href = blobURL;
    link.download = `${statementName}`;
    link.click();
  } catch (error) {
    Analytics.track('Banking Statement Requested', {
      success: false,
    });
    console.error(error);
  }
};

export const downloadStatementCSV = (
  statement: StatementInfo,
  data: Transaction[],
) => {
  const dateVal = DateTime.fromISO(statement.period, {
    zone: 'utc',
  });
  const beginningOfMonth = dateVal.startOf('month');
  const endofMonth = dateVal.endOf('month');

  // use only the transactions for the month selected
  // use only the transactons for the specified account
  let response = [];

  response = data
    .filter((t) => {
      const { createdAt } = t;
      const parsedCreatedAt = DateTime.fromSQL(createdAt);
      return (
        parsedCreatedAt >= beginningOfMonth && parsedCreatedAt <= endofMonth
      );
    })
    .map(({ createdAt, summary, direction, amount, balance }) => {
      return {
        'Created At': DateTime.fromSQL(createdAt).toFormat('LLL dd, yyyy'),
        Summary: summary,
        Direction: direction,
        Amount: formatCurrency(Number(amount) / 100),
        Balance: formatCurrency(Number(balance) / 100),
      };
    });

  if (response.length === 0) {
    response.push({
      'Created At': '',
      Summary: '',
      Direction: '',
      Amount: '',
      Balance: '',
    });
  }
  downloadCSV({
    data: response,
    fileName: `Banking Transactions ${statement.period}`,
    withDate: false,
  });
};

export const filterStatementsByYear = (
  year: string,
  _statements: StatementInfo[],
): StatementInfo[] => {
  const filteredStatements = _statements.filter((statement) => {
    const [statementYear] = statement.period.split('-');
    return statementYear === year;
  });
  return filteredStatements;
};

export const getYears = (statements: StatementInfo[]) => {
  if (!statements.length) {
    return [];
  }
  const extractYears = statements.map((s) => {
    const [year] = s.period.split('-');
    return year;
  });

  return [...new Set(extractYears)].reverse();
};
