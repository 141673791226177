import {
  AuthenticationChallengeModel,
  AuthenticationModel,
  AuthenticationTokenModel,
  CodeAuthenticationModel,
  FlexHttpError,
  PKCE,
  PlatformError,
  PlatformResponse,
  postAuthorizeChallengeResponse,
  RegisterAuthenticationFactorModel,
  RegisterAuthenticationFactorResponseModel,
  WWWAuthenticateBearerError,
} from './models/authorize.models';
import { AuthenticationFactorList } from '@flexbase-eng/types/dist/identity';
import getPkce from 'oauth-pkce';
import { retrieveTokenFromLocalStorage } from '../../utilities/auth/store-token';
import {
  CreateAccountRequest,
  CreateAccountResponse,
} from './models/identity.model';

class PlatformAuthClient {
  constructor(
    private readonly _baseUrl: string,
    private readonly _clientId: string,
  ) {
    this._correlationId = crypto.randomUUID();
  }
  private readonly _correlationId: string;
  private readonly _regexWwwAuthenticate =
    /(?<var>\w+)=((?<![\\])['"])(?<val>(.(?!(?<![\\])\2))*.?)\2/g;

  async generatePKCE(): Promise<PKCE> {
    return new Promise<PKCE>((resolve, reject) => {
      getPkce(43, (error, value) => {
        if (error) {
          reject(error);
        } else {
          resolve({
            codeVerifier: value.verifier,
            codeChallenge: value.challenge,
          });
        }
      });
    });
  }

  async generateChallengeFromValue(codeVerifier: string): Promise<PKCE> {
    const rawChallenge = await crypto.subtle.digest(
      'SHA-256',
      new TextEncoder().encode(codeVerifier),
    );

    const codeChallenge = btoa(
      new Uint8Array(rawChallenge).reduce(
        (data, byte) => data + String.fromCharCode(byte),
        '',
      ),
    )
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=/g, '');

    return {
      codeVerifier,
      codeChallenge,
    };
  }

  async getFactors() {
    return await this.get<AuthenticationFactorList>(
      '/identity/authorize/factors',
      false,
    );
  }

  async createAccount(
    request: CreateAccountRequest,
  ): Promise<PlatformResponse<CreateAccountResponse>> {
    return await this.post<CreateAccountResponse>(
      '/identity/accounts',
      request,
      {
        anonymousRoute: true,
        throwOnError: true,
      },
    );
  }

  async registerFactor(
    factor: RegisterAuthenticationFactorModel,
  ): Promise<PlatformResponse<RegisterAuthenticationFactorResponseModel>> {
    const { factorType, ...theRest } = factor;
    return await this.post(
      '/identity/authorize/factors',
      { method: factorType, ...theRest },
      { throwOnError: true },
    );
  }

  async deleteFactor(methodId: string) {
    return await this.delete(`/identity/authorize/factors`, { methodId }, true);
  }

  async issueChallenge(challenge: AuthenticationChallengeModel) {
    return await this.post<postAuthorizeChallengeResponse>(
      '/identity/authorize/factors/issue/',
      challenge,
      { throwOnError: false },
    );
  }

  async requestTokenByCode({
    code,
    methodId,
    grantType,
    scope,
    codeVerifier,
  }: {
    code: string;
    methodId: string;
    grantType: 'otp' | 'totp';
    codeVerifier: string;
    scope?: string;
  }) {
    return await this.requestToken<CodeAuthenticationModel>({
      code,
      methodId,
      grant_type: grantType,
      scope,
      client_id: this._clientId,
      codeVerifier,
    });
  }

  async requestTokenByPassword(username: string, password: string) {
    return await this.requestToken(
      {
        username,
        password,
        grant_type: 'password',
        client_id: this._clientId,
      },
      true,
    );
  }

  async requestTokenByApi(username: string, password: string, code?: string) {
    return await this.requestToken(
      {
        username,
        password,
        code: code,
        grant_type: 'api',
        client_id: this._clientId,
      },
      true,
    );
  }

  async requestTokenByRefresh(refreshToken: string) {
    return await this.requestToken(
      {
        refresh_token: refreshToken,
        grant_type: 'refresh_token',
        client_id: this._clientId,
      },
      true,
    );
  }

  async requestTokenByMagicLink(link: string, codeVerifier?: string) {
    return await this.requestToken({
      token: link,
      grant_type: 'link',
      client_id: this._clientId,
      ...(codeVerifier && { codeVerifier }),
    });
  }

  async requestToken<T extends AuthenticationModel>(
    authentication: T,
    anonymousRoute = false,
  ): Promise<PlatformResponse<AuthenticationTokenModel>> {
    const body = this.encodeURI(authentication);

    const headers = await this.createDefaultHeaders(undefined, anonymousRoute);
    headers.set(
      'Content-Type',
      'application/x-www-form-urlencoded;charset=UTF-8',
    );

    const response = await fetch(this._baseUrl + '/identity/authorize/token', {
      method: 'POST',
      body,
      headers,
    });

    return await this.handleAuthResponse<AuthenticationTokenModel>(
      response,
      false,
    );
  }

  async initiatePasswordReset(email: string, codeChallenge: string) {
    const client_id = this._clientId;
    return await this.post<void>(
      '/identity/authorize/reset',
      { email, codeChallenge, client_id },
      { throwOnError: false },
    );
  }

  async updatePassword(link: string, password: string, codeVerifier: string) {
    return await this.put<AuthenticationTokenModel>(
      '/identity/authorize/reset',
      {
        token: link,
        password,
        codeVerifier,
      },
      false,
    );
  }

  private async handleAuthResponse<T>(
    response: Response,
    throwOnError: boolean,
  ): Promise<PlatformResponse<T>> {
    const statusCode = response.status;

    const matches = (response.headers.get('www-authenticate') ?? '').matchAll(
      this._regexWwwAuthenticate,
    );

    let wwwErrorCode = '';
    let wwwCodeVerifier: string | undefined;

    for (const match of matches) {
      const group = match.groups ?? {};

      switch (group['var']) {
        case 'error':
          wwwErrorCode = group['val'];
          break;

        case 'codeVerifier':
          wwwCodeVerifier = group['val'];
          break;
      }
    }

    const wwwAuthenticate = {
      error: wwwErrorCode as WWWAuthenticateBearerError,
      codeVerifier: wwwCodeVerifier,
    };

    const correlationId = response.headers.get('x-correlation-id') ?? '0';
    const requestId = response.headers.get('x-request-id') ?? '0';
    let body: T | undefined;
    let error: PlatformError | undefined;

    if (response.status >= 400) {
      error = (await response.json()) as PlatformError;

      if (throwOnError) {
        throw new FlexHttpError(
          error.message,
          statusCode,
          correlationId,
          requestId,
          wwwAuthenticate,
          error.errors,
          response.headers,
        );
      }
    } else {
      body =
        response.status !== 204 ? ((await response.json()) as T) : undefined;
    }

    return {
      statusCode,
      correlationId,
      requestId,
      wwwAuthenticate,
      body,
      error,
      rawResponse: response,
    };
  }

  async getAuthorizationToken() {
    const authorizationToken = retrieveTokenFromLocalStorage();

    if (!authorizationToken) {
      return undefined;
    }

    return authorizationToken;
  }

  encodeURI(body: Record<string, string | number | boolean | undefined>) {
    return Object.keys(body)
      .filter((key) => body[key] !== undefined)
      .map(
        (key) =>
          encodeURIComponent(key) + '=' + encodeURIComponent(body[key] ?? ''),
      )
      .join('&');
  }

  async createDefaultHeaders(
    values?: HeadersInit,
    anonymousRoute = false,
  ): Promise<Headers> {
    const headers = new Headers(values);

    const token = anonymousRoute
      ? undefined
      : await this.getAuthorizationToken();

    if (token?.access_token) {
      headers.set('Authorization', `${token.token_type} ${token.access_token}`);
    }

    headers.set('x-correlation-id', this._correlationId);
    headers.set('Accept', 'application/json');

    return headers;
  }

  async get<T>(endPoint: string, throwOnError = true) {
    const headers = await this.createDefaultHeaders();

    const response = await fetch(this._baseUrl + endPoint, {
      method: 'GET',
      headers,
    });

    return this.handleAuthResponse<T>(response, throwOnError);
  }

  async post<T>(
    endPoint: string,
    body?: unknown,
    options?: Partial<{ throwOnError: boolean; anonymousRoute: boolean }>,
  ) {
    const bodyInit = body ? JSON.stringify(body) : undefined;

    const headers = await this.createDefaultHeaders(
      body ? { 'Content-Type': 'application/json' } : undefined,
      options?.anonymousRoute,
    );

    const response = await fetch(this._baseUrl + endPoint, {
      method: 'POST',
      body: bodyInit,
      headers,
    });
    return this.handleAuthResponse<T>(response, options?.throwOnError ?? true);
  }

  async put<T>(endPoint: string, body?: unknown, throwOnError = true) {
    const bodyInit = body ? JSON.stringify(body) : undefined;

    const headers = await this.createDefaultHeaders(
      body ? { 'Content-Type': 'application/json' } : undefined,
    );

    const response = await fetch(this._baseUrl + endPoint, {
      method: 'PUT',
      body: bodyInit,
      headers,
    });
    return this.handleAuthResponse<T>(response, throwOnError);
  }

  async patch<T>(endPoint: string, body?: unknown, throwOnError = true) {
    const bodyInit = body ? JSON.stringify(body) : undefined;

    const headers = await this.createDefaultHeaders(
      body ? { 'Content-Type': 'application/json' } : undefined,
    );

    const response = await fetch(this._baseUrl + endPoint, {
      method: 'PATCH',
      body: bodyInit,
      headers,
    });
    return this.handleAuthResponse<T>(response, throwOnError);
  }

  async delete(endPoint: string, body?: unknown, throwOnError = true) {
    const bodyInit = body ? JSON.stringify(body) : undefined;

    const headers = await this.createDefaultHeaders(
      body ? { 'Content-Type': 'application/json' } : undefined,
    );

    const response = await fetch(this._baseUrl + endPoint, {
      method: 'DELETE',
      headers,
      body: bodyInit,
    });
    return this.handleAuthResponse<void>(response, throwOnError);
  }
}

export const platformAuthClient = new PlatformAuthClient(
  import.meta.env.VITE_APP_PLATFORM_URL,
  import.meta.env.VITE_APP_CLIENT_ID,
);
