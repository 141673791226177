import { useEffect } from 'react';
import { InvoiceWizard, useInvoiceWizard } from '../../invoice-wizard';
import AddLineItems from './add-line-items';

const AddLineItemsStep = () => {
  const { setState } = useInvoiceWizard();

  useEffect(() => {
    setState({ isNextEnabled: true });
  }, []);

  return (
    <InvoiceWizard.Step hideNext hideBack>
      <AddLineItems />
    </InvoiceWizard.Step>
  );
};

AddLineItemsStep.stepId = 'add-line-items-step';

export default AddLineItemsStep;
