import {
  Button,
  Flex,
  PasswordInput,
  Stack,
  Text,
  TextInput,
} from '@mantine/core';
import { useStyles } from './integration-modal.styles';
import { useForm } from '@mantine/form';
import { EmailValidator } from '@utilities/validators/validate-email';
import { RequiredFieldValidator } from '@utilities/validators/validate-required';
import { useLoginContext } from 'areas/login/use-login';

const PasswordLoginStep = () => {
  const { classes } = useStyles();
  const { loginOauth } = useLoginContext();

  const loginForm = useForm({
    initialValues: {
      email: '',
      password: '',
    },
    validate: {
      email: EmailValidator(),
      password: RequiredFieldValidator(),
    },
  });
  const handleFormSubmit = async () => {
    const validationResult = loginForm.validate();
    if (!validationResult.hasErrors) {
      const { email, password } = loginForm.values;
      await loginOauth(email, password);
    }
  };

  return (
    <Stack spacing="md">
      <Text mt="xl">Sign into Flex to continue.</Text>
      <TextInput
        label="Username or email"
        placeholder="Username or email"
        classNames={{ error: classes.error }}
        {...loginForm.getInputProps('email')}
      />
      <PasswordInput
        label="Password"
        classNames={{ error: classes.error }}
        placeholder="Enter your password"
        {...loginForm.getInputProps('password')}
      />
      <Flex justify={'flex-end'}>
        <Button size="sm" mt="xl" onClick={handleFormSubmit}>
          Submit
        </Button>
      </Flex>
    </Stack>
  );
};

export default PasswordLoginStep;
