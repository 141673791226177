import { createStyles, rem } from '@mantine/core';

export const useLoginStyles = createStyles((theme) => ({
  factorRadioBox: {
    backgroundColor: theme.fn.themeColor('neutral', 0),
    width: '100%',
    padding: 20,
    color: theme.fn.themeColor('neutral', 5),
    borderRadius: 8,
    border: `1px solid ${theme.fn.themeColor('neutral', 3)}`,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    gap: '0.75rem',
    cursor: 'pointer',
    transition: '.4s ease',
    ...theme.fn.hover({
      backgroundColor: '#A1BEAD',
      border: '1px solid #A1BEAD',
    }),
  },
  title: {
    fontSize: '3rem',
    fontWeight: 400,
    lineHeight: 'normal',
    fontFamily: 'Redaction',
    color: theme.fn.themeColor('neutral', 0),
    '@media (max-width: 768px)': {
      fontSize: '2rem',
    },
  },
  goBackSection: {
    gap: 10,
    display: 'flex',
    cursor: 'pointer',
    marginBottom: '1.25rem',
    alignItems: 'center',
    color: theme.fn.themeColor('neutral', 0),
    '&:hover': {
      textDecoration: 'underline',
    },
    svg: {
      width: '1.25rem',
      height: '1.25rem',
      '@media (max-width: 768px)': {
        width: '1rem',
        height: '1rem',
      },
    },
  },
  reSendCode: {
    fontSize: rem(14),
    cursor: 'pointer',
    color: theme.fn.themeColor('neutral', 0),
    '&:hover': {
      textDecoration: 'underline',
    },
    textAlign: 'right',
  },
  alert: {
    fontWeight: 400,
    fontSize: rem(14),
    lineHeight: rem(19),
    padding: theme.spacing.sm,
    color: theme.fn.themeColor('neutral', 9),
    borderRadius: theme.defaultRadius,
    background: theme.fn.themeColor('critical', 0),
    border: `1px solid ${theme.fn.themeColor('critical', 2)}`,
  },
  textLine: {
    color: theme.fn.themeColor('neutral', 0),
  },
  label: {
    color: theme.fn.themeColor('neutral', 0),
  },
  error: {
    color: theme.fn.themeColor('critical', 2),
  },
}));
