/**
 * Extract ToFrom patron name from the transaction summary
 *
 * @param summary
 */
export const extractToFrom = (summary: string) => {
  if (summary.includes('|')) {
    return summary.split('|')[0].trim();
  }
  return summary;
};

type CreditOrDebit = 'Credit' | 'Debit';

export const parseTransactionDisplayValueByType = (
  val: string,
  direction: CreditOrDebit | '',
) => {
  function parseATMTransactionType(v: string, dir: CreditOrDebit | '') {
    return dir === 'Credit' ? 'atmDeposit' : v;
  }

  const parsedType =
    val === 'atmTransaction' ? parseATMTransactionType(val, direction) : val;

  //TODO: Add incoming international wires type
  // Unit transaction types: https://www.unit.co/docs/api/transactions/#transaction-types
  // we are technically mapping both transaction types and payment status's here. one lookup to map out a human readable value.
  const transactionMapping: Record<string, string> = {
    originatedAchTransaction: 'Originated ACH',
    receivedAchTransaction: 'Incoming ACH',
    returnedAchTransaction: 'Returned ACH',
    returnedReceivedAchTransaction: 'Returned received ACH',
    dishonoredAchTransaction: 'Dishonored ACH',
    bookTransaction: 'Flex transfer',
    purchaseTransaction: 'Debit card purchase',
    atmTransaction: 'ATM withdrawal',
    feeTransaction: 'Fee',
    feeReversalTransaction: 'Fee reversal',
    cardReversalTransaction: 'Debit card refund',
    cardTransaction: 'Debit card transaction',
    wireTransaction: 'Wire',
    releaseTransaction: 'Release',
    adjustmentTransaction: 'Adjustment',
    interestTransaction: 'Interest',
    disputeTransaction: 'Dispute',
    checkDepositTransaction: 'Check deposit',
    checkPaymentTransaction: 'Check payment',
    returnedCheckDepositTransaction: 'Returned check deposit',
    paymentAdvanceTransaction: 'Payment advance',
    repaidPaymentAdvanceTransaction: 'Repaid payment advance',
    paymentCanceledTransaction: 'Payment canceled',
    rewardTransaction: 'Reward',
    negativeBalanceCoverageTransaction: 'Negative balance coverage',
    pushToCardTransaction: 'Push to card',
    accountLowBalanceClosureTransaction: 'Account low balance closure',
    bankRepaymentTransaction: 'Bank repayment',
    atmDeposit: 'ATM deposit',
    wire: 'domestic wire',
    book: 'Internal transfer',
    ach: 'domestic ACH',
    outgoingACHInternationalWire: 'international wire',
    returnedWireTransaction: 'Returned wire',
  };

  let displayValue = transactionMapping[parsedType];

  const transactionTypesThatNeedDirection = [
    'domestic ACH',
    'domestic wire',
    'international wire',
  ];

  if (transactionTypesThatNeedDirection.includes(displayValue)) {
    const directionMapping = {
      Credit: 'Outgoing',
      Debit: 'Incoming',
    };

    const mappedDirection = direction ? directionMapping[direction] : '';

    displayValue = mappedDirection
      ? `${mappedDirection} ${displayValue}`
      : displayValue;
  }

  return displayValue;
};
