import { atom, selector } from 'recoil';
import {
  flexbaseBankingClient,
  flexbaseOnboardingClient,
} from '../../../../services/flexbase-client';
import type { DepositAccount as BaseDepositAccount } from '../../../../services/flexbase/banking.model';
import type {
  DepositAccount,
  PlaidAccount,
} from '../../../banking/move-funds/move-funds.model';
import { ApplicationState } from '../../../../recoil-state/application/product-onboarding';
import { IndividualOrCompany } from './international-payments/util/types';

export const depositInitial = {
  asOf: '',
  companyId: '',
  createdAt: '',
  id: '',
  isDefault: false,
  name: '',
  nickName: '',
  status: '',
  type: 'depositAccount' as const,
  accountType: 'checking' as const,
  ucCustomerId: '',
  ucDepositId: '',
  userId: '',
  version: 0,
  routingNumber: '',
  accountNumber: '',
  balance: 0,
  available: 0,
  tier: 1 as const,
  tenantId: '',
  plaidOrDeposit: 'deposit',
} as DepositAccount;

export const counterpartyInitial = {
  accountNumber: '',
  routingNumber: '',
  asOf: '',
  companyId: '',
  createdAt: '',
  id: '',
  response: {
    data: {
      attributes: {
        accountNumber: '',
        accountType: '',
        bank: '',
        createdAt: '',
        name: '',
        permissions: '',
        routingNumber: '',
        tags: {
          companyId: '',
          userId: '',
          tenantId: '',
        },
        type: '',
      },
      id: '',
      relationships: {
        customer: {
          data: {
            id: '',
            type: '',
          },
        },
      },
      type: '',
    },
  },
  status: 'active' as const,
  type: 'ach' as const,
  ucCounterpartyId: '',
  nickName: '',
  name: '',
  ucCustomerId: '',
  userId: '',
  version: 0,
  tenantId: '',
  lastUsedAt: '',
  bank: '',
  frecencyScore: 0,
  recipientId: '',
};

export const recipientInitial = {
  name: '',
  accountNumber: '',
  routingNumber: '',
  accountType: '',
  nickName: '',
  type: 'ach',
  recipientType: 'individual',
  address: '',
  addressLine2: '',
  city: '',
  state: '',
  postalCode: '',
  country: '',
} as Recipient;

export type PayMethod =
  | 'wire'
  | 'ach'
  | 'book'
  | 'internationalWire'
  | 'internationalWireUSD';

// i dont know why i dont like it, it's just countarperty flattened.
// i cannot figure out how to use mantine form with typescript + deeply nested interface requirements (address)
export type Recipient = {
  name: string;
  routingNumber: string;
  accountNumber: string;
  accountType: string;
  type: PayMethod;
  dob?: string;
  recipientType: IndividualOrCompany;
  nickName: string;
  address: string;
  addressLine2?: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  firstName?: string;
  lastName?: string;
};

export type MoneyMovementDetails = {
  amount: number;
  sourceAccount: BaseDepositAccount;
  internalNotes?: string;
  memo: string;
  files?: File[];
  scheduledFor: string | null;
};

export const initialMoneyMovementAmountDetails = {
  amount: 0,
  memo: '',
  internalNotes: '',
  sourceAccount: depositInitial,
  files: [],
  scheduledFor: null,
} as MoneyMovementDetails;

// the Payment Account used for Card Payments
export const paymentAccount = atom<PlaidAccount | DepositAccount | undefined>({
  key: 'card_payment_account',
  default: undefined,
});

// Fetch and cache a User's deposit accounts
export const depositAccountsQuery = selector<DepositAccount[]>({
  key: 'banking/depositAccounts',
  get: async () => {
    try {
      const { accounts } = await flexbaseBankingClient.getDepositList();
      return (accounts ?? []).map((account) => ({
        ...account,
        plaidOrDeposit: 'deposit',
      }));
    } catch (e) {
      return [];
    }
  },
});

// Fetch and cache a Company's Plaid-linked accounts
export const plaidAccountsQuery = selector<PlaidAccount[]>({
  key: 'banking/plaidAccounts',
  get: async () => {
    try {
      const { accounts } =
        await flexbaseOnboardingClient.getPlaidCompanyAccounts();
      return (accounts ?? []).map((account) => ({
        ...account,
        plaidOrDeposit: 'plaid',
      }));
    } catch (e) {
      return [];
    }
  },
});

// handle Payment Account state with a reasonable, derived default
export const paymentAccountQuery = selector<
  DepositAccount | PlaidAccount | undefined
>({
  key: 'banking/defaultPaymentAccount',
  get: ({ get }) => {
    const selection = get(paymentAccount);
    if (!selection) {
      const { company } = get(ApplicationState);
      const depositAccounts = get(depositAccountsQuery).filter(
        (a) => a.status === 'Open',
      );
      const plaidAccounts = get(plaidAccountsQuery).filter(
        (account) => account.active && !account.unlinked,
      );
      return (
        depositAccounts.find((account) => account.isDefault) ??
        depositAccounts[0] ??
        plaidAccounts.find(
          (account) => account.id === company.primaryPlaidAccount,
        ) ??
        plaidAccounts[0]
      );
    }
    return selection;
  },
  set: ({ set }, account) => set(paymentAccount, account),
});
