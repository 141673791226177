import { useNavigate } from 'react-router-dom';
import { CreateWizard, WizardState, WizardStep } from './create-wizard';
import { StepOne } from './steps/step-one';
import { StepTwo } from './steps/step-two';
import { Box } from '@mantine/core';

const steps: WizardStep[] = [
  {
    id: StepOne.stepId,
    title: 'Step one',
    element: <StepOne />,
    metadata: {
      someHint: <Box>Hint: this is step one</Box>,
    },
  },
  {
    id: StepTwo.stepId,
    title: 'Step two',
    element: <StepTwo />,
  },
];

const SampleWizard = () => {
  const navigate = useNavigate();

  const handleCancelClick = () => {
    navigate(-1);
  };

  const initialState: WizardState = {
    someStateValue: '',
    counter: 0,
  };

  return (
    <CreateWizard
      steps={steps}
      initialState={initialState}
      onCancel={handleCancelClick}
    />
  );
};

export default SampleWizard;
