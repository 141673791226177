import { useSpendPlansFeatureFlag } from '@utilities/feature-flags';
import {
  CreateSpendPlanWizard,
  SpendPlanFormState,
} from '../create/create-spend-plan.wizard';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { WizardStepConfig } from '@common/wizard/wizard.types';
import { useGetAdminSpendPlan } from '@queries/use-spend-plans';
import { usePlatformPersonContext } from 'providers/platform-person.context';
import { Box, LoadingOverlay } from '@mantine/core';
import { useRecoilValue } from 'recoil';
import { IsAdmin } from 'recoil-state/application/product-onboarding';
import { SpendPlanDetailsStep } from '../create/steps/details/details-step';
import { SpendPlanDetailsStepPreview } from '../create/steps/details/details-step.preview';
import { CreateSpendPlanProvider } from '../create/create-spend-plan.provider';
import { mapSpendPlanToFormState } from '../utils/map-spend-plan-to-form-state';
import { SpendPlanSpendControlsStep } from '../create/steps/spend-controls/spend-controls-step';
import { SpendPlanSpendControlsStepPreview } from '../create/steps/spend-controls/spend-controls-step.preview';
import { SpendPlansTeamMembersStep } from '../create/steps/team-members/team-members-step';
import { SpendPlanTeamMembersStepPreview } from '../create/steps/team-members/team-members-step.preview';
import { SpendPlanRestrictionsStep } from '../create/steps/restrictions/restrictions-step';
import { SpendPlanRestrictionsStepPreview } from '../create/steps/restrictions/restrictions-step.preview';
import { SpendPlanReviewStep } from '../create/steps/review/review-step';

export const EditSpendPlan = () => {
  const isFeatureFlagEnabled = useSpendPlansFeatureFlag();
  const isAdmin = useRecoilValue(IsAdmin);
  const navigate = useNavigate();
  const { id = '' } = useParams();
  const { person } = usePlatformPersonContext();
  const {
    data: spendPlanData,
    isPending,
    isError,
  } = useGetAdminSpendPlan({
    accountId: person.accountId,
    spendPlanId: id,
    isAdmin,
  });

  if (!isFeatureFlagEnabled) {
    return <Navigate to="/" />;
  } else if (!isAdmin) {
    return <Navigate to="/spend-plans" />;
  } else if (isPending) {
    return <LoadingOverlay visible />;
  } else if (isError) {
    return <Box>Something went wrong. Please refresh and try again.</Box>;
  }

  const spendPlan = spendPlanData;
  const initialState: SpendPlanFormState = mapSpendPlanToFormState(spendPlan);

  const steps: WizardStepConfig<SpendPlanFormState>[] = [
    {
      id: SpendPlanDetailsStep.stepId,
      title: 'Spend plan details',
      element: <SpendPlanDetailsStep spendPlan={spendPlan} />,
      previewElement: <SpendPlanDetailsStepPreview />,
    },

    {
      id: SpendPlanSpendControlsStep.stepId,
      title: 'Spend plan limit',
      element: <SpendPlanSpendControlsStep />,
      previewElement: <SpendPlanSpendControlsStepPreview />,
    },

    {
      id: SpendPlansTeamMembersStep.stepId,
      title: 'Add team members to this spend plan',
      element: <SpendPlansTeamMembersStep />,
      previewElement: <SpendPlanTeamMembersStepPreview />,
    },

    {
      id: SpendPlanRestrictionsStep.stepId,
      title: 'Define restrictions',
      element: <SpendPlanRestrictionsStep />,
      previewElement: <SpendPlanRestrictionsStepPreview />,
    },

    {
      id: SpendPlanReviewStep.stepId,
      title: 'Review spend plan details',
      element: <SpendPlanReviewStep spendPlan={spendPlan} />,
      options: {
        disableScrollToPreview: true,
      },
    },
  ];

  const handleCancelClick = () => {
    navigate(`/spend-plans/${id}/details`);
  };

  return (
    <CreateSpendPlanWizard
      steps={steps}
      initialState={initialState}
      onCancel={handleCancelClick}
      wrapper={({ children }) => (
        <CreateSpendPlanProvider spendPlan={spendPlan}>
          {children}
        </CreateSpendPlanProvider>
      )}
    />
  );
};
