import {
  CreateSpendPlan,
  LinkCardToSpendPlanRequest,
  SpendPlanAdminView,
  SpendPlanView,
  UpdateSpendPlan,
} from '@flexbase-eng/types/dist/accounting';
import { showNotification } from '@mantine/notifications';
import { platformClient } from '../services/platform/platform-client';
import {
  UseQueryResult,
  queryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { isFunction } from 'underscore';

type UseGetSpendPlansOptions<TAdmin = boolean> = {
  enabled?: boolean;
  accountId: string;
  isAdmin: TAdmin;
  select?: (
    plans: (SpendPlanAdminView | SpendPlanView)[],
  ) => (SpendPlanAdminView | SpendPlanView)[];
};

type UseGetSingleSpendPlanOptions = {
  accountId: string;
  spendPlanId: string;
  isAdmin?: boolean;
};

type UseSpendPlanCardsProps = {
  accountId: string;
  spendPlanId: string;
};

type UseSpendPlansByCardIdProps = {
  accountId: string;
  cardId: string;
  enabled: boolean;
};

const QUERY_KEY = 'spend_plans';

async function getSpendPlanCards(accountId: string, spendPlanId: string) {
  return await platformClient.getSpendPlanCards(accountId, spendPlanId);
}

async function getSpendPlans(accountId: string) {
  return await platformClient.getSpendPlans(accountId);
}

async function getAdminSpendPlans(accountId: string) {
  return await platformClient.getAdminSpendPlans(accountId);
}

async function getSingleSpendPlan(accountId: string, spendPlanId: string) {
  return await platformClient.getSpendPlanDetails(accountId, spendPlanId);
}

async function getAdminSingleSpendPlan(accountId: string, spendPlanId: string) {
  return await platformClient.getAdminSpendPlanDetails(accountId, spendPlanId);
}

async function getSpendPlanRequests(accountId: string, spendPlanId: string) {
  if (spendPlanId && accountId) {
    return await platformClient.getSpendPlanRequests(accountId, spendPlanId);
  } else {
    return [];
  }
}

const getAllSpendPlansQuery = ({
  accountId,
  isAdmin,
  enabled,
  select,
}: Partial<UseGetSpendPlansOptions>) =>
  queryOptions({
    queryKey: [QUERY_KEY, accountId, 'all', { isAdmin }],
    queryFn: () => {
      if (accountId) {
        return isAdmin
          ? getAdminSpendPlans(accountId)
          : getSpendPlans(accountId);
      }

      return [];
    },
    select,
    enabled: enabled && !!accountId,
    meta: {
      errorMessage: 'We are unable to retrieve your spend plans at the moment.',
    },
  });

export function useGetSpendPlans(
  p: UseGetSpendPlansOptions<true>,
): UseQueryResult<SpendPlanAdminView[]>;
export function useGetSpendPlans(
  p: UseGetSpendPlansOptions<false>,
): UseQueryResult<SpendPlanView[]>;
export function useGetSpendPlans(
  p: UseGetSpendPlansOptions,
): UseQueryResult<(SpendPlanAdminView | SpendPlanView)[]>;
export function useGetSpendPlans(options: UseGetSpendPlansOptions) {
  return useQuery(getAllSpendPlansQuery(options));
}

const getSpendPlanMemberDetailsQuery = ({
  accountId,
  spendPlanId,
  isAdmin,
}: UseGetSingleSpendPlanOptions) =>
  queryOptions({
    queryKey: [
      QUERY_KEY,
      accountId,
      'spend_plan',
      spendPlanId,
      'details',
      { isAdmin: false },
    ],
    enabled: !!accountId && !isAdmin,
    queryFn: () => getSingleSpendPlan(accountId, spendPlanId),
    meta: {
      errorMessage:
        'We are unable to retrieve your spend plan details at the moment.',
    },
  });

export const useGetSpendPlan = (options: UseGetSingleSpendPlanOptions) => {
  return useQuery(getSpendPlanMemberDetailsQuery(options));
};

const getSpendPlanAdminDetailsQuery = ({
  accountId,
  spendPlanId,
  isAdmin,
}: UseGetSingleSpendPlanOptions) =>
  queryOptions({
    queryKey: [
      QUERY_KEY,
      accountId,
      'spend_plan',
      spendPlanId,
      'details',
      { isAdmin: true },
    ],
    enabled: !!accountId && !!isAdmin,
    queryFn: () => getAdminSingleSpendPlan(accountId, spendPlanId),
    meta: {
      errorMessage:
        'We are unable to retrieve your spend plan details at the moment.',
    },
  });

export const useGetAdminSpendPlan = (options: UseGetSingleSpendPlanOptions) => {
  return useQuery(getSpendPlanAdminDetailsQuery(options));
};

export function useActiveSpendPlans(
  options: UseGetSpendPlansOptions<true>,
): UseQueryResult<SpendPlanAdminView[], Error>;
export function useActiveSpendPlans(
  options: UseGetSpendPlansOptions<false>,
): UseQueryResult<SpendPlanView[], Error>;
export function useActiveSpendPlans(
  options: UseGetSpendPlansOptions,
): UseQueryResult<(SpendPlanAdminView | SpendPlanView)[], Error>;
export function useActiveSpendPlans(
  options: UseGetSpendPlansOptions,
): UseQueryResult<(SpendPlanAdminView | SpendPlanView)[], Error> {
  return useGetSpendPlans({
    ...options,
    select: (plans) => {
      const activePlans = plans.filter((plan) => plan.isPlanActive);

      return isFunction(options.select)
        ? options.select(activePlans)
        : activePlans;
    },
  });
}

export function useInactiveSpendPlans(
  options: UseGetSpendPlansOptions<true>,
): UseQueryResult<SpendPlanAdminView[], Error>;
export function useInactiveSpendPlans(
  options: UseGetSpendPlansOptions<false>,
): UseQueryResult<SpendPlanView[], Error>;
export function useInactiveSpendPlans(
  options: UseGetSpendPlansOptions,
): UseQueryResult<(SpendPlanAdminView | SpendPlanView)[], Error>;
export function useInactiveSpendPlans(
  options: UseGetSpendPlansOptions,
): UseQueryResult<(SpendPlanAdminView | SpendPlanView)[], Error> {
  return useGetSpendPlans({
    ...options,
    select: (plans) => {
      const inactivePlans = plans.filter((plan) => !plan.isPlanActive);

      return isFunction(options.select)
        ? options.select(inactivePlans)
        : inactivePlans;
    },
  });
}

function createSpendPlan({
  accountId,
  payload,
}: {
  accountId?: string;
  payload: CreateSpendPlan;
}) {
  if (!accountId) {
    throw new Error('Missing accountId');
  }

  return platformClient.createSpendPlan(accountId, payload);
}

export const useCreateSpendPlan = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createSpendPlan,
    onSuccess: async (_, { accountId = '' }) => {
      await queryClient.invalidateQueries(
        getAllSpendPlansQuery({ accountId, isAdmin: true }),
      );
    },
  });
};

function updateSpendPlan({
  accountId,
  spendPlanId,
  payload,
}: {
  accountId: string;
  spendPlanId: string;
  payload: UpdateSpendPlan;
}) {
  return platformClient.updateSpendPlan(accountId, spendPlanId, payload);
}

export const useUpdateSpendPlan = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateSpendPlan,
    onSuccess: async (_, { accountId, spendPlanId }) => {
      await Promise.allSettled([
        queryClient.invalidateQueries(
          getAllSpendPlansQuery({ accountId, isAdmin: true }),
        ),
        queryClient.invalidateQueries(
          getSpendPlanAdminDetailsQuery({ accountId, spendPlanId }),
        ),
        queryClient.invalidateQueries(
          getSpendPlanLimitsQueryOptions({ accountId, spendPlanId }),
        ),
      ]);
    },
  });
};

async function getSpendPlanTransactions(
  accountId: string,
  spendPlanId: string,
) {
  return await platformClient.getSpendPlanTransactions(accountId, spendPlanId);
}

type UseSpendPlanTransactionsProps = {
  accountId: string;
  spendPlanId: string;
};

export function useGetSpendPlanTransactions(
  props: UseSpendPlanTransactionsProps,
) {
  const { accountId, spendPlanId } = props;
  return useQuery({
    enabled: !!accountId && !!spendPlanId,
    queryKey: [QUERY_KEY, accountId, 'spend_plan', spendPlanId, 'transactions'],
    queryFn: () => getSpendPlanTransactions(accountId, spendPlanId),
  });
}

type SpendPlanLimitsParams = {
  accountId: string;
  spendPlanId: string | undefined;
};

const getSpendPlanLimitsQueryOptions = ({
  accountId,
  spendPlanId,
}: SpendPlanLimitsParams) =>
  queryOptions({
    enabled: !!accountId && !!spendPlanId,
    queryKey: [QUERY_KEY, accountId, 'spend_plan', spendPlanId, 'limits'],
    queryFn: async () => {
      if (spendPlanId) {
        return await platformClient.getSpendPlanLimits(accountId, spendPlanId);
      }
    },
  });

export function useGetSpendPlanLimits(params: SpendPlanLimitsParams) {
  return useQuery(getSpendPlanLimitsQueryOptions(params));
}

const getSpendPlanCardsQuery = ({
  accountId,
  spendPlanId,
}: UseSpendPlanCardsProps) =>
  queryOptions({
    enabled: !!accountId && !!spendPlanId,
    queryKey: [QUERY_KEY, accountId, 'spend_plan', spendPlanId, 'cards'],
    queryFn: () => getSpendPlanCards(accountId, spendPlanId),
  });

export function useGetSpendPlanCards(props: UseSpendPlanCardsProps) {
  return useQuery(getSpendPlanCardsQuery(props));
}

const getSpendPlanRequestsQuery = ({
  accountId,
  spendPlanId,
}: {
  accountId: string;
  spendPlanId: string;
}) =>
  queryOptions({
    enabled: !!accountId && !!spendPlanId,
    queryKey: [QUERY_KEY, accountId, 'spend_plan', spendPlanId, 'requests'],
    queryFn: () => getSpendPlanRequests(accountId, spendPlanId),
  });

export function useGetSpendPlanRequests(
  accountId: string,
  spendPlanId: string,
) {
  return useQuery(getSpendPlanRequestsQuery({ accountId, spendPlanId }));
}

function answerRequest({
  body,
  accountId,
  spendPlanId,
  requestId,
}: {
  body: {
    decision: 'approved' | 'declined';
    reason: string;
  };
  accountId: string;
  spendPlanId: string;
  requestId: string;
}) {
  return platformClient.approveDeclineRequests(
    body,
    accountId,
    spendPlanId,
    requestId,
  );
}

export const useApproveDenyRequest = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: answerRequest,
    onSuccess: async (_, { accountId, spendPlanId }) => {
      await queryClient.invalidateQueries(
        getSpendPlanRequestsQuery({ accountId, spendPlanId }),
      );
    },
    onError: () => {
      showNotification({
        color: 'red',
        title: 'Error',
        message: 'Unable to answer request',
      });
    },
  });
};

function requestFunds({
  accountId,
  spendPlanId,
  body,
}: {
  accountId: string;
  spendPlanId: string;
  body: {
    amount: number;
    description?: string;
  };
}) {
  return platformClient.requestFunds(accountId, spendPlanId, body);
}

export const useRequestFunds = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: requestFunds,
    onSuccess: async (_, { accountId, spendPlanId }) => {
      await queryClient.invalidateQueries(
        getSpendPlanRequestsQuery({ accountId, spendPlanId }),
      );
    },
  });
};

export const useLinkCardToSpendPlan = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      accountId,
      spendPlanId,
      cardId,
      payload,
    }: {
      accountId: string;
      spendPlanId: string;
      cardId: string;
      payload: LinkCardToSpendPlanRequest;
    }) => {
      return await platformClient.linkCardToSpendPlan(
        accountId,
        spendPlanId,
        cardId,
        payload,
      );
    },
    onSuccess: async (_, { accountId, spendPlanId }) => {
      await queryClient.invalidateQueries(
        getSpendPlanCardsQuery({ accountId, spendPlanId }),
      );
    },
  });
};

async function getSpendPlansByCardId(accountId: string, cardId: string) {
  return await platformClient.getSpendPlansByCardId(accountId, cardId);
}

export function useGetSpendPlansByCardId(props: UseSpendPlansByCardIdProps) {
  const { accountId, cardId, enabled } = props;
  return useQuery({
    enabled: !!accountId && !!cardId && enabled,
    queryKey: [QUERY_KEY, accountId, 'cards', cardId, 'spend_plans'],
    queryFn: () => getSpendPlansByCardId(accountId, cardId),
  });
}
