import { flexbaseOnboardingClient } from '../services/flexbase-client';
import { useQueryClient } from '@tanstack/react-query';

const QUERY_KEY = 'credit_transactions_csv';

type ApiTrueFalse = 'true' | 'false';
type GetCreditTransactionCsvParams = {
  after?: string;
  before?: string;
  sort?: 'desc' | 'asc';
  noInterest?: ApiTrueFalse;
  inclReversed?: ApiTrueFalse;
  inclExpired?: ApiTrueFalse;
  justSettledPays?: ApiTrueFalse;
  justSettledInvs?: ApiTrueFalse;
};

/**
 * Make ad-hoc queries to fetch credit transaction CSV data.
 */
export function useFetchCreditTransactionsCsv() {
  const queryClient = useQueryClient();

  const fetchQuery = (id: string, options: GetCreditTransactionCsvParams) => {
    return queryClient.fetchQuery({
      queryKey: [QUERY_KEY, id, options],
      queryFn: () =>
        flexbaseOnboardingClient.getCompanyTransactionsCsv(id, {
          justSettledInvs: 'true',
          justSettledPays: 'true',
          useBillScheme: 'true',
          sort: 'desc',
          ...options,
        }),
    });
  };

  return {
    fetchQuery,
  };
}
