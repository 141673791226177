import { CreateSpendPlanFrequencyEnum } from '@flexbase-eng/types/dist/accounting';
import { DateValue } from '@mantine/dates';
import { useForm } from '@mantine/form';
import {
  ConditionalFieldValidator,
  RequiredFieldValidator,
} from '../../../../../utilities/validators/validate-required';

export type RecurringRadioValue = 'recurring' | 'onetime';

export type DetailsStepFormValues = {
  name: string;
  description: string;
  recurring: RecurringRadioValue;
  frequency: CreateSpendPlanFrequencyEnum;
  expires: boolean;
  expirationDate: DateValue;
};

export const DetailsStepFormInitialValues: DetailsStepFormValues = {
  name: '',
  description: '',
  recurring: 'recurring',
  frequency: CreateSpendPlanFrequencyEnum.Monthly,
  expires: false,
  expirationDate: null,
};

const DetailsStepFormKeys = Object.keys(
  DetailsStepFormInitialValues,
) as (keyof DetailsStepFormValues)[];

type UseDetailsFormProps = {
  initialValues: DetailsStepFormValues;
};

export const useDetailsForm = ({ initialValues }: UseDetailsFormProps) => {
  // ensure the form doesn't contain unrelated values
  initialValues = DetailsStepFormKeys.reduce<DetailsStepFormValues>(
    (acc, k) => {
      return {
        ...acc,
        [k]: initialValues[k],
      };
    },
    { ...DetailsStepFormInitialValues },
  );

  return useForm<DetailsStepFormValues>({
    initialValues,
    validate: {
      name: RequiredFieldValidator(),
      expirationDate: (value, formValues) =>
        ConditionalFieldValidator(formValues.expires)(value),
    },
  });
};
