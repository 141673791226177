import {
  ActiveFiltersReturnType,
  createUseFiltersHook,
  FilterFnMap,
} from '@common/filter/filters';
import { atom } from 'recoil';
import { BillpayCreditPaymentsTable } from './billpay-credit-overview';

const BillpayCreditPaymentsFilterState = atom<
  FilterFnMap<BillpayCreditPaymentsTable>
>({
  key: 'billpay_credit_payments_filter_state',
  default: {},
});

const BillpayPaymentsFilterStateModal = atom<
  FilterFnMap<BillpayCreditPaymentsTable>
>({
  key: 'billpay_credit_transactions_state_modal',
  default: {},
});

export function useBillpayPaymentsFilters(): ActiveFiltersReturnType<BillpayCreditPaymentsTable> {
  return createUseFiltersHook<BillpayCreditPaymentsTable>(
    BillpayCreditPaymentsFilterState,
  );
}
export function useBillpayTransactionsModalFilters(): ActiveFiltersReturnType<BillpayCreditPaymentsTable> {
  return createUseFiltersHook<BillpayCreditPaymentsTable>(
    BillpayPaymentsFilterStateModal,
  );
}
