import { Box, Button, Divider, Group, Text } from '@mantine/core';
import { useForm } from '@mantine/form';
import FlexbaseInput from 'components/input/flexbase-input';
import { useStyle } from './styles';
import { formatUSPhoneNumber } from '../../../../utilities/formatters/format-phone-number';
import { validateUSPhoneNumber } from '../../../../utilities/validators/validate-phone-number';
import { EmailValidator } from '../../../../utilities/validators/validate-email';
import { OnboardingUser } from '../../../../types/onboarding-info';
import { RequiredFieldValidator } from '@utilities/validators';
import { useOnboardingStyles } from '../../onboarding.styles';

type Props = {
  onCancel: () => void;
  setControlPerson: (request: Partial<OnboardingUser>) => void;
  newControlPerson: Partial<OnboardingUser>;
};

const AddControlPersonForm = ({
  onCancel,
  setControlPerson,
  newControlPerson,
}: Props) => {
  const { classes } = useStyle();
  const { classes: onboardingStyles } = useOnboardingStyles();

  const form = useForm({
    initialValues: {
      firstName: newControlPerson.firstName || '',
      lastName: newControlPerson.lastName || '',
      email: newControlPerson.email || '',
      cellPhone: newControlPerson.cellPhone || '',
    },
    validate: {
      firstName: RequiredFieldValidator(),
      lastName: RequiredFieldValidator(),
      cellPhone: (val) =>
        validateUSPhoneNumber(val) ? null : 'Invalid phone number',
      email: EmailValidator('Invalid email address'),
    },
  });

  const onBack = () => {
    onCancel();
  };

  const createControlPerson = () => {
    try {
      const formValues = form.values;
      const request: Partial<OnboardingUser> = {
        firstName: formValues.firstName,
        lastName: formValues.lastName,
        email: formValues.email,
        cellPhone: formValues.cellPhone,
        roles: ['ADMIN'],
      };
      const validationResult = form.validate();
      if (!validationResult.hasErrors) {
        setControlPerson(request);
        onBack();
      }
    } catch (error) {
      console.error('Unable to create a control person', error);
    }
  };

  const setPhoneNumber = (value: string) =>
    form.setFieldValue('cellPhone', formatUSPhoneNumber(value));

  return (
    <Box mt={'xl'} bg="#ECE9E2">
      <div className={classes.formContainer}>
        <Text>Add control person</Text>
      </div>
      <Divider />
      <Box p={'md'}>
        <Box className={onboardingStyles.containerOwnerForm}>
          <FlexbaseInput
            classNames={{
              label: onboardingStyles.inputLabel,
              root: onboardingStyles.btnSpacingAddressPage,
            }}
            placeholder="Enter first name"
            label="Legal first name"
            data-sardine-id="input-new-control-person-first-name"
            {...form.getInputProps('firstName')}
          />
          <FlexbaseInput
            classNames={{
              label: onboardingStyles.inputLabel,
              root: onboardingStyles.btnSpacingAddressPage,
            }}
            placeholder="Enter last name"
            label="Legal last name"
            data-sardine-id="input-new-control-person-last-name"
            {...form.getInputProps('lastName')}
          />
        </Box>
        <Box className={onboardingStyles.containerOwnerForm} mt={'md'}>
          <FlexbaseInput
            classNames={{
              label: onboardingStyles.inputLabel,
              root: onboardingStyles.btnSpacingAddressPage,
            }}
            placeholder="Email address"
            label="Email address"
            data-sardine-id="input-new-control-person-email"
            {...form.getInputProps('email')}
          />
          <FlexbaseInput
            classNames={{
              label: onboardingStyles.inputLabel,
              root: onboardingStyles.btnSpacingAddressPage,
            }}
            placeholder="Enter number"
            label="Phone number"
            data-sardine-id="input-new-control-person-cellphone"
            {...form.getInputProps('cellPhone')}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
        </Box>
        <Group mt="xl">
          <Button variant="outline" mr="sm" onClick={() => onBack()}>
            Cancel
          </Button>
          <Button variant="light" onClick={() => createControlPerson()}>
            Save
          </Button>
        </Group>
      </Box>
    </Box>
  );
};

export default AddControlPersonForm;
