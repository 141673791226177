import { useGetCreditPayment } from '@queries/use-credit-payments';
import { useGetDepositAccounts } from '@queries/use-deposit-accounts';

export const usePaymentReceipt = (paymentId: string) => {
  const {
    data,
    isLoading: isPaymentLoading,
    isError: isPaymentError,
  } = useGetCreditPayment(paymentId ?? '');
  const {
    data: depositAccountsData,
    isLoading: isDepositAccountsLoading,
    isError: isDepositAccountsError,
  } = useGetDepositAccounts();

  const payment = data?.payment;
  const depositAccounts = depositAccountsData?.accounts ?? [];
  const paymentAccount = depositAccounts.find(
    (acc) => acc.id == payment?.depositId,
  );

  const isError = isPaymentError || isDepositAccountsError;
  const isLoading = isPaymentLoading || isDepositAccountsLoading;

  return {
    data: payment,
    paymentAccount,
    isLoading,
    isError,
  };
};
