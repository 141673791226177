import { ValidateRoutingNumber } from '@utilities/validators';
import { flexbaseBankingClient } from '@services/flexbase-client';
import { InstitutionResponse } from '@services/flexbase/banking.model';
import { PayMethod } from 'areas/payments/components/send-payment/payment.states';
import { RoutingNumberForm } from 'areas/payments/pages/add-recipient/steps/payment-details/forms/domestic/routing-number-input';
import { useCallback, useEffect, useState } from 'react';

const getRoutingNumberErrorByType = (
  routingData: InstitutionResponse | undefined,
  type: PayMethod,
) => {
  const bankName = routingData?.institution?.name ?? '';
  const parsedBankCopy = bankName ? `(${bankName}) ` : '';

  switch (type) {
    case 'ach':
      if (routingData && !routingData.institution.isACHSupported) {
        return `The bank associated with this routing number ${parsedBankCopy}does not accept ACH payments`;
      }
      break;
    case 'wire':
      if (routingData && !routingData.institution.isWireSupported) {
        return `The bank associated with this routing number ${parsedBankCopy}does not accept wire payments`;
      }
      break;
  }

  return '';
};

export const checkRoutingNumberUponSubmit = async (
  form: RoutingNumberForm<any>,
  paymentMethod: PayMethod,
) => {
  const createGenericError = () => {
    form.setFieldError(
      'routingNumber',
      `Routing number is invalid for ${paymentMethod} payments`,
    );
  };

  try {
    const res = await flexbaseBankingClient.checkRoutingNumberUnit(
      form.values.routingNumber,
    );

    if (!res.success || !res.institution) {
      createGenericError();
      return null;
    }

    const routingNumberError = getRoutingNumberErrorByType(res, paymentMethod);

    if (routingNumberError && routingNumberError.length > 0) {
      form.setFieldError('routingNumber', routingNumberError);
      return null;
    }
  } catch (e) {
    createGenericError();
    return null;
  }

  return form.values;
};

type ValidationResults = {
  error: string;
  validationResult: InstitutionResponse | undefined;
  isPending: boolean;
};

const initialValidationResults: ValidationResults = {
  error: '',
  validationResult: undefined,
  isPending: false,
};

export const useValidateRoutingNumber = (
  routingNumber: string,
  paymentMethod: PayMethod,
): ValidationResults => {
  const [validationResults, setValidationResults] = useState<ValidationResults>(
    initialValidationResults,
  );

  const checkRoutingNumberSupport = useCallback(
    async (routingNum: string) => {
      setValidationResults((prev) => ({ ...prev, isPending: true }));
      try {
        const res =
          await flexbaseBankingClient.checkRoutingNumberUnit(routingNum);
        const routingNumberError = getRoutingNumberErrorByType(
          res,
          paymentMethod,
        );
        if (res.success && !routingNumberError) {
          setValidationResults({
            error: '',
            validationResult: res,
            isPending: false,
          });
        } else {
          setValidationResults({
            error: routingNumberError,
            validationResult: res,
            isPending: false,
          });
        }
      } catch (error) {
        setValidationResults({
          error: `Routing number is invalid for ${paymentMethod} payments`,
          validationResult: undefined,
          isPending: false,
        });
      }
    },
    [paymentMethod],
  );

  useEffect(() => {
    if (routingNumber.length === 9) {
      if (!ValidateRoutingNumber(routingNumber)) {
        setValidationResults({
          error:
            'Routing number must be 9 digits and match an existing bank routing number.',
          validationResult: undefined,
          isPending: false,
        });
      } else {
        checkRoutingNumberSupport(routingNumber);
      }
    } else {
      setValidationResults(initialValidationResults);
    }
  }, [routingNumber, checkRoutingNumberSupport]);

  return validationResults;
};
