import { useRecoilState } from 'recoil';
import { ApplicationState } from '../../../../../recoil-state/application/product-onboarding';
import { SummaryEditBox, SummaryEditBoxClassnames } from '../summary-edit-box';
import { useState } from 'react';
import { useForm } from '@mantine/form';
import FlexbaseInput from 'components/input/flexbase-input';
import FlexbaseSelect from '../../../../../components/select/flexbase-select';
import { US_STATES } from '../../../../../constants/constants';
import { flexbaseOnboardingClient } from '../../../../../services/flexbase-client';
import { validateRequired } from '../../../../../utilities/validators/validate-required';
import { validateState } from '../../../../../utilities/validators/validate-state';
import { formatZip } from '../../../../../utilities/formatters/format-address';
import { useOnboardingStyles } from '../../../onboarding.styles';
import { useStyles } from '../summary.styles';
import { validatePostalCode } from '@utilities/validators';
import { AddressFormValues } from '../../address-verification/address-form-types';
import GooglePlacesSuggest from 'components/input/google-places-suggest-input';

type Props = {
  entity: 'user' | 'company';
  classNames?: SummaryEditBoxClassnames;
};
const AddressEditForm = ({ entity, classNames }: Props) => {
  const { classes: onboardingClasses } = useOnboardingStyles();
  const { classes, cx } = useStyles();
  const [{ user, company }, setStatus] = useRecoilState(ApplicationState);
  const [searchAddress, setSearchAddress] = useState(
    entity === 'user' ? user.address.line1 : company.address.line1,
  );

  const addressForm = useForm<AddressFormValues>({
    initialValues: {
      line1:
        (entity === 'user' ? user.address.line1 : company.address.line1) || '',
      line2:
        (entity === 'user' ? user.address.line2 : company.address.line2) || '',
      state:
        (entity === 'user' ? user.address.state : company.address.state) || '',
      postalCode:
        (entity === 'user'
          ? user.address.postalCode
          : company.address.postalCode) || '',
      city:
        (entity === 'user' ? user.address.city : company.address.city) || '',
      country:
        (entity === 'user' ? user.address.country : company.address.country) ||
        '',
    },
    validate: {
      line1: (value) =>
        validateRequired(value) ? null : 'Street address is required',
      state: (value) => (validateState(value) ? null : 'State is required'),
      postalCode: (value) =>
        validatePostalCode(value) ? null : 'Zip is required',
      city: (value) => (validateRequired(value) ? null : 'City is required'),
    },
  });

  const setStreet = (street: string) => {
    setSearchAddress(street);
  };

  const selectAddress = (item: {
    address: string;
    country: string;
    state: string;
    city: string;
    postalCode: string;
  }) => {
    addressForm.setFieldValue('line1', item.address.trim());
    addressForm.setFieldValue('city', item.city);
    addressForm.setFieldValue('state', item.state);
    addressForm.setFieldValue('postalCode', item.postalCode);
  };

  const setZip = (zip: string) => {
    addressForm.setFieldValue('postalCode', formatZip(zip));
  };

  const addressString =
    entity === 'user' ? (
      <div style={{ lineHeight: '26px' }}>
        <div>
          {user.address.line1} {user.address.line2 || ''}
        </div>
        <div>
          {user.address.city} {user.address.state} {user.address.postalCode}
        </div>
      </div>
    ) : (
      <div style={{ lineHeight: '26px' }}>
        <div>
          {company.address.line1} {company.address.line2 || ''}
        </div>
        <div>
          {company.address.city} {company.address.state}{' '}
          {company.address.postalCode}
        </div>
      </div>
    );

  const onSave = async () => {
    const validationResult = addressForm.validate();
    if (validationResult.hasErrors) {
      throw 'Please correct all errors before saving';
    }
    try {
      const address = addressForm.values;
      if (entity === 'user') {
        await flexbaseOnboardingClient.updateUser({
          id: user.id,
          address,
        });
        setStatus((prev) => ({
          ...prev,
          user: {
            ...prev.user,
            address,
          },
        }));
      } else {
        await flexbaseOnboardingClient.updateCompany({
          id: company.id,
          address,
        });
        setStatus((prev) => ({
          ...prev,
          company: {
            ...prev.company,
            address,
          },
        }));
      }
    } catch (e) {
      throw 'An error occurred while trying to update your address.';
    }
  };

  return (
    <SummaryEditBox
      label={entity === 'user' ? 'Address' : 'Business address'}
      value={addressString}
      classNames={classNames}
      onCancelClick={() => addressForm.reset()}
      savePromise={onSave}
      editInputs={
        <div
          className={cx(classes.inputContainer, classes.addressInputContainer)}
        >
          <GooglePlacesSuggest
            label="Street"
            value={searchAddress}
            onChange={(value) => setStreet(value)}
            onItemSubmit={selectAddress}
            placeholder="Address"
            classNames={{ label: onboardingClasses.inputLabel }}
            className={onboardingClasses.bottomSpacing}
            data-sardine-id="input-search-edit-address"
            id="input-search-address"
          />

          <FlexbaseInput
            label="Apt/Unit number"
            data-sardine-id="input-edit-address-line2"
            placeholder="Apt, suite, etc. (Optional)"
            {...addressForm.getInputProps('line2')}
            classNames={{ label: onboardingClasses.inputLabel }}
          />
          <FlexbaseInput
            label="City"
            placeholder="City"
            {...addressForm.getInputProps('city')}
            data-sardine-id="input-edit-city"
            classNames={{ label: onboardingClasses.inputLabel }}
          />
          <FlexbaseSelect
            {...addressForm.getInputProps('state')}
            label="State"
            data-sardine-id="input-edit-state"
            placeholder="State"
            data={US_STATES}
            searchable
            dropdownPosition="bottom"
            maxDropdownHeight={400}
            nothingFound="No data"
            filter={(value, item) => {
              const lowerCaseValue = value.toLowerCase();
              return (
                item.label?.toLowerCase().includes(lowerCaseValue) ||
                item.value.toLowerCase().includes(lowerCaseValue)
              );
            }}
            classNames={{ label: onboardingClasses.inputLabel }}
          />
          <FlexbaseInput
            label="Zip code"
            placeholder="Zip code"
            pattern={'[0-9]*'}
            data-sardine-id="input-edit-postal-code"
            {...addressForm.getInputProps('postalCode')}
            onChange={(e) => setZip(e.target.value)}
            classNames={{ label: onboardingClasses.inputLabel }}
          />
        </div>
      }
    />
  );
};

export default AddressEditForm;
