import { Box, Group, Select, Text } from '@mantine/core';
import { useStyles } from './styles';
import { useState } from 'react';
import { IStatements } from 'types/statements';
import { FlexbaseTable } from '@common/table';
import PdfCell from 'areas/cards/statements/pdf-cell';
import { useMediaQuery } from '@mantine/hooks';
import { TableColumn } from 'react-data-table-component';

const BillPayCreditStatements = () => {
  const { classes, theme, cx } = useStyles();
  // This will be the hook to obtain the data
  // const { data }= useBillpayCreditStatements()
  const useMobileView = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
  const [filterDate, setFilterDate] = useState('');

  // Fake data until official endpoint exists
  const monthlyData = [
    {
      date: 'October',
      target: '2024-10-31',
      after: '2024-10-01',
      before: '2024-10-31',
      type: 'monthly',
    },
    {
      date: 'September',
      target: '2024-09-30',
      after: '2024-09-01',
      before: '2024-09-30',
      type: 'monthly',
    },
    {
      date: 'August',
      target: '2024-08-31',
      after: '2024-08-01',
      before: '2024-08-31',
      type: 'monthly',
    },
    {
      date: 'July',
      target: '2024-07-31',
      after: '2024-07-01',
      before: '2024-07-31',
      type: 'monthly',
    },
    {
      date: 'June',
      target: '2024-06-30',
      after: '2024-06-01',
      before: '2024-06-30',
      type: 'monthly',
    },
  ];

  const columns: TableColumn<IStatements>[] = [
    {
      name: (
        <Text size="13px" fw={500}>
          Month
        </Text>
      ),
      cell: (statement) => {
        return <div>{statement.date}</div>;
      },
      selector: (statement) => statement.target,
      sortable: true,
      width: useMobileView ? 'auto' : '80%',
      grow: 1,
    },
    {
      name: (
        <Text className={classes.pdfTableHeader} size="13px" fw={500}>
          PDF
        </Text>
      ),
      cell: (statement) => {
        return (
          <PdfCell
            companyId={''}
            accountId={''}
            statement={statement}
            hidePdfButton={monthlyData.length === 0}
          />
        );
      },
    },
  ];

  return (
    <Box className={classes.container}>
      <Group position="right" mb="xl">
        <Select
          className={cx(monthlyData.length === 0 && classes.blurryTextStyle)}
          value={filterDate}
          data={['2024']}
          placeholder="Filter by year"
          onChange={(value) => setFilterDate(value!)}
          disabled={monthlyData.length === 0}
        />
      </Group>
      <FlexbaseTable
        data={monthlyData}
        columns={columns}
        noDataComponent={'There are no statements to display'}
        pagination={monthlyData.length > 8}
      />
    </Box>
  );
};

export default BillPayCreditStatements;
