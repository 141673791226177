import { Loader, Text, TextInput } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { useValidateRoutingNumber } from '@utilities/custom-hooks/use-validate-routing-number';
import { PayMethod } from 'areas/payments/components/send-payment/payment.states';

type RoutingNumber = { routingNumber: string };
export type RoutingNumberForm<T extends RoutingNumber> = UseFormReturnType<T>;

type Props<T extends RoutingNumber> = {
  paymentMethod: PayMethod;
  form: RoutingNumberForm<T>;
};

const RoutingNumberInput = <T extends RoutingNumber>({
  paymentMethod,
  form,
}: Props<T>) => {
  const routingNumber = form.values.routingNumber;
  const { error, validationResult, isPending } = useValidateRoutingNumber(
    routingNumber,
    paymentMethod,
  );

  const bankName = validationResult?.institution?.name ?? '';
  return (
    <>
      <TextInput
        mt={'md'}
        label="Routing number"
        placeholder="Enter routing number"
        maxLength={9}
        data-testid="routingNumber"
        rightSection={isPending ? <Loader size="xs" /> : null}
        {...form.getInputProps('routingNumber')}
        error={form.errors.routingNumber ?? error}
      />
      {bankName && !error ? (
        <Text size={'xs'} mt={6}>
          {bankName}
        </Text>
      ) : null}
    </>
  );
};

export default RoutingNumberInput;
