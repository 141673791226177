import { LoadingOverlay } from '@mantine/core';
import { AuthenticationTokenModel } from '@services/platform/models/authorize.models';
import {
  retrieveTokenFromLocalStorage,
  storeToken,
} from '@utilities/auth/store-token';
import { usePolling } from '@utilities/polling/polling';
import jwtDecode from 'jwt-decode';
import { DateTime } from 'luxon';
import { createContext, ReactNode, useContext, useMemo, useState } from 'react';

export const KEY_TOKEN_STORAGE = 'fb_token';
export const KEY_USER_EMAIL_STORAGE = 'fb_user_email';
export const KEY_REMEMBER_USER = 'fb_remember_user';
export const KEY_FULL_TOKEN_STORAGE = 'fb_full_token';

class AuthenticatedStorage {
  private _innerStorage: Storage = localStorage; // default to localStorage so it will local auth if they checked rememberMe

  set useSessionStorage(use: boolean) {
    this._innerStorage = use ? sessionStorage : localStorage;
  }

  clear = (): void => this._innerStorage.clear();

  clearAll = (): void => {
    localStorage?.clear();
    sessionStorage.clear();
  };

  getItem = (key: string): string | null => this._innerStorage.getItem(key);

  key = (index: number): string | null => this._innerStorage.key(index);

  removeItem = (key: string): void => this._innerStorage.removeItem(key);

  setItem = (key: string, value: string): void =>
    this._innerStorage.setItem(key, value);
}

const authenticatedStorage = new AuthenticatedStorage();

type UseAuthTokenReturnType = {
  signIn: (
    authentication: AuthenticationTokenModel,
    userData?: { remember: boolean; email: string },
  ) => void;
  clearToken: () => void;
  user: { remember: boolean; email: string | null };
  userIsAuthorized: boolean;
  logout: () => Promise<void>;
};

const AuthContext = createContext<UseAuthTokenReturnType>(
  {} as UseAuthTokenReturnType,
);
type AuthContextProps = { children: ReactNode };
export const AuthProvider = ({ children }: AuthContextProps) => {
  const { clearAllPolls } = usePolling();
  const [authenticationToken, setAuthenticationToken] =
    useState<AuthenticationTokenModel | null>(retrieveTokenFromLocalStorage());
  const [rememberUser, setRememberUser] = useState(
    localStorage?.getItem(KEY_REMEMBER_USER) === 'true',
  );
  const [userEmail, setUserEmail] = useState<string | null>(
    localStorage?.getItem(KEY_USER_EMAIL_STORAGE),
  );
  const [loading, setLoading] = useState(false);

  function clearToken(): void {
    localStorage?.removeItem(KEY_FULL_TOKEN_STORAGE);
    localStorage?.removeItem(KEY_TOKEN_STORAGE);
    setAuthenticationToken(null);
  }

  const signIn = (
    authentication: AuthenticationTokenModel,
    user?: { remember: boolean; email: string },
  ) => {
    storeToken(authentication);
    if (user?.remember) {
      localStorage?.setItem(KEY_REMEMBER_USER, 'true');
      localStorage?.setItem(KEY_USER_EMAIL_STORAGE, user.email);
    }
    setAuthenticationToken(authentication);
    setRememberUser(user?.remember ?? false);
    setUserEmail(user?.email ?? null);
  };

  const logout = async () => {
    const remMe = localStorage.getItem(KEY_REMEMBER_USER);
    const remEmail = localStorage.getItem(KEY_USER_EMAIL_STORAGE);
    setLoading(true);
    clearToken();
    clearAllPolls();
    authenticatedStorage.clear();
    if (remMe === 'true' && remEmail) {
      localStorage?.setItem(KEY_REMEMBER_USER, remMe);
      localStorage?.setItem(KEY_USER_EMAIL_STORAGE, remEmail);
    } else {
      authenticatedStorage.clear();
    }
    sessionStorage.setItem('logout', 'true');
    setTimeout(() => {
      location.reload();
    }, 500);
  };

  const userIsAuthorized = useMemo((): boolean => {
    if (authenticationToken === null) {
      return false;
    }

    try {
      const decodedToken = jwtDecode<{ exp: number }>(
        authenticationToken.access_token,
      );
      const timeStuff = DateTime.fromSeconds(decodedToken.exp);
      return timeStuff > DateTime.now();
    } catch (e) {
      return false;
    }
  }, [authenticationToken]);

  return (
    <AuthContext.Provider
      value={{
        logout,
        signIn,
        userIsAuthorized,
        user: { remember: rememberUser, email: userEmail },
        clearToken,
      }}
    >
      {loading ? <LoadingOverlay visible={true} /> : children}
    </AuthContext.Provider>
  );
};

export const useAuthToken = () => {
  return useContext(AuthContext);
};
