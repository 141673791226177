import {
  Card,
  Text,
  Avatar,
  Flex,
  Box,
  useMantineTheme,
  Paper,
  Divider,
  Stack,
  rem,
  Group,
  Center,
  Loader,
} from '@mantine/core';
import { getInitialsOfNames } from '@utilities/extensions/object';
import {
  DepositAccount,
  PlaidAccount,
} from 'areas/banking/move-funds/move-funds.model';
import { ParsedAccount } from 'areas/payments/pages/recipient-details/types';
import GetPaddedAccountMask from '@utilities/formatters/get-padded-account-mask';
import {
  FiArrowDown,
  FiCreditCard,
  FiDollarSign,
  FiMapPin,
  FiUser,
} from 'react-icons/fi';
import { LineOfCreditSource } from '../amount-source-step/line-of-credit-source';
import { useCreditLines } from '@queries/use-credit-lines';
import { useInvoiceWizard } from '../../invoice-wizard';
import { DateTime } from 'luxon';
import { calculatePaymentArrivalDate } from '@utilities/dates/dates';

type ReviewBodyLocProps = {
  recipientAccount?: ParsedAccount;
  recipientName: string;
  sendFrom?: DepositAccount | PlaidAccount;
  sendOnDate: Date;
  invoiceTotal?: {
    cents: number;
    formatted: string;
  };
  memo: string;
  description?: string;
  isActionDisabled: boolean;
};

export default function ReviewBodyLoc({
  recipientAccount,
  recipientName,
  sendFrom,
  sendOnDate,
  invoiceTotal,
  memo,
  description,
  isActionDisabled,
}: ReviewBodyLocProps) {
  const { data: linesOfCredit, isLoading: isLoadingLinesOfCredit } =
    useCreditLines();
  const { state } = useInvoiceWizard();
  const { paymentInfo: { creditProgramId } = {} } = state;
  const lines = linesOfCredit?.lines || [];
  const theme = useMantineTheme();
  const sendOnDateLabel =
    DateTime.fromJSDate(sendOnDate).toLocaleString(DateTime.DATE_MED) || '';
  const arriveOnDate = calculatePaymentArrivalDate({
    paymentType: recipientAccount?.type ?? 'wire',
    sendOn: sendOnDate,
  });
  const arriveOnDateLabel =
    arriveOnDate.toLocaleString(DateTime.DATE_MED) || '';
  const etaLabel = arriveOnDate.hasSame(DateTime.fromJSDate(sendOnDate), 'day')
    ? 'Same day'
    : '1 business day';

  if (isLoadingLinesOfCredit) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }
  return (
    <>
      <Paper radius="xs" bg="neutral.0" withBorder>
        <Box w="100%" p="xxl">
          <Text c="neutral.7">Payment amount</Text>
          <Text size={40} lh={1} mb="xxs">
            {invoiceTotal?.formatted}
          </Text>
          <Divider />
          <Flex mt="md" gap="md">
            <Stack
              justify="start"
              align="center"
              h="fit-content"
              sx={{
                border: `1px solid ${theme.colors.neutral[3]}`,
                borderRadius: rem(200),
              }}
              px="xxs"
              py="xs"
            >
              <Avatar size="sm">
                <FiDollarSign size={rem(16)} color={theme.colors.neutral[5]} />
              </Avatar>
              <FiArrowDown size={rem(20)} color={theme.colors.neutral[5]} />
              <Avatar size="sm">
                <FiUser size={rem(16)} color={theme.colors.neutral[5]} />
              </Avatar>
            </Stack>
            <Stack>
              <Stack spacing={0}>
                <Text c="neutral.7">You&apos;re sending</Text>
                <Text size="xl" c="neutral.9" lh={1} weight="500">
                  {invoiceTotal?.formatted} USD
                </Text>
              </Stack>
              <Stack spacing={0}>
                <Text c="neutral.7">to</Text>
                <Text size="xl" c="neutral.9" lh={1} weight="500">
                  {recipientName}
                </Text>
                {description && <Text c="neutral.7">for {description}</Text>}
              </Stack>
            </Stack>
          </Flex>
          <Box
            mt="md"
            bg="neutral.2"
            px="lg"
            py="sm"
            sx={{ borderRadius: rem(200) }}
          >
            <Group position="apart">
              <Stack spacing="xxs">
                <Text size="sm" c="neutral.7" lh={1}>
                  Sending on
                </Text>
                <Text c="neutral.9" weight="500" lh={1}>
                  {sendOnDateLabel}
                </Text>
              </Stack>
              <Stack spacing="xxs" align="center">
                <Text size="sm" c="neutral.7" lh={1}>
                  ETA
                </Text>
                <Text size="sm" c="neutral.9" lh={1}>
                  {etaLabel}
                </Text>
              </Stack>
              <Stack spacing="xxs">
                <Text size="sm" c="neutral.7" lh={1}>
                  Should arrive
                </Text>
                <Text c="neutral.9" weight="500" lh={1}>
                  {arriveOnDateLabel}
                </Text>
              </Stack>
            </Group>
          </Box>
        </Box>
      </Paper>

      <Text mt="xl" weight="500" size="xl">
        Recipient details
      </Text>
      <Card p="xl" withBorder mt="md">
        <Stack>
          <Flex align="center">
            <Avatar mr="sm" size={rem(32)}>
              <Text c="neutral.6" size="xs" weight="500">
                {getInitialsOfNames(recipientName)?.slice(0, 2).toUpperCase()}
              </Text>
            </Avatar>
            <Stack spacing={0}>
              <Text c="neutral.9" weight="500" lh={1}>
                {recipientName}
              </Text>
            </Stack>
          </Flex>
          {recipientAccount?.type === 'wire' && recipientAccount?.address && (
            <Stack spacing="xxs">
              <Text size="sm" c="neutral.7">
                Recipient address
              </Text>
              <Flex align="center">
                <Avatar mr="sm" size={rem(32)}>
                  <FiMapPin size={rem(16)} color={theme.colors.neutral[5]} />
                </Avatar>
                <Stack spacing={rem(2)}>
                  <Text size="sm" lh={1}>
                    {recipientAccount?.address?.address}
                    {recipientAccount?.address?.address2
                      ? `, ${recipientAccount?.address?.address2}`
                      : null}
                  </Text>
                  <Text size="sm" lh={1}>
                    {recipientAccount?.address?.city},{' '}
                    {recipientAccount?.address?.state}{' '}
                    {recipientAccount?.address?.postalCode}
                  </Text>
                </Stack>
              </Flex>
            </Stack>
          )}
          <Stack spacing="xxs">
            <Text size="sm" c="neutral.7">
              Payment method
            </Text>
            <Flex align="center">
              <Avatar mr="sm" size={rem(32)}>
                <FiCreditCard size={rem(16)} color={theme.colors.neutral[5]} />
              </Avatar>
              <Stack spacing={rem(2)}>
                <Text size="sm" lh={1}>
                  {recipientAccount?.type.toUpperCase()}
                </Text>
                <Text size="sm" lh={1}>
                  Account{' '}
                  {GetPaddedAccountMask(
                    recipientAccount?.accountNumber ?? '',
                    2,
                  )}{' '}
                  | Routing{' '}
                  {GetPaddedAccountMask(
                    recipientAccount?.routingNumber ?? '',
                    2,
                  )}
                </Text>
              </Stack>
            </Flex>
          </Stack>
        </Stack>
      </Card>

      <Box mt="xxl">
        <Text mt="xl" weight="500" mb="md" size="xl">
          Transferring from
        </Text>
        <LineOfCreditSource
          invoiceTotal={invoiceTotal?.cents ?? 0}
          isActionDisabled={isActionDisabled}
          sendFrom={sendFrom}
          paymentAccounts={[]}
          onAccountChange={() => null}
          creditPrograms={lines}
          creditProgramId={creditProgramId}
          onCreditProgramChange={() => null}
        />

        <Text mt="xxl" weight="500" size="xl">
          Memo for recipient
        </Text>
        <Text w="80%">{memo}</Text>
      </Box>
    </>
  );
}
