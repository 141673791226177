import { ReactNode } from 'react';
import NavTabs, { NavTab } from './nav-tabs';
import { createStyles } from '@mantine/core';

type Props = {
  children: ReactNode;
  tabs: NavTab[];
  onTabChange?: (tab: NavTab) => void;
};

export const NavTabContainer = ({ children, tabs, onTabChange }: Props) => {
  const { classes } = useStyles();

  return (
    <>
      <NavTabs tabs={tabs} onTabChange={onTabChange} />
      <div className={classes.contentContainer}>{children}</div>
    </>
  );
};

const useStyles = createStyles((theme) => ({
  contentContainer: {
    width: '100%',
    marginTop: theme.spacing.md,
    marginBottom: theme.spacing.md,
  },
}));
