import { useEffect, useState } from 'react';
import {
  PlaidLinkOnSuccess,
  PlaidLinkOnSuccessMetadata,
  PlaidLinkOnExit,
  PlaidLinkOnExitMetadata,
  PlaidLinkError,
  PlaidLinkOptions,
  PlaidLinkStableEvent,
  usePlaidLink,
} from 'react-plaid-link';
import flexbaseClient from 'services/flexbase-client';
import { atom, useRecoilValue } from 'recoil';
import PlaidContext from '../../providers/plaid-context';
import { showNotification } from '@mantine/notifications';
import { platformClient } from '@services/platform/platform-client';

type PlaidDetails = {
  oauth: boolean;
  callbackUri: string;
};

type PlaidBankingComponentProps = {
  onSuccess(): Promise<void>;
  onError(): void;
  setLoading(loading: boolean): void;
  receivedRedirectUri?: string;
};
const PlaidState = atom<PlaidDetails>({
  key: 'plaid-state',
  default: { oauth: false, callbackUri: '' },
});

export const onSuccess = async () => {
  showNotification({
    color: 'flexbase-teal',
    title: 'Plaid Link successful!',
    message: 'Plaid is now linked to fund your new Flex deposit account.',
  });
};

export const onError = () => {
  showNotification({
    color: 'red',
    title: 'Error linking Plaid',
    message:
      'Plaid linking is unavailable at this time, please try again later!',
  });
};

export const usePlaidBankingComponent = ({
  onSuccess: onSuccessFn,
  onError: onErrorFn,
  setLoading,
}: PlaidBankingComponentProps) => {
  const [token, setToken] = useState<string | null>(null);
  const [isOauth, setIsOauth] = useState(true);
  const plaidState = useRecoilValue(PlaidState);
  const [receivedRedirectUri, setReceivedRedirectUri] = useState('');

  useEffect(() => {
    if (plaidState?.oauth && plaidState?.callbackUri) {
      setIsOauth(true);
      setReceivedRedirectUri(plaidState.callbackUri);
    } else {
      setIsOauth(false);
    }
  }, [plaidState]);

  const _onSuccess: PlaidLinkOnSuccess = (
    publicToken: string,
    metadata: PlaidLinkOnSuccessMetadata,
  ) => {
    setLoading(true);
    PlaidContext.clearPlaidContext();
    flexbaseClient
      .exchangePlaidPublicToken(publicToken, metadata)
      .then((response) => {
        setLoading(false);
        response.success ? onSuccessFn() : onErrorFn();
      });
  };

  const _onExit: PlaidLinkOnExit = (
    error: PlaidLinkError | null,
    metadata: PlaidLinkOnExitMetadata,
  ) => {
    if (error != null) {
      platformClient.createPlaidOnExitRecord({
        metadata: {
          status: metadata.status,
          linkSessionId: metadata.link_session_id,
          requestId: metadata.request_id,
          institution: {
            institutionId: metadata?.institution?.institution_id ?? '',
            name: metadata?.institution?.name ?? '',
          },
        },
        error: {
          errorCode: error.error_code,
          errorMessage: error.error_message,
          errorType: error.error_type,
          displayMessage: error.display_message,
        },
      });
    }
    setLoading(false);
  };

  const initPlaid = async () => {
    if (!token) {
      const linkToken = await flexbaseClient.getPlaidLinkToken();
      setToken(linkToken);
    }
  };

  const _onEvent = (eventName: PlaidLinkStableEvent | string) => {
    if (['ERROR', 'EXIT', 'FAIL_OAUTH'].includes(eventName)) {
      onError();
    }
  };

  const config: PlaidLinkOptions = {
    onSuccess: _onSuccess,
    onEvent: _onEvent,
    onExit: _onExit,
    token,
    receivedRedirectUri,
  };

  const { open, ready, error: linkError } = usePlaidLink(config);

  useEffect(() => {
    initPlaid();
  }, []);

  useEffect(() => {
    if (isOauth && ready && token) {
      open();
    }
  }, [token, isOauth, ready]);

  return { open, linkError, ready, isOauth };
};
