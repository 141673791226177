import {
  CreateSpendPlanFrequencyEnum,
  SpendPlanAdminView,
  SpendPlanAdminViewFrequencyEnum,
} from '@flexbase-eng/types/dist/accounting';
import { SpendPlanFormState } from '../create/create-spend-plan.wizard';
import { SpendPlanMemberFields } from '../create/steps/team-members/team-members-step.context';

const FREQUENCY_MAP: Record<
  SpendPlanAdminViewFrequencyEnum,
  CreateSpendPlanFrequencyEnum
> = {
  [SpendPlanAdminViewFrequencyEnum.Onetime]:
    CreateSpendPlanFrequencyEnum.Onetime,
  [SpendPlanAdminViewFrequencyEnum.Daily]: CreateSpendPlanFrequencyEnum.Daily,
  [SpendPlanAdminViewFrequencyEnum.Weekly]: CreateSpendPlanFrequencyEnum.Weekly,
  [SpendPlanAdminViewFrequencyEnum.Monthly]:
    CreateSpendPlanFrequencyEnum.Monthly,
  [SpendPlanAdminViewFrequencyEnum.Quarterly]:
    CreateSpendPlanFrequencyEnum.Quarterly,
  [SpendPlanAdminViewFrequencyEnum.Annually]:
    CreateSpendPlanFrequencyEnum.Annually,
};

function parseMembers(spendPlan: SpendPlanAdminView) {
  const allMembers: SpendPlanMemberFields[] = [];

  spendPlan.managers?.filter(Boolean).forEach((manager) => {
    allMembers.push({
      isManager: true,
      id: manager.personId,
      firstName: manager.personName,
      lastName: '',
      email: manager.personEmail,
      fullFundsAccess: manager.fullFundAccess,
      repeatFundsAccess: manager.repeatFundAccess,
      currentAssigned: manager.initialLimit,
      assigned: manager.futureLimit,
    });
  });

  spendPlan.members?.filter(Boolean).forEach((member) => {
    allMembers.push({
      isManager: false,
      id: member.personId,
      firstName: member.personName,
      lastName: '',
      email: member.personEmail,
      fullFundsAccess: member.fullFundAccess,
      repeatFundsAccess: member.repeatFundAccess,
      currentAssigned: member.initialLimit,
      assigned: member.futureLimit,
    });
  });

  return allMembers;
}

export function mapSpendPlanToFormState(
  spendPlan: SpendPlanAdminView,
): SpendPlanFormState {
  const expirationDate: string | undefined = spendPlan.expirationDate;
  const expires = !!expirationDate;

  const currentLimit = spendPlan.initialLimit;
  const futureLimit = spendPlan.limit;

  const restrictAccountingCategories =
    !!spendPlan.allowedRutterCategories.length;
  const merchantCategoryRestrictionType = spendPlan.blockedMccs.length
    ? 'blacklist'
    : spendPlan.allowedMccs.length
      ? 'whitelist'
      : null;
  const restrictMerchantCategories = !!merchantCategoryRestrictionType;

  return {
    name: spendPlan.name,
    description: spendPlan.description,
    recurring:
      spendPlan.frequency === SpendPlanAdminViewFrequencyEnum.Onetime
        ? 'onetime'
        : 'recurring',
    frequency: FREQUENCY_MAP[spendPlan.frequency],
    expires,
    expirationDate: spendPlan.expirationDate
      ? new Date(spendPlan.expirationDate)
      : null,
    currentLimit,
    futureLimit,
    repeatCurrentLimit: currentLimit === futureLimit,
    managers: false,
    members: parseMembers(spendPlan),
    restrictAccountingCategories,
    restrictMerchantCategories,
    accountingCategories: new Set(spendPlan.allowedRutterCategories),
    merchantCategoryBlacklist: new Set(spendPlan.blockedMccs),
    merchantCategoryWhitelist: new Set(spendPlan.allowedMccs),
    merchantCategoryRestrictionType,
    receiptPolicy: 'none', // TODO: need to get receipt policy from spend plan
    receiptThreshold: '', // TODO: need to get receipt threshold from spend plan
  };
}
