import { Anchor, Box, Text } from '@mantine/core';
import { useStartScreenStyles } from './styles';
import { useMediaQuery } from '@mantine/hooks';
import { CreditBankingContentSm } from './credit-banking/credit-banking-content-sm';
import { CreditBankingContentLg } from './credit-banking/credit-banking-content-lg';
import { useNavigate } from 'react-router-dom';
import { FlexbaseFooter } from '@common/footer/flexbase-footer';
import FlexIconLink from '@common/icons/flex-icon-link';
import { CreditBankingFooter } from 'areas/onboarding-v2/credit-banking.footer';
import { useRecoilValue } from 'recoil';
import { RegistrationProductsState } from '../../../../recoil-state/application/product-onboarding';

export const CreditAndBankingStart = () => {
  const { classes } = useStartScreenStyles();
  const navigate = useNavigate();
  const useSmallContent = useMediaQuery('(max-width: 767px)');
  const product = useRecoilValue(RegistrationProductsState);
  const isBankingOnly = product.every((p) => p === 'BANKING');

  return (
    <div>
      <Box
        sx={(t) => ({
          minHeight: '100vh',
          backgroundColor: t.fn.primaryColor(),
          width: '100%',
          color: t.fn.themeColor('neutral', 0),
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundImage: `url(/images/page-top-frame.png)`,
        })}
      >
        <div className={classes.headerContainer}>
          <FlexIconLink width={112} />
          <Text>
            Already have an account?{' '}
            <Anchor
              style={{ color: '#BDF3D2' }}
              onClick={() => navigate('/login')}
              data-testid="button-login"
            >
              Login.
            </Anchor>
          </Text>
        </div>
        {useSmallContent ? (
          <CreditBankingContentSm />
        ) : (
          <CreditBankingContentLg />
        )}
      </Box>
      {isBankingOnly ? (
        <FlexbaseFooter
          footerMarginTop="0"
          disclaimer2={false}
          classNames={{
            footerDiscloure: classes.footerDisclosure,
            footer: classes.footerContainer,
          }}
        />
      ) : (
        <CreditBankingFooter className={classes.footerContainer} />
      )}
    </div>
  );
};
