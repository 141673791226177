import { CreditCard } from '@services/flexbase/card.model';
import {
  useChargeCardsModalFilters,
  useCreditCardsModalFilters,
} from '../use-credit-cards-filters';
import { useMemo } from 'react';
import { uniq } from 'underscore';
import { MultiSelect } from '@mantine/core';
import { ActiveFiltersReturnType } from '@common/filter/filters';
import { CardTableRow } from '../../card-table-row.model';

type Props = {
  cards: CreditCard[];
};

type CardNameFilterProps = Props & {
  filterHook: ActiveFiltersReturnType<CardTableRow>;
};

const CardNameFilter = ({ cards, filterHook }: CardNameFilterProps) => {
  const { addFilter, removeFilter, getFilterByKey } = filterHook;

  const cardNames = useMemo(() => {
    return uniq(cards.map((d) => d.cardName).filter((name) => !!name));
  }, [cards]);

  return (
    <MultiSelect
      data={cardNames}
      placeholder="Card name"
      searchable
      nothingFound="Nothing found"
      value={getFilterByKey('name')?.filterValue || []}
      onChange={(val) => {
        if (val.length > 0) {
          addFilter('name', {
            key: 'name',
            filterValue: val,
            fn: (row) => val.includes(row.cardName || ''),
            label: `Card name: ${val.join(', ')}`,
            showChip: true,
          });
        } else {
          removeFilter('name');
        }
      }}
    />
  );
};

export const CreditCardNameFilter = ({ cards }: Props) => {
  const filterHook = useCreditCardsModalFilters();

  return <CardNameFilter cards={cards} filterHook={filterHook} />;
};

export const ChargeCardNameFilter = ({ cards }: Props) => {
  const filterHook = useChargeCardsModalFilters();

  return <CardNameFilter cards={cards} filterHook={filterHook} />;
};
