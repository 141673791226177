import FlagIcon from 'areas/payments/components/send-payment/flag-icon';
import { Box, Select, SelectProps } from '@mantine/core';
import { ComponentPropsWithoutRef, forwardRef } from 'react';
import {
  getCurrencyFlag,
  supportedCurrenciesData,
} from '../international-payments/util';
import {
  AvailableCurrencies,
  CurrencySelectProps,
} from '../international-payments/util/types';

type SelectItemProps = ComponentPropsWithoutRef<'div'> & CurrencySelectProps;

const SelectItem = forwardRef<HTMLDivElement, SelectItemProps>(
  ({ label, value, code, ...others }: SelectItemProps, innerRef) => (
    <div ref={innerRef} {...others}>
      <Box
        sx={() => ({
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
        })}
      >
        <Box>{label}</Box>
        <Box>
          <FlagIcon flagNationCode={code} />
        </Box>
      </Box>
    </div>
  ),
);

SelectItem.displayName = 'SelectItem';

type Props = {
  value: AvailableCurrencies;
} & Partial<SelectProps>;

const CurrencySelect = ({ ...props }: Partial<Props>) => {
  return (
    <Select
      {...props}
      data={supportedCurrenciesData}
      label="Currency"
      placeholder="Choose a currency"
      searchable
      id="currency-dropdown"
      rightSection={
        <FlagIcon
          width="1rem"
          height="1rem"
          flagNationCode={getCurrencyFlag(props.value!)}
        />
      }
      itemComponent={SelectItem}
    />
  );
};

export default CurrencySelect;
