import { useForm } from '@mantine/form';
import { useState, useRef, useEffect } from 'react';
import { Alert, Button, Group, Select, TextInput, Box } from '@mantine/core';
import { RedAlertIcon } from 'assets/svg';
import { useStyles } from './debit-card-details.styles';
import { Limits, UpdateCardRequest } from 'services/flexbase/banking.model';
import { formatCurrency } from 'utilities/formatters';
import { checkATMWithdrawalLimits, checkSpendingLimits } from '../util';
import type { Tier } from 'areas/banking/move-funds/move-funds.model';
import { formatNumberStringWithDecimals } from 'utilities/formatters/format-strings';
import TwoFactorAuthSection from '../two-factor/two-factor-auth-section';
import { useUpdateDebitCard } from '@queries/use-debit-cards';
import {
  CardExpensesTypes,
  cardIsChargeCard,
  FlexCard,
} from '@services/flexbase/card.model';
import Multiselect from 'areas/cards/credit-cards/card-details/custom-multiselect';
import { useUpdateCard } from '@queries/use-credit-cards';

/*
TODO: This type is a hack to get the type of the expensesTypes object to work.

It's not ideal, but it works for now, and we should prob consider a better
way both at the UI and api level around how we're going to unify the
concept of 'cards' considering the most recent Unit charge cards work.
*/
type UpdateCardParams = {
  id: string;
  card: {
    cardName: string | undefined;
    expensesTypes: CardExpensesTypes & Limits;
    notifyUse: boolean | undefined;
    terminateAt: string | null;
  };
};

type Props = {
  cardData: any;
  setCardData: (x: any) => void;
  setEditCard: (x: boolean) => void;
  accountTier: Tier;
  atmWithdrawalLimit: number;
};

const UpdateDebitOrChargeCard = ({
  cardData,
  setCardData,
  setEditCard,
  accountTier,
  atmWithdrawalLimit,
}: Props) => {
  const { classes } = useStyles();
  const buttonRef = useRef<HTMLDivElement>(null);
  const [updateCusToken, setUpdateCusToken] = useState(false);
  const {
    mutate: updateDebitCard,
    isPending: updateDebitCardPending,
    error: updateDebitCardError,
  } = useUpdateDebitCard();
  const { mutate: updateCreditCard, isPending: isCreditCardPending } =
    useUpdateCard();

  const isPending = updateDebitCardPending || isCreditCardPending;

  const isChargeCard = cardIsChargeCard(cardData as unknown as FlexCard);

  const errorMessages = [
    'a Customer Token is required',
    'Bearer token is invalid or expired',
  ];
  const needsUpdateToken = (errorMsg: string) =>
    errorMessages.some((message) => errorMsg.includes(message));

  const { limit, cardName, limitType, expensesTypes } = cardData;

  const form = useForm({
    initialValues: {
      amount: limit ? limit / 100 : 0,
      cardName,
      limitType,
      withdrawalLimit: atmWithdrawalLimit,
      groups: expensesTypes?.groups ?? [],
    },
  });

  const onSpendingLimitInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    form.setFieldValue(
      'amount',
      Number(formatNumberStringWithDecimals(e.currentTarget.value)),
    );
  };

  const onAtmWithdrawalInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    form.setFieldValue(
      'withdrawalLimit',
      Number(formatNumberStringWithDecimals(e.currentTarget.value)),
    );
  };

  const getDebitCardLimits = (values: typeof form.values) => {
    const { limitType: currentLimitType, amount, withdrawalLimit } = values;
    const cardLimits: Limits = {};

    if (withdrawalLimit !== atmWithdrawalLimit) {
      cardLimits.dailyWithdrawal = Math.round(withdrawalLimit * 100);
    }

    switch (currentLimitType) {
      case 'daily':
        cardLimits.dailyPurchase = Math.round(amount * 100);
        break;
      case 'monthly':
        cardLimits.monthlyPurchase = Math.round(amount * 100);
        break;
      default:
        break;
    }

    return cardLimits;
  };

  const updateCard = () => {
    const newCardName = [null, ''].includes(form.values.cardName)
      ? null
      : form.values.cardName;

    if (isChargeCard) {
      const updateCardParams: UpdateCardParams = {
        id: cardData.id,
        card: {
          cardName: newCardName,
          expensesTypes: {
            amount:
              form.values.limitType === 'unlimited'
                ? 0
                : Number(form.values.amount),
            groups: form.values.groups,
            interval:
              form.values.limitType === 'unlimited'
                ? null
                : form.values.limitType,
          },
          notifyUse: undefined,
          terminateAt: null,
        },
      };

      if (form.values.limitType !== 'unlimited') {
        const purchaseTypeKey =
          form.values.limitType === 'daily'
            ? 'dailyPurchase'
            : 'monthlyPurchase';

        updateCardParams.card.expensesTypes[purchaseTypeKey] = Math.round(
          form.values.amount * 100,
        );
      }

      updateCreditCard(updateCardParams, {
        onSuccess: (data) => {
          const { card } = data;
          setCardData({
            ...card,
            limit: form.values.amount,
            cardName: form.values.cardName,
            limitType: form.values.limitType,
          });
          setEditCard(false);
        },
        onError: (err) => {
          console.error('Unable to update the card info', err);
          const { error: errorMsg = '' } = JSON.parse(err.message);
          if (needsUpdateToken(errorMsg)) {
            setUpdateCusToken(true);
          }
        },
      });
    } else {
      const updateDebitCardParams: UpdateCardRequest = {
        id: cardData.id,
        cardName: newCardName,
        limits: getDebitCardLimits(form.values),
      };

      updateDebitCard(updateDebitCardParams, {
        onSuccess: (data) => {
          const { card } = data;
          setCardData({
            ...card,
            limit: form.values.amount,
            cardName: form.values.cardName,
            limitType: form.values.limitType,
            groups: form.values.groups,
          });
          setEditCard(false);
        },
        onError: (err) => {
          console.error('Unable to update the card info', err);
          const { error: errorMsg = '' } = JSON.parse(err.message);
          if (needsUpdateToken(errorMsg)) {
            setUpdateCusToken(true);
          }
        },
      });
    }
  };

  useEffect(() => {
    if (buttonRef.current) {
      buttonRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  return (
    <div className={classes.padding}>
      <TextInput
        label="Card name"
        placeholder="Card name"
        value={form.values.cardName}
        onChange={(event) => {
          form.setFieldValue('cardName', event.currentTarget.value);
        }}
      />
      {!(isChargeCard || cardData.cardType === 'VIRTUAL') && (
        <TextInput
          mt="sm"
          value={formatCurrency(form.values.withdrawalLimit || '0')}
          onChange={onAtmWithdrawalInputChange}
          label={'Daily ATM withdrawal limit'}
          error={checkATMWithdrawalLimits(
            form.values.withdrawalLimit,
            accountTier,
          )}
        />
      )}

      {isChargeCard && (
        <Box mt="sm">
          <Multiselect form={form} />
        </Box>
      )}

      <Select
        mt="sm"
        label="Spending limit type"
        value={form.values.limitType}
        data={[
          { label: 'Daily', value: 'daily' },
          { label: 'Monthly', value: 'monthly' },
          { value: 'unlimited', label: 'Unlimited' },
        ]}
        onChange={(value) => {
          form.setFieldValue('limitType', value);
        }}
      />

      {form.values.limitType !== 'unlimited' && (
        <TextInput
          mt="sm"
          value={formatCurrency(form.values.amount || '0')}
          onChange={onSpendingLimitInputChange}
          label={`${
            form.values.limitType === 'daily' ? 'Daily' : 'Monthly'
          } spending limit`}
          error={
            !isChargeCard &&
            checkSpendingLimits(
              form.values.amount,
              form.values.limitType,
              accountTier,
            )
          }
        />
      )}
      {/* do not display error message if we ask for 2FA */}
      {updateDebitCardError &&
        !needsUpdateToken(updateDebitCardError.message) && (
          <Alert color="red" mt="lg" icon={<RedAlertIcon />}>
            Unable to update the card. Please, try it again.
          </Alert>
        )}

      {updateCusToken && (
        <Box mt="sm">
          <TwoFactorAuthSection onSuccess={updateCard} />
        </Box>
      )}

      <Group mt="lg" position="right" ref={buttonRef}>
        <Button variant="outline" onClick={() => setEditCard(false)}>
          Cancel
        </Button>
        <Button variant="light" loading={isPending} onClick={updateCard}>
          Save
        </Button>
      </Group>
    </div>
  );
};

export default UpdateDebitOrChargeCard;
