import { useExpenseLinks } from '@queries/use-integrations';
import { useRecoilValue } from 'recoil';
import { IsIntegrationsAuthorized } from 'recoil-state/integrations/integrations';
import { isQuickbooksBankFeeds } from '.';

/**
 * @returns true if user is authorized to sync expenses with integrations
 */
export function useSyncExpenseAllowed() {
  const isSyncAuthorized = useRecoilValue(IsIntegrationsAuthorized);

  return isSyncAuthorized;
}

/**
 * Helper method to wrap common logic to get the currently active expense link and its connectionId.
 */
export function useActiveExpenseLink() {
  const isAuthorized = useRecoilValue(IsIntegrationsAuthorized);
  const { data: expenseLinks, isPending } = useExpenseLinks(isAuthorized);
  const expenseLink = expenseLinks?.find(
    (link) => link.isReady && !isQuickbooksBankFeeds(link),
  );
  const connectionId = expenseLink?.connectionId;

  return {
    /**
     * The currently active expense link.
     */
    expenseLink,

    /**
     * List of all expense links returned from Platform.
     */
    expenseLinks,

    /**
     * Connection ID for the active expense link, or empty string if no active expense link.
     */
    connectionId,

    /**
     * True if the expenseLinks query is pending.
     */
    isPending,
  };
}
