import {
  Query,
  queryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { platformClient } from '../services/platform/platform-client';
import {
  AccountingSettings,
  Expenses,
  PatchAccountingCoaRequestAccountsInner,
  SyncedExpenses,
  Transactions,
} from '@flexbase-eng/types/dist/accounting';
import { updateQueryData } from './helpers';
import { showNotification } from '@mantine/notifications';
import { logger } from '@sentry/utils';

const LINKS_QUERY_KEY = 'integration_links';
const CONNECTION_LINKS_QUERY_KEY = 'connection_expense_links';

async function getExpenseLinks() {
  return (await platformClient.getExpenseLinks()).links;
}

async function getAdsLinks() {
  return (await platformClient.getAdsLinks()).links;
}

async function getCommerceLinks() {
  return (await platformClient.getCommerceLinks()).links;
}

export function expenseLinksQueryOptions(enabled: boolean) {
  return queryOptions({
    queryKey: [LINKS_QUERY_KEY, 'accounting'],
    queryFn: () => getExpenseLinks(),
    enabled,
    meta: {
      errorMessage: 'Could not retrieve accounting integrations at this time.',
    },
  });
}

export function adsLinksQueryOptions(isAuthorized: boolean) {
  return queryOptions({
    queryKey: [LINKS_QUERY_KEY, 'ads'],
    queryFn: () => getAdsLinks(),
    enabled: !!isAuthorized,
    meta: {
      errorMessage: 'Could not retrieve ads integrations at this time.',
    },
  });
}

export function commerceLinksQueryOptions(isAuthorized: boolean) {
  return queryOptions({
    queryKey: [LINKS_QUERY_KEY, 'commerce'],
    queryFn: () => getCommerceLinks(),
    enabled: !!isAuthorized,
    meta: {
      errorMessage: 'Could not retrieve eCommerce integrations at this time.',
    },
  });
}

export function useExpenseLinks(isAuthorized: boolean) {
  return useQuery(expenseLinksQueryOptions(isAuthorized));
}

export function useAdsLinks(isAuthorized: boolean) {
  return useQuery(adsLinksQueryOptions(isAuthorized));
}

export function useCommerceLinks(isAuthorized: boolean) {
  return useQuery(commerceLinksQueryOptions(isAuthorized));
}

export const useExpenseLinksByConnection = (
  connectionId: string,
  enabled: boolean,
) => {
  return useQuery({
    queryKey: [CONNECTION_LINKS_QUERY_KEY, connectionId],
    queryFn: async () => {
      return await platformClient.getChartOfAccountsByConnectionId(
        connectionId,
      );
    },
    enabled,
    meta: {
      errorMessage: `Unable to retrieve expense links for category : ${connectionId}`,
    },
  });
};

const mutationUpdateExpenseLinksByCategory = async (params: {
  categories: PatchAccountingCoaRequestAccountsInner[];
  connectionId: string;
}) => {
  return await platformClient.updateExpenseLinksByCategory(
    params.categories,
    params.connectionId,
  );
};

export function useUpdateChartOfAccountsByCategoryId() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: mutationUpdateExpenseLinksByCategory,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [CONNECTION_LINKS_QUERY_KEY],
      });
      showNotification({
        color: 'flexbase-teal',
        title: 'Success',
        message: 'Saved mapping preferences.',
      });
    },
    onError: (error, variables) => {
      showNotification({
        color: 'red',
        title: 'Error',
        message: `${error}`,
      });
      logger.error(
        `Unable save mappings for connection id ${variables.connectionId} : `,
        error,
      );
    },
  });
}

function deleteExpenseLink(params: { connectionId: string }) {
  return platformClient.deleteExpenseLink(params.connectionId);
}

export function useDeleteExpenseLink() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteExpenseLink,
    onSuccess: () => {
      // TODO: remove setTimeout once race condition is fixed
      // backend has a race condition where GET /links returns
      // stale data if we fetch it too soon after disconnecting
      setTimeout(() => {
        queryClient.invalidateQueries({
          queryKey: [LINKS_QUERY_KEY],
        });
      }, 500);
    },
  });
}

async function exchangeIntegrationToken(publicToken: string) {
  return await platformClient.exchangeIntegrationToken({ publicToken });
}

export function useExchangeIntegrationTokenMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: exchangeIntegrationToken,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [LINKS_QUERY_KEY],
      });
    },
  });
}

const SYNCED_TRANSACTIONS_QUERY_KEY = 'synced_transactions';

async function querySyncedTransactions(
  connectionId: string,
  transactionIds: string[],
  enabled: boolean,
) {
  if (!enabled || !connectionId || transactionIds.length === 0) {
    return {
      accounts: { type: 'Accounts', items: [] },
      expenses: [],
    };
  }

  return await platformClient.querySyncedTransactions(
    connectionId,
    transactionIds,
  );
}

type UseSyncedTransactionsQueryProps<T> = {
  enabled?: boolean;
  connectionId: string;
  transactionIds: string[];
  refetchInterval?:
    | ((query: Query<SyncedExpenses>) => number | false)
    | number
    | false;
  updateCache?: boolean;
  select?: (expenses: SyncedExpenses) => T;
};

export function useSyncedTransactionsQuery<T = SyncedExpenses>(
  props: UseSyncedTransactionsQueryProps<T>,
) {
  const {
    enabled,
    connectionId,
    transactionIds,
    refetchInterval,
    select,
    updateCache,
  } = props;

  const queryClient = useQueryClient();

  return useQuery({
    queryKey: [
      SYNCED_TRANSACTIONS_QUERY_KEY,
      connectionId,
      {
        transactionIds,
        enabled,
        updateCache,
      },
    ],
    enabled,
    refetchInterval,
    queryFn: async () => {
      const data = await querySyncedTransactions(
        connectionId,
        transactionIds,
        enabled ?? true,
      );

      if (updateCache) {
        updateQueryData<SyncedExpenses>(
          queryClient,
          [SYNCED_TRANSACTIONS_QUERY_KEY, connectionId],
          (oldData) => {
            // can't just use a spread, also gotta rebuild nested objects to trigger updates
            return oldData
              ? {
                  accounts: oldData.accounts
                    ? {
                        type: oldData.accounts.type,
                        items: oldData.accounts.items.map(
                          ({ ...item }) => item,
                        ),
                      }
                    : undefined,
                  expenses: oldData.expenses.map((e) => {
                    const update = data.expenses.find(
                      (u) => u.transactionId === e.transactionId,
                    );

                    return (
                      update || {
                        ...e,
                        expense: {
                          ...e.expense,
                        },
                      }
                    );
                  }),
                }
              : oldData;
          },
        );
      }

      return data;
    },
    select,
  });
}

async function syncTransactions(params: {
  connectionId: string;
  transactions: Transactions[];
  method: 'post' | 'patch';
}): Promise<Record<string, Expenses>> {
  const results = await platformClient.syncTransactions(
    params.connectionId,
    { transactions: params.transactions },
    params.method,
  );

  return results.transactions.reduce(
    (transactionsMap, transaction) => {
      transactionsMap[transaction.transactionId] = transaction;
      return transactionsMap;
    },
    {} as Record<string, Expenses>,
  );
}

export function useSyncTransactionsMutation() {
  return useMutation({
    mutationFn: syncTransactions,
  });
}

export function useUpdateAccountingSettings() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: {
      connectionId: string;
      request: AccountingSettings;
    }) => {
      return platformClient.updateAccountingSettings(params);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [LINKS_QUERY_KEY, 'accounting'],
      });
    },
  });
}

export const useCreateIntuitFeedConnection = (
  callBack?: (connectionId: string) => void,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      return await platformClient.createBankFeedConnection();
    },
    onSuccess: async (resp) => {
      await queryClient.invalidateQueries({
        queryKey: [LINKS_QUERY_KEY, 'accounting'],
      });

      if (callBack) {
        callBack(resp.connectionId);
      }
    },
  });
};

export const useGenerateBankFeedOtp = () => {
  return useMutation({
    mutationFn: async ({ connectionId }: { connectionId: string }) => {
      return await platformClient.getBankFeedOtp(connectionId);
    },
  });
};

export const useBankFeedOtpPolling = (
  connectionId: string,
  callBack: (otp: string) => void,
  shouldFetch = false,
) => {
  const { data, error, isSuccess } = useQuery({
    queryKey: ['bankFeedOtp', connectionId],
    queryFn: async () => {
      const resp = await platformClient.getBankFeedOtp(connectionId);
      if (resp?.otp) {
        callBack(resp.otp);
      }
      return resp;
    },
    refetchInterval: shouldFetch ? 5000 : false,
    enabled: !!connectionId,
  });

  return { data, error, isSuccess };
};
