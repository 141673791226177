import useModal from '@common/modal/modal-hook';
import { CreditCard } from '@services/flexbase/card.model';
import { isTruthyString } from '@utilities/validators/validate-string';
import { CardsTableHeader } from 'areas/cards/credit-cards/credit-cards-header/credit-cards-table-header';
import { ChargeCardNameFilter } from 'areas/cards/credit-cards/credit-cards-header/filters/card-name.filter';
import { ChargeCardNumberFilter } from 'areas/cards/credit-cards/credit-cards-header/filters/card-number.filter';
import { ChargeCardsAmountSpentFilter } from 'areas/cards/credit-cards/credit-cards-header/filters/card-spent-amounts.filter';
import { ChargeCardStatusFilter } from 'areas/cards/credit-cards/credit-cards-header/filters/card-status.filter';
import { ChargeCardTypeFilter } from 'areas/cards/credit-cards/credit-cards-header/filters/card-type.filter';
import { ChargeCardsCardholderFilter } from 'areas/cards/credit-cards/credit-cards-header/filters/cardholder.filter';
import { CreditCardsDateRangeFilter } from 'areas/cards/credit-cards/credit-cards-header/filters/credit-cards-date-range.filter';
import {
  useChargeCardsFilters,
  useChargeCardsModalFilters,
} from 'areas/cards/credit-cards/credit-cards-header/use-credit-cards-filters';
import IssueChargeCard from './issue-charge-cards';
import { FilterConfig, useFilterModal } from '@common/filter/filter-modal';

type ChargeCardsTableHeader = {
  cardsData: CreditCard[];
};

export const ChargeCardsTableHeader = ({
  cardsData,
}: ChargeCardsTableHeader) => {
  const { openFullModal, closeAllModals } = useModal();

  const filterHook = useChargeCardsFilters();
  const modalFilterHook = useChargeCardsModalFilters();
  const {
    activeFiltersArray,
    removeAllFilters,
    getFilterByKey,
    addFilter,
    removeFilter,
    openResponsiveFilterModal,
  } = useFilterModal({ filterHook, modalFilterHook });

  const searchValue = getFilterByKey('search')?.filterValue || '';

  const filters: FilterConfig[] = [
    {
      key: 'status',
      header: 'Status',
      component: <ChargeCardStatusFilter />,
    },
    {
      key: 'type',
      header: 'Type',
      component: <ChargeCardTypeFilter />,
    },
    {
      key: 'cardholder',
      header: 'Cardholder',
      component: <ChargeCardsCardholderFilter cards={cardsData} />,
    },
    {
      key: 'number',
      header: 'Number',
      component: <ChargeCardNumberFilter cards={cardsData} />,
    },
    {
      key: 'name',
      header: 'Card name',
      component: <ChargeCardNameFilter cards={cardsData} />,
    },
    {
      key: 'spent',
      header: 'Monthly usage',
      component: <ChargeCardsAmountSpentFilter />,
    },
    {
      key: 'date',
      header: 'Last modified',
      component: <CreditCardsDateRangeFilter />,
    },
  ];

  const handleSearchChange = (value: string) => {
    if (!value) {
      removeFilter('search');
      return;
    }

    addFilter('search', {
      key: 'search',
      filterValue: value,
      label: `Includes ${value}`,
      showChip: false,
      fn: (transaction) => {
        const normalizedFilterText = value.toLowerCase().trim();
        return Object.values(transaction)
          .filter(isTruthyString)
          .map((v) => v.toLowerCase())
          .some((v) => v.includes(normalizedFilterText));
      },
    });
  };

  const handleIssueCard = () => {
    openFullModal(<IssueChargeCard closeModal={closeAllModals} />);
  };

  const handleOpenFilters = () => {
    openResponsiveFilterModal(filters);
  };

  const handleRemoveFilter = (filterKey: string) => {
    removeFilter(filterKey);
  };

  const handleClearFilters = () => {
    removeAllFilters();
  };

  return (
    <CardsTableHeader
      issueCardLabel="New charge card"
      filterArray={activeFiltersArray}
      searchValue={searchValue}
      onSearchChange={handleSearchChange}
      onOpenFilters={handleOpenFilters}
      onRemoveFilter={handleRemoveFilter}
      onClearFilters={handleClearFilters}
      onIssueCard={handleIssueCard}
    />
  );
};
