import { useGetUsers } from '@queries/use-users';
import { useBeneficiaries } from './use-beneficiaries';
import { useGetBankingPayments } from '@queries/use-payments';
import {
  useGetInternationalPayments,
  useInternationalPaymentRates,
} from '@queries/use-international-payments';
import { useGetDepositAccounts } from '@queries/use-deposit-accounts';
import { GetMoneyMovementsParams } from '@services/flexbase/banking.model';
import { useBillPayPayments } from 'areas/billpay/use-bill-pay';

export const usePayments = (params?: GetMoneyMovementsParams) => {
  const {
    data: depositAccounts,
    isLoading: isLoadingDepositAccounts,
    error: depositAccountsError,
  } = useGetDepositAccounts();

  const {
    data: payments,
    error,
    isLoading,
    refetch,
  } = useGetBankingPayments(params);
  const {
    data: users,
    error: usersError,
    isLoading: usersIsLoading,
    refetch: usersRefetch,
  } = useGetUsers();
  const {
    data: intlPaymentsData,
    error: intlPaymentsIsError,
    isLoading: intlPaymentsLoading,
    refetch: intlPaymentsRefetch,
  } = useGetInternationalPayments();
  const {
    data: beneficiaries,
    error: beneficiariesError,
    isLoading: isLoadingBeneficiaries,
    refetch: beneficiariesRefetch,
  } = useBeneficiaries();

  const handleRetry = () => {
    refetch();
    usersRefetch();
    intlPaymentsRefetch();
    beneficiariesRefetch();
  };

  const intlPaymentsMapped = intlPaymentsData
    ? intlPaymentsData.payments.map((intlPayment) => {
        const beneficiary = beneficiaries?.find(
          (b) => b.id === intlPayment?.beneficiaryId,
        );
        const currency =
          intlPayment && beneficiary ? beneficiary.currency : 'USD';

        return {
          ...intlPayment,
          currency,
        };
      })
    : [];

  const intlPayments = useInternationalPaymentRates(intlPaymentsMapped);

  const loading =
    isLoadingDepositAccounts ||
    isLoading ||
    usersIsLoading ||
    intlPaymentsLoading ||
    isLoadingBeneficiaries;

  const isError =
    depositAccountsError ||
    error ||
    usersError ||
    intlPaymentsIsError ||
    beneficiariesError;

  return {
    depositAccounts: depositAccounts?.accounts ?? [],
    users: users ?? [],
    payments: payments ?? [],
    beneficiaries: beneficiaries ?? [],
    intlPayments,
    loading,
    isError,
    refetch: handleRetry,
  };
};

export const useBillpayPayments = () => {
  const {
    data: depositAccounts,
    isLoading: isLoadingDepositAccounts,
    error: depositAccountsError,
    refetch: depositRefetch,
  } = useGetDepositAccounts();

  const {
    data: paymentsData,
    error,
    isLoading,
    refetch,
  } = useBillPayPayments();

  const {
    data: users,
    error: usersError,
    isLoading: usersIsLoading,
    refetch: usersRefetch,
  } = useGetUsers();

  const handleRetry = () => {
    refetch();
    usersRefetch();
    depositRefetch();
  };

  const loading = isLoadingDepositAccounts || isLoading || usersIsLoading;

  const isError = depositAccountsError || error || usersError;

  return {
    depositAccounts: depositAccounts?.accounts ?? [],
    users: users ?? [],
    payments: paymentsData.payments ?? [],
    loading,
    isError,
    refetch: handleRetry,
  };
};
