import { Tier } from '../../../../banking/move-funds/move-funds.model';

// hardcoded values from Unit
const DAILY_ACH_CREDIT_LIMITS_BY_ACCOUNT_TIER = {
  0: 250_000,
  1: 250_000,
  2: 1_000_000,
  3: 6_000_000,
  4: 6_000_000,
  // "10" is a placeholder for Flex on Flex's internal high-limit tier,
  // which does not currently fit into the numeric Tier scheme. But it will some day.
  10: Infinity,
};

export const getDailyAchCreditLimitByTier = (tier: Tier) => {
  return DAILY_ACH_CREDIT_LIMITS_BY_ACCOUNT_TIER[tier];
};

export const formatToTwoDecimalPlaces = (val: number | string) => {
  return parseFloat(val.toString()).toFixed(2);
};
